import React, { useState, useCallback, useMemo } from 'react';
import { faFont, faStar, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Tooltip } from '@mui/material';
import { CollapsableMenuGroup, ConfirmDialog } from 'components/Common';
import {
  themeFontsSelector,
  defaultThemeFontsSelector,
  isScriptDisabledSelector,
  agentViewSelector,
} from 'redux/selectors';
import {
  toggleDisableScriptFont,
  changeDefaultThemeFonts,
  changeThemeFonts,
} from 'redux/slices';
import ItemSelectorMenuItem from './ItemSelectorMenuItem';

const scriptFonts = [
  'MS Madi',
  'Rouge Script',
  'Send Flowers',
  'Dancing Script',
  'Clicker Script',
  'Pacifico',
  'Damion',
];

const fontPairings = [
  {
    name: 'Quicksand Medium / Quicksand Regular',
    headerFont: 'Quicksand Medium',
    bodyFont: 'Quicksand Regular',
  },
  {
    name: 'Playfair Display / Hind',
    headerFont: 'Playfair Display',
    bodyFont: 'Hind',
  },
  {
    name: 'Bellota Text / Figtree',
    headerFont: 'Bellota Text',
    bodyFont: 'Figtree',
  },
  {
    name: 'Oswald / Roboto',
    headerFont: 'Oswald',
    bodyFont: 'Roboto',
  },
  {
    name: 'Quattrocento / Open Sans',
    headerFont: 'Quattrocento',
    bodyFont: 'Open Sans',
  },
];

const ThemeFontMenuItem = () => {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);

  // Selectors
  const { headerFont, bodyFont, scriptFont } = useSelector(themeFontsSelector);
  const defaultFonts = useSelector(defaultThemeFontsSelector);
  const agentView = useSelector(agentViewSelector);
  const isScriptDisabled = useSelector(isScriptDisabledSelector);

  // Memoized font options
  const fontPairingNames = useMemo(
    () => fontPairings.map((pairing) => pairing.name),
    [],
  );
  const scriptFontsOptions = useMemo(() => scriptFonts, []);

  // Normalize font strings for comparison
  const normalizeFont = (font) => font?.trim().toLowerCase() || '';

  // Find current fontPairing index
  const fontPairingIndex = useMemo(() => {
    return fontPairings.findIndex(
      (pairing) =>
        normalizeFont(pairing.headerFont) === normalizeFont(headerFont) &&
        normalizeFont(pairing.bodyFont) === normalizeFont(bodyFont),
    );
  }, [headerFont, bodyFont]);

  // Find current scriptFont index
  const scriptFontIndex = useMemo(() => {
    return scriptFonts.findIndex(
      (font) => normalizeFont(font) === normalizeFont(scriptFont),
    );
  }, [scriptFont]);

  // Handlers
  const handleSetThemeFonts = useCallback(
    (selectedPairingName) => {
      const selectedPairing = fontPairings.find(
        (pairing) => pairing.name === selectedPairingName,
      );
      if (selectedPairing) {
        dispatch(
          changeThemeFonts({
            headerFont: selectedPairing.headerFont,
            bodyFont: selectedPairing.bodyFont,
            scriptFont,
          }),
        );
      }
    },
    [dispatch, scriptFont],
  );

  const handleSetScriptFont = useCallback(
    (newScriptFont) => {
      dispatch(
        changeThemeFonts({
          headerFont,
          bodyFont,
          scriptFont: newScriptFont,
        }),
      );
    },
    [dispatch, headerFont, bodyFont],
  );

  const handleConfirm = useCallback(() => {
    dispatch(changeDefaultThemeFonts({ headerFont, bodyFont, scriptFont }));
    setShowConfirm(false);
  }, [headerFont, bodyFont, scriptFont, dispatch]);

  const handleToggleDisable = useCallback(() => {
    dispatch(toggleDisableScriptFont());
  }, [dispatch]);

  // Determine if current fonts are default fonts
  const isDefaultHeaderBodyFont =
    normalizeFont(headerFont) === normalizeFont(defaultFonts.headerFont) &&
    normalizeFont(bodyFont) === normalizeFont(defaultFonts.bodyFont);
  const isDefaultScriptFont =
    normalizeFont(scriptFont) === normalizeFont(defaultFonts.scriptFont);

  // Toolbars
  const headerToolbar = useMemo(() => {
    const icon = isDefaultHeaderBodyFont ? faStarSolid : faStar;
    const tooltipTitle = isDefaultHeaderBodyFont ? 'Default' : 'Set as Default';
    return (
      <Tooltip title={tooltipTitle}>
        <Button
          size="small"
          sx={{ minWidth: '30px' }}
          onClick={() => {
            if (!isDefaultHeaderBodyFont) setShowConfirm(true);
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            color={isDefaultHeaderBodyFont ? '#ffd500' : 'grey'}
          />
        </Button>
      </Tooltip>
    );
  }, [isDefaultHeaderBodyFont]);

  const scriptToolbar = useMemo(() => {
    const icon = isDefaultScriptFont ? faStarSolid : faStar;
    const tooltipTitle = isDefaultScriptFont ? 'Default' : 'Set as Default';
    return (
      <Tooltip title={tooltipTitle}>
        <Button
          size="small"
          sx={{ minWidth: '30px' }}
          onClick={() => {
            if (!isDefaultScriptFont) setShowConfirm(true);
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            color={isDefaultScriptFont ? '#ffd500' : 'grey'}
          />
        </Button>
      </Tooltip>
    );
  }, [isDefaultScriptFont]);

  const disableScriptToolbar = useMemo(() => {
    const iconColor = isScriptDisabled ? 'grey' : '#097CC7';
    const tooltipTitle = isScriptDisabled
      ? 'Enable Handwritten Fonts'
      : 'Disable Handwritten Fonts';
    return (
      <Tooltip title={tooltipTitle}>
        <Button
          size="small"
          sx={{ minWidth: '30px' }}
          onClick={handleToggleDisable}
        >
          <FontAwesomeIcon icon={faEyeSlash} color={iconColor} />
        </Button>
      </Tooltip>
    );
  }, [isScriptDisabled, handleToggleDisable]);

  // Logging for debugging

  return (
    <>
      <CollapsableMenuGroup
        hideChevron
        label="Theme Fonts"
        icon={<FontAwesomeIcon icon={faFont} fontSize={18} />}
      >
        <div style={{ paddingBottom: 10 }}>
          <ItemSelectorMenuItem
            itemToggle={agentView && headerToolbar}
            initialValue={fontPairingNames[fontPairingIndex]}
            items={fontPairingNames}
            onPageChange={handleSetThemeFonts}
            label="Header/Body"
          />
        </div>
        <Divider />
        <div style={{ paddingBottom: 15 }}>
          <ItemSelectorMenuItem
            toolbar={agentView && disableScriptToolbar}
            itemToggle={agentView && scriptToolbar}
            initialValue={scriptFonts[scriptFontIndex]}
            items={scriptFontsOptions}
            onPageChange={handleSetScriptFont}
            label="Handwritten"
          />
        </div>
      </CollapsableMenuGroup>

      <ConfirmDialog
        title="Set Theme Font?"
        message="Do you want to set this as your default theme font?"
        open={showConfirm}
        setOpen={setShowConfirm}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default React.memo(ThemeFontMenuItem);
