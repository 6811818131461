import React from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { ButtonFileInput } from 'components/Fields/ButtonFileInput';

export const HeadshotPanel = () => {
  return (
    <FormPanel hideArrow>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            name="headshotFile"
            label={'Headshot'}
            component={ButtonFileInput}
            dropZoneId={'setting-form'}
            identifier={'id'}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="iconFile"
            label={'Icon'}
            component={ButtonFileInput}
            dropZoneId={'setting-form'}
            identifier={'id'}
          />
        </Grid>
      </Grid>
    </FormPanel>
  );
};
