import { useEffect } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import {
  FontAwesomeIconComp,
  MenuItemButton,
  MenuItemIcon,
} from 'components/Common';
import {
  getIsMultiline,
  iconAndLabel,
} from 'components/ModelViews/Documents/DocumentPdfViewer/utils/field';
import { activeFieldsSelector } from 'redux/selectors';
import {
  removeBannerMessage,
  setActiveField,
  setBannerMessage,
} from 'redux/slices';
import {
  DeleteField,
  FieldAssign,
  FontSize,
  LineHeight,
  Required,
  TextAlign,
  TextIndent,
  WhiteBackground,
} from './menuItems';

export const TextToolbar = () => {
  const activeFields = useSelector(activeFieldsSelector);
  const selectedField = activeFields[0];
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setActiveField([]));
  };

  const hasSingleField = activeFields.length === 1;

  useEffect(() => {
    const bannerMessage = {
      severity: 'info',
      bannerMessage:
        'White backgrounds should not be used to cover terms that have already been signed by parties.',
    };

    if (selectedField.fieldTransparency) {
      dispatch(setBannerMessage(bannerMessage));
    }
    return () => {
      dispatch(removeBannerMessage(bannerMessage));
    };
  }, [selectedField.id, selectedField.fieldTransparency]);

  return (
    <>
      <MenuItemButton
        icon={iconAndLabel[selectedField.type].icon}
        label={iconAndLabel[selectedField.type].label}
        rightAction={
          <MenuItemIcon onClick={handleClose}>
            <FontAwesomeIconComp icon={faTimes} />
          </MenuItemIcon>
        }
        isSelected
      />
      <Box mt={1}>
        <FieldAssign />
        {hasSingleField && selectedField.type !== 'checkbox' && (
          <>
            <TextAlign />
            <FontSize />
            {getIsMultiline(selectedField) && (
              <>
                <LineHeight />
                <TextIndent />
              </>
            )}
            <WhiteBackground />
          </>
        )}
        <Required />
        <DeleteField />
      </Box>
    </>
  );
};
