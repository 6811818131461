import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SideDrawer } from 'components/Common';
import { InvoiceForm } from 'components/ModelViews/Invoices/InvoiceForm';
import { defaultValues } from 'components/ModelViews/Invoices/InvoiceForm/defaultValues';
import { useDrawer } from 'hooks';
import {
  contactsAPI,
  transactionsAPI,
  useCreateInvoiceMutation,
  useGetInvoiceQuery,
} from 'redux/rtk-query';
import { processFormData } from '../InvoiceForm/formData';

export const EditInvoiceModal = ({ params, path }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const paramName = Object.keys(params)[0];

  const [createInvoice] = useCreateInvoiceMutation();
  const { isOpen, openDrawer, closeDrawer } = useDrawer(true, path);

  const { data: invoiceData } = useGetInvoiceQuery({
    id: params[paramName],
  });

  const handleClose = () => {
    closeDrawer();
  };

  const afterSave = () => {
    handleClose();
  };

  useEffect(() => {
    if (invoiceData?.data) {
      setInitialValues({
        ...defaultValues,
        ...invoiceData.data,
      });
      openDrawer();
    }
  }, [invoiceData]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    return await createInvoice(formData).then(() => {
      dispatch(contactsAPI.util.invalidateTags(['Invoices']));
      dispatch(transactionsAPI.util.invalidateTags(['Invoices']));
    });
  };

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      <InvoiceForm
        title={invoiceData?.data?.name}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditInvoiceModal.propTypes = {
  path: PropTypes.string,
  params: PropTypes.object,
};
