import { Avatar as AvatarMUI, Box, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';

export const Avatar = styled(AvatarMUI)({
  backgroundColor: '#ececec',
  width: 84,
  height: 84,
  fontSize: 40,
  fontFamily: 'Quicksand',
});

export const SettingsIcon = styled(FontAwesomeIconComp)({
  position: 'absolute',
  right: '24px',
  top: '24px',
  color: '#969696',
});
export const Name = styled(Typography)({
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '28px',
  color: '#1C1C1C',
});
export const DetailsBox = styled(Box)(() => ({
  position: 'relative',
  height: 'fit-content',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 0,
}));
export const DetailsWrapper = styled(Box)({
  '& .MuiCard-root:not(:first-of-type)': {
    marginTop: 16,
  },
});
export const InfoListItem = styled(ListItem)({
  padding: 2,
  color: '#565656',
  fontSize: 13,
  '& .MuiListItemIcon-root': {
    color: '#969696',
  },
});
