import React from 'react';
import PropTypes from 'prop-types';
import {
  faCheck,
  faClockRotateLeft,
  faDownload,
  faFileEdit,
} from '@fortawesome/pro-light-svg-icons';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { AppBar, Button, Divider, Toolbar, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp, MenuButton } from 'components/Common';
import { RoleHeadIcon } from 'components/Icons';
import { EDIT_DOCUMENT } from 'consts';
import { conversion, getColorByStatus, getIconByStatus } from 'helpers';
import { CloseApproveComponent } from '../../DocumentsList/components/menuItemContent/CloseApproveComponent';
import { ReviseComponent } from '../../DocumentsList/components/menuItemContent/ReviseComponent';
import { UnApproveComponent } from '../../DocumentsList/components/menuItemContent/UnApproveComponent';

function DocumentPreviewToolbar({ document }) {
  const navigate = useNavigate();
  const UnapproveButton = () => {
    const title = (
      <>
        <FontAwesomeIconComp
          style={{ marginRight: '8px' }}
          icon={getIconByStatus('Completed')}
          color={getColorByStatus('Completed')}
        />
        Un-Approve
      </>
    );
    return (
      <MenuButton title={title}>
        <UnApproveComponent item={document} />
      </MenuButton>
    );
  };

  const ApproveButton = () => {
    const title = (
      <>
        <FontAwesomeIconComp
          style={{ marginRight: '8px' }}
          icon={getIconByStatus('Approved')}
          color={getColorByStatus('Approved')}
        />
        Approve
      </>
    );
    return (
      <MenuButton title={title}>
        <CloseApproveComponent item={document} />
      </MenuButton>
    );
  };

  const ReviseButton = () => {
    const title = (
      <>
        <FontAwesomeIconComp
          style={{ marginRight: '8px' }}
          icon={getIconByStatus('Revise')}
          color={getColorByStatus('Revise')}
        />
        Revise
      </>
    );
    return (
      <MenuButton title={title}>
        <ReviseComponent />
      </MenuButton>
    );
  };

  return (
    <AppBar
      elevation={2}
      color={'default'}
      position="sticky"
      sx={{ top: 0, zIndex: 1100, maxHeight: '38px' }}
    >
      <Toolbar sx={{ minHeight: '38px' }} disableGutters variant="dense">
        {document.closedApproved ? <UnapproveButton /> : <ApproveButton />}
        <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
        <ReviseButton />
        <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
        <Button
          size={'small'}
          onClick={() => {
            const basePath = location.pathname.replace(
              /\/documentPreview\/.*$/,
              '',
            );
            navigate(`${basePath}/${EDIT_DOCUMENT(document.id)}`);
          }}
        >
          <FontAwesomeIconComp
            style={{ marginRight: '8px' }}
            icon={faFileEdit}
          />
          Edit
        </Button>
        <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
        <Button size={'small'}>
          <FontAwesomeIconComp
            style={{ marginRight: '8px' }}
            icon={faDownload}
          />
          Download
        </Button>
        <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
        <Button size={'x-small'}>
          <FontAwesomeIconComp
            style={{ marginRight: '8px' }}
            icon={faClockRotateLeft}
          />
          Audit Log
        </Button>
        {document.roles && <RoleHeadsToolbar roles={document.roles} />}
      </Toolbar>
    </AppBar>
  );
}

export default DocumentPreviewToolbar;

DocumentPreviewToolbar.propTypes = {
  document: PropTypes.object.isRequired,
};

const RoleHeadsToolbar = ({ roles }) => {
  const getTooltipTitle = (role) => {
    let title = <span>{conversion.getName(role.contact)}</span>;
    if (role.signedDateTime) {
      let formattedDate = DateTime.fromISO(role.signedDateTime).toLocaleString(
        DateTime.DATETIME_SHORT,
      );
      title = (
        <span>
          {title}
          <br />
          Signed at {formattedDate}
        </span>
      );
    } else if (role.lastViewDateTime) {
      let formattedDate = DateTime.fromISO(
        role.lastViewDateTime,
      ).toLocaleString(DateTime.DATETIME_SHORT);
      title = (
        <span>
          {title}
          <br />
          Viewed at {formattedDate}
        </span>
      );
    } else if (role.type === 'cc') {
      title = (
        <span>
          {title}
          <br />
          CC
        </span>
      );
    } else {
      title = (
        <span>
          {title}
          <br />
          Unviewed
        </span>
      );
    }
    return title;
  };
  console.log('roles', roles);
  return (
    <Box display={'flex'} justifyContent={'center'} width={'50%'}>
      {roles.map((role, index) => (
        <Tooltip title={getTooltipTitle(role)} key={index}>
          <Box
            key={index}
            sx={{
              textAlign: 'center',
              paddingTop: '4px',
              display: 'inline-block',
            }}
          >
            <RoleHeadIcon style={{ padding: '2px 4px' }} role={role} />
            <div style={{ marginTop: '-13px' }}>
              {role.type === 'cc' ? (
                <>
                  <span
                    style={{
                      color: '#999',
                      paddingTop: '8px',
                      display: 'block',
                      fontSize: '10px',
                    }}
                  >
                    CC
                  </span>
                </>
              ) : (
                <>
                  {role.signedDateTime ? (
                    <>
                      <FontAwesomeIconComp
                        icon={faCheck}
                        fontSize={'9px'}
                        color={'green'}
                      />
                    </>
                  ) : role.lastViewDateTime ? (
                    <>
                      <FontAwesomeIconComp
                        icon={faEyeSlash}
                        fontSize={'9px'}
                        color={'#999'}
                      />
                    </>
                  ) : (
                    <>
                      <FontAwesomeIconComp
                        icon={faEyeSlash}
                        fontSize={'9px'}
                        color={'#999'}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

RoleHeadsToolbar.propTypes = {
  roles: PropTypes.array.isRequired,
};
