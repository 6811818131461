import * as yup from 'yup';
import { validateFormValues } from 'helpers/validation';

const option = yup.object().shape({
  value: yup.number(),
  label: yup.string(),
  key: yup.number(),
});

const validationSchema = yup.object().shape({
  name: yup.string().trim().max(64).nullable().required('Required'),
  parent: option.nullable(),
  type: yup.string().nullable(),
});

export const validate = validateFormValues(validationSchema);
