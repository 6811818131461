import { CardHeader as MuiCardHarder, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled(Typography)({
  fontSize: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const AbeCardHeader = styled(MuiCardHarder, {
  shouldForwardProp: (prop) => prop !== 'stickyHeader',
})(({ stickyHeader }) => ({
  padding: '7px 12px',
  maxHeight: '38px',
  '& .MuiCardHeader-avatar': {
    width: '30px',
    justifyContent: 'flex-start',
    marginRight: '0',
  },
  ...(stickyHeader && {
    position: 'sticky',
    top: 0,
    zIndex: 1100,
    backgroundColor: 'inherit',
    //add boxShadow to make it look like a sticky header
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  }),
}));
