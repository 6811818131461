import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { ClickableTypography } from 'components/Styled';
import { UPDATE_INVOICE, colors } from 'consts';
import { conversion } from 'helpers';

export const InvoiceRow = ({
  invoice,
  showIndent = false,
  showPaidTo = false,
}) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(UPDATE_INVOICE(invoice.id));
  };
  return (
    <Grid container fontSize={14}>
      <ClickableTypography
        variant="text"
        onClick={onClickHandler}
        container
        component={Grid}
        xs={12}
        display="flex"
        justifyContent="space-between"
      >
        <Grid item textAlign="left">
          {showIndent ? '— ' : ''}
          {invoice.name}
          {showPaidTo && invoice.paidTo && (
            <Typography
              component={'span'}
              fontSize={12}
              fontStyle={'italic'}
              color={colors.GREY}
            >
              - {conversion.getName(invoice.paidTo)}
            </Typography>
          )}
        </Grid>
        <Grid item textAlign="right">
          {conversion.formatCurrency(invoice.price)}
        </Grid>
      </ClickableTypography>
    </Grid>
  );
};

InvoiceRow.propTypes = {
  invoice: PropTypes.object,
  showIndent: PropTypes.bool,
  showPaidTo: PropTypes.bool,
};
