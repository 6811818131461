import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { hexToRgb } from '@mui/system';
import { useCurrentItem } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useCurrentItem';
import { getComputedBorderRadius } from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';
import { CM_ITEM_TYPES as itemTypes } from 'consts';
import { customColorSelectorCM } from 'redux/selectors';

export const Shape = React.memo(({ itemId }) => {
  const currentItem = useCurrentItem(itemId);
  const customColor = useSelector(customColorSelectorCM);

  const getColor = useCallback(
    (color) => {
      if (color === 'customColor') {
        if (customColor === 'mainframeTheme') {
          if (currentItem.style?.mainframeThemeColor) {
            return currentItem.style.mainframeThemeColor;
          }
          return currentItem.type === itemTypes.shapes.line
            ? '#C9232D'
            : '#FFFFFF';
        }
        return customColor ? customColor : '#48484A';
      }
      return color;
    },
    [customColor, currentItem.style?.mainframeThemeColor],
  );

  const computedBorderRadius = useMemo(
    () => getComputedBorderRadius(currentItem),
    [currentItem],
  );
  // Memoize the style object to avoid recalculating on every render
  const style = useMemo(() => {
    const divStyle = {
      position: 'relative',
      zIndex: -1,
      backgroundColor: getColor(currentItem.style?.backgroundColor),
      height: '100%',
      width: '100%',
      opacity: currentItem?.style?.transparency,
      borderRadius: currentItem.roundedLine
        ? parseFloat(currentItem.h) + 'px'
        : computedBorderRadius,
      overflow:
        currentItem.roundedLine ||
        (currentItem.style?.borderRadius &&
          parseInt(currentItem.style?.borderRadius?.replace('%', '')) > 0)
          ? 'hidden'
          : 'visible',
      borderStyle: 'solid',
      borderWidth: currentItem?.style?.borderWidth
        ? currentItem?.style?.borderWidth + 'px'
        : '0',
      borderColor: currentItem?.style?.borderColor
        ? getColor(currentItem?.style?.borderColor)
        : '#000',
    };

    // Check if background property exists
    if (currentItem?.style?.background) {
      divStyle.background = getColor(currentItem?.style?.background);

      // If backgroundThemeColor is present, blend the theme color with background
      if (currentItem.style?.backgroundThemeColor) {
        const themeColor =
          customColor === 'mainframeTheme' ? '#FFFFFF' : customColor;
        const rgbString = hexToRgb(themeColor).match(/(\d+),\s*(\d+),\s*(\d+)/);
        const rgba = currentItem.style?.background.match(
          /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/,
        );

        if (rgbString && rgba) {
          divStyle.background = `rgba(${rgbString[1]}, ${rgbString[2]}, ${rgbString[3]}, ${rgba[4]})`;
        }
      }
    }

    return divStyle;
  }, [
    currentItem.style?.backgroundColor,
    currentItem.style?.background,
    currentItem.style?.backgroundThemeColor,
    currentItem.style?.borderColor,
    currentItem.style?.borderRadius,
    currentItem.style?.borderWidth,
    currentItem.style?.transparency,
    currentItem.roundedLine,
    currentItem.w,
    currentItem.h,
    getColor,
    customColor,
    computedBorderRadius,
  ]);

  return <div style={style}></div>;
});

Shape.displayName = 'Shape';

Shape.propTypes = {
  itemId: PropTypes.string.isRequired,
};
