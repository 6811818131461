import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { MenuItemButton } from 'components/Common';
import { DeleteField } from 'components/ModelViews/Documents/DocumentEditorDrawer/components/DocumentEditorMenu/components/ActiveFieldMenu/components/menuItems';
import { iconAndLabel } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/field';
import { activeFieldsSelector } from 'redux/selectors';

export const StrikethroughToolbar = () => {
  const activeFields = useSelector(activeFieldsSelector);

  return (
    <>
      <MenuItemButton
        icon={iconAndLabel[activeFields[0].type].icon}
        label={iconAndLabel[activeFields[0].type].label}
        isSelected
      />
      <Box mt={1}>
        <DeleteField />
      </Box>
    </>
  );
};
