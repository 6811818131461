import { useMemo } from 'react';
import { DateTime, Interval } from 'luxon';

export const useTaskDueDate = (dueOn, length = 'short') => {
  if (!dueOn) return null;
  return useMemo(() => {
    const date1 = DateTime.fromISO(dueOn, { zone: 'UTC' });
    const date2 = DateTime.now();
    let diff = Interval.fromDateTimes(date2, date1);
    let pastDue = false;
    if (diff.invalid?.reason === 'end before start') {
      diff = Interval.fromDateTimes(date1, date2);
      pastDue = true;
    }
    const diffDays = parseInt(diff.length('days'));
    const diffHours = parseInt(diff.length('hours'));
    const diffMins = parseInt(diff.length('minutes'));
    let label;
    if (diffDays >= 1) {
      if (length === 'short') {
        label = `${diffDays}d`;
      } else {
        label = `${diffDays} day${diffDays > 1 ? 's' : ''}`;
      }
    } else if (diffHours >= 1) {
      if (length === 'short') {
        label = `${diffHours}h`;
      } else {
        label = `${diffHours} hour${diffHours > 1 ? 's' : ''}`;
      }
    } else if (diffMins >= 0) {
      if (length === 'short') {
        label = `${diffMins}m`;
      } else {
        label = `${diffMins} minute${diffMins > 1 ? 's' : ''}`;
      }
    }
    return `${label} ${pastDue ? 'late' : ''}`;
  }, [dueOn]);
};
