import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  types: [],
};

export const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {},
});

export const vendorsSliceReducer = vendorsSlice.reducer;
