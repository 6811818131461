import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Box } from '@mui/material';
import { FormPanel } from 'components/Common';
import { Autocomplete, Select, TextInput } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { SHARE_WITH_OPTIONS } from 'consts';
import { useLoadHierarchyOptions } from 'hooks';
import { useGetRolesQuery } from 'redux/rtk-query';

const RoleForm = (props) => {
  const roles = useLoadHierarchyOptions(useGetRolesQuery);

  const renderFields = () => (
    <FormPanel startOpen>
      <Field startOpen name="name" label="Role Name" component={TextInput} />
      <Box />
      <Field
        name="parent"
        label="Parent Role"
        options={roles}
        component={Autocomplete}
      />
      <Field
        name="shareWith"
        label="Shared with"
        options={SHARE_WITH_OPTIONS}
        component={Select}
      />
    </FormPanel>
  );

  return (
    <AbeForm handleSubmitFunc={props.handleSubmit} {...props}>
      {renderFields}
    </AbeForm>
  );
};

export default RoleForm;

RoleForm.propTypes = {
  title: PropTypes.string,
  nameButton: PropTypes.string,
  submitError: PropTypes.string,
  handleSubmit: PropTypes.func,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  afterSave: PropTypes.func,
};
