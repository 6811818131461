import React from 'react';
import PropTypes from 'prop-types';
import { faContactCard, faPen } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {
  CommentCount,
  TaskDueOn,
  TaskRelatedModel,
} from 'components/CellComponents';
import { PageLink } from 'components/Styled';
import { DETAILS_TRANSACTION, UPDATE_TASK, UPDATE_TRANSACTION } from 'consts';
import { conversion } from 'helpers';

const FullName = ({ value }) => {
  return <>{conversion.getName(value.owner)}</>;
};

FullName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const TaskName = ({ value }) => {
  return value ? (
    <PageLink to={`${UPDATE_TASK(value.id)}`}>
      <div> {value.name} </div>
    </PageLink>
  ) : (
    <></>
  );
};
TaskName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

// function that take a var 'value' that has key of roles. return the first two roles contacts names that CONTAIN Buyer or Seller or Landlord or Tenant
const TransactionCustomers = ({ value }) => {
  const customersString = conversion.getCustomersString(value);
  return <div>{customersString}</div>;
};

TransactionCustomers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'dueOn',
    value: 'dueOn',
    label: 'Due On',
    Wrapper: TaskDueOn,
    width: '10%',
    disableSort: true,
  },
  {
    id: 'name',
    value: ['name', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    Wrapper: TaskName,
    width: '33%',
    disableSort: true,
  },
  {
    id: 'status',
    value: 'status',
    label: 'Status',
    width: '10%',
    disableSort: true,
  },
  {
    id: 'comments',
    value: 'comments',
    label: 'Comments',
    Wrapper: CommentCount,
    width: '5%',
    cellAlign: 'center',
    disableSort: true,
  },
  {
    id: 'linkedTo',
    value: ['contact', 'transaction', 'id'],
    label: 'Linked To',
    Wrapper: TaskRelatedModel,
    width: '20%',
    disableSort: true,
  },
  {
    id: 'agent',
    value: 'modelAccess',
    label: 'Assigned To',
    Wrapper: FullName,
    width: '15%',
    disableSort: true,
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();

  return [
    {
      name: 'Edit',
      icon: faPen,
      onClick: (item) => {
        navigate(UPDATE_TRANSACTION(item.id));
      },
    },
    {
      name: 'Details',
      icon: faContactCard,
      onClick: (item) => {
        navigate(`/${DETAILS_TRANSACTION(item.id)}`);
      },
    },
  ];
};
