import { ActiveUsers, Users } from 'components/Common/Table/components';

export const getCells = (getRowClickHandler) => [
  {
    id: 'name',
    value: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    isCollapsibleCell: true,
  },
  {
    id: 'usersCount',
    value: 'totalUsers',
    numeric: true,
    disablePadding: false,
    label: 'Total Users',
    width: 200,
    Wrapper: Users,
    getRowClickHandler,
  },
  {
    id: 'activeUsersCount',
    value: 'activeUsers',
    numeric: true,
    disablePadding: false,
    label: 'Active Users',
    width: 200,
    Wrapper: ActiveUsers,
    getRowClickHandler,
  },
];
