import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import { SelectPlaceholderText } from 'components/Styled';

export const YearPickerField = ({ input, availablePeriods = [], sx = {} }) => {
  const yearOptions = Array.from(Array(121), (_, i) => {
    return {
      value: new Date().getFullYear() - i,
      label: new Date().getFullYear() - i,
    };
  });

  const handleYearChange = (event) => {
    input.onChange({
      ...input.value,
      year: event.target.value,
    });
  };

  const isYearDisabled = (year) => {
    return !availablePeriods.some((p) => p.year === year);
  };

  return (
    <>
      <Select
        {...input}
        size={'small'}
        IconComponent={() => null}
        displayEmpty
        MenuProps={{
          sx: {
            maxHeight: 300,
          },
        }}
        label={'Year (optional)'}
        sx={{
          border: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '& .MuiOutlinedInput-input': {
            padding: 0,
            paddingRight: '0 !important',
            textAlign: 'center',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          ...sx,
        }}
        onChange={handleYearChange}
        value={input.value?.year || ''}
      >
        <MenuItem value="">
          <SelectPlaceholderText>Year</SelectPlaceholderText>
        </MenuItem>
        {yearOptions.map(({ value, label }) => {
          return (
            <MenuItem
              key={value}
              value={value}
              disabled={isYearDisabled(value)}
            >
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

YearPickerField.propTypes = {
  input: PropTypes.object,
  availablePeriods: PropTypes.array,
  sx: PropTypes.object,
};
