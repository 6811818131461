import React from 'react';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { Divider, Grid, IconButton, Typography, Link } from '@mui/material';
import { Box } from '@mui/system';
import { AbeCard, FontAwesomeIconComp, ModuleIcon } from 'components/Common';
import { DETAILS_TRANSACTION } from 'consts';
import { useGetContactRelatedTransactionsQuery } from 'redux/rtk-query';

export const ContactRelatedTransactionsCard = () => {
  const { contactDetailsId } = useParams();

  const [itemLimit, setItemLimit] = React.useState(1);

  const { data: relatedTransactionData } =
    useGetContactRelatedTransactionsQuery(contactDetailsId, {
      skip: !contactDetailsId,
    });

  const relatedTransactions = relatedTransactionData?.data?.items || [];
  const relatedTransactionCount =
    relatedTransactionData?.data?.meta?.total || 0;

  return (
    <AbeCard
      icon={<ModuleIcon module={'Transactions'} />}
      title={'Related Transactions'}
    >
      <Divider />
      <Grid container>
        {relatedTransactions.map((relatedTransaction) => (
          <Box
            px={2}
            py={0.5}
            width={'100%'}
            key={`${relatedTransaction.transactionId}-${relatedTransaction.role}`}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <Typography
                  component={Link}
                  href={`/${DETAILS_TRANSACTION(relatedTransaction.transactionId)}`}
                  underline={'none'}
                  variant="body1"
                >
                  {relatedTransaction.name}
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'left' }}>
                <Typography variant="body1" color="textSecondary">
                  {relatedTransaction.role}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
        {relatedTransactionCount > itemLimit && (
          <Box width={'100%'} textAlign="center">
            <IconButton
              size={'x-small'}
              onClick={() => {
                setItemLimit(itemLimit + 1);
              }}
            >
              <FontAwesomeIconComp fontSize={14} icon={faChevronDown} />
            </IconButton>
          </Box>
        )}
      </Grid>
    </AbeCard>
  );
};
