import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Autocomplete } from 'components/Fields/Autocomplete';
import { useLoadMoreOptions } from 'hooks';
import { useGetListsQuery } from 'redux/rtk-query';

const getListOption = ({ id, name }) => {
  return {
    value: id,
    label: name,
    key: id,
  };
};

export const ListsAutocomplete = (props) => {
  const lists = useLoadMoreOptions(useGetListsQuery, getListOption);

  return (
    <Field
      {...props}
      size={'small'}
      options={lists.options}
      component={Autocomplete}
      onOpen={lists.handleOpen}
      loadMoreResults={lists.handleMore}
      isLoading={lists.isFetching}
      handleSearch={lists.handleSearch}
    />
  );
};

ListsAutocomplete.defaultProps = {
  freeSolo: false,
  onOpen: null,
  isLoading: false,
};

ListsAutocomplete.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  freeSolo: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  iconStart: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  handleSearch: PropTypes.func,
};
