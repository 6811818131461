import React from 'react';
import PropTypes from 'prop-types';
import { faArrows } from '@fortawesome/pro-solid-svg-icons';
import '../../../css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { Box, styled } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';
import { roleColors } from 'consts';
import {
  activeFieldIdsSelector,
  htmlTemplateSelector,
  scaleSelector,
} from 'redux/selectors';
import { setActiveField, updateField } from 'redux/slices';
import { getClippingPath } from '../../../utils/field';
import {
  generateColorIndex,
  getRoleAndOrderFromRoleName,
} from '../../../utils/roles';

const defaultFieldColor = '#7dccff';

function getItemColor(field) {
  let output = defaultFieldColor;
  const roleName = field.role;
  if (typeof roleName !== 'undefined' && roleName) {
    const { role, order } = getRoleAndOrderFromRoleName(roleName);
    const colorIndex = generateColorIndex({
      role: role,
      order: order,
    });
    output = roleColors[colorIndex];
  }
  return output;
}

const PulsingBox = styled(Box)(() => ({
  '@keyframes pulseField': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

export const ResizableDraggable = ({ field, children }) => {
  const dispatch = useDispatch();
  const activeFieldIds = useSelector(activeFieldIdsSelector);
  const htmlTemplate = useSelector(htmlTemplateSelector);
  const scale = useSelector(scaleSelector);
  const isActive = activeFieldIds.includes(field.id);

  const getBackgroundColorElement = () => {
    let fieldTransparency = field.fieldTransparency;
    let opacity = field.fieldTransparency ? 1 : 0.5;
    if (field.type === 'strikethrough') {
      fieldTransparency = true;
      opacity = 0;
    }
    let transparentText = field.type === 'text' && field.fieldTransparency;
    let clippingPath = getClippingPath(field);
    return (
      <PulsingBox
        className={'textIndexClip'}
        sx={{
          WebkitTransform: 'translate3d(0, 0, 0)',
          clipPath: clippingPath,
          backgroundColor: fieldTransparency ? 'white' : getItemColor(field),
          opacity: opacity,
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          height: '100%',
          animation:
            isActive && !transparentText ? 'pulseField 2s infinite' : '',
          border: field.fieldTransparency ? '2px solid' : 0,
          borderColor: field.fieldTransparency ? getItemColor(field) : '',
        }}
      />
    );
  };

  let left =
    field.type === 'checkbox'
      ? parseFloat(field.left.replace('px', '')) - 3
      : parseFloat(field.left.replace('px', ''));
  let top =
    field.type === 'checkbox'
      ? parseFloat(field.top.replace('px', '')) - 3
      : parseFloat(field.top.replace('px', ''));
  let width =
    field.type === 'checkbox'
      ? parseFloat(field.width) + 6
      : parseFloat(field.width.replace('px', ''));
  let height =
    field.type === 'checkbox'
      ? parseFloat(field.height) + 6
      : parseFloat(field.height.replace('px', ''));

  const handleResizeStop = (e, direction, ref, delta) => {
    const newWidth = parseFloat(field.width) + delta.width;
    const newHeight = parseFloat(field.height) + delta.height;
    dispatch(
      updateField({
        fieldId: field.id,
        properties: { width: newWidth + 'px', height: newHeight + 'px' },
      }),
    );
  };

  const handleDrag = () => {
    if (field.groupId || activeFieldIds.length > 1) {
      const currentElement = document.querySelector(
        `[data-field-id="${field.id}"]`,
      );
      const transform = currentElement.style.transform;

      // Check if transform is set and matches expected patterns
      const transformValues = transform.match(/-?\d+(\.\d+)?/g) || [0, 0];
      const x = parseFloat(transformValues[0]) - parseFloat(field.left);
      const y = parseFloat(transformValues[1]) - parseFloat(field.top);

      // Make an array with both the fields with the same groupId and the active fields
      const fields = htmlTemplate.filter(
        (possField) =>
          (field.groupId && possField.groupId === field.groupId) ||
          activeFieldIds.includes(possField.id),
      );
      fields.forEach((fieldVar) => {
        if (fieldVar.id !== field.id) {
          const fieldObject = fieldVar;
          // left and top must be greater than 0
          let left = Math.max(parseFloat(fieldObject.left) + x, 0);
          let top = Math.max(parseFloat(fieldObject.top) + y, 0);
          const groupElement = document.querySelector(
            `[data-field-id="${fieldVar.id}"]`,
          );
          groupElement.style.transform = `translate(${left}px, ${top}px)`;
        }
      });
    }
  };

  const handleDragStop = (e, data) => {
    if (field.type === 'checkbox') {
      const newLeft = data.x + 3;
      const newTop = data.y + 3;
      dispatch(
        updateField({
          fieldId: field.id,
          properties: { left: newLeft + 'px', top: newTop + 'px' },
        }),
      );
    } else {
      dispatch(
        updateField({
          fieldId: field.id,
          properties: { left: data.x + 'px', top: data.y + 'px' },
        }),
      );
    }
  };

  const onFieldClick = (e) => {
    if (e.shiftKey) {
      // If Shift is pressed, add the field to the active fields
      dispatch(setActiveField([...activeFieldIds, field.id]));
      e.preventDefault();
    } else {
      dispatch(setActiveField(field.id));
    }
  };

  return (
    <Rnd
      data-field-id={field.id}
      data-group-id={field.groupId}
      bounds={'parent'}
      scale={scale}
      dragHandleClassName="moveHandle"
      resizeHandleComponent={{
        bottomRight: (
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              display: isActive ? 'block' : null,
            }}
          />
        ),
      }}
      style={{
        position: 'absolute',
        fontFamily: 'Arial',
        fontWeight: 600,
        textAlign: field.textAlign,
        fontSize: field.fontSize,
      }}
      size={{ width, height }}
      position={{ x: left, y: top }}
      onResizeStop={handleResizeStop}
      onDrag={handleDrag}
      onDragStop={handleDragStop}
      onClick={onFieldClick}
      lockAspectRatio={
        field.type === 'signatureField' ||
        field.type === 'signatureInitialField'
      }
      resizeHandleStyles={{
        bottomRight: {
          width: 20,
          height: 20,
          right: -5,
          bottom: -5,
          zIndex: 100,
        },
      }}
      resizeHandleWrapperClass={'resizeHandleWrapper'}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: field.type === 'strikethrough',
        bottomRight: field.type !== 'checkbox' && isActive,
        bottomLeft: field.type === 'strikethrough',
        topLeft: field.type === 'strikethrough',
      }}
      minHeight={field.type === 'strikethrough' ? 20 : null}
    >
      <Box
        className="moveHandle"
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          display: isActive ? 'flex' : 'none',
          right: '-21px',
          position: 'absolute',
          padding: '4px',
          WebkitUserSelect: 'all',
          MozUserSelect: 'all',
          userSelect: 'all',
          background: 'white',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          border: '1px solid #b8bcbf',
        }}
      >
        <FontAwesomeIconComp fontSize={12} icon={faArrows} />
      </Box>
      {getBackgroundColorElement()}
      {children}
    </Rnd>
  );
};

ResizableDraggable.propTypes = {
  field: PropTypes.object.isRequired,
  children: PropTypes.node,
  onFieldClick: PropTypes.func,
};
