import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ConfirmationButton } from 'components/Common/ConfirmationButton';
import { TRANSACTION_TYPES } from 'consts';
import {
  useUpdateTransactionTypeMutation,
  tasksAPI,
  documentsAPI,
  notesAPI,
} from 'redux/rtk-query';

export const RejectOfferButton = ({ offer, handleClose }) => {
  const [updateTransactionType] = useUpdateTransactionTypeMutation();
  const dispatch = useDispatch();

  const handleReject = async () => {
    await updateTransactionType({
      id: offer.id,
      transactionType: TRANSACTION_TYPES.ARCHIVED_OFFER,
    });
    dispatch(tasksAPI.util.invalidateTags(['Tasks']));
    dispatch(documentsAPI.util.invalidateTags(['Documents']));
    dispatch(notesAPI.util.invalidateTags(['Notes']));
    handleClose();
  };
  return (
    <ConfirmationButton
      label={'Reject Offer'}
      confirmTitle={'Reject Offer'}
      confirmMessage={'Are you sure you want to reject this offer?'}
      handleConfirm={handleReject}
      color={'error'}
    />
  );
};

RejectOfferButton.propTypes = {
  offer: PropTypes.object,
  handleClose: PropTypes.func,
};
