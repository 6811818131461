// ReportFilters.js
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Button } from '@mui/material';
import ConditionGroup from './ConditionGroup';

export const ReportFilters = () => {
  return (
    <>
      <FieldArray name="filters">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <ConditionGroup
                key={name}
                name={name}
                showGroupCondition={index > 0}
                onRemoveGroup={() => fields.remove(index)}
              />
            ))}
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                fields.push({
                  groupingCondition: 'And',
                  filters: [],
                })
              }
              sx={{ marginTop: 2 }}
            >
              Add Group
            </Button>
          </>
        )}
      </FieldArray>
    </>
  );
};
