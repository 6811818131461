// eslint-disable-next-line import/no-unresolved
// import 'propertySearchReact/GlobalStyles';
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { SharedContext } from 'contexts/SharedContext';
import useUserToken from 'hooks/useUserToken';

// import getDeviceInfo from '../utils/getDeviceInfo';
// If you have these hooks, re-enable them:
// import useDocumentTitle from '../hooks/useDocumentTitle';
// import useUpdateOnCSSVarChange from '../hooks/useUpdateOnCSSVarChange';
// If you have a SCSS module for styling, import it:
import './properties.module.scss';

// Lazy-load the remote "PropertySearch"
const RemoteSearchWidget = lazy(
  // eslint-disable-next-line import/no-unresolved
  () => import('propertySearchReact/PropertySearch'),
);

function PropertiesPage() {
  // Use custom hook to get user token, loading state, etc.
  const { userToken, isLoading, user } = useUserToken();

  // Some shared piece of state:
  const [state, setState] = useState('Shared State');

  // If you need to re-check CSS variables:
  // useUpdateOnCSSVarChange();

  // Example device info function:
  // const info = getDeviceInfo();

  useEffect(() => {
    // eslint-disable-next-line import/no-unresolved
    import('propertySearchReact/GlobalStyles')
      .then(() => {
        console.log('Global styles loaded successfully.');
      })
      .catch((err) => {
        console.error('Error loading global styles:', err);
      });
  }, []);
  return (
    <SharedContext.Provider
      value={{ state, setState, userToken, user, isLoading }}
    >
      <Suspense fallback={<CircularProgress />}>
        <RemoteSearchWidget
          sharedState={{ state, setState, userToken, user, isLoading }}
        />
      </Suspense>
    </SharedContext.Provider>
  );
}

export default PropertiesPage;
