import React from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CurrencyInput,
  DatePicker,
  Select,
  TextInput,
} from 'components/Fields';
import { FormCondition } from 'components/Forms';
import { PM_FILE_OPTIONS } from 'consts';
import { useTransactionOptions } from 'hooks';

export const LeasePanel = () => {
  let form = useForm();
  const { leaseTypeOptions } = useTransactionOptions(form);

  return (
    <FormPanel label={'Lease'}>
      <FormCondition
        when="pmFile"
        operator="doesNotEqual"
        is={PM_FILE_OPTIONS.LEASE_ONLY.value}
      >
        <Grid item xs={12}>
          <Field
            label="Lease Type"
            name={'leaseType'}
            component={Select}
            options={leaseTypeOptions}
          />
        </Grid>
      </FormCondition>
      <Grid item xs={12}>
        <Field
          label="Rent Amount"
          name={'rentAmount'}
          component={CurrencyInput}
        />
      </Grid>
      <FormCondition when="pmFile" operator="equals" is={'Yes'}>
        <Grid item xs={12}>
          <Field
            label="Security Deposit"
            name={'pmDeposit'}
            component={CurrencyInput}
            tooltip={{
              title:
                'This is the security deposit held per the terms of the lease.',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Additional Fee"
            name={'pmNonRefundable'}
            component={CurrencyInput}
            tooltip={{
              title:
                'This is a non-refundable fee, such as a pet fee or other upfront cost to the tenant due under the lease. This fee is paid directly to the owner and not held in escrow.',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Additional Deposit"
            name={'pmRefundable'}
            component={CurrencyInput}
            tooltip={{
              title:
                'This is a refundable deposit held in escrow by the company.',
            }}
          />
        </Grid>
      </FormCondition>
      <Grid item xs={12}>
        <Field
          label="Lease Begins"
          name={'leaseBegins'}
          component={DatePicker}
        />
      </Grid>
      <Grid item xs={12}>
        <Field label="Lease Ends" name={'leaseEnds'} component={DatePicker} />
      </Grid>
      <FormCondition when="pmFile" operator="doesNotEqual" is={'NO'}>
        <Grid item xs={12}>
          <Field
            label="Late Fee Percent"
            name={'lateFeePercent'}
            step={'0.01'}
            component={TextInput}
            tooltip={{
              title: 'Enter the late fee percentage.',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Late Fee Flat Rate"
            name={'lateFeeFlatRate'}
            component={CurrencyInput}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Nsf Fee Flat Rate"
            name={'nsfFeeFlatRate'}
            component={CurrencyInput}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Rent Due On"
            name={'rentDueOn'}
            type={'number'}
            component={TextInput}
            tooltip={{
              title: 'Enter the day of the month that rent is due.',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Rent Late On"
            name={'rentLateOn'}
            type={'number'}
            component={TextInput}
            tooltip={{
              title: 'Enter the day of the month that rent is late.',
            }}
          />
        </Grid>
      </FormCondition>
      <FormCondition when="pmFile" operator="equals" is={'YES'}>
        <Grid item xs={12}>
          <Field
            label="Description of Deposits"
            name={'pmDescription'}
            component={TextInput}
            multiline
            rows={4}
          />
        </Grid>
      </FormCondition>
    </FormPanel>
  );
};

LeasePanel.propTypes = {};
