import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import {
  Select,
  TextInput,
  CheckboxField,
  NumberInput,
} from 'components/Fields';
import { CATEGORY_STATUS } from 'consts';
import { getSelectOptions } from 'helpers';
import { useGetReportModulesQuery } from 'redux/rtk-query';

function GeneralInfoForm() {
  const { data: moduleData } = useGetReportModulesQuery();
  const moduleOptions = useMemo(() => {
    return moduleData?.data
      ? Object.keys(moduleData.data).map((module) => ({
          label: module,
          value: module,
        }))
      : [];
  }, [moduleData]);
  return (
    <>
      <Field
        name="module"
        label="Module"
        component={Select}
        options={moduleOptions}
        required
      />
      <Field name="name" label="Report Name" component={TextInput} required />
      <Field
        name="systemDescription"
        label="System Description"
        component={TextInput}
      />
      <Field
        name="description"
        label="Public Description"
        component={TextInput}
        required
      />
      <Field
        name="status"
        label="Status"
        component={Select}
        required
        options={getSelectOptions(CATEGORY_STATUS)}
      />
      <Field name="limit" label="Limit Results" component={NumberInput} />
      <Field name="testMode" label="Test Mode" component={CheckboxField} />
    </>
  );
}

export default GeneralInfoForm;
