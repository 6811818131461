import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { AuditLogList } from 'components/Common/AuditLogDrawer/components/AuditLogList/AuditLogList';
import { FontAwesomeIconComp } from 'components/Common/FontAwesomeIconComp';
import { SideDrawer } from 'components/Common/SideDrawer';
import { HeadingSection, Header } from 'components/Styled';
import { useDrawer } from 'hooks';

export const AuditLogDrawer = ({ params, path }) => {
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false, path);

  const uuid = params?.uuid;
  const modelType = params?.modelType;

  useEffect(() => {
    if (uuid && modelType) {
      openDrawer();
    }
  }, [uuid, modelType]);
  return (
    <>
      <SideDrawer width={'60%'} isOpen={isOpen} closeDrawer={closeDrawer}>
        <HeadingSection>
          <Header variant="h4">Audit Log</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => closeDrawer()}
          />
        </HeadingSection>
        <AuditLogList modelType={modelType} uuid={uuid} />
      </SideDrawer>
    </>
  );
};

AuditLogDrawer.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};
