// ThemeColorMenuItem.js

import React, { useState, useCallback, useMemo } from 'react';
import { faBrush } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsableMenuGroup, ConfirmDialog } from 'components/Common';
import ColorPicker from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ColorPicker';
import {
  customColorSelectorCM,
  defaultColorSelectorCM,
  agentViewSelector,
} from 'redux/selectors';
import { changeThemeColor, changeDefaultThemeColor } from 'redux/slices';

const ThemeColorMenuItem = () => {
  const dispatch = useDispatch();
  const customColor = useSelector(customColorSelectorCM);
  const defaultColor = useSelector(defaultColorSelectorCM);
  const agentView = useSelector(agentViewSelector); // Adjust if agentView is stored elsewhere

  const [showConfirm, setShowConfirm] = useState(false);
  // const [changeThemeColorMutation] = useChangeThemeColorMutation(); // RTK Query mutation hook

  const colorOptions = useMemo(
    () => [
      '#221e1f',
      '#4a494b',
      '#62808e',
      '#149a89',
      '#45b150',
      '#1cbbd4',
      '#448fcd',
      '#2c648e',
      '#4356a6',
      '#884298',
      '#8b0913',
      '#ef558b',
      '#ed93a3',
      '#c18864',
    ],
    [],
  );

  const handleThemeColorChange = useCallback(
    (color) => {
      if (agentView && customColor === color) {
        setShowConfirm(true);
      } else {
        dispatch(changeThemeColor(color));
      }
    },
    [agentView, customColor, dispatch],
  );

  const handleConfirm = useCallback(async () => {
    //TODO: Implement changeThemeColorMutation
    // await changeThemeColorMutation({ themeColor: customColor });
    dispatch(changeDefaultThemeColor(customColor));
    setShowConfirm(false);
  }, [customColor, dispatch]);

  return (
    <>
      <CollapsableMenuGroup
        hideChevron
        label="Theme Color"
        icon={<FontAwesomeIcon icon={faBrush} fontSize={18} />}
      >
        <div style={{ padding: '12px' }}>
          <ColorPicker
            colors={colorOptions}
            color={customColor}
            defaultColor={defaultColor}
            onChange={handleThemeColorChange}
            showMainframeColor
            showTransparent={false}
          />
        </div>
      </CollapsableMenuGroup>

      <ConfirmDialog
        title={'Set Theme Color?'}
        message={'Do you want to set this as your default theme color?'}
        open={showConfirm}
        setOpen={setShowConfirm}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default React.memo(ThemeColorMenuItem);
