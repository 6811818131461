import React from 'react';
import PropTypes from 'prop-types';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ContactInfoItems } from 'components/Common';
import ModelDetailsCollapse from 'components/ModelDetailsCollapse';
import { CONTACT_TYPES } from 'consts';
import { conversion } from 'helpers';
import { ContactAvatar, CustomerRating, VendorRating } from './components';

const getTypeLabel = (contactInfo) =>
  contactInfo.jobTitle ? contactInfo.jobTitle : contactInfo.type;

const getCompanyLabel = (contactInfo) =>
  contactInfo.companyName ||
  (contactInfo.brokerage && contactInfo.brokerage.id
    ? contactInfo.brokerage.companyName
    : null);

const getItems = (contactInfo) => [
  ...(contactInfo.mobilePhone
    ? [
        {
          icon: faPhone,
          content: conversion.formatPhoneNumber(contactInfo.mobilePhone),
        },
      ]
    : []),
  ...(contactInfo.email
    ? [
        {
          icon: faEnvelope,
          content: contactInfo.email,
        },
      ]
    : []),
];

export const TopCard = ({ contactInfo }) => {
  const typeLabel = getTypeLabel(contactInfo);
  const companyLabel = getCompanyLabel(contactInfo);
  const items = getItems(contactInfo);

  return (
    <>
      <Grid
        py={1}
        container
        justifyContent={{ xs: 'flex-start', md: 'center' }}
      >
        <Grid item>
          <ContactAvatar contactInfo={contactInfo} />
        </Grid>
        <Grid item p={1} xs={8}>
          <Grid item xs={12}>
            <Typography
              fontWeight={'500'}
              color={'#979797'}
              variant={'body1'}
              fontSize={20}
            >
              {conversion.getName(contactInfo)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontWeight={'500'}
              color={'#979797'}
              variant={'body1'}
              fontSize={12}
            >
              {typeLabel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontWeight={'500'}
              color={'#979797'}
              variant={'body1'}
              fontSize={12}
            >
              {companyLabel && companyLabel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ContactInfoItems items={items} />
          </Grid>
          <Grid item xs={12}>
            <Box pt={1}>
              {contactInfo.type === CONTACT_TYPES.CUSTOMER.value ? (
                <CustomerRating customerScore={contactInfo.customerScore} />
              ) : (
                <VendorRating score={contactInfo.score} />
              )}
            </Box>
          </Grid>
          {contactInfo.type === CONTACT_TYPES.VENDOR.value && (
            <Grid item xs={12}>
              <Box pt={1}></Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ModelDetailsCollapse data={contactInfo} />
    </>
  );
};

TopCard.propTypes = {
  contactInfo: PropTypes.object.isRequired,
};
