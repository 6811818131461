import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { ItemCountProvider } from 'contexts';
import { useGetTransactionQuery } from 'redux/rtk-query';
import { TimeframesList } from './components/TimeframesList';

export const TimeframeCard = ({ transactionId, ...props }) => {
  let { transactionDetailsId } = useParams();
  const { data } = useGetTransactionQuery(
    { id: transactionId || transactionDetailsId },
    {
      skip: !transactionId && !transactionDetailsId,
    },
  );

  const transaction = data?.data || {};
  return (
    <ItemCountProvider>
      <AbeCard {...props} title={'Timeframes'}>
        <Divider />
        <TimeframesList transaction={transaction} />
      </AbeCard>
    </ItemCountProvider>
  );
};

TimeframeCard.propTypes = {
  transactionId: PropTypes.string,
};
