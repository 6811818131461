import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ImageList, ImageListItem, Button } from '@mui/material';
import CenteredImage from 'components/Common/CenteredImage';
import { colors } from 'consts';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const GalleryImages = ({ files, cols = 4, onClose }) => {
  const dispatch = useDispatch();
  const activeItem = useSelector(selectCurrentItem);

  const handleImageClick = (fileUuid) => () => {
    dispatch(
      updateItemCM({
        itemId: activeItem.id,
        properties: {
          fileId: fileUuid,
          backgroundH: 0,
          backgroundW: 0,
          positionX: 0,
          positionY: 0,
        },
      }),
    );
    onClose();
  };

  return (
    <ImageList
      cols={cols} // Set the number of columns
      gap={0} // Adjust gap between images
      sx={{
        padding: 0,
        margin: 0,
      }}
    >
      {files.map((file) => (
        <ImageListItem key={file.uuid || file.fileUuid}>
          <Button
            onClick={handleImageClick(file.uuid || file.fileUuid)}
            sx={{
              p: 0,
              width: '100%',
              '&:hover': {
                border: `4px solid ${colors.LIGHT_BLUE_1}`,
                borderRadius: 0,
              },
            }}
          >
            <CenteredImage
              fitWidth={file.mimetype === 'image/svg+xml'}
              file={{
                uuid: file.uuid || file.fileUuid,
              }}
            />
          </Button>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

GalleryImages.propTypes = {
  files: PropTypes.array.isRequired,
  cols: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default GalleryImages;
