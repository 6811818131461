import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/baseQuery';
import { invoiceUrlBuilder } from './urlBuilder/invoices';

export const invoicesAPI = createApi({
  reducerPath: 'invoicesAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Invoice', 'Tasks', 'Commissions'],
  endpoints: (build) => ({
    validateInvoice: build.mutation({
      query: (body) => ({
        url: invoiceUrlBuilder.validateInvoice(),
        method: 'POST',
        data: {
          data: body,
        },
      }),
      invalidatesTags: [],
    }),
    createInvoice: build.mutation({
      query: (body) => ({
        url: invoiceUrlBuilder.createInvoice(),
        method: 'POST',
        data: {
          data: body,
        },
      }),
      invalidatesTags: ['Invoice'],
    }),
    updateInvoice: build.mutation({
      query: (body) => ({
        url: invoiceUrlBuilder.updateInvoice(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Invoice', 'Tasks', 'Commissions'],
    }),
    getInvoice: build.query({
      query: (args) => ({
        url: invoiceUrlBuilder.getInvoice(args),
        method: 'GET',
      }),
      providesTags: ['Invoice'],
    }),
    getInvoices: build.query({
      query: (args) => ({
        url: invoiceUrlBuilder.getInvoices(args),
        method: 'GET',
      }),
      providesTags: ['Invoice'],
    }),
    getInvoiceMedia: build.query({
      query: (args) => ({
        url: invoiceUrlBuilder.getInvoiceMedia(args),
        method: 'GET',
      }),
      providesTags: ['Notes'],
    }),
    getAvailablePeriods: build.query({
      query: (args) => ({
        url: invoiceUrlBuilder.getAvailablePeriods(args),
        method: 'GET',
      }),
      providesTags: ['Invoice'],
    }),
  }),
});

export const {
  useValidateInvoiceMutation,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useGetInvoiceMediaQuery,
  useGetAvailablePeriodsQuery,
} = invoicesAPI;
