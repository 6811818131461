import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useChangePasswordMutation } from 'redux/rtk-query';
import PasswordForm from './PasswordForm';

export const Password = ({ userId }) => {
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const onSubmit = (values) => {
    changePassword({
      userId,
      ...values,
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        password: '',
        confirm: '',
      }}
      component={(props) => <PasswordForm {...props} isLoading={isLoading} />}
    />
  );
};

Password.propTypes = {
  userId: PropTypes.string,
};
