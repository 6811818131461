import { USER_STATUSES } from 'consts/user';

export const defaultValues = {
  username: null,
  contact: null,
  setting: null,
  firstName: null,
  middleName: null,
  lastName: null,
  nickname: null,
  email: null,
  roles: [],
  groups: [],
  companyStructureUnits: [],
  language: null,
  locale: null,
  timezone: null,
  currency: null,
  status: USER_STATUSES[0].value,
  password: null,
  confirm: null,
};
