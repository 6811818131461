import React from 'react';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CollapsableMenuGroup } from 'components/Common';
import DeleteMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/DeleteMenuItem';
import DuplicateMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/DuplicateMenuItem';
import MoveToBackMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/MoveToBackMenuItem';
import MoveToFrontMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/MoveToFrontMenuItem';

const OptionsMenuItem = () => {
  return (
    <CollapsableMenuGroup
      label={'Options'}
      icon={<FontAwesomeIcon icon={faCog} fontSize={'1.8rem'} />}
    >
      <MoveToBackMenuItem />
      <MoveToFrontMenuItem />
      <DuplicateMenuItem />
      <DeleteMenuItem />
    </CollapsableMenuGroup>
  );
};

export default OptionsMenuItem;
