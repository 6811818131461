import React, { useRef, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { Outlet } from 'react-router-dom';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
} from '@mui/material';
import { CreateButton, FontAwesomeIconComp } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { ButtonSection, PageMainContent } from 'components/Styled';
import * as RoutesLinks from 'consts/routes';
import { ContactsTable, ListsSection } from './components';
import { Popper } from './styled';

export const ContactsPage = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <>
      <LayoutHeader title={'Contacts'}>
        <ButtonSection>
          <Button variant="grey">Merge Suggestions</Button>
          <Button variant="grey">Merge</Button>
          <Button
            variant="grey"
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={() => setOpen(!open)}
            endIcon={
              <FontAwesomeIconComp
                icon={open ? faChevronUp : faChevronDown}
                color="#969696"
              />
            }
          >
            Export All
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{ zIndex: 10, borderRadius: '8px' }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                  borderRadius: '8px',
                }}
              >
                <Paper sx={{ mt: '8px' }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem>CSV of Table</MenuItem>
                      <MenuItem>{'CSV of "Entire Record"'}</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <CreateButton
            path={RoutesLinks.CREATE_CONTACT}
            title="Create Contact"
          />
        </ButtonSection>
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <ListsSection />
        <ContactsTable />
      </PageMainContent>
      <Outlet />
    </>
  );
};
