import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Grid } from '@mui/material';
import { FontAwesomeIconComp, FormPanel } from 'components/Common';
import { CONTACT_TYPES } from 'consts';
import {
  AddContactRelationshipModal,
  AddDataRelationshipModal,
  RelationshipInfo,
} from './components';
import { AddButton } from './styled';

export const RelationshipBox = () => {
  const [showAddContact, setShowAddContact] = useState(false);
  const [showAddData, setShowAddData] = useState(false);
  const { getState } = useForm();

  return [CONTACT_TYPES.LEAD.value, CONTACT_TYPES.CUSTOMER.value].includes(
    getState().values.type,
  ) ? (
    <>
      <FormPanel label={'Relationships'}>
        <AddButton
          variant="outlined"
          startIcon={<FontAwesomeIconComp icon={faPlus} />}
          onClick={() => setShowAddContact(true)}
        >
          Add Existing Contact
        </AddButton>
        <AddButton
          variant="outlined"
          startIcon={<FontAwesomeIconComp icon={faPlus} />}
          onClick={() => setShowAddData(true)}
        >
          Add Data Only
        </AddButton>
        <Grid container mt={1}>
          <FieldArray name="relations">
            {({ fields }) =>
              fields.map((name, index) => {
                return (
                  <RelationshipInfo
                    key={index}
                    relationship={getState().values.relations[index]}
                  />
                );
              })
            }
          </FieldArray>
        </Grid>
      </FormPanel>
      <AddContactRelationshipModal
        open={showAddContact}
        closeHandler={() => setShowAddContact(false)}
      />
      <AddDataRelationshipModal
        open={showAddData}
        closeHandler={() => setShowAddData(false)}
      />
    </>
  ) : null;
};

RelationshipBox.propTypes = {
  push: PropTypes.func,
};
