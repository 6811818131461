import React from 'react';
import { faBringForward } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItemButton, FontAwesomeIconComp } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { moveToFrontCM } from 'redux/slices';

const MoveToFrontMenuItem = () => {
  const dispatch = useDispatch();
  const activeField = useSelector(selectCurrentItem);

  const handleMoveToFrontClick = () => {
    dispatch(moveToFrontCM(activeField.id));
  };

  return (
    <MenuItemButton
      icon={<FontAwesomeIconComp fontSize={18} icon={faBringForward} />}
      label={'Send Forward'}
      onClick={handleMoveToFrontClick}
    />
  );
};

export default MoveToFrontMenuItem;
