import { useForm } from 'react-final-form';

export function useIsRequired(name) {
  let required = false;

  if (name) {
    let form = useForm();
    const field = form.getFieldState(name);
    if (name === 'rolesStr.Buyer') {
      console.log('name:', field);
    }
    if (field && field.data && field.data.required) {
      required = true;
    }
  }
  return required;
}
