// PositionedImage.js

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useIsActive from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useIsActive'; // Adjust the import path accordingly
import useGetMediaUrl from 'hooks/useGetMediaUrl';
import { itemGroupsSelectorCM, selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices/clickMarketing/clickMarketing';

const PositionedImage = ({
  src,
  alt = '',
  positionX = 0,
  positionY = 0,
  backgroundW,
  backgroundH,
  containerWidth,
  containerHeight,
  borderRadius = 0,
  style = {},
  itemId,
}) => {
  const dispatch = useDispatch();
  const isActive = useIsActive(itemId);
  const itemGroups = useSelector(itemGroupsSelectorCM);
  const currentItem = useSelector(selectCurrentItem);
  const activeStyle = currentItem
    ? itemGroups?.[currentItem.page]?.[currentItem.modularType]?.[
        currentItem.templateId
      ]?.activeStyle
    : null;
  const [fileSrc, setFileSrc] = useState(
    typeof imageSrc === 'object' ? null : src,
  );
  // const realSrc = typeof imageSrc === 'object' ? imageSrc.url : imageSrc;

  const { data: fileData } = useGetMediaUrl(
    {
      uuid: src?.uuid,
    },
    {
      skip: src?.uuid,
    },
  );

  useEffect(() => {
    if (fileData) {
      setFileSrc(fileData?.data?.url);
    }
  }, [fileData]);

  // State variables for dragging
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [initialPositionX, setInitialPositionX] = useState(positionX);
  const [initialPositionY, setInitialPositionY] = useState(positionY);

  // Refs to hold current positions
  const currentPositionXRef = useRef(positionX);
  const currentPositionYRef = useRef(positionY);

  // State variables for rendering
  const [currentPositionX, setCurrentPositionX] = useState(positionX);
  const [currentPositionY, setCurrentPositionY] = useState(positionY);

  // Update refs and state when positions change
  useEffect(() => {
    currentPositionXRef.current = positionX;
    currentPositionYRef.current = positionY;
    setCurrentPositionX(positionX);
    setCurrentPositionY(positionY);
  }, [positionX, positionY]);

  // Load image dimensions if backgroundW or backgroundH are missing
  useEffect(() => {
    if (!backgroundW || !backgroundH) {
      const image = new Image();
      image.src = fileSrc;

      image.onload = () => {
        const imageRatio = image.width / image.height;
        const itemWidth = parseFloat(containerWidth);
        const itemHeight = parseFloat(containerHeight);

        let targetWidth, targetHeight;

        // Determine which dimension fills first
        if (image.width / itemWidth < image.height / itemHeight) {
          // Width fills first
          targetWidth = itemWidth;
          targetHeight = itemWidth / imageRatio;
        } else {
          // Height fills first
          targetHeight = itemHeight;
          targetWidth = itemHeight * imageRatio;
        }

        // Center the image
        const newPositionX = (itemWidth - targetWidth) / 2;
        const newPositionY = (itemHeight - targetHeight) / 2;

        // Dispatch the update action
        dispatch(
          updateItemCM({
            itemId,
            properties: {
              backgroundW: targetWidth,
              backgroundH: targetHeight,
              positionX: newPositionX,
              positionY: newPositionY,
            },
          }),
        );
      };

      image.onerror = () => {
        console.error('Failed to load image:', fileSrc);
      };
    }
  }, [
    backgroundW,
    backgroundH,
    fileSrc,
    containerWidth,
    containerHeight,
    dispatch,
    itemId,
    activeStyle,
  ]);

  // Handle dragging events
  useEffect(() => {
    const handleMouseMoveWindow = (e) => {
      handleMouseMove(e);
    };

    const handleMouseUpWindow = () => {
      handleMouseUp();
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMoveWindow);
      window.addEventListener('mouseup', handleMouseUpWindow);
    } else {
      window.removeEventListener('mousemove', handleMouseMoveWindow);
      window.removeEventListener('mouseup', handleMouseUpWindow);
    }

    // Cleanup on unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMoveWindow);
      window.removeEventListener('mouseup', handleMouseUpWindow);
    };
  }, [isDragging]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    if (!isActive) return;
    setIsDragging(true);
    setStartX(e.clientX);
    setStartY(e.clientY);
    setInitialPositionX(currentPositionXRef.current);
    setInitialPositionY(currentPositionYRef.current);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const deltaX = e.clientX - startX;
    const deltaY = e.clientY - startY;

    let newPositionX = initialPositionX + deltaX;
    let newPositionY = initialPositionY + deltaY;

    // Constrain the newPositionX and newPositionY
    const parsedContainerWidth = parseFloat(containerWidth);
    const parsedContainerHeight = parseFloat(containerHeight);
    const parsedBackgroundW = parseFloat(backgroundW);
    const parsedBackgroundH = parseFloat(backgroundH);

    const diffX = parsedContainerWidth - parsedBackgroundW;
    const diffY = parsedContainerHeight - parsedBackgroundH;

    let minX, maxX;
    if (diffX <= 0) {
      // Image is wider than container
      minX = diffX;
      maxX = 0;
    } else {
      // Image is narrower than container
      minX = 0;
      maxX = diffX;
    }

    let minY, maxY;
    if (diffY <= 0) {
      // Image is taller than container
      minY = diffY;
      maxY = 0;
    } else {
      // Image is shorter than container
      minY = 0;
      maxY = diffY;
    }

    newPositionX = Math.min(Math.max(newPositionX, minX), maxX);
    newPositionY = Math.min(Math.max(newPositionY, minY), maxY);

    // Update refs
    currentPositionXRef.current = newPositionX;
    currentPositionYRef.current = newPositionY;

    // Update state to trigger re-render
    setCurrentPositionX(newPositionX);
    setCurrentPositionY(newPositionY);

    // Optionally update Redux store here for live updates
    // dispatch(updateItemCM({ itemId, properties: { positionX: newPositionX, positionY: newPositionY } }));
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);

      // Dispatch the update to Redux using refs
      dispatch(
        updateItemCM({
          itemId,
          properties: {
            positionX: currentPositionXRef.current,
            positionY: currentPositionYRef.current,
          },
        }),
      );
    }
  };

  const sanitizedBackgroundW =
    typeof backgroundW === 'number' ? Math.max(backgroundW, 1) : backgroundW;
  const sanitizedBackgroundH =
    typeof backgroundH === 'number' ? Math.max(backgroundH, 1) : backgroundH;

  const containerStyle = {
    position: 'relative',
    width: containerWidth,
    height: containerHeight,
    overflow: 'hidden',
    borderRadius,
    ...style,
  };

  const imageStyle = {
    position: 'absolute',
    left: currentPositionX,
    top: currentPositionY,
    width: sanitizedBackgroundW,
    height: sanitizedBackgroundH,
    cursor: isDragging ? 'grabbing' : 'grab',
    userSelect: 'none',
  };

  return (
    <div style={containerStyle}>
      <img
        src={fileSrc}
        alt={alt}
        style={imageStyle}
        onMouseDown={handleMouseDown}
        draggable={false}
      />
    </div>
  );
};

PositionedImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  positionX: PropTypes.number,
  positionY: PropTypes.number,
  backgroundW: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundH: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  containerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  containerHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  borderRadius: PropTypes.number,
  style: PropTypes.object,
  itemId: PropTypes.string.isRequired,
};

export default PositionedImage;
