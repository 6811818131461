import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card } from '@mui/material';
import { DetailsPanels } from 'components/Common';
import { DetailsLayout } from 'components/Layouts';
import { DetailsBox, DetailsWrapper } from 'components/Styled';
import { UPDATE_CAPPING } from 'consts';
import { useGetCappingQuery } from 'redux/rtk-query';

export const DetailsCappingPage = () => {
  let { cappingDetailsId } = useParams();
  const navigate = useNavigate();
  const { data: cappingData } = useGetCappingQuery(
    { id: cappingDetailsId },
    { refetchOnMountOrArgChange: true },
  );

  return (
    <>
      {cappingData && (
        <>
          <DetailsLayout
            header={
              <>
                <span>{cappingData.data.name}</span>
                <Button
                  size={'small'}
                  onClick={() => {
                    navigate(UPDATE_CAPPING(cappingDetailsId));
                  }}
                >
                  Edit
                </Button>
              </>
            }
            leftContent={
              <>
                <DetailsWrapper>
                  <Card>
                    <DetailsBox>
                      <Box width={'100%'}>
                        <DetailsPanels
                          data={cappingData?.data}
                          panels={{
                            Overview: [
                              'name',
                              'calculationType',
                              'calculationPeriod',
                              'cappingLevels',
                            ],
                          }}
                        />
                      </Box>
                    </DetailsBox>
                  </Card>
                </DetailsWrapper>
              </>
            }
            rightContent={<></>}
          />
          <Outlet />
        </>
      )}
    </>
  );
};
