import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import { omitNull } from 'helpers';
import { usePrevious } from 'hooks';
import {
  documentsAPI,
  tasksAPI,
  useGetTransactionQuery,
  useUpdateTransactionMutation,
} from 'redux/rtk-query';
import { defaultValues } from '../TransactionForm/defaultValues';
import {
  checkForTimeframeValidations,
  processFormData,
} from '../TransactionForm/formData';
import { TransactionForm } from '../TransactionForm/TransactionForm';

export const EditTransactionPage = ({ params }) => {
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [updateTransaction] = useUpdateTransactionMutation();

  const paramName = Object.keys(params)[0];
  const { data: transactionData, isSuccess } = useGetTransactionQuery({
    id: params[paramName],
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prevIsOpen = usePrevious(isOpen);

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      setTimeout(function () {
        navigate(-1);
      }, 500);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      let data = { ...transactionData.data };
      data.agent = data?.modelAccess?.owner;
      data.shareWithIds = data?.modelAccess?.sharedWithUsers;
      data.companyStructureUnits = data?.modelAccess?.sharedWithUnits;
      // PARSE ROLES FROM ARRAY TO OBJECT WITH ROLE KEYS
      data.timeframeCalculation = {
        timeframeCalculation:
          'Calendar Days, Ends at Midnight, Skip if ends Weekends, Holidays and Observed Holidays and ends at 11:59pm',
      };
      data.outsideReferral = data.outsideReferral ? 'Yes' : 'No';
      data.addressConfirmed =
        data.address && data.address.street1 && data.address.street1.length > 0;
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull(data),
      });
      setIsOpen(true);
    }
  }, [isSuccess]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    let timeframeValidations = checkForTimeframeValidations(formData);
    if (timeframeValidations.timeframes.length > 0) {
      return {
        error: {
          data: {
            message: timeframeValidations.timeframes,
          },
        },
      };
    }
    return await updateTransaction(formData).then((response) => {
      dispatch(tasksAPI.util.invalidateTags(['Tasks']));
      dispatch(documentsAPI.util.invalidateTags(['Documents']));
      return response;
    });
  };

  return (
    <SideDrawer width={'95%'} isOpen={isOpen}>
      <TransactionForm
        title={initialValues.name}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditTransactionPage.propTypes = {
  params: PropTypes.object,
};
