import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
});

export const rolesSliceReducer = rolesSlice.reducer;
