import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import { InvoiceForm } from 'components/ModelViews/Invoices/InvoiceForm';
import { defaultValues } from 'components/ModelViews/Invoices/InvoiceForm/defaultValues';
import { conversion } from 'helpers';
import { useDrawer, useFetchRelatedModel } from 'hooks';
import {
  contactsAPI,
  transactionsAPI,
  useCreateInvoiceMutation,
  useGetInvoiceTemplateQuery,
} from 'redux/rtk-query';
import { processFormData } from '../InvoiceForm/formData';

export const CreateInvoiceModal = ({ params, path }) => {
  const dispatch = useDispatch();
  const relatedParams = useParams();
  const location = useLocation();
  const commission = location.state?.commission;
  const relatedModelId = parseInt(
    relatedParams.contactDetailsId || relatedParams.transactionDetailsId,
  );
  const relatedModelType = relatedParams.contactDetailsId
    ? 'contact'
    : 'transaction';

  const [initialValues, setInitialValues] = useState({});

  const [createInvoice] = useCreateInvoiceMutation();
  const { isOpen, openDrawer, closeDrawer } = useDrawer(true, path);

  const invoiceTemplateId = params['invoiceTemplateId'];

  const { data: invoiceTemplateData } = useGetInvoiceTemplateQuery({
    id: invoiceTemplateId,
  });

  const response = useFetchRelatedModel(relatedModelType, relatedModelId);
  const relatedModel = response.data?.data;

  const handleClose = () => {
    closeDrawer();
  };

  const afterSave = () => {
    handleClose();
  };

  useEffect(() => {
    if (invoiceTemplateData?.data?.type) {
      setInitialValues({
        ...defaultValues,
        name: invoiceTemplateData?.data?.name,
        invoiceTemplate: invoiceTemplateData?.data,
        type: invoiceTemplateData?.data?.type,
        transaction: relatedModelType === 'transaction' ? relatedModel : null,
        period:
          relatedModelType === 'transaction' ? relatedModel?.period : null,
        contact: relatedModelType === 'contact' ? relatedModel : null,
        commission,
      });
      openDrawer();
    }
  }, [relatedModelType, relatedModelId, relatedModel, invoiceTemplateData]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    return await createInvoice(formData).then(() => {
      if (relatedModelType === 'contact') {
        dispatch(contactsAPI.util.invalidateTags(['Invoices']));
      }
      if (relatedModelType === 'transaction') {
        dispatch(transactionsAPI.util.invalidateTags(['Invoices']));
      }
    });
  };

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      <InvoiceForm
        title={invoiceTemplateData?.data?.name}
        subTitle={conversion.getName(relatedModel)}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

CreateInvoiceModal.propTypes = {
  path: PropTypes.string,
  params: PropTypes.object,
};
