import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { Paper, Grid } from '@mui/material';
import { SideDrawer, SaveAndCancelButtons } from 'components/Common';
import { ContactAutocomplete, Select } from 'components/Fields';
import { Header, HeadingSection, CreateBox } from 'components/Styled';
import { roleTypes } from 'consts';
import { getSelectOptions, conversion } from 'helpers';

export const OtherRoleSelection = ({ isOpen, handleClose }) => {
  const [otherRole, setOtherRole] = React.useState('');
  const [roleContact, setRoleContact] = React.useState(null);
  const form = useForm(); // Access the form API

  const addOtherRole = (e) => {
    setOtherRole(e.target.value);
  };

  const handleSave = () => {
    if (!roleContact || !otherRole) {
      return;
    }
    let roles = form.getFieldState('roles').value;
    roles.push({
      id: roleContact.value,
      contact: roleContact.value,
      order: 1,
      role: otherRole,
    });
    form.change('roles', roles);

    handleClose();
  };

  // Create a MUI Select field with the role options inside the box
  return (
    <SideDrawer width={'45%'} isOpen={isOpen}>
      <HeadingSection>
        <Header variant="h4">Add Role</Header>
        <SaveAndCancelButtons
          closeDrawer={handleClose}
          onClickSave={handleSave}
        />
      </HeadingSection>

      <Paper elevation={0} sx={{ padding: 2, margin: 2 }}>
        <CreateBox spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                isRequired
                value={otherRole}
                input={{
                  onChange: addOtherRole,
                  value: otherRole,
                }}
                meta={{}}
                label={'Role'}
                options={getSelectOptions(roleTypes)}
              />
            </Grid>
            <Grid item xs={12}>
              <ContactAutocomplete
                required
                name="contact"
                label="Contact"
                getOption={(contact) => ({
                  value: contact,
                  label: conversion.getName(contact),
                  key: contact.id,
                })}
                input={{
                  onChange: (value) => {
                    setRoleContact(value);
                  },
                  value: roleContact || '',
                }}
                meta={{ touched: true, error: '' }}
              />
            </Grid>
          </Grid>
        </CreateBox>
      </Paper>
    </SideDrawer>
  );
};

OtherRoleSelection.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
