import { nanoid } from 'nanoid';
import { setMenu } from '../documentEditor';
import {
  adjustFieldTemplatesOnUpdate,
  adjustGroupCheckboxFieldsOnUpdate,
  alignItems,
  applyAndTrackChanges,
  assignRoleToNewItem,
  checkForMissingSignerRolesWithoutFields,
  collectActiveFields,
  collectGroupFieldIds,
  createInitialsForSigners,
  createNewItem,
  extractGroupIds,
  findExtremePosition,
  parseActiveFieldIds,
  reflectPosition,
  trackFieldChange,
  updateFieldTemplates,
  updateProperties,
} from '../helpers';

export const fieldsReducer = {
  setPlacingItem: (state, action) => {
    state.placingItem = action.payload;
  },
  updateField: (state, action) => {
    const initialTemplate = JSON.parse(
      JSON.stringify(state.currentDocument.htmlTemplate),
    );

    const { fieldId, properties: newProperties } = action.payload;
    const neededFields = fieldId ? [fieldId] : state.activeFieldIds;
    const properties = updateProperties(newProperties);

    reflectPosition(state, fieldId, neededFields, properties);
    adjustGroupCheckboxFieldsOnUpdate(state, neededFields[0], properties);
    adjustFieldTemplatesOnUpdate(state, neededFields[0], properties);

    if (!properties?.collisions && !properties?.error) {
      state.needsSaving = true;
    }

    state.currentDocument.htmlTemplate = state.currentDocument.htmlTemplate.map(
      (field) => {
        if (neededFields.includes(field.id)) {
          return { ...field, ...properties };
        }
        return field;
      },
    );

    if (properties?.role) {
      checkForMissingSignerRolesWithoutFields(state);
    }

    // Capture the final state of htmlTemplate
    const finalTemplate = state.currentDocument.htmlTemplate;

    // Compare initial and final templates to log changes
    const changes = finalTemplate
      .map((field, index) => {
        if (JSON.stringify(field) !== JSON.stringify(initialTemplate[index])) {
          return {
            fieldId: field.id,
            before: initialTemplate[index],
            after: field,
          };
        }
        return null;
      })
      .filter((change) => change !== null);

    const skipChanges = ['collisions', 'error', 'fontSize'];
    if (
      changes.length > 0 &&
      skipChanges.every((prop) => !Object.keys(properties).includes(prop))
    ) {
      trackFieldChange(state, changes);
    }
  },
  deleteSelectedFields: (state) => {
    let changes = [];
    let remainingFields = [];

    state.currentDocument.htmlTemplate.forEach((field) => {
      if (state.activeFieldIds.includes(field.id)) {
        // Track the deletion
        changes.push({ fieldId: field.id, before: field, after: null });
      } else {
        // Keep the field if it's not being deleted
        remainingFields.push(field);
      }
    });

    trackFieldChange(state, changes);
    state.currentDocument.htmlTemplate = remainingFields;
    state.activeFieldIds = [];
  },
  setActiveField: (state, action) => {
    const activeFieldIds = parseActiveFieldIds(action.payload);
    const activeFields = collectActiveFields(
      state.currentDocument.htmlTemplate,
      activeFieldIds,
    );
    const groupIds = extractGroupIds(activeFields);
    const groupFieldIds = collectGroupFieldIds(
      state.currentDocument.htmlTemplate,
      groupIds,
    );
    state.activeFieldIds = [...new Set([...activeFieldIds, ...groupFieldIds])];
    setMenu(state, { payload: 'primary' });
  },
  dropPlacingItem: (state, action) => {
    const { x, y, width, height, pageNumber } = action.payload;
    const placingItem = state.placingItem;
    const role = assignRoleToNewItem(
      placingItem,
      state.currentDocument.roles,
      state.fieldsTemplates[placingItem],
    );

    const newItem = createNewItem({
      x,
      y,
      width,
      height,
      pageNumber,
      placingItem,
      role,
    });
    newItem.required = [
      'signatureField',
      'signatureInitialField',
      'signDate',
      'printName',
    ].includes(placingItem)
      ? 'yes'
      : undefined;

    const changes = [{ fieldId: newItem.id, before: null, after: newItem }];
    trackFieldChange(state, changes);

    updateFieldTemplates(state, placingItem, width, height, role);
    state.currentDocument.htmlTemplate.push(newItem);

    if (state.placingItem === 'strikethrough') {
      state.activeFieldIds = createInitialsForSigners(
        state,
        x,
        y,
        width,
        height,
        pageNumber,
      );
    } else {
      state.activeFieldIds = [newItem.id];
    }
    state.placingItem = false;
  },
  arrangeGroup: (state, action) => {
    const direction = action.payload;
    let itemsToArrange = state.currentDocument.htmlTemplate.filter((field) =>
      state.activeFieldIds.includes(field.id),
    );

    if (itemsToArrange.length === 0) return;

    const extremePosition = findExtremePosition(
      state.currentDocument.htmlTemplate.filter((field) =>
        state.activeFieldIds.includes(field.id),
      ),
      direction,
    );
    applyAndTrackChanges(state, (field) =>
      alignItems(field, extremePosition, direction),
    );
  },
  groupFields: (state) => {
    const groupId = nanoid();
    const fieldsIds = state.activeFieldIds;
    state.currentDocument.htmlTemplate = state.currentDocument.htmlTemplate.map(
      (field) => {
        if (fieldsIds.includes(field.id)) {
          field.groupId = groupId;
        }
        return field;
      },
    );
  },
  unGroupFields: (state) => {
    state.currentDocument.htmlTemplate = state.currentDocument.htmlTemplate.map(
      (field) => {
        if (state.activeFieldIds.includes(field.id)) {
          field.groupId = null;
          field.groupType = null;
        }
        return field;
      },
    );
  },
  duplicateOnAllPages: (state) => {
    ///take state.numPages and duplicate the selected field (should only ever be one selected here) on all pages except the page the field is currently on
    const selectedField = state.currentDocument.htmlTemplate.find((field) =>
      state.activeFieldIds.includes(field.id),
    );
    const newFields = [];
    const changes = [];
    for (let i = 1; i <= state.numPages; i++) {
      // page number from selectedField.pageContainer which is "pageContainer1" for page 1, etc,
      if (i !== parseInt(selectedField.pageContainer.match(/\d+/)[0], 10)) {
        const newField = {
          ...selectedField,
          id: nanoid(),
          pageContainer: `pageContainer${i}`,
        };
        newFields.push(newField);
        changes.push({ fieldId: newField.id, before: null, after: newField });
      }
    }
    state.currentDocument.htmlTemplate =
      state.currentDocument.htmlTemplate.concat(newFields);
    trackFieldChange(state, changes);
  },
  manageTemplate: (state, action) => {
    //action.payload.action is either "removeFields" or "addFields"
    //there is also action.payload.includeTextCheckboxes and action.payload.includeSignatureInitials
    const htmlTemplate = state.currentDocument.htmlTemplate;
    const newItems = [];
    if (action.payload.action === 'removeFields') {
      htmlTemplate.forEach((item) => {
        if (
          (action.payload.includeSignatureInitials &&
            ![
              'signatureField',
              'signatureInitialField',
              'signDate',
              'printName',
            ].includes(item.type)) ||
          (action.payload.includeTextCheckboxes &&
            [
              'signatureField',
              'signatureInitialField',
              'signDate',
              'printName',
            ].includes(item.type))
        ) {
          newItems.push(item);
        }
      });
    } else {
      const defaultFieldArray =
        state.currentDocument.defaultTemplate.htmlTemplate;
      defaultFieldArray.forEach((item) => {
        if (
          (action.payload.includeSignatureInitials &&
            [
              'signatureField',
              'signatureInitialField',
              'signDate',
              'printName',
            ].includes(item.type)) ||
          (action.payload.includeTextCheckboxes &&
            ![
              'signatureField',
              'signatureInitialField',
              'signDate',
              'printName',
            ].includes(item.type))
        ) {
          newItems.push(item);
        }
      });
    }

    state.currentDocument.htmlTemplate = newItems;
  },
};
