import React from 'react';
import PropTypes from 'prop-types';
import { roleTypes } from 'consts';
import { getRolesByType } from 'helpers/roles';
import { RolesField } from '../RolesField';

export const OtherRolesFields = (props) => {
  return (
    <div>
      {Object.values(roleTypes).map((roleType) => {
        if (getRolesByType(props.input.value, roleType).length > 0) {
          return (
            <RolesField
              {...props}
              key={roleType}
              noAdd
              role={roleType}
              contactType={roleType}
            />
          );
        }
      })}
    </div>
  );
};

OtherRolesFields.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  noAdd: PropTypes.bool,
  role: PropTypes.string,
  contactType: PropTypes.string,
  removeRole: PropTypes.func,
  updateRoleName: PropTypes.func,
  addRole: PropTypes.func,
  hideModalList: PropTypes.func,
};
