import React from 'react';
import PropTypes from 'prop-types';
import { faImagePolaroid } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItemButton, SideDrawer } from 'components/Common';
import MediaLibrary from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/MediaLibrary/MediaLibrary';

const ChangeImageMenuItem = ({ hasImage }) => {
  const [showMediaLibrary, setShowMediaLibrary] = React.useState(false);
  return (
    <>
      <MenuItemButton
        label={`${hasImage ? 'Change' : 'Select'} Image`}
        icon={<FontAwesomeIcon icon={faImagePolaroid} fontSize={18} />}
        onClick={() => {
          setShowMediaLibrary(true);
        }}
      />
      <SideDrawer width={'80%'} isOpen={showMediaLibrary}>
        <MediaLibrary
          onClose={() => {
            setShowMediaLibrary(false);
          }}
        />
      </SideDrawer>
    </>
  );
};

ChangeImageMenuItem.propTypes = {
  hasImage: PropTypes.bool,
};

export default ChangeImageMenuItem;
