import { useForm } from 'react-final-form';
import { useFormValidation } from 'components/Forms';
import { handleResetRequired } from 'helpers';

// Custom hook to encapsulate the validation logic
export function useFormValidationProcess() {
  const form = useForm(); // useForm hook from react-final-form
  const { validate, processFormData } = useFormValidation(); // Assuming useFormValidation is a context or another hook

  return async (name = null, value = null) => {
    // make copy of form values
    let values = form.getState().values;
    if (name && value) {
      values = {
        ...values,
        [name]: value,
      };
    }
    const formData = processFormData(values);
    const response = await validate(formData);
    handleResetRequired(form);
    if (response?.error) {
      form.batch(() => {
        response.error.data.message.forEach((error) => {
          const errorData = JSON.parse(error);
          form.registerField(
            errorData[0],
            (fieldState) => {
              // eslint-disable-next-line no-unused-vars
              const { tooltip } = fieldState.data; // true
            },
            {
              // ...other subscription items
              data: true,
            },
          );
          if (errorData[1] === 'required') {
            form.mutators.setFieldData(errorData[0], { required: true });
          } else {
            form.mutators.setFieldData(errorData[0], { error: errorData[1] });
          }
        });
      });
    }
  };
}
