import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import { Box } from '@mui/material';
import { FormPanel, SideDrawer } from 'components/Common';
import { ContactAutocomplete, TextInput } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { conversion } from 'helpers';

export const AddContactRelationshipModal = ({ closeHandler, open }) => {
  const {
    mutators: { push },
  } = useForm();

  const renderFields = () => (
    <Box p={2}>
      <FormPanel startOpen>
        <Field
          required
          name="relation"
          label="Relation"
          component={TextInput}
        />
        <ContactAutocomplete
          required
          name="contact"
          label="Contact"
          getOption={({ id, ...rest }) => ({
            value: conversion.getName(rest),
            label: conversion.getName(rest),
            key: id,
          })}
        />
      </FormPanel>
    </Box>
  );

  const onSubmit = async (values) => {
    push('relations', {
      name: values.contact.value,
      relationType: values.relation,
    });
    closeHandler();
  };

  return (
    <SideDrawer isOpen={open}>
      <AbeForm
        title={'Add a Relationship'}
        handleSubmitFunc={onSubmit}
        afterSave={closeHandler}
      >
        {renderFields}
      </AbeForm>
    </SideDrawer>
  );
};

AddContactRelationshipModal.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
};
