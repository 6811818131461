import { Container, Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';

export const MainContainerWrapper = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '456px',
  height: '472px',
  boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  margin: 0,
});

export const GoogleButton = styled(Box)({
  justifyContent: 'center',
  height: '52px',
  width: '320px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: '#4285f4',
  cursor: 'pointer',
  boxShadow: '0 2px 4px 0 rgb(0 0 0 / 25%)',
  '&:hover': {
    boxShadow: '0px 0px 5px 2px rgba(66, 133, 244, 0.6)',
    transition: 'box-shadow 0.218s',
  },
  '& span': {
    color: '#fff',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '28px',
  },
});

export const GoogleLogo = styled(Box)({
  borderRadius: '4px',
  height: '24px',
  width: '24px',
  marginRight: '8px',
});

export const CredsButton = styled(Box)({
  justifyContent: 'center',
  height: '52px',
  width: '320px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid #E8E8E8',
  cursor: 'pointer',
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '28px',
  '&:hover': {
    boxShadow: '0px 0px 5px 2px rgba(66, 133, 244, 0.6)',
    transition: 'box-shadow 0.218s',
  },
});

export const AlterTextWrapper = styled(Box)({
  display: 'flex',
  width: '320px',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '24px 0',
});

export const AlterLoginText = styled(Typography)({
  margin: '0 16px',
  color: '#969696',
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '20px',
  textAlign: 'center',
});
export const DividerStyled = styled(Divider)({
  width: '58px',
});

export const LogoBox = styled(Box)({
  marginBottom: '50px',
});

export const AbeLogo = styled(Box)({
  height: '75px',
  marginBottom: '10px',
});
