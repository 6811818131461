import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Button, List, ListItemButton } from '@mui/material';
import { SkeletonSummary, SideDrawer } from 'components/Common';
import { SearchInput } from 'components/SearchInput';
import {
  ButtonSection,
  Header,
  HeaderSubtitle,
  HeadingSection,
} from 'components/Styled';
import { EDIT_DOCUMENT } from 'consts';
import { useDrawer } from 'hooks';
import {
  useGetDocumentQuery,
  useGetDocumentTemplatesListQuery,
  useUpdateDocumentMutation,
} from 'redux/rtk-query';

const AddDocumentItem = ({ doc, onConfirm }) => {
  return (
    <ListItemButton
      onClick={() => {
        onConfirm(doc);
      }}
    >
      {doc.name}
    </ListItemButton>
  );
};

AddDocumentItem.propTypes = {
  doc: PropTypes.object,
  onConfirm: PropTypes.func,
};

export const SelectDocumentDrawer = ({ params, path }) => {
  const navigate = useNavigate();
  const { isOpen, closeDrawer } = useDrawer(true, path);

  const paramName = Object.keys(params)[0];

  const [search, setSearch] = useState('');

  const [updateDocument] = useUpdateDocumentMutation();

  const { data: documentData } = useGetDocumentQuery({
    id: params[paramName],
  });

  const { data, isFetching } = useGetDocumentTemplatesListQuery({
    page: 1,
    limit: 100,
    search: search,
    order: 'ASC',
    orderBy: 'name',
    column: ['name'],
    parentId: documentData?.data?.documentTemplate?.id,
    status: 'Completed',
  });

  const onConfirm = async (id) => {
    await updateDocument({
      id: parseInt(params[paramName]),
      documentTemplateId: id,
    });
    await closeDrawer(() => {
      navigate(`${EDIT_DOCUMENT(params[paramName])}`);
    });
  };

  return (
    <SideDrawer isOpen={isOpen}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box>
          <HeadingSection>
            <Box>
              <Header variant="h4">Select Document</Header>
              <HeaderSubtitle>
                for {documentData?.data?.documentTemplate.name}
              </HeaderSubtitle>
            </Box>
            <ButtonSection>
              <Button
                variant="outlined"
                onClick={() => {
                  closeDrawer();
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </ButtonSection>
          </HeadingSection>
          <Box
            sx={{
              margin: '0px 2px',
              padding: '6px 18px',
              backgroundColor: 'white',
            }}
          >
            <SearchInput changeHandler={setSearch} />
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {isFetching ? (
            <SkeletonSummary />
          ) : (
            <List>
              {data?.data?.map((doc) => (
                <AddDocumentItem
                  key={doc.id}
                  doc={doc}
                  onConfirm={() => {
                    onConfirm(doc.id);
                  }}
                />
              ))}
            </List>
          )}
        </Box>
      </Box>
    </SideDrawer>
  );
};

SelectDocumentDrawer.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
};
