import React from 'react';
import { faSquareCheck, faSquareMinus } from '@fortawesome/pro-solid-svg-icons';
import { styled, Checkbox as MaterialCheckbox } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';

const UncheckedCheckbox = styled('span')({
  boxSizing: 'border-box',
  width: '15px',
  height: '15px',
  border: '1px solid #E8E8E8',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
  borderRadius: '4px',
  backgroundColor: '#ffffff',
  margin: 'auto',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
});

const CheckedIcon = (props) => (
  <FontAwesomeIconComp icon={faSquareCheck} {...props} />
);
const CheckedCheckbox = styled(CheckedIcon)({
  width: '15px',
  height: '15px',
  borderRadius: '4px',
});

const IndeterminateIcon = (props) => (
  <FontAwesomeIconComp icon={faSquareMinus} {...props} />
);
const IndeterminateCheckbox = styled(IndeterminateIcon)({
  width: '15px',
  height: '15px',
  borderRadius: '4px',
});

export const Checkbox = (props) => (
  <MaterialCheckbox
    color="primary"
    sx={{ padding: 0 }}
    icon={<UncheckedCheckbox />}
    checkedIcon={<CheckedCheckbox />}
    indeterminateIcon={<IndeterminateCheckbox />}
    {...props}
  />
);
