import { createSlice } from '@reduxjs/toolkit';

const resetSlice = createSlice({
  name: 'reset',
  initialState: {},
  reducers: {
    resetState: () => {
      return {};
    },
  },
});

export const { resetState } = resetSlice.actions;
export const resetSliceReducer = resetSlice.reducer;
