import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
});

export const transactionsSliceReducer = transactionsSlice.reducer;
