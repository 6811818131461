import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { CreateButton, Table } from 'components/Common';
import { useTableData } from 'hooks';
import { CreateContactPage } from 'pages/ContactsPages';
import { useGetContactsQuery } from 'redux/rtk-query';
import { Cells } from './config';

export const ContactsTable = ({ onRowClick, type, title }) => {
  const [isCreating, setIsCreating] = useState(false);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    search,

    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    rows,
  } = useTableData(useGetContactsQuery, Cells, 'updatedAt', 'desc', 10, {
    type: type,
  });
  // const {data, isLoading} = useGetContactsQuery({
  //     page: page,
  //     limit: limit,
  //     search: search,
  //     column: ['lists', 'notes', 'tasks'],
  //     order: {[`sort_${orderByKey}`]: order.toUpperCase()},
  //     type: type,
  // });

  const navigate = useNavigate();
  const MenuItems = useMemo(
    () => [
      {
        name: 'View',
        icon: faEye,
        onClick: ({ uuid }) => navigate(`/admin/users/${uuid}`),
      },
    ],
    [navigate],
  );

  const handleCreateClick = () => {
    setIsCreating(true);
  };

  return (
    <>
      <Table
        title={title}
        afterSearch={
          <CreateButton onClick={handleCreateClick} title="Create Contact" />
        }
        order={order}
        orderBy={orderBy}
        onRowClick={onRowClick}
        handleSearch={setSearch}
        search={search}
        rows={rows}
        totalPages={totalPages}
        menuItems={MenuItems}
        cells={Cells}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]}
        setPage={setPage}
        handleRequestSort={handleRequestSort}
        setRowsPerPage={setRowsPerPage}
      />
      {isCreating && (
        <CreateContactPage createType={type} afterSaveAction={onRowClick} />
      )}
    </>
  );
};

ContactsTable.propTypes = {
  onRowClick: PropTypes.func,
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  title: PropTypes.string,
};
