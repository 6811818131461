import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { conversion } from 'helpers';
import {
  useGetContactQuery,
  useGetMlsListingQuery,
  useGetParcelQuery,
  useGetTransactionQuery,
} from 'redux/rtk-query';
import { isTemplateViewSelector } from 'redux/selectors';
import { getCustomerRolesTypes, getFirstOfRole } from '../utils/roles';

const processRolesToAutofill = (modelData, autofillData) => {
  if (modelData.roles) {
    const sortedRoles = [...modelData.roles].sort((a, b) => {
      if (a.role === b.role) {
        return a.order - b.order; // Sort by order if types are the same
      }
      return a.role.localeCompare(b.role); // Sort by type
    });
    const customerRoles = conversion.getCustomerRoles(sortedRoles);
    const otherRoles = sortedRoles.filter((role) => {
      const isCustomer = customerRoles.find(
        (cRole) => cRole.role === role.role && cRole.order === role.order,
      );
      return !isCustomer;
    });
    const combinedRoles = [...customerRoles, ...otherRoles];
    combinedRoles.forEach((role) => {
      const contact = role.contact;
      const roleOption = role.role;
      let name;
      if (!contact) {
        name = role.contactName;
      } else {
        name = conversion.getName(contact);
      }
      let contactAutofill;
      if (!contact) {
        contactAutofill = {
          name: name,
        };
      } else {
        contactAutofill = {
          name: name,
          phone: contact.mobilePhone,
          email: contact.email,
          address: conversion.addressToString(contact.primaryAddress, true),
          contactCompany: contact.brokerage
            ? conversion.getName(contact.brokerage)
            : contact.companyName,
          fax: contact.officeFax,
          mlsId: contact.mlsId,
          licenseNumber: contact.license,
          id: contact.id,
          poaName:
            role.type && role.type === 'poa' ? conversion.getName(contact) : '',
          roleType: role?.type ? role.type : 'inactive',
          signedDateTime: DateTime.fromISO(role.signedDateTime).toLocaleString(
            DateTime.DATE_SHORT,
          ),
          signature: contact.signatureString,
          initial: contact.initialString,
          serializedData: contact.serializedData,
        };
      }
      const keyName = `${conversion.wordsToCamelCase(roleOption)}${role.order}`;
      autofillData[keyName] = contactAutofill;
    });
  }
};
const processAutofillData = (
  modelData,
  mlsListingData = {},
  parcelData = {},
) => {
  const autofillData = {};

  if (modelData && modelData.roles) {
    const combinedRoles = [...modelData.roles, ...modelData.rolesStr];

    autofillData.property = {
      accountId: modelData?.transaction?.id,
      address: conversion.addressToString(modelData?.address, true),
      parcelNumber: parcelData?.apn,
      legalDescription: parcelData?.legalDescription
        ? parcelData?.legalDescription
        : mlsListingData?.mlsFields?.taxlegaldescription,
      county: parcelData?.county,
      expectedCloseDate: modelData?.expectedCloseDate
        ? DateTime.fromISO(modelData?.expectedCloseDate).toLocaleString(
            DateTime.DATE_SHORT,
          )
        : null,
      executedDate: modelData?.executedDate
        ? DateTime.fromISO(modelData?.executedDate).toLocaleString(
            DateTime.DATE_SHORT,
          )
        : null,
    };

    processRolesToAutofill(modelData, autofillData);

    const sellerAgent = modelData.representing.includes('Seller')
      ? getFirstOfRole(modelData.roles, 'Team Member')
      : getFirstOfRole(modelData.roles, 'Cooperating Agent');

    autofillData.sellerAgent = {
      name: conversion.getName(sellerAgent?.contact),
      brokerage: sellerAgent?.contact?.brokerage
        ? conversion.getName(sellerAgent?.contact?.brokerage)
        : sellerAgent?.contact?.companyName,
    };

    const buyerAgent = modelData.representing.includes('Buyer')
      ? getFirstOfRole(modelData.roles, 'Team Member')
      : getFirstOfRole(modelData.roles, 'Cooperating Agent');

    autofillData.buyerAgent = {
      name: conversion.getName(buyerAgent?.contact),
      brokerage: buyerAgent?.contact?.brokerage
        ? conversion.getName(buyerAgent?.contact?.brokerage)
        : buyerAgent?.contact?.companyName,
    };

    const customerTypes = getCustomerRolesTypes(modelData.representing);
    autofillData.allSellerNames = conversion.getAllCustomersString(
      combinedRoles,
      customerTypes[0],
    );
    autofillData.allBuyerNames = conversion.getAllCustomersString(
      combinedRoles,
      customerTypes[1],
    );
  }

  return autofillData;
};
export const useAutofillData = (documentData) => {
  const isTemplateView = useSelector(isTemplateViewSelector);
  const [autofillData, setAutofillData] = useState(null);
  const { data: transactionData } = useGetTransactionQuery(
    { id: documentData?.transaction?.id },
    { skip: !documentData?.transaction?.id },
  );
  const { data: contactData } = useGetContactQuery(
    { id: documentData?.contact?.id },
    { skip: !documentData?.contact?.id },
  );
  const { data: mlsListingData } = useGetMlsListingQuery(
    { id: documentData?.transaction?.mlsListing, withExtras: true },
    { skip: typeof documentData?.transaction?.mlsListing !== 'string' },
  );
  const { data: parcelData } = useGetParcelQuery(
    { hjid: documentData?.transaction?.parcel },
    { skip: typeof documentData?.transaction?.parcel !== 'string' },
  );

  useEffect(() => {
    if (isTemplateView && documentData?.moduleRelationType) {
      const customerTypes = getCustomerRolesTypes(
        documentData.moduleRelationType,
      );
      const processedAutofillData = processAutofillData({
        representing: documentData.moduleRelationType,
        roles: [
          {
            order: 1,
            contact: {},
            role: customerTypes[0],
          },
          {
            order: 2,
            contact: {},
            role: customerTypes[0],
          },
          {
            order: 3,
            contact: {},
            role: customerTypes[0],
          },
          {
            order: 4,
            contact: {},
            role: customerTypes[0],
          },
          {
            order: 1,
            contact: {},
            role: customerTypes[1],
          },
          {
            order: 2,
            contact: {},
            role: customerTypes[1],
          },
          {
            order: 3,
            contact: {},
            role: customerTypes[1],
          },
          {
            order: 4,
            contact: {},
            role: customerTypes[1],
          },
          {
            order: 1,
            contact: {},
            role: 'Closing Agent',
          },
          {
            order: 1,
            contact: {},
            role: 'Escrow Agent',
          },
          {
            order: 1,
            contact: {},
            role: 'Broker',
          },
        ],
        rolesStr: [],
      });
      setAutofillData(processedAutofillData);
    } else {
      let dataToProcess = null;

      // If there's transaction data, use it. Otherwise, use the contact data.
      if (transactionData) {
        dataToProcess = transactionData;
      } else if (contactData) {
        dataToProcess = contactData;
      }

      if (dataToProcess) {
        // Process the data to create the autofill object
        const processedAutofillData = processAutofillData(
          dataToProcess?.data,
          mlsListingData?.data,
          parcelData?.data,
        );
        setAutofillData(processedAutofillData);
      }
    }
  }, [
    transactionData,
    contactData,
    mlsListingData,
    parcelData,
    isTemplateView,
  ]);

  return autofillData;
};
