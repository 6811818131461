import React from 'react';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { PhotoSetDrawer } from 'pages/TransactionsPages/DetailsTransactionPage/components/TransactionDetails/components/PhotoSetDrawer';
import { useGetDocumentsListQuery, useGetDocumentQuery } from 'redux/rtk-query';

const PhotoSetButton = () => {
  const { transactionDetailsId } = useParams();
  const [viewPhotoSetOpen, setViewPhotoSetOpen] = React.useState(false);
  const relatedModelId = transactionDetailsId;
  const relatedModelType = 'transaction';
  const { data } = useGetDocumentsListQuery({
    page: 1,
    limit: 10,
    search: 'Photo Set',
    [`${relatedModelType}Id`]: parseInt(relatedModelId),
  });

  const { data: documentData } = useGetDocumentQuery(
    {
      id: data?.data?.[0]?.id,
    },
    {
      skip: !data?.data?.[0]?.id,
    },
  );

  const viewPhotoSet = () => {
    setViewPhotoSetOpen(true);
  };

  const isPhotoSet = documentData?.data?.attachments?.length > 0;

  return (
    <>
      {isPhotoSet && (
        <Tooltip title={'Photo Set'}>
          <IconButton
            color={'info'}
            onClick={viewPhotoSet}
            sx={{
              bgcolor: 'white',
              '&:hover': {
                bgcolor: 'white',
              },
            }}
          >
            <FontAwesomeIcon icon={faCamera} />
          </IconButton>
        </Tooltip>
      )}
      <PhotoSetDrawer
        documentData={documentData?.data}
        open={viewPhotoSetOpen}
        onClose={() => setViewPhotoSetOpen(false)}
      />
    </>
  );
};

export default PhotoSetButton;
