import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  faArrowDown,
  faArrowRight,
  faTrash,
  faBrush,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip, TextField, Grid, Box } from '@mui/material';
import { selectCurrentItem, customColorSelectorCM } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const GradientPicker = ({ styleProperty, onDelete }) => {
  const dispatch = useDispatch();
  const item = useSelector(selectCurrentItem);
  const themeColor = useSelector(customColorSelectorCM); // Adjust selector as per your Redux state

  const initialColor = item?.style?.[styleProperty];
  const [color, setColor] = useState(initialColor);

  const {
    isGradient,
    setSolid,
    setGradient,
    degrees,
    setDegrees,
    setA,
    rgbaArr,
    valueToHex,
  } = useColorPicker(color, setColor);

  const [hexInput, setHexInput] = useState('');

  useEffect(() => {
    if (!isGradient) {
      setHexInput(valueToHex());
    } else {
      setHexInput('');
    }
  }, [valueToHex, isGradient]);

  useEffect(() => {
    dispatch(
      updateItemCM({
        itemId: item.id,
        properties: {
          style: {
            [styleProperty]: color === '' ? null : color,
          },
        },
      }),
    );
  }, [color, dispatch, item.id, styleProperty]);

  const handleHexChange = (e) => {
    const hexValue = e.target.value;
    setHexInput(hexValue);
    if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexValue)) {
      setSolid(hexValue);
    }
  };

  const presetColors = useMemo(
    () => ['#231F20', '#48484A', '#B1B3B6', '#E6E7E8', '#ffffff'],
    [],
  );

  const isThemeColorSelected = color === themeColor;

  return (
    <>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={6}>
          <Button
            color="primary"
            fullWidth
            variant={!isGradient ? 'contained' : 'outlined'}
            onClick={() => setSolid()}
          >
            Solid
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            fullWidth
            variant={isGradient ? 'contained' : 'outlined'}
            onClick={() => setGradient()}
          >
            Gradient
          </Button>
        </Grid>
      </Grid>

      <ColorPicker
        style={{
          backgroundColor: 'white',
        }}
        value={color}
        onChange={setColor}
        hideAdvancedSliders
        hideColorGuide
        hideInputType
        hideControls
        hideInputs
        hidePresets
        width={205}
        height={205}
      />

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          {!isGradient && (
            <TextField
              size="small"
              variant="outlined"
              label="Hex"
              value={hexInput}
              onChange={handleHexChange}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            label="Opacity"
            type="number"
            inputProps={{ min: 0, max: 100, step: 1 }}
            value={parseInt(rgbaArr[3] * 100)}
            onChange={(e) => setA(e.target.value / 100)}
          />
        </Grid>
      </Grid>

      {!isGradient && (
        <Box sx={{ pt: 1 }}>
          <Tooltip title="Theme Color">
            <Button
              onClick={() => setSolid(themeColor)}
              sx={{
                border: isThemeColorSelected
                  ? '3px solid #3f51b5'
                  : '1px solid rgba(0,0,0,0.12)',
                height: 30,
                width: 30,
                minWidth: 30,
                marginRight: '3px',
                backgroundColor: themeColor,
              }}
            >
              <FontAwesomeIcon icon={faBrush} style={{ color: '#fff' }} />
            </Button>
          </Tooltip>

          {presetColors.map((preset, index) => (
            <Button
              key={index}
              onClick={() => setSolid(preset)}
              sx={{
                border:
                  color === preset
                    ? '3px solid #3f51b5'
                    : '1px solid rgba(0,0,0,0.12)',
                height: 30,
                width: 30,
                minWidth: 30,
                marginRight: '3px',
                backgroundColor: preset,
              }}
            />
          ))}
        </Box>
      )}

      <Grid container spacing={2} sx={{ mt: 1 }}>
        {isGradient && (
          <Grid item xs={6}>
            <Tooltip title="Change Gradient Direction" arrow placement="top">
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setDegrees(degrees === 180 ? 90 : 180)}
              >
                <FontAwesomeIcon
                  icon={degrees === 180 ? faArrowRight : faArrowDown}
                />
              </Button>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={6}>
          <Button color="primary" variant="outlined" onClick={onDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

GradientPicker.propTypes = {
  styleProperty: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

export default React.memo(GradientPicker);
