import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import { FormPanel } from 'components/Common';
import {
  Autocomplete,
  ContactAutocomplete,
  PhoneNumber,
  TextInput,
} from 'components/Fields';
import { FormCondition } from 'components/Forms';
import { CONTACT_TYPES } from 'consts';
import { useLoadMoreOptions } from 'hooks';
import { useGetCompaniesQuery } from 'redux/rtk-query';

const getCompanyOption = ({ id, companyName }) => ({
  value: id,
  label: companyName,
  key: id,
});

export const CompanyBox = ({ showAllFields }) => {
  const type = useField('type').input.value;
  const companies = useLoadMoreOptions(useGetCompaniesQuery, getCompanyOption, {
    type: type,
  });

  return (
    <FormPanel label={'Company information'}>
      <FormCondition
        showAllFields={showAllFields}
        when={'type'}
        operator={'oneOf'}
        is={[
          CONTACT_TYPES.LEAD.value,
          CONTACT_TYPES.CUSTOMER.value,
          CONTACT_TYPES.BROKERAGE.value,
          CONTACT_TYPES.CLOSING_ESCROW_AGENT.value,
          CONTACT_TYPES.LENDER.value,
          CONTACT_TYPES.COMPANY_REFERRAL_AGENT.value,
          CONTACT_TYPES.VENDOR.value,
          CONTACT_TYPES.COMPANY_ADMIN.value,
        ]}
      >
        <Field
          name="companyName"
          label="Company Name"
          creatable
          options={companies.options}
          component={Autocomplete}
          onOpen={companies.handleOpen}
          loadMoreResults={companies.handleMore}
          isLoading={companies.isFetching}
          handleSearch={companies.handleSearch}
        />
      </FormCondition>
      <FormCondition
        showAllFields={showAllFields}
        when={'type'}
        operator={'oneOf'}
        is={[
          CONTACT_TYPES.COMPANY_AGENT.value,
          CONTACT_TYPES.COOPERATING_AGENT.value,
        ]}
      >
        <Field
          name="brokerage"
          label="Brokerage"
          component={ContactAutocomplete}
          type={CONTACT_TYPES.BROKERAGE.value}
        />
      </FormCondition>
      <FormCondition
        showAllFields={showAllFields}
        when={'type'}
        operator={'oneOf'}
        is={[
          CONTACT_TYPES.LEAD.value,
          CONTACT_TYPES.CUSTOMER.value,
          CONTACT_TYPES.BROKERAGE.value,
          CONTACT_TYPES.CLOSING_ESCROW_AGENT.value,
          CONTACT_TYPES.LENDER.value,
          CONTACT_TYPES.COMPANY_REFERRAL_AGENT.value,
          CONTACT_TYPES.VENDOR.value,
          CONTACT_TYPES.COMPANY_ADMIN.value,
        ]}
      >
        <Field name="jobTitle" label="Job Title" component={TextInput} />
      </FormCondition>
      <Field name="officePhone" label="Office Phone" component={PhoneNumber} />
      <Field name="officeFax" label="Office Fax" component={PhoneNumber} />
    </FormPanel>
  );
};

CompanyBox.propTypes = {
  showAllFields: PropTypes.bool,
};
