import React from 'react';
import PropTypes from 'prop-types';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { RowButtonWrapper } from 'components/Styled';
import { EDIT_DOCUMENT } from 'consts';
import { useOpenDrawer } from 'hooks';
import { MainContent, MainSummary } from './styled';

export const MarketingItem = ({ marketing }) => {
  const openDrawer = useOpenDrawer();
  const routeParams = useParams();
  const handleEdit = () => {
    openDrawer(EDIT_DOCUMENT(marketing.id), routeParams['*']);
  };

  return (
    <MainContent>
      <MainSummary>
        {/*<MarketingItemIcon type={marketing.type} />*/}
        {marketing.name}
      </MainSummary>
      <RowButtonWrapper className={'showOnHover'}>
        <IconButton onClick={handleEdit} size={'small'}>
          <FontAwesomeIconComp icon={faEdit} size="sm" />
        </IconButton>
      </RowButtonWrapper>
    </MainContent>
  );
};

MarketingItem.propTypes = {
  marketing: PropTypes.object,
};
