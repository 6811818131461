import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { GroupToolbar } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ActiveFieldMenu';
import { CM_ITEM_TYPES } from 'consts';
import {
  activeFieldIdsSelectorCM,
  cmBlockTypeSelectorCM,
  selectCurrentItem,
} from 'redux/selectors';
import { ActiveFieldMenu, PrimaryMenu } from './components';

export const ClickMarketingMenu = ({ headerHeight }) => {
  const cmBlockType = useSelector(cmBlockTypeSelectorCM);
  const activeFieldIds = useSelector(activeFieldIdsSelectorCM);
  const activeField = useSelector(selectCurrentItem);
  const showPrimaryMenu =
    activeFieldIds.length === 0 ||
    (cmBlockType === CM_ITEM_TYPES.modular.Template &&
      [
        CM_ITEM_TYPES.text.list,
        CM_ITEM_TYPES.text.autofill,
        CM_ITEM_TYPES.text.fillnow,
      ].includes(activeField?.type));
  const showActiveFieldMenu = activeFieldIds.length === 1 && !showPrimaryMenu;
  const showGroupMenu =
    activeFieldIds.length > 1 && cmBlockType !== CM_ITEM_TYPES.modular.Template;

  return (
    <Box
      sx={{
        background: 'white', // background color of the menu
        height: `calc(100vh - ${headerHeight}px)`,
        overflowY: 'scroll',
      }}
    >
      {showPrimaryMenu && <PrimaryMenu />}
      {showActiveFieldMenu && <ActiveFieldMenu />}
      {showGroupMenu && <GroupToolbar />}
    </Box>
  );
};

ClickMarketingMenu.propTypes = {
  headerHeight: PropTypes.number,
};
