import React from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CurrencyInput,
  DatePicker,
  Select,
  TextInput,
} from 'components/Fields';
import { useTransactionOptions } from 'hooks';

export const PropertyMgmtPanel = () => {
  const form = useForm();
  const { pmStatusOptions, paymentMethodOptions } = useTransactionOptions(form);

  return (
    <FormPanel label={'Property Mgmt'}>
      <Grid item xs={12}>
        <Field
          label="Mgmt Status"
          name={'pmStatus'}
          component={Select}
          options={pmStatusOptions}
          tooltip={{
            title:
              "It's important to keep the status of the management account up to date and accurate.",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Rent"
          name={'pmRent'}
          component={CurrencyInput}
          tooltip={{
            title:
              'This is the current rent amount for the period listed. The rent amount must be updated if it changes.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Period"
          name={'period'}
          component={DatePicker}
          readOnlyWhenNotEmpty
          tooltip={{
            title:
              'The period is the month in which the management account is open. The period will automatically move forward one month each time the account is reconciled. When entering this for the first time, enter the first day of the first month in which rent is collected for the first time.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Payment Method"
          name={'paymentMethod'}
          component={Select}
          options={paymentMethodOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Notes This Period"
          name={'notesThisPeriod'}
          component={TextInput}
          multiline
          rows={4}
          tooltip={{
            title:
              'These notes are added to the owner report for the period listed.',
          }}
        />
      </Grid>
    </FormPanel>
  );
};

PropertyMgmtPanel.propTypes = {};
