import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime, Interval } from 'luxon';
import { Box, Typography } from '@mui/material';
import { Checkbox } from 'components/Common';
import { CardContentRow, PageLink } from 'components/Styled';
import { colors, TASK_STATUSES, UPDATE_TASK } from 'consts';
import { conversion } from 'helpers';
import { TaskBox } from './styled';

export const TaskSummary = ({ task, showRelatedModel = false }) => {
  const [isCompleted, setIsCompleted] = useState(
    task.status === TASK_STATUSES.COMPLETED ||
      task.status === TASK_STATUSES.CANCELLED,
  );

  const completeTask = () => {
    setIsCompleted(!isCompleted);
  };

  const timeLeftLabel = useMemo(() => {
    const date1 = DateTime.fromISO(task.dueOn, { zone: 'UTC' });
    const date2 = DateTime.now();
    let diff = Interval.fromDateTimes(date2, date1);
    let pastDue = false;
    if (diff.invalid?.reason === 'end before start') {
      diff = Interval.fromDateTimes(date1, date2);
      pastDue = true;
    }
    const diffDays = diff.length('days');
    const diffHours = diff.length('hours');
    const diffMins = diff.length('minutes');
    let label;
    if (diffDays >= 1) {
      label = `${parseInt(diffDays)}d`;
    } else if (diffHours >= 1) {
      label = `${parseInt(diffHours)}h`;
    } else if (diffMins >= 0) {
      label = `${parseInt(diffMins)}m`;
    }
    return (
      <Typography
        component={'span'}
        color={pastDue ? '#c9232d' : ''}
        fontSize={12}
        textAlign={'right'}
      >
        {label} {pastDue && 'late'}
      </Typography>
    );
  }, [task.dueOn]);

  const relatedModelString = useMemo(() => {
    if (task.contact) {
      return conversion.getName(task.contact);
    } else {
      return conversion.getName(task.transaction);
    }
  }, [task.id]);

  return (
    <CardContentRow>
      <Box display={'flex'} gap={1} px={1.4} py={1}>
        <Checkbox onClick={completeTask} checked={isCompleted} />
        <TaskBox iscompleted={isCompleted.toString()}>
          <Typography component={'span'} fontSize={14}>
            <PageLink underline="hover" to={UPDATE_TASK(task.id)}>
              {task.name}{' '}
              {showRelatedModel && (
                <Typography
                  color={colors.GREY}
                  variant={'caption'}
                  component={'span'}
                >
                  {relatedModelString}
                </Typography>
              )}
            </PageLink>
          </Typography>
          {timeLeftLabel}
        </TaskBox>
      </Box>
    </CardContentRow>
  );
};

TaskSummary.propTypes = {
  task: PropTypes.shape({
    name: PropTypes.string,
    dueOn: PropTypes.string,
    id: PropTypes.number,
    relatedModel: PropTypes.string,
    contact: PropTypes.object,
    transaction: PropTypes.object,
    status: PropTypes.string,
  }),
  showRelatedModel: PropTypes.bool,
};
