import React from 'react';
import PropTypes from 'prop-types';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';

const CenteredImage = ({ file, fitWidth = false }) => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    paddingTop: '100%', // Maintains a 1:1 aspect ratio (square)
    overflow: 'hidden',
  };

  const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: fitWidth ? 'auto' : '100%',
    padding: fitWidth ? '10px' : '0',
    height: fitWidth ? 'auto' : '100%',
    objectFit: 'cover', // Ensures the image covers the container
    transform: 'translate(-50%, -50%)', // Centers the image
  };

  return (
    <div style={containerStyle}>
      <AbeFileImage file={file} style={imageStyle} />
    </div>
  );
};

CenteredImage.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  fitWidth: PropTypes.bool,
};

export default CenteredImage;
