import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { FontAwesomeIconComp, ModuleIcon } from 'components/Common';
import { PageLink } from 'components/Styled';
import { conversion } from 'helpers';
import { useGetRecentlyViewedQuery } from 'redux/rtk-query';
import {
  LowerListItemButton,
  LowerListItemIcon,
  RecentlyViewedBtn,
  RecentlyViewedBtnTxt,
} from '../../styled';

export const recentlyViewedItems = [
  {
    module: 'Transactions',
    name: '1811 Antilles Place Orlando',
    status: 'Listed',
  },
  {
    module: 'Transactions',
    name: '123 Main St Apt 1 Orlando',
    status: 'Draft',
  },
  {
    module: 'Transactions',
    name: '11855 Padua Lane Orlando',
    status: 'Pending',
  },
  {
    module: 'Contacts',
    name: 'Bob Cristea',
    type: 'Customer',
  },
  {
    module: 'Contacts',
    name: 'Michael Johnson',
    type: 'Customer',
  },
  {
    module: 'Contacts',
    name: 'Emily Parker',
    type: 'Customer',
  },
  {
    module: 'Contacts',
    name: 'Jane Smith',
    type: 'Customer',
  },
  {
    module: 'Contacts',
    name: 'John Don',
    type: 'Customer',
  },
  {
    module: 'Contacts',
    name: 'Gigi Cardano',
    type: 'Company Agent',
  },
];

export const RecentlyViewed = ({ fullMenu, setFullMenu, open }) => {
  const navigate = useNavigate();
  const recentItems = useGetRecentlyViewedQuery(undefined, { force: true });
  const recentlyViewedItemsToDisplay =
    recentItems?.data?.data.map((item) => {
      const model =
        item.capping || item.contact || item.setting || item.transaction;
      const module = item.capping
        ? 'Compensations'
        : item.contact
          ? 'Contacts'
          : item.setting
            ? 'Settings'
            : 'Transactions';
      const typeOrStatus = item.contact
        ? item.contact.type || null
        : item.transaction
          ? item.transaction.status || null
          : item.capping
            ? item.capping.status || null
            : item.setting
              ? item.setting.status || null
              : null;
      return {
        name: conversion.getName(model),
        icon: (
          <ModuleIcon
            fontSize={'1.4rem'}
            module={module}
            type={typeOrStatus}
            closedApproved={model.closedApproved}
          />
        ),
        module,
        modelId: model.id,
      };
    }) || [];

  useEffect(() => {
    recentItems.refetch();
  }, [navigate]); // Trigger refetch when the navigate function changes

  return (
    <List disablePadding={open}>
      <ListItem disablePadding sx={{ display: open ? 'block' : 'none' }}>
        <RecentlyViewedBtn
          onClick={() => setFullMenu(!fullMenu)}
          sx={{ justifyContent: open ? 'initial' : 'center' }}
        >
          <RecentlyViewedBtnTxt
            disableTypography
            primary="RECENTLY VIEWED"
            sx={{ opacity: open ? 1 : 0 }}
          />
          <FontAwesomeIconComp
            icon={faChevronDown}
            color={'#969696'}
            size="xs"
          />
        </RecentlyViewedBtn>
      </ListItem>

      {recentlyViewedItemsToDisplay.map((item, index) => (
        <ListItem
          key={index}
          disablePadding
          sx={{ display: 'block' }}
          component={PageLink}
          to={`/${item.module.toLowerCase()}/details/${item.modelId}/`}
        >
          <LowerListItemButton
            sx={{
              justifyContent: open ? 'center' : 'center',
              padding: open ? '0 16px' : 0.5,
            }}
          >
            <LowerListItemIcon sx={{ mr: open ? 1 : '0' }}>
              {item.icon}
            </LowerListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body4',
                noWrap: true,
              }}
              primary={item.name}
              sx={{ display: open ? 'block' : 'none', fontSize: '13px' }}
            />
          </LowerListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

RecentlyViewed.propTypes = {
  fullMenu: PropTypes.bool,
  setFullMenu: PropTypes.func,
  open: PropTypes.bool,
};
