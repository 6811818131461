import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, List } from '@mui/material';
import { styled } from '@mui/system';
import { MenuItemButton } from 'components/Common';
import { colors } from 'consts/colors';

export const NestedList = styled(List)(({ dense = false }) => ({
  backgroundColor: '#fff',
  // outline: dense ? null : `4px solid ${colors.MUTED_BLUE}`,
  // outlineOffset: dense ? '-1px' : '-4px',
  borderBottom: dense ? null : `4px solid ${colors.MUTED_BLUE}`,
  borderRight: dense ? null : `4px solid ${colors.MUTED_BLUE}`,
  borderLeft: dense ? null : `4px solid ${colors.MUTED_BLUE}`,

  padding: '0',
}));

export const CollapsableMenuGroup = ({
  label,
  icon,
  isSelected = false,
  hideChevron = true,
  dense = false,
  children,
  selectedColor,
  sx = {},
  collapseSx = {},
}) => {
  const [selected, setSelected] = useState(isSelected);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);
  return (
    <>
      <MenuItemButton
        isSelected={selected}
        onClick={() => {
          setSelected(!selected);
        }}
        icon={icon}
        label={label}
        isGroup={!hideChevron}
        dense={dense}
        selectedColor={selectedColor}
        sx={sx}
      />
      <Collapse sx={collapseSx} in={selected}>
        <NestedList dense={dense}>{children}</NestedList>
      </Collapse>
    </>
  );
};

CollapsableMenuGroup.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.object,
  isSelected: PropTypes.bool,
  dense: PropTypes.bool,
  hideChevron: PropTypes.bool,
  children: PropTypes.node.isRequired,
  selectedColor: PropTypes.string,
  sx: PropTypes.object,
  collapseSx: PropTypes.object,
};
