import React from 'react';
import { Divider, TableContainer } from '@mui/material';
import { AbeCard } from 'components/Common';
import { draftData, listedData, pendingData, soldData } from 'helpers';
import { SalesPipelineHeader, SalesPipelineSection } from './components';
import { PipelineTable } from './styled';

export const SalesPipelineCard = () => {
  return (
    <AbeCard title={'Sales Pipeline'}>
      <Divider />
      <TableContainer>
        <PipelineTable>
          <SalesPipelineHeader />
          <SalesPipelineSection status={'Listed'} data={listedData} />
          <SalesPipelineSection status={'Pending'} data={pendingData} />
          <SalesPipelineSection status={'Draft'} data={draftData} />
          <SalesPipelineSection
            title={'Closing in Progress'}
            status={'Sold'}
            data={soldData}
          />
          <SalesPipelineSection
            hideTable
            title={'Closed Sale'}
            extraText={'(12 mo.)'}
            status={'Sold'}
            data={{}}
          />
        </PipelineTable>
      </TableContainer>
    </AbeCard>
  );
};

SalesPipelineCard.propTypes = {};
