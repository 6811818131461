import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import { LayoutHeader } from 'components/Layouts';
import { CommissionCard } from 'components/ModelViews';
import { ButtonSection } from 'components/Styled';
import { DETAILS_TRANSACTION } from 'consts';
import { CommissionInvoices } from 'pages/TransactionsPages/CommissionOverviewPage/components/CommissionInvoices/CommissionInvoices';
import {
  useGetTransactionQuery,
  useGetTransactionClosingStatementQuery,
} from 'redux/rtk-query';
import { ClosingStatementPreview } from './components/ClosingStatementPreview';

export const CommissionOverviewPage = () => {
  let { transactionDetailsId } = useParams();
  const navigate = useNavigate();

  const [transactionInfo, setTransactionInfo] = useState(null);
  const {
    data: transactionData,
    isSuccess,
    isFetching,
  } = useGetTransactionQuery({ id: transactionDetailsId });

  const { data: documentData } = useGetTransactionClosingStatementQuery({
    id: transactionDetailsId,
  });

  const closingStatementData = documentData?.data;

  useEffect(() => {
    if (isSuccess) {
      setTransactionInfo(transactionData.data);
    }
  }, [isFetching, isSuccess]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {transactionInfo && (
        <>
          <LayoutHeader title={transactionData?.data?.name}>
            <ButtonSection>
              <Button
                onClick={() => {
                  navigate(`/${DETAILS_TRANSACTION(transactionDetailsId)}`);
                }}
              >
                Back to Transaction
              </Button>
            </ButtonSection>
          </LayoutHeader>
          <Grid
            spacing={2}
            pb={2}
            container
            sx={{ flexGrow: 1, overflow: 'hidden' }}
          >
            <Grid item xs={closingStatementData ? 7 : 6}>
              {closingStatementData ? (
                <ClosingStatementPreview
                  closingStatementData={closingStatementData}
                />
              ) : (
                <CommissionCard overview />
              )}
            </Grid>
            <Grid item xs={closingStatementData ? 5 : 6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CommissionInvoices />
                </Grid>
                {closingStatementData && (
                  <Grid item xs={12}>
                    <CommissionCard overview />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Outlet />
        </>
      )}
    </div>
  );
};
