import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SideDrawer } from 'components/Common';
import { useDrawer } from 'hooks';
import {
  useGetDocumentTemplateQuery,
  useUpdateDocumentTemplateMutation,
} from 'redux/rtk-query';
import { DocumentTemplateForm } from '../DocumentTemplateForm/DocumentTemplateForm';

export const DocumentTemplateEditPage = ({ params, path, open = true }) => {
  const [initialValues, setInitialValues] = useState({});
  const { isOpen, closeDrawer } = useDrawer(open, path);
  const paramName = Object.keys(params)[0];

  const id = params[paramName];

  const [updateDocumentTemplate] = useUpdateDocumentTemplateMutation();

  const { data, isSuccess } = useGetDocumentTemplateQuery(
    { id: id },
    { skip: !id },
  );

  useEffect(() => {
    setInitialValues({
      ...data?.data,
    });
  }, [0, isSuccess]);

  const handleSubmit = async (values) => {
    return await updateDocumentTemplate({
      id: values.id,
      type: values.type,
      name: values.name,
      status: values.status,
      moduleClassName: values.moduleClassName,
      nameFormat: values.nameFormat,
      parentTemplateId: values.parentTemplate?.id,
      aliasDocumentId: values.aliasDocument?.id,
      revisionDate: values.revisionDate,
      multiplesAllowed: values.multiplesAllowed,
      required: values.required,
      closedApproved: values.closedApproved,
      modelAccess: values.modelAccess,
    });
  };

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      {/*{type === 'Click Marketing' ? (*/}
      {/*  <ClickMarketingForm*/}
      {/*    title={`Create Click Marketing Template`}*/}
      {/*    initialValues={initialValues}*/}
      {/*    handleSubmitFunc={handleSubmit}*/}
      {/*    afterSave={afterSave}*/}
      {/*  />*/}
      {/*) : (*/}
      <DocumentTemplateForm
        title={'Edit Template'}
        initialValues={initialValues}
        handleSubmitFunc={handleSubmit}
        afterSave={closeDrawer}
      />
      {/*)}*/}
    </SideDrawer>
  );
};

DocumentTemplateEditPage.propTypes = {
  params: PropTypes.object,
  type: PropTypes.string,
  path: PropTypes.string,
  open: PropTypes.bool,
};
