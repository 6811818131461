import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormState, Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { Select } from 'components/Fields';

export const DatasetsFields = ({ input, columnOptions }) => {
  const name = input.name; // Extract the field name
  const { values } = useFormState();

  // Define axes based on chart type
  const getAxesForChartType = (chartType) => {
    switch (chartType) {
      case 'Circle Chart Pie Charts':
      case 'Bar Chart Horizontal':
      case 'Bar Chart Vertical':
        return ['X', 'Y'];
      case 'Bar Chart Stacked':
      case 'Bar Chart Stacked with Groups':
        return ['X', 'Y', 'Y'];
      // Add cases for other chart types as needed
      default:
        return [];
    }
  };

  const axes = getAxesForChartType(values.type);

  // Use useFieldArray hook to access fields
  const { fields } = useFieldArray(name);

  // Adjust fields using useEffect
  useEffect(() => {
    if (fields.length < axes.length) {
      for (let i = fields.length; i < axes.length; i++) {
        fields.push({ axis: axes[i], columnId: '' });
      }
    } else if (fields.length > axes.length) {
      for (let i = fields.length - 1; i >= axes.length; i--) {
        fields.remove(i);
      }
    }
  }, [axes.length]); // Adjust when axes length changes

  return (
    <div>
      {fields.map((name, index) => (
        <div key={name}>
          <label>Axis {axes[index]}</label>
          <Field
            name={`${name}.columnId`}
            component={Select}
            options={columnOptions}
          />
        </div>
      ))}
    </div>
  );
};

DatasetsFields.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  columnOptions: PropTypes.array.isRequired,
};
