import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { ListPagination } from 'components/ListPagination'; // Import ListPagination
import { ListTabComponent } from '../ListTab';
import {
  DividerStyled,
  ExpandIcon,
  ListAccordion,
  ListAccordionDetails,
  ListAccordionDetHeader,
  ListAccordionSum,
} from './styled';

export const ListsAccordeonItem = ({
  list,
  title,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  totalPages,
  totalCount,
}) => {
  const navigate = useNavigate();

  const getClickHandler = (id) => {
    navigate(`/contacts/list/${id}/`);
  };

  return (
    <>
      <DividerStyled />
      <ListAccordion defaultExpanded>
        <ListAccordionSum
          expandIcon={<ExpandIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ListAccordionDetHeader>{title}</ListAccordionDetHeader>
        </ListAccordionSum>
        <ListAccordionDetails>
          {list?.map(
            ({ name, uuid, isFavourite, id, totalContactsCount, type }) => (
              <ListTabComponent
                key={id ? id : name}
                isFavourite={isFavourite}
                contactCount={totalContactsCount}
                name={name}
                uuid={uuid}
                id={id}
                type={type}
                getClickHandler={() => getClickHandler(id ? id : type)}
              />
            ),
          )}
        </ListAccordionDetails>
        {totalPages > 1 && (
          <Box display={'flex'} justifyContent={'space-between'} p={1}>
            <ListPagination
              hideText
              totalCount={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={(newPage) => setPage(newPage)}
              handleChangeRowsPerPage={(event) =>
                setRowsPerPage(parseInt(event.target.value, 10))
              }
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        )}
      </ListAccordion>
    </>
  );
};

ListsAccordeonItem.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setPage: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  totalPages: PropTypes.number,
  totalCount: PropTypes.number,
};
