import { DateTime } from 'luxon';
import { omitNull, omitProperties } from 'helpers';
import { defaultValues } from './defaultValues';

const mergeRolesWithTeamMembers = (filteredAllValues) => {
  // Clone existing roles
  const roles = [...filteredAllValues.roles];

  console.log(filteredAllValues);
  // Create a map of team member IDs for quick lookup
  const teamMemberIds = new Set(
    filteredAllValues.team.map((member) => member.teamMember?.id),
  );

  // Add or update roles based on team members
  filteredAllValues.team.forEach((teamMember) => {
    const existingRoleIndex = roles.findIndex(
      (role) =>
        role.contact.id === teamMember.teamMember?.id &&
        role.role === 'Team Member',
    );

    const newRole = {
      role: 'Team Member',
      order: teamMember.order,
      contact: teamMember.teamMember,
    };

    if (existingRoleIndex === -1) {
      roles.push(newRole);
    } else {
      roles[existingRoleIndex] = newRole;
    }
  });

  // Remove roles that are no longer needed
  const updatedRoles = roles.filter(
    (role) =>
      role.role !== 'Team Member' || teamMemberIds.has(role.contact?.id),
  );

  return updatedRoles;
};

export const processFormData = (values) => {
  let formData = values.values ? values.values : values;
  let allValues = { ...defaultValues, ...omitNull(formData) };
  allValues = omitProperties(allValues, [
    'shareWithOption',
    'lastActivity',
    'isFavourite',
    'timeframeCalculation',
    'addressConfirmed',
  ]);

  const { modelAccess, ...filteredAllValues } = allValues;

  const roles = mergeRolesWithTeamMembers(filteredAllValues);

  return {
    ...filteredAllValues,
    roles,
    primaryTransactionId: allValues.primaryTransaction?.id,
    referralAgentId: parseInt(allValues.referralAgent?.id),
    outsideReferral: allValues.outsideReferral === 'Yes',
    timeframes: allValues.timeframes
      ?.filter((timeframe) => !timeframe.noDeadline && timeframe.dueOn)
      .map((timeframe) => {
        return {
          ...timeframe,
          dueOn: DateTime.fromFormat(
            timeframe.dueOn,
            'yyyy-MM-dd HH:mm:ss',
          ).toUTC(),
        };
      }),
    parcel: allValues.parcel?.id,
    mlsListing: allValues.mlsListing?.id?.toString(),
    agentId: modelAccess?.owner?.id,
    shareWithIds: modelAccess.sharedWithUsers?.map(
      ({ value, id }) => value || id,
    ),
    companyStructureUnitsIds: modelAccess.sharedWithUnits?.map(({ id }) => id),
  };
};

export const checkForTimeframeValidations = (values) => {
  const errors = { timeframes: [] };
  if (
    values.status &&
    (values.status === 'Pending' || values.status === 'Closed Sale')
  ) {
    const timeframe = values.timeframes.filter(
      (timeframe) => timeframe.type === 'Inspection Deadline',
    );
    if (timeframe.length === 0 || timeframe[0].dateTime === null) {
      errors.timeframes.push('["timeframes.Inspection Deadline", "required"]');
    }
    const timeframe2 = values.timeframes.filter(
      (timeframe) => timeframe.type === 'Expected Closing',
    );
    if (timeframe2.length === 0 || timeframe2[0].dateTime === null) {
      errors.timeframes.push('["timeframes.Expected Closing", "required"]');
    }
    if (!values.contractExecutionDate) {
      errors.timeframes.push('["timeframes.Contract Execution", "required"]');
    }
    if (
      values.financingType &&
      values.financingType !== 'Cash' &&
      (values.representing.includes('Buyer') ||
        values.representing.includes('Seller'))
    ) {
      const timeframe = values.timeframes.filter(
        (timeframe) => timeframe.type === 'Loan Approval Deadline',
      );
      if (timeframe.length === 0 || timeframe[0].dateTime === null) {
        errors.timeframes.push(
          '["timeframes.Loan Approval Deadline", "required"]',
        );
      }
    }
    if (parseFloat(values.additionalEscrowAmount) > 0) {
      const timeframe = values.timeframes.filter(
        (timeframe) => timeframe.type === 'Additional Escrow Deadline',
      );
      if (timeframe.length === 0 || timeframe[0].dateTime === null) {
        errors.timeframes.push(
          '["timeframes.Additional Escrow Deadline", "required"]',
        );
      }
    }
    if (parseFloat(values.escrowAmount) > 0) {
      const timeframe = values.timeframes.filter(
        (timeframe) => timeframe.type === 'Escrow Deadline',
      );
      if (timeframe.length === 0 || timeframe[0].dateTime === null) {
        errors.timeframes.push('["timeframes.Escrow Deadline", "required"]');
      }
    }
  }
  if (values.status && values.status === 'Listed' && values.pmFile !== 'Yes') {
    const timeframe = values.timeframes.filter(
      (timeframe) => timeframe.type === 'Listing Expiration',
    );
    if (timeframe.length === 0 || timeframe[0].dateTime === null) {
      errors.timeframes.push('["timeframes.Listing Expiration", "required"]');
    }
    if (!values.listExecutionDate) {
      errors.timeframes.push('["timeframes.Listing Execution", "required"]');
    }
  }
  if (values.status && values.status === 'Closed Lease') {
    if (!values.leaseBegins) {
      errors.timeframes.push('["timeframes.Lease Begins", "required"]');
    }
    const timeframe1 = values.timeframes.filter(
      (timeframe) => timeframe.type === 'Lease Ends',
    );
    if (timeframe1.length === 0 || timeframe1[0].dateTime === null) {
      errors.timeframes.push('["timeframes.Lease Ends", "required"]');
    }
  }
  return errors;
};
