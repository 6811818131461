import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Comment } from 'components/Common';
import { TextInputField } from 'components/Styled';

export const CommentsSection = ({ input }) => {
  const [newComment, setNewComment] = useState('');

  const comments = input.value || [];
  const setComments = input.onChange;

  const handleAddComment = () => {
    const newCommentObj = {
      id: Date.now(),
      message: newComment,
      createdAt: new Date(),
      createdBy: 'You',
    };
    setComments([...comments, newCommentObj]);
    setNewComment('');
  };

  return (
    <div>
      {comments.length === 0 ? (
        <Box sx={{ p: 1 }} textAlign={'center'}>
          <Typography variant="body1" component="div">
            No comments
          </Typography>
        </Box>
      ) : (
        <Box sx={{ p: 1 }}>
          {comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}
        </Box>
      )}
      <Box sx={{ px: 1 }} display={'flex'} alignItems={'center'}>
        <TextInputField
          fullWidth
          size={'small'}
          label="Add Comment"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <Button sx={{ ml: 1 }} variant={'outlined'} onClick={handleAddComment}>
          Add
        </Button>
      </Box>
    </div>
  );
};

CommentsSection.propTypes = {
  input: PropTypes.object,
};
