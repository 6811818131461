import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { faGauge } from '@fortawesome/pro-solid-svg-icons';
import { Box, Grid } from '@mui/material';
import { DetailsPanels, FontAwesomeIconComp } from 'components/Common';
import { CardContentRow } from 'components/Styled';
import { STATUS_OPTIONS } from 'consts';
import { conversion } from 'helpers';
import {
  ChangeCompensationButton,
  AddCommissionInvoiceButton,
} from './components';

const formatTypes = {
  contractPrice: 'currency',
  grossCommission: 'currency',
  netToAgent: 'currency',
  dueToOwner: 'currency',
  invoiceTotal: 'currency',
  contractVolume: 'currency',
  grossCommissionAfterReferral: 'currency',
  grossCommissionTeam: 'currency',
  netToCompanyTeam: 'currency',
  toCompanyBeforeOverride: 'currency',
  bonusOrFlatRate: 'currency',
  otherDebitCredit: 'currency',
  referralCommission: 'currency',
  referralFlatRate: 'currency',
  depositAmount: 'currency',
  depositAmountMinusGrossCommission: 'currency',
  listVolume: 'currency',
  teamPercent: 'percentage',
  agentSplit: 'percentage',
  period: 'date',
  closeDate: 'date',
  depositDate: 'date',
  closedApproved: 'checkbox',
};

export const CommissionSummary = ({ commission, overview }) => {
  const [panelAttributes, setPanelAttributes] = React.useState([]);
  const [commissionData, setCommissionData] = React.useState({ ...commission });

  useEffect(() => {
    let newPanelAttributes = [
      'agentSplit',
      'contractVolume',
      'netToAgent',
      'closedApproved',
    ];
    if (parseInt(commissionData.teamPercent) !== 100) {
      newPanelAttributes = [
        'agentSplit',
        'teamPercent',
        'grossCommissionTeam',
        'contractVolume',
        'netToAgent',
      ];
    }

    if (commissionData.status === STATUS_OPTIONS.PROPERTY_MGMT.value) {
      newPanelAttributes = ['grossCommission', 'netToAgent'];
      if (parseInt(commissionData.teamPercent) !== 100) {
        newPanelAttributes.push('teamPercent');
      }
    }
    if (overview) {
      newPanelAttributes = [
        'closeDate',
        'depositDate',
        'depositAmount',
        'contractPrice',
        'grossCommission',
        'referralCommission',
        'grossCommissionAfterReferral',
        'netToAgent',
        'compensation',
        'agentSplit',
        'teamPercent',
        'closedApproved',
      ];

      if (commissionData.depositAmount > 0) {
        const depositAmountMinusGrossCommission =
          commissionData.depositAmount - commissionData.grossCommission;
        setCommissionData({
          ...commissionData,
          depositAmountMinusGrossCommission,
        });

        newPanelAttributes.splice(
          newPanelAttributes.indexOf('depositAmount') + 1,
          0,
          'depositAmountMinusGrossCommission',
        );
      }
      commissionData.compensation = (
        <ChangeCompensationButton commission={commission} />
      );
    }

    setPanelAttributes(newPanelAttributes);
  }, []);

  return (
    <CardContentRow>
      <Box display={'flex'} flexDirection="column">
        <DetailsPanels
          data={commissionData}
          formatTypes={formatTypes}
          translatedLabels={{
            contractPrice:
              commissionData.status === STATUS_OPTIONS.PROPERTY_MGMT.value
                ? 'Rent'
                : 'Contract Price',
          }}
          panels={{
            [conversion.getName(commissionData.modelAccess.owner)]: {
              attributes: panelAttributes,
              icon: (
                <Grid
                  container
                  pl={1}
                  spacing={1}
                  justifyContent={'space-between'}
                >
                  <Grid item>
                    <FontAwesomeIconComp icon={faGauge} />
                  </Grid>
                  {overview && (
                    <Grid item>
                      <AddCommissionInvoiceButton commission={commission} />
                    </Grid>
                  )}
                </Grid>
              ),
            },
          }}
        />
      </Box>
    </CardContentRow>
  );
};

CommissionSummary.propTypes = {
  commission: PropTypes.object,
  showRelatedModel: PropTypes.bool,
  overview: PropTypes.bool,
};
