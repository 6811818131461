import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { ClickMarketingMenu } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/ClickMarketingMenu';
import PagesNavigation from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/PagesNavigation/PagesNavigation';
import ClickMarketingViewer from 'components/ModelViews/Documents/ClickMarketingViewer/ClickMarketingViewer';
import { showPagesNavSelector } from 'redux/selectors';

function ClickMarketingEditor({
  documentData,
  templateView = false,
  headerHeight,
}) {
  const showPagesNav = useSelector(showPagesNavSelector);

  console.log('showPagesNav', showPagesNav);
  return (
    <Grid alignContent={'flex-start'} container>
      <Grid
        item
        xs={2.2}
        sx={{
          height: 'auto',
          width: '100%',
        }}
      >
        <ClickMarketingMenu headerHeight={headerHeight} />
      </Grid>
      {showPagesNav && (
        <Grid
          item
          xs={1.8}
          style={{
            height: '100%',
            width: '100%',
            overflowY: 'scroll',
          }}
          // onMouseDown={() => {
          //   dispatch(setCurrentItem(null));
          // }}
        >
          <PagesNavigation />
        </Grid>
      )}

      <ClickMarketingViewer
        documentData={documentData}
        templateView={templateView}
      />
    </Grid>
  );
}

export default ClickMarketingEditor;

ClickMarketingEditor.propTypes = {
  documentData: PropTypes.object.isRequired,
  templateView: PropTypes.bool,
  headerHeight: PropTypes.number,
};
