export const Cells = [
  {
    id: 'deliveryLine',
    value: 'deliveryLine',
    disablePadding: true,
    label: 'Delivery Line',
  },
  {
    id: 'hjid',
    value: 'hjid',
    disablePadding: false,
    label: 'MLS ID',
  },
];
