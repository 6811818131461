import { Grid, IconButton, styled } from '@mui/material';

export const FilePreviewBox = styled(Grid)(({ theme, removed }) => ({
  border: `1px solid ${theme.palette.primary.lightgrey}`,
  borderRadius: '8px',
  backgroundColor: 'white',
  position: 'relative',
  '&:not(:last-child)': {
    marginRight: 10,
  },
  '&:hover button': {
    display: 'block',
  },
  '& > div': {
    opacity: removed ? 0.4 : 1,
  },
}));

FilePreviewBox.defaultProps = {
  container: true,
  item: true,
  xs: 4,
};

export const ThumbnailWrapper = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
});

ThumbnailWrapper.defaultProps = {
  container: false,
  item: true,
  xs: 4,
};

export const FileDeleteIcon = styled(IconButton)({
  position: 'absolute',
  right: -10,
  top: -10,
  background: 'white',
  color: 'darkgrey',
  padding: 0,
  fontSize: 25,
  display: 'none',
  '&:hover': {
    background: 'white',
  },
});
