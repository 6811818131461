import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useField, useForm } from 'react-final-form';
import { Button, Grid } from '@mui/material';
import { GoogleAddressLookup, TextInput } from 'components/Fields';
import {
  conversion,
  getMlsParams,
  lookForMls,
  lookForParcelByAddress,
} from 'helpers';
import { useGetMlsListingsQuery, useGetParcelsQuery } from 'redux/rtk-query';

const emptyAddress = {
  city: null,
  country: null,
  zipCode: null,
  street1: null,
  street2: null,
  state: '',
  latitude: '',
  longitude: '',
};
export const ParcelLookupAddress = ({ input }) => {
  let form = useForm();
  const [validating, setValidating] = useState(false);
  const [linking, setLinking] = useState(false);
  let addressConfirmed = useField('addressConfirmed');
  let address = useField('address').input.value;

  const { data: parcelData, isSuccess: isParcelSuccess } = useGetParcelsQuery(
    {
      limit: 10,
      page: 1,
      address: conversion.addressToParcelAddress(address.street1),
      state: address.state,
      zip: address.zipCode,
      secondaryNumber: address.street2,
    },
    {
      skip: !linking || !address.street1 || !address.state || !address.zipCode,
    },
  );

  useEffect(() => {
    if (isParcelSuccess) {
      lookForParcelByAddress(form, parcelData.data);
    }
  }, [isParcelSuccess]);

  const mlsParams = useMemo(() => {
    return getMlsParams(form.getState().values);
  }, [form.getState().values.parcel]);

  const {
    data: mlsData,
    isSuccess: isMlsSuccess,
    isFetching: isMlsFetching,
  } = useGetMlsListingsQuery(
    {
      limit: 10,
      page: 1,
      ...mlsParams,
    },
    { skip: !linking || !mlsParams.parcelNumber },
  );

  useEffect(() => {
    if (isMlsSuccess && !isMlsFetching) {
      lookForMls(form, mlsData.data);
      setLinking(false);
    }
  }, [isMlsSuccess, isMlsFetching]);

  const checkForValidations = useCallback(async () => {
    if (validating) {
      if (
        !form.getState().hasValidationErrors &&
        !form.getState().hasValidationErrors
      ) {
        setValidating(false);
        setLinking(true);
      }
    }
  }, [validating, form.getState().values]);

  useEffect(() => {
    checkForValidations();
  }, [validating]);

  useEffect(() => {
    if (validating) {
      if (
        !form.getState().hasValidationErrors &&
        !form.getState().hasValidationErrors
      ) {
        setValidating(false);
      }
    }
  }, [
    form.getState().hasValidationErrors,
    form.getState().hasValidationErrors,
  ]);

  const handleConfirmAddress = useCallback(() => {
    if (
      !validating &&
      addressConfirmed.input.value &&
      input.value.street1 !== ''
    ) {
      input.onChange(emptyAddress);
      form.change('parcel', {
        id: '',
        name: '',
      });
      form.change('mlsListing', {
        id: '',
        name: '',
      });
      form.change('addressConfirmed', false);
      form.mutators.setFieldData('mlsListing', { tooltip: false });
      form.mutators.setFieldData('parcel', { tooltip: false });
      form.mutators.setFieldData(`${input.name}.street2`, { tooltip: false });
    } else {
      setValidating(true);
    }
  }, [addressConfirmed.input.value, validating]);

  const getValidator = (name) => {
    return !form.getState().values.addressConfirmed &&
      validating &&
      (!input.value[name] || input.value[name] === '')
      ? (value) => (value ? undefined : 'Required')
      : () => {};
  };

  const getText = () => {
    if (linking) {
      return <>LINKING</>;
    }
    return addressConfirmed.input.value === true
      ? 'Reset Address'
      : 'Confirm Address';
  };

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Field
          label="Street 1"
          name={`${input.name}.street1`}
          validate={getValidator('street1')}
          key={validating ? 1 : 0}
          readOnly={addressConfirmed.input.value === true}
        >
          {(props) => (
            <div>
              <GoogleAddressLookup
                onPlaceSelected={input.onChange}
                {...props}
              />
            </div>
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Unit or Apt #"
          name={`${input.name}.street2`}
          component={TextInput}
          readOnly={addressConfirmed.input.value === true}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="City"
          name={`${input.name}.city`}
          component={TextInput}
          validate={getValidator('city')}
          key={validating ? 1 : 0}
          readOnly={addressConfirmed.input.value === true}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Field
            label="State"
            name={`${input.name}.state`}
            component={TextInput}
            validate={getValidator('state')}
            key={validating ? 1 : 0}
            readOnly={addressConfirmed.input.value === true}
            hideError
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label="Zip Code"
            name={`${input.name}.zipCode`}
            component={TextInput}
            validate={getValidator('zipCode')}
            key={validating ? 1 : 0}
            readOnly={addressConfirmed.input.value === true}
            hideError
          />
        </Grid>
        <Grid item xs={4} style={{ alignSelf: 'flex-start' }}>
          <Button
            variant={'outlined'}
            style={{ width: '100%', padding: 6, margin: '10px 0' }}
            onClick={handleConfirmAddress}
          >
            {getText()}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

ParcelLookupAddress.propTypes = {
  input: PropTypes.object,
};
