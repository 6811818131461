import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  ClickAwayListener,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Checkbox, FontAwesomeIconComp } from 'components/Common';
import { ChecklistItemInput, ChecklistRow } from './styled';

export const CheckListItem = ({ setItems, items, item }) => {
  const [isSelected, setIsSelected] = useState(false);

  const [name, setName] = useState(item.name);

  const handleSelectItem = () => {
    setIsSelected(true);
  };

  const handleCheckClick = () => {
    let itemsCopy = [...items];
    let newItems = itemsCopy.map((itemCopy) => {
      if (itemCopy === item) {
        return {
          ...itemCopy,
          status: itemCopy.status === 'Completed' ? 'New' : 'Completed',
        };
      }
      return itemCopy;
    });
    setItems(newItems);
  };

  const saveItem = () => {
    let itemsCopy = [...items];
    let newItems = itemsCopy.map((itemCopy) => {
      if (itemCopy === item) {
        return { ...itemCopy, name };
      }
      return itemCopy;
    });
    setItems(newItems);
  };

  const removeItem = () => {
    let newItems = items
      .map((itemCopy) => {
        if (itemCopy.id.includes('temp-')) {
          return null; // mark item for removal
        } else {
          return { ...itemCopy, removed: true };
        }
      })
      .filter((itemCopy) => itemCopy !== null); // filter out the marked items
    setItems(newItems);
  };

  const handleClickAway = () => {
    setIsSelected(false);
    if (name.length === 0) {
      removeItem();
    } else {
      saveItem();
    }
  };

  const handleKeyPress = (e) => {
    if (e.code === 'Enter') {
      saveItem();
      handleClickAway();
    }
  };

  useEffect(() => {
    if (name.length === 0) {
      setIsSelected(true);
    }
  }, [name]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ListItem
        secondaryAction={
          isSelected && (
            <>
              <IconButton
                onClick={removeItem}
                variant={'rowButton'}
                size={'small'}
                edge="end"
              >
                <FontAwesomeIconComp fontSize={12} icon={faTrash} />
              </IconButton>
            </>
          )
        }
        disablePadding
      >
        <ChecklistRow onClick={handleSelectItem} dense>
          <ListItemIcon style={{ justifyContent: 'center', minWidth: 30 }}>
            <Checkbox
              edge="start"
              checked={item.status === 'Completed'}
              onClick={handleCheckClick}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          {isSelected ? (
            <ChecklistItemInput
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={handleKeyPress}
              size="small"
              placeholder="Checklist Item"
            />
          ) : (
            <ListItemText
              sx={
                item.status === 'Completed'
                  ? {
                      textDecoration: 'line-through',
                      color: 'text.disabled',
                    }
                  : {}
              }
              primary={name}
            />
          )}
        </ChecklistRow>
      </ListItem>
    </ClickAwayListener>
  );
};

CheckListItem.propTypes = {
  setItems: PropTypes.func,
  items: PropTypes.array,
  item: PropTypes.shape({
    status: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object,
};
