import React, { useContext } from 'react';
import { faUndo } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { DocumentCardContext } from '../../../context/DocumentCardContext';

export function DocumentListRefresh() {
  const { refetchDocuments, setIsForcedRefresh } =
    useContext(DocumentCardContext);

  const handleRefreshClick = () => {
    setIsForcedRefresh(true); // Start of forced refresh
    refetchDocuments().finally(() => {
      setIsForcedRefresh(false); // End of forced refresh
    });
  };

  return (
    <Button size={'x-small'} onClick={handleRefreshClick}>
      <FontAwesomeIconComp fontSize={12} icon={faUndo} />
    </Button>
  );
}
