import React from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { CurrencyInput, Select } from 'components/Fields';
import { FormCondition } from 'components/Forms';
import { STATUS_OPTIONS } from 'consts';
import { useTransactionOptions } from 'hooks';

export const ContractPanel = () => {
  let form = useForm();
  const { financingTypeOptions } = useTransactionOptions(form);

  const shouldShow = () => {
    if (
      form.getState().values.status !== STATUS_OPTIONS.LISTED.value &&
      (form.getState().values.representing?.includes('Buyer') ||
        form.getState().values.representing?.includes('Seller'))
    ) {
      return true;
    }
  };

  return (
    <>
      {shouldShow() && (
        <FormPanel label={'Contract'}>
          <Grid item xs={12}>
            <Field
              label={
                form.getState().values.status === STATUS_OPTIONS.DRAFT.value
                  ? 'Est. Contract Price'
                  : 'Contract Price'
              }
              name={'contractPrice'}
              component={CurrencyInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              label="Financing Type"
              name={'financingType'}
              component={Select}
              options={financingTypeOptions}
            />
          </Grid>
          <FormCondition
            renderWhenHidden
            when="status"
            operator="doesNotEqual"
            is={STATUS_OPTIONS.DRAFT.value}
          >
            <Grid item xs={12}>
              <Field
                label="Escrow Amount"
                name={'escrowAmount'}
                component={CurrencyInput}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                label="Additional Escrow Amount"
                name={'additionalEscrowAmount'}
                component={CurrencyInput}
              />
            </Grid>
          </FormCondition>
        </FormPanel>
      )}
    </>
  );
};

ContractPanel.propTypes = {};
