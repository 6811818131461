import { useEffect, useState } from 'react';

export function useAccessDeniedHandler(error) {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (error?.data?.message) {
      const messageCode = error.data.message;
      // Check if the message indicates an access denial
      if (messageCode.includes('ACCESS_DENIED')) {
        // Extract and format the message to make it more user-friendly
        const formattedMessage =
          messageCode.split('_').slice(0, -2).join(' ').toLowerCase() +
          ' access denied.';
        setMessage(
          formattedMessage.charAt(0).toUpperCase() + formattedMessage.slice(1),
        );
      } else {
        setMessage(''); // Reset the message if the current error is not an access denial
      }
    }
  }, [error]);

  return message;
}
