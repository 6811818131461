import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup } from '@mui/material';
import { ImageGalleryPreview } from 'components/Common/ImageGalleryPreview/ImageGalleryPreview';
import { importTemplateCM } from 'redux/slices/clickMarketing/thunk';

export const TemplateImagePreview = ({ template, onClose }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const buttons = useMemo(
    () => (
      <ButtonGroup>
        <Button
          onClick={() => {
            onClose();
            dispatch(importTemplateCM({ templateId: template?.id, page }));
          }}
        >
          Import
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ButtonGroup>
    ),
    [dispatch, template?.id, page],
  );

  if (!template) {
    return null;
  }

  const { attachments: files } = template;

  // Regular expression to extract the number after 'thumbnail-large_'
  const thumbnailLargeRegex = /thumbnail-large_(\d+)/;

  // Filter files where the 'name' includes 'thumbnail-large_'
  const filteredFiles = files.filter(
    (file) => file.name && thumbnailLargeRegex.test(file.name),
  );

  // Sort the filtered files based on the extracted number
  const sortedFiles = filteredFiles.sort((a, b) => {
    const matchA = a.name.match(thumbnailLargeRegex);
    const matchB = b.name.match(thumbnailLargeRegex);

    const numA = matchA ? parseInt(matchA[1], 10) : 0;
    const numB = matchB ? parseInt(matchB[1], 10) : 0;

    return numA - numB;
  });

  return (
    <ImageGalleryPreview
      files={sortedFiles}
      buttons={buttons}
      page={page}
      onPageChange={setPage}
    />
  );
};

TemplateImagePreview.propTypes = {
  template: PropTypes.object,
  onClose: PropTypes.func,
};
