import React from 'react';
import PropTypes from 'prop-types';
import {
  faAsterisk,
  faCalendarAlt,
  faCalendarEdit,
  faWandSparkles,
} from '@fortawesome/pro-regular-svg-icons';
import { useMediaQuery } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { isAutoFill } from '../../../utils/field';

const FieldIcon = ({ field }) => {
  const isSmallScreen = useMediaQuery('(max-width:700px)');
  let color = null;
  let icon = null;
  if (isAutoFill(field)) {
    icon = faWandSparkles;
    color = '#8F35AA';
  } else if (field.type === 'signDate') {
    icon = faCalendarEdit;
    color = '#4153AF';
  } else if (field.type === 'datePicker') {
    icon = faCalendarAlt;
    color = '#4153AF';
  }
  if (field.required === true || field.required === 'yes') {
    icon = icon ? icon : faAsterisk;
    color = '#c9232d';
  }
  if (isSmallScreen) {
    icon = true;
  }

  if (icon) {
    return (
      <FontAwesomeIconComp
        icon={icon}
        style={{
          color: 'white',
          fontSize: 8,
          fontWeight: '400',
          position: 'absolute',
          top: -5,
          right: -5,
          backgroundColor: color,
          borderRadius: 15,
          padding: 3,
          zIndex: 3,
        }}
      />
    );
  }
};

FieldIcon.propTypes = {
  field: PropTypes.object.isRequired,
};

export default FieldIcon;
