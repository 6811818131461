// ItemGroup.js

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import DesignItem from './DesignItem';

const ItemGroup = ({
  itemIds,
  templateId,
  itemsById,
  modularType,
  preview,
  scale,
}) => {
  const zIndex = useMemo(() => {
    return ['Header', 'Footer'].includes(modularType) ? 1 : 0;
  }, [modularType]);

  return (
    <Box zIndex={zIndex} position="relative">
      {itemIds.map((itemId) => {
        const item = itemsById[itemId];
        if (!item) return null;
        return (
          <DesignItem
            key={`${templateId}-${itemId}`}
            item={item}
            preview={preview}
            scale={scale}
          />
        );
      })}
    </Box>
  );
};

ItemGroup.propTypes = {
  itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  templateId: PropTypes.string.isRequired,
  modularType: PropTypes.string.isRequired,
  itemsById: PropTypes.object.isRequired,
  preview: PropTypes.bool,
  scale: PropTypes.number,
};

// Custom comparison function
const areEqual = (prevProps, nextProps) => {
  const {
    itemIds: prevItemIds,
    templateId: prevTemplateId,
    modularType: prevModularType,
    preview: prevPreview,
    scale: prevScale,
  } = prevProps;
  const {
    itemIds: nextItemIds,
    templateId: nextTemplateId,
    modularType: nextModularType,
    preview: nextPreview,
    scale: nextScale,
  } = nextProps;

  // Compare primitive props
  if (
    prevTemplateId !== nextTemplateId ||
    prevModularType !== nextModularType ||
    prevPreview !== nextPreview ||
    prevScale !== nextScale
  ) {
    return false;
  }

  // Compare itemIds array
  if (prevItemIds.length !== nextItemIds.length) return false;
  for (let i = 0; i < prevItemIds.length; i++) {
    if (prevItemIds[i] !== nextItemIds[i]) return false;
  }

  // Compare itemsById reference
  if (prevProps.itemsById !== nextProps.itemsById) return false;

  return true;
};

export default React.memo(ItemGroup, areEqual);
