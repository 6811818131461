import React from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { Select, TextInput } from 'components/Fields';
import { Languages, Pronouns, SiteBranding } from 'consts';
import { getSelectOptions } from 'helpers/form';
import { SocialMediaLinks } from 'pages/SettingsPages/SettingForm/components/ProfilePanel/components/SocialMediaLinks';
import { ProfileBulletList } from './components/ProfileBulletList';
import { Testimonials } from './components/Testimonials';

export const ProfilePanel = () => {
  //TODO don't show SiteBranding.ICON if there is no icon uploaded

  return (
    <FormPanel label="Profile" hideArrow>
      <Grid container>
        <Grid item xs={6}>
          <Field name="agentName" label={'Agent Name'} component={TextInput} />
        </Grid>
        <Grid item xs={6}>
          <Field name={'title'} label={'Title'} component={TextInput} />
        </Grid>
        <Grid item xs={6}>
          <Field name={'emailAddress'} label={'Email'} component={TextInput} />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="siteBranding"
            label="Site Branding"
            defaultValue={SiteBranding.HEADSHOT}
            options={getSelectOptions(SiteBranding)}
            component={Select}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={'phoneNumber'}
            label={'Phone Number'}
            component={TextInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={'officeNumber'}
            label={'Office Number'}
            component={TextInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={'pronouns'}
            label={'Pronouns'}
            component={Select}
            options={getSelectOptions(Pronouns)}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={'languages'}
            label={'Languages'}
            component={Select}
            options={getSelectOptions(Languages)}
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="socialMediaLinks" component={SocialMediaLinks} />
        </Grid>
        <Grid item xs={12}>
          <Field name="bio" label={'Bio'} component={TextInput} rows={8} />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="achievements"
            label={'Achievements'}
            component={ProfileBulletList}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="designations"
            label={'Designations'}
            component={ProfileBulletList}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="communityInvolvement"
            label={'Community Involvement'}
            component={ProfileBulletList}
          />
        </Grid>
        <Grid item xs={12}>
          <Testimonials />
        </Grid>
      </Grid>
    </FormPanel>
  );
};
