import React from 'react';
import PropTypes from 'prop-types';
import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { Box } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';

export const LoadingOverlay = (props) => {
  if (props.loading) {
    return (
      <Box
        sx={{
          position: 'relative',
          zIndex: 9999999999999,
          height: '100%',
          width: '100%',
          margin: 'auto',
          display: 'flex',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Box
          sx={{
            width: props.page ? 816 : '100%',
            height: props.page ? 1056 : '100%',
            background: 'white',
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            backgroundColor: 'white',
            paddingBottom: 10,
          }}
        >
          <FontAwesomeIconComp
            color={'black'}
            icon={faLoader}
            fontSize={100}
            spin
            style={{
              alignSelf: 'center',
              justifyContent: 'center',
              flexGrow: 1,
            }}
          />
        </Box>
      </Box>
    );
  }

  return null;
};

LoadingOverlay.propTypes = {
  loading: PropTypes.bool,
  zoom: PropTypes.number,
  page: PropTypes.bool,
};
