import queryString from 'query-string';
import { endpoints } from 'consts';

export const documentUrlBuilder = {
  getList: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.documents}/?${urlParams}`;
  },
  createDocument: function () {
    return endpoints.documents;
  },
  createMultipleDocuments: function () {
    return `${endpoints.documents}/multiple/`;
  },
  updateDocument: function ({ id }) {
    return `${endpoints.documents}/${id}`;
  },
  getDocument: ({ id, ...other }) => {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.documents}/${id}/?${urlParams}`;
  },
  uploadFiles: function (id) {
    return `${endpoints.documents}/${id}/media/`;
  },
  getDocumentPdfUrl: ({ id }) => {
    return `${endpoints.documents}/${id}/media/urls`;
  },
};
