import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux'; // Adjust the path
import { Autocomplete, TextField } from '@mui/material';
import { useLoadMoreOptions } from 'hooks';
import { useGetUsersQuery, useSwitchUserMutation } from 'redux/rtk-query';
import { setUserLogin, setSnackbarOpen } from 'redux/slices';
import { resetAllApiSlices } from 'utils/invalidateTags';

const getUserOption = ({ id, uuid, name }) => ({
  value: uuid,
  label: name,
  key: id,
});

export const SwitchUserAutocomplete = ({ onSuccess }) => {
  const { handleOpen, options, handleSearch } = useLoadMoreOptions(
    useGetUsersQuery,
    getUserOption,
  );
  const [switchUser] = useSwitchUserMutation();
  const dispatch = useDispatch();

  async function handleSwitchUser(userId) {
    return switchUser(userId);
  }

  const handleUserSwitch = async (value) => {
    try {
      const response = await handleSwitchUser({ userUUID: value.value });
      onSuccess();
      resetAllApiSlices(dispatch);

      dispatch(setUserLogin(response.data.data));

      // setSnackbarMessage(`Switched to ${value.label}`);
      dispatch(
        setSnackbarOpen({
          message: `Switched to ${value.label}`,
          severity: 'success',
        }),
      );

      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } catch (error) {
      console.log('Failed to switch user', error);
      dispatch(
        setSnackbarOpen({
          message: 'Failed to switch user',
          severity: 'error',
        }),
      );
    }
  };

  return (
    <div>
      <Autocomplete
        onOpen={handleOpen}
        options={options}
        getOptionLabel={(option) => option.label}
        onChange={(e, value) => handleUserSwitch(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            onChange={(e) => handleSearch(e.target.value)}
          />
        )}
      />
    </div>
  );
};

SwitchUserAutocomplete.propTypes = {
  onSuccess: PropTypes.func,
};
