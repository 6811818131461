import { useEffect, useState } from 'react';
import { TRANSACTION_TYPES } from 'consts';
import { useGetOffersQuery } from 'redux/rtk-query';

export const useActiveOffer = (transactionId) => {
  const [activeOffer, setActiveOffer] = useState(null);
  const { data: offersData } = useGetOffersQuery({
    id: transactionId,
  });

  useEffect(() => {
    if (offersData?.data) {
      const activeOffer = offersData.data.find(
        (offer) => offer.transactionType === TRANSACTION_TYPES.ACTIVE_OFFER,
      );
      setActiveOffer(activeOffer);
    }
  }, [offersData]);

  return activeOffer;
};
