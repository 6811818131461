import React, { useMemo, useState } from 'react';
import { faPen, faEye } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/Common';
import { useGetUsersQuery } from 'redux/rtk-query';
import { Cells } from './config';

const RowsPerPageOptions = [15, 25, 50];

export const UsersTable = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [search, setSearch] = useState('');

  const { data, isLoading } = useGetUsersQuery({
    page,
    search,
    limit,
    orderBy: 'createdAt',
    column: ['status'],
  });

  const navigate = useNavigate();

  const rows = useMemo(() => data?.data?.items || [], [data?.data]);
  const totalPages = useMemo(() => data?.data?.meta?.totalPages, [data?.data]);
  const MenuItems = useMemo(
    () => [
      {
        name: 'View',
        icon: faEye,
        onClick: ({ uuid }) => navigate(`/admin/users/${uuid}`),
      },
      {
        name: 'Edit',
        icon: faPen,
        onClick: ({ uuid }) => navigate(`/admin/users/edit/${uuid}`),
      },
    ],
    [navigate],
  );

  return (
    <Table
      handleSearch={setSearch}
      search={search}
      isLoading={isLoading}
      rows={rows}
      totalPages={totalPages}
      menuItems={MenuItems}
      cells={Cells}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={RowsPerPageOptions}
      setPage={setPage}
      setRowsPerPage={setLimit}
    />
  );
};

UsersTable.propTypes = {};
