import React from 'react';
import PropTypes from 'prop-types';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Avatar, Name, SettingsIcon, DetailsBox } from 'components/Styled';
import { Username, FieldBox, FieldName, FieldValue, Divider } from './styled';

const ICON_COLOR = '#969696';

export const UserDetails = ({
  user: { uuid, avatar, name, username, contact, setting },
}) => {
  return (
    <DetailsBox>
      <Link to={`/admin/users/edit/${uuid}`}>
        <SettingsIcon icon={faGear} color={ICON_COLOR} />
      </Link>
      <Avatar alt={name} src={avatar} />
      <Name>{name}</Name>
      <Username>{username}</Username>
      <FieldBox>
        <FieldName>Contact</FieldName>
        <FieldValue>{contact.name}</FieldValue>
      </FieldBox>
      <Divider />
      <FieldBox>
        <FieldName>Settings</FieldName>
        <FieldValue>{setting.name}</FieldValue>
      </FieldBox>
    </DetailsBox>
  );
};

UserDetails.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    contact: PropTypes.object,
    setting: PropTypes.object,
  }),
};
