import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { ItemCountProvider } from 'contexts';
import { useGetContactQuery, useGetTransactionQuery } from 'redux/rtk-query';
import { TasksList } from '../TasksList';

export const TaskCard = ({ transactionId, view, type, ...rest }) => {
  const params = useParams();
  const relatedModelId =
    transactionId || params.contactDetailsId || params.transactionDetailsId;
  const relatedModelType = params.contactDetailsId
    ? 'contact'
    : params.transactionDetailsId
      ? 'transaction'
      : null;

  const [relatedModel, setRelatedModel] = useState(null);

  const { data: contactData, isSuccess: isContact } = useGetContactQuery(
    { id: relatedModelId },
    { skip: relatedModelType !== 'contact' },
  );
  const { data: transactionData, isSuccess: isTransaction } =
    useGetTransactionQuery(
      { id: relatedModelId },
      { skip: relatedModelType !== 'transaction' },
    );

  useEffect(() => {
    if (isContact) {
      setRelatedModel(contactData.data);
    }
    if (isTransaction) {
      setRelatedModel(transactionData.data);
    }
  }, [
    isContact,
    isTransaction,
    relatedModelId,
    relatedModelType,
    contactData,
    transactionData,
  ]);

  return (
    <ItemCountProvider>
      <AbeCard title={'Tasks'} {...rest}>
        <Divider />
        <TasksList
          relatedModelType={relatedModelType}
          relatedModel={relatedModel}
          view={view}
          type={type}
        />
      </AbeCard>
    </ItemCountProvider>
  );
};

TaskCard.propTypes = {
  view: PropTypes.string,
  type: PropTypes.string,
  transactionId: PropTypes.string,
};
