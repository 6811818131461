import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Table } from 'components/Common';
import { ColumnManager } from 'components/Common/Table/components/ColumnManager';
import { useTableData } from 'hooks';
import { useGetClickMarketingTemplatesQuery } from 'redux/rtk-query';
import AddDocumentTemplateButton from './components/AddDocumentTemplateButton';
import { Cells, MenuItems } from './config';

const initialColumns = Cells.map((cell) => ({ ...cell, isVisible: true }));

export const ClickMarketingTable = () => {
  const {
    tab,
    parentId = tab === 'clickMarketing' ? 'clickMarketing' : 'home',
  } = useParams();

  const {
    order,
    orderBy,
    filterValues,
    page,
    rowsPerPage,
    search,
    loading,
    setFilterValues,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    totalCount,
    rows,
    visibleColumns,
    resetColumns,
    toggleColumnVisibility,
  } = useTableData(
    useGetClickMarketingTemplatesQuery,
    initialColumns,
    'name',
    'asc',
    10,
  );

  const onRowClick = (row) => {
    console.log(row);
  };

  return (
    <>
      <Table
        setFilterValues={setFilterValues}
        filterValues={filterValues}
        onRowClick={onRowClick}
        handleSearch={setSearch}
        search={search}
        afterSearch={
          <Box display={'flex'} alignItems={'center'}>
            <ColumnManager
              columns={visibleColumns}
              setColumns={toggleColumnVisibility}
              resetColumns={resetColumns}
            />
            <AddDocumentTemplateButton viewingId={parentId} />
          </Box>
        }
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={visibleColumns.filter((col) => col.isVisible)}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
