import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Table } from 'components/Common';
import { ColumnManager } from 'components/Common/Table/components/ColumnManager';
import { conversion, isAdmin } from 'helpers';
import { useTableData } from 'hooks';
import { useGetContactsQuery, useGetProfileQuery } from 'redux/rtk-query';
import { selectedContactsSelector } from 'redux/selectors';
import { setSelectedContacts } from 'redux/slices';
import { TableToolbar } from './components';
import { Cells, MenuItems } from './config';

export const ContactsTable = () => {
  const [tableName, setTableName] = useState('');

  const dispatch = useDispatch();
  let { listId } = useParams();
  // const selectedList = useSelector(selectedListSelector);
  const selected = useSelector(selectedContactsSelector);
  const { data: { data: { permissions } = false } = {} } = useGetProfileQuery();

  //string listId to sentence case
  const listType = conversion.camelCaseToWords(listId);
  const queryType =
    !parseInt(listId) && listId !== 'contact'
      ? parseInt(listId)
        ? parseInt(listId)
        : listType
      : null;

  const {
    order,
    orderBy,
    filterValues,
    page,
    rowsPerPage,
    search,
    loading,
    setFilterValues,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    totalCount,
    rows,
    otherData,
    visibleColumns,
    resetColumns,
    toggleColumnVisibility,
  } = useTableData(useGetContactsQuery, Cells, 'updatedAt', 'desc', 10, {
    type: queryType,
    contact_list_id: parseInt(listId) ? listId : null,
  });

  useEffect(() => {
    let name = 'All Contacts';
    if (listId && !parseInt(listId)) {
      name = `All ${conversion.titleCase(listId)}s`;
    } else if (otherData?.name) {
      name = otherData.name;
    }

    setTableName(name);
  }, [listId, otherData]);

  useEffect(() => {
    if (listId) {
      setPage(1);
    }
  }, [listId]);

  const getClickHandler = (id) => {
    if (id === 'check') {
      const allRowIds = rows.map((row) => row.id);
      dispatch(setSelectedContacts(allRowIds));
    } else if (id === 'uncheck') {
      dispatch(setSelectedContacts([]));
    } else {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      dispatch(setSelectedContacts(newSelected));
    }
  };

  const showCheckbox = isAdmin(permissions) || parseInt(listId);

  return (
    <>
      <Table
        title={tableName}
        handleSearch={setSearch}
        search={search}
        afterSearch={
          <Box>
            <ColumnManager
              columns={visibleColumns}
              setColumns={toggleColumnVisibility}
              resetColumns={resetColumns}
            />
            <TableToolbar />
          </Box>
        }
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={visibleColumns.filter((col) => col.isVisible)}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setSelected={showCheckbox ? getClickHandler : null}
        selected={selected}
        setFilterValues={setFilterValues}
        filterValues={filterValues}
      />
    </>
  );
};
