import React from 'react';
import PropTypes from 'prop-types';
import {
  faCircleInfo,
  faFolderGrid,
  faLayerGroup,
  faSidebar,
  faUserSecret,
} from '@fortawesome/pro-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { BooleanCell, DateCell } from 'components/CellComponents';
import { FontAwesomeIconComp } from 'components/Common';
import {
  DETAILS_DOCUMENT_TEMPLATE,
  EDIT_DOCUMENT_TEMPLATE,
  TEMPLATE_MANAGER,
} from 'consts';
import { columnTypes } from 'consts/columnTypes';
import { STATUSES, TYPES } from 'consts/documentTemplate';
import { getSelectOptions } from 'helpers/form';

const getGoToLabel = (row) =>
  row?.children.length > 0 && row.type === 'File'
    ? 'File Group'
    : row?.aliasDocument && row.type === 'Folder'
      ? 'Folder Alias'
      : row?.aliasDocument && row.type === 'File'
        ? 'File Alias'
        : null;

const DocumentTemplateName = ({ value }) => {
  let navigate = useNavigate();
  const icon =
    value?.children.length > 0 && value.type === 'File'
      ? faLayerGroup
      : value?.aliasDocument && value.type === 'Folder'
        ? faFolderGrid
        : value?.aliasDocument && value.type === 'File'
          ? faUserSecret
          : null;
  const title = getGoToLabel(value);
  return value ? (
    <Box>
      <Box component={'span'} pr={1}>
        {value.name}
      </Box>
      {icon ? (
        <Tooltip title={`Go to ${title}`}>
          <Button
            size={'x-small'}
            onClick={() => {
              navigate(`/${TEMPLATE_MANAGER('documentTemplates', value.id)}`);
            }}
          >
            <FontAwesomeIconComp icon={icon} />
          </Button>
        </Tooltip>
      ) : null}
    </Box>
  ) : (
    <></>
  );
};

DocumentTemplateName.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    children: PropTypes.array,
    type: PropTypes.string,
    aliasDocument: PropTypes.object,
  }),
};

export const Cells = [
  {
    id: 'name',
    label: 'Name',
    value: ['name', 'id', 'type', 'children', 'aliasDocument'],
    numeric: false,
    disablePadding: true,
    static: true,
    Wrapper: DocumentTemplateName,
  },
  {
    id: 'status',
    value: ['status'],
    label: 'Status',
    filterable: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: getSelectOptions(STATUSES),
    disablePadding: true,
  },
  {
    id: 'moduleClassName',
    value: ['moduleClassName'],
    label: 'Module Class Name',
    disablePadding: true,
  },
  {
    id: 'moduleRelationType',
    value: ['moduleRelationType'],
    label: 'Module Attribute Name',
    disablePadding: true,
  },
  {
    id: 'revisionDate',
    value: 'revisionDate',
    label: 'Revision Date',
    Wrapper: DateCell,
  },
  {
    id: 'type',
    value: ['type'],
    label: 'Type',
    filterable: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: getSelectOptions(TYPES),
    disablePadding: true,
  },
  {
    id: 'required',
    value: 'required',
    label: 'required',
    disablePadding: true,
    Wrapper: BooleanCell,
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();
  const { tab } = useParams();

  return [
    {
      name: 'Edit Alias',
      icon: faSidebar,
      closeOnClick: true,
      onClick: (row) => {
        navigate(EDIT_DOCUMENT_TEMPLATE(row.aliasDocument.id));
      },
      shouldRender: (row) => row?.aliasDocument && row?.type === 'File',
    },
    {
      name: (row) => `Go to ${getGoToLabel(row)}`,
      icon: faUserSecret,
      closeOnClick: true,
      divider: true,
      onClick: (row) => {
        navigate(`/${TEMPLATE_MANAGER(tab, row.id)}`);
      },
      shouldRender: (row) => getGoToLabel(row) !== null,
    },
    {
      name: 'Edit',
      icon: faSidebar,
      closeOnClick: true,
      onClick: (row) => {
        navigate(EDIT_DOCUMENT_TEMPLATE(row.id));
      },
      shouldRender: (row) =>
        !row?.aliasDocument &&
        row?.type === 'File' &&
        row?.children.length === 0,
    },
    {
      name: 'Details',
      icon: faCircleInfo,
      closeOnClick: true,
      onClick: (row) => {
        navigate(DETAILS_DOCUMENT_TEMPLATE(row.id));
      },
    },
  ];
};
