import { FORM_ERROR } from 'final-form';
import _ from 'lodash';

export const useFormSubmitHandler = (handleSubmitFunc, afterSave) => {
  const onSubmit = async (values) => {
    let response = await handleSubmitFunc(values);
    // Assuming success is indicated by the absence of an error property

    if (!response || !response.error) {
      if (afterSave) {
        afterSave(response);
      }
    } else {
      let errors = {};
      // Assuming response.error.data.message is an array of error messages
      // and each error message is a JSON string containing an array with
      // the path to the field and the error message for that field
      if (response.error?.data?.message) {
        response.error.data.message.forEach((errorStr) => {
          if (errorStr.includes('data.')) {
            errorStr = errorStr.replace('data.', '');
          }
          try {
            let [fieldPath, errorMessage] = JSON.parse(errorStr);
            // Use lodash's set function or a similar approach to handle nested paths
            // This allows setting a deeply nested error message based on a string path
            _.set(errors, fieldPath, errorMessage);
          } catch (e) {
            console.error('Error parsing error response:', e);
            // Handle unexpected error format
          }
        });
      } else if (response.error.message) {
        errors[FORM_ERROR] = response.error.message;
      }
      // Return the structured errors object for React Final Form
      return errors;
    }
  };

  return onSubmit;
};
