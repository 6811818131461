import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List, ListItemButton, Collapse, Typography } from '@mui/material';
import { fontOptions } from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';
import { conversion } from 'helpers';
import { selectCurrentItem } from 'redux/selectors';
import FontOption from './FontOption';

const FontOptionsList = () => {
  const item = useSelector(selectCurrentItem);
  const [activeGroup, setActiveGroup] = useState(null);

  useEffect(() => {
    if (item && item.style && item.style.fontFamily) {
      for (const fontGroupKey of Object.keys(fontOptions)) {
        const fontGroup = fontOptions[fontGroupKey];
        if (Array.isArray(fontGroup)) {
          if (fontGroup.includes(item.style.fontFamily)) {
            setActiveGroup(fontGroupKey);
            break;
          }
        } else {
          const fontArray = item.style.fontFamily.split(' ');
          if (Object.keys(fontGroup).includes(fontArray[0])) {
            setActiveGroup(fontGroupKey);
            break;
          }
        }
      }
    }
  }, [item]);

  if (!item) return null;

  return (
    <List>
      {Object.keys(fontOptions).map((fontGroup) => {
        const keyName = conversion.wordsToCamelCase(fontGroup);
        const key = `font-group-option-${keyName}`;
        const fontFamily = fontOptions[fontGroup];
        const isActive = fontGroup === activeGroup;

        return (
          <React.Fragment key={key}>
            <ListItemButton
              selected={isActive}
              onClick={() => {
                const newFontGroup = isActive ? null : fontGroup;
                setActiveGroup(newFontGroup);
              }}
            >
              <Typography noWrap style={{ fontSize: 20 }}>
                {fontGroup}
              </Typography>
            </ListItemButton>
            <Collapse in={isActive} timeout="auto" unmountOnExit>
              <List dense>
                <FontOption fontFamily={fontFamily} />
              </List>
            </Collapse>
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default React.memo(FontOptionsList);
