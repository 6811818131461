import React, { useMemo, useState } from 'react';
import setFieldData from 'final-form-set-field-data';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { MenuButton } from 'components/Common';
import { ListsAutocomplete, UserAutocomplete } from 'components/Fields';
import { activityFiltersSelector } from 'redux/selectors';
import { setActivityFilters } from 'redux/slices';
import { CurrentActivityFilters } from '../CurrentActivityFilters';

export const ActivityFilters = () => {
  const [filterType, setFilterType] = useState('lists');
  // eslint-disable-next-line no-unused-vars
  const [filterValue, setFilterValue] = useState('');

  const activityFilters = useSelector(activityFiltersSelector);

  const dispatch = useDispatch();

  const memoizedFilterValues = useMemo(() => {
    return {
      lists: activityFilters.lists || [],
      owners: activityFilters.owners || [],
    };
  }, [activityFilters]);

  const [formValues, setFormValues] = useState({
    lists: memoizedFilterValues.lists,
    owners: memoizedFilterValues.owners,
  });

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
    setFilterValue('');
  };
  // eslint-disable-next-line no-unused-vars
  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
  };

  const applyFilters = (values) => {
    dispatch(
      setActivityFilters({
        filterType,
        filterValues: values[filterType],
      }),
    );
    setFormValues(values);
  };

  return (
    <>
      <CurrentActivityFilters />
      <MenuButton
        title={
          <Button component={'p'} size={'small'} variant="contained">
            Activity Filters
          </Button>
        }
      >
        <Box>
          <FormControl fullWidth component="fieldset">
            <RadioGroup
              row
              sx={{ justifyContent: 'space-evenly' }}
              value={filterType}
              onChange={handleFilterTypeChange}
            >
              <FormControlLabel
                sx={{ display: 'inline-flex' }}
                value="lists"
                control={<Radio />}
                label="List"
              />
              <FormControlLabel
                value="owners"
                control={<Radio />}
                label="Owner"
                sx={{ display: 'inline-flex' }}
              />
            </RadioGroup>
          </FormControl>
          <Form
            mutators={{ setFieldData }}
            initialValues={formValues}
            onSubmit={applyFilters}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {filterType === 'lists' && (
                  <ListsAutocomplete name="lists" label={'Lists'} multiple />
                )}
                {filterType === 'owners' && (
                  <Field
                    name="owners"
                    label="Owners"
                    multiple
                    component={UserAutocomplete}
                  />
                )}
                <Button type="submit" variant="contained">
                  Apply
                </Button>
              </form>
            )}
          />
        </Box>
      </MenuButton>
    </>
  );
};

ActivityFilters.propTypes = {};
