import { Box, styled } from '@mui/system';

export const ImageContainer = styled(Box)({
  bottom: '-2px',
  position: 'absolute',
  display: 'flex',
  width: '100%',
});

export const SignatureImage = styled('img')({
  maxWidth: '50px',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
});

export const DateSpan = styled('span')({
  bottom: -11,
  position: 'absolute',
  height: 10,
  fontSize: '6px',
  width: '100%',
  display: 'block',
  textAlign: 'center',
  fontWeight: 'normal',
});
