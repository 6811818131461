import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, useForm } from 'react-final-form';
import { Box, Button } from '@mui/material';
import { FormPanel, SideDrawer } from 'components/Common';
import {
  RightsPermissions,
  TextInput,
  YearOptionalDatePicker,
} from 'components/Fields';
import {
  ButtonSection,
  CreateBox,
  Header,
  HeadingSection,
} from 'components/Styled';

export const AddDataRelationshipModal = ({ closeHandler, open }) => {
  const {
    mutators: { push },
  } = useForm();

  const onSubmit = async (values) => {
    push('relations', { name: values.name, relationType: values.relation });
    closeHandler();
  };

  return (
    <SideDrawer isOpen={open}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <CreateBox>
            <form onSubmit={handleSubmit}>
              <HeadingSection>
                <Header variant="h4">Add a Relationship</Header>
                <ButtonSection>
                  <RightsPermissions />
                  <Button
                    variant="outlined"
                    onClick={() => closeHandler(false)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                </ButtonSection>
              </HeadingSection>
              <Box p={2}>
                <FormPanel startOpen>
                  <Field
                    required
                    name="relation"
                    label="Relation"
                    component={TextInput}
                  />
                  <Field
                    required
                    name="name"
                    label="Name"
                    component={TextInput}
                  />
                  <Field
                    name="description"
                    label="Description"
                    component={TextInput}
                  />
                  <Field
                    name="birthday"
                    label="Birthday"
                    component={YearOptionalDatePicker}
                  />
                </FormPanel>
              </Box>
            </form>
          </CreateBox>
        )}
      />
    </SideDrawer>
  );
};

AddDataRelationshipModal.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
};
