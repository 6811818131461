import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faCircle, faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  ClickAwayListener,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';

export const ProfileBulletListItemInput = styled(TextField)`
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 175, 233, 0.25);
  }
`;

export const ProfileBulletListItem = ({
  setProfileBulletLists,
  profileBulletLists,
  profileBulletList,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [label, setLabel] = useState(profileBulletList.label);

  const saveItem = () => {
    setProfileBulletLists(
      profileBulletLists.map((a) =>
        a === profileBulletList ? { ...a, label } : a,
      ),
    );
  };

  const removeItem = () => {
    setProfileBulletLists(
      profileBulletLists.filter((a) => a !== profileBulletList),
    );
  };

  const handleClickAway = () => {
    if (!isSelected) {
      saveItem();
    }
    setIsSelected(false);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter' || !isSelected) {
      saveItem();
      setIsSelected(false);
    }
  };

  const displayLabel = label.replace(/\u00A0/g, ' '); // Unicode for &nbsp;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ListItem
        secondaryAction={
          isSelected && (
            <IconButton onClick={removeItem} size="small" edge="end">
              <FontAwesomeIconComp fontSize={12} icon={faTrash} />
            </IconButton>
          )
        }
        disablePadding
      >
        {isSelected || !label ? (
          <ProfileBulletListItemInput
            fullWidth
            autoFocus
            value={label}
            onFocus={(e) => {
              e.target.setSelectionRange(0, 0);
            }}
            onChange={(e) => setLabel(e.target.value)}
            onKeyDown={handleKeyDown}
            size="small"
            placeholder="Add Item"
            multiline
          />
        ) : (
          <>
            <ListItemIcon sx={{ minWidth: 20 }}>
              <FontAwesomeIconComp fontSize={8} icon={faCircle} />
            </ListItemIcon>
            <ListItemText
              primary={displayLabel}
              onClick={() => setIsSelected(true)}
            />
          </>
        )}
      </ListItem>
    </ClickAwayListener>
  );
};

ProfileBulletListItem.propTypes = {
  setProfileBulletLists: PropTypes.func,
  profileBulletLists: PropTypes.array,
  profileBulletList: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
