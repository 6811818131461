import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_ROUTES } from 'consts';
import { omitNull } from 'helpers';
import { useEditRoleMutation, useGetRoleQuery } from 'redux/rtk-query';
import RoleForm, { defaultValues } from '../RoleForm';

export const EditRolePage = () => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  let { roleId } = useParams();
  const navigate = useNavigate();
  const { data } = useGetRoleQuery(roleId);

  const [editRole, { isSuccess, isLoading }] = useEditRoleMutation();

  useEffect(() => {
    if (data) {
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull({ ...data.data }),
      });
    }
  }, [data]);

  const onSubmit = ({ parent, ...values }) => {
    return editRole({
      ...values,
      parentId: parent ? parent.value : null,
      roleId: Number(values.id),
    });
  };

  const afterSave = () => {
    navigate(ADMIN_ROUTES.ROLES.path);
  };

  return (
    <RoleForm
      initialValues={initialValues}
      handleSubmit={onSubmit}
      title={'Edit Role'}
      isLoading={isLoading}
      isSuccess={isSuccess}
      afterSave={afterSave}
    />
  );
};
