import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { CollapsableMenuGroup } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import FontOption from './FontOption';

const FontOptionGroup = ({ fontFamily }) => {
  const item = useSelector(selectCurrentItem);
  const [activeFontGroup, setActiveFontGroup] = useState(null);

  useEffect(() => {
    if (item && item.style && item.style.fontFamily) {
      const fontArray = item.style.fontFamily.split(' ');
      const fontGroupKeys = Object.keys(fontFamily);
      for (const fontGroupKey of fontGroupKeys) {
        if (fontArray[0] === fontGroupKey) {
          setActiveFontGroup(fontGroupKey);
          break;
        }
      }
    }
  }, [item, fontFamily]);

  if (!item) return null;

  return (
    <>
      {Object.keys(fontFamily).map((fontGroup) => {
        const fontGroupFonts = fontFamily[fontGroup];
        const isActive = activeFontGroup === fontGroup;

        return (
          <CollapsableMenuGroup
            key={`font-option-${fontGroup}`}
            label={
              <Typography
                noWrap
                style={{
                  fontFamily: fontGroup,
                  fontSize: 20,
                }}
              >
                {fontGroup}
              </Typography>
            }
            isSelected={isActive}
            onClick={() => {
              setActiveFontGroup(isActive ? null : fontGroup);
            }}
          >
            <FontOption
              fontFamilyGroup={fontGroup}
              fontFamily={fontGroupFonts}
            />
          </CollapsableMenuGroup>
        );
      })}
    </>
  );
};

export default React.memo(FontOptionGroup);

FontOptionGroup.propTypes = {
  fontFamily: PropTypes.object.isRequired,
};
