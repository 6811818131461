import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DetailsPanels } from 'components/Common';

export const BottomCard = ({ contactInfo, attributes }) => {
  return (
    <Box width={'100%'}>
      <DetailsPanels
        data={contactInfo}
        panels={{
          Overview: attributes,
        }}
      />
    </Box>
  );
};

BottomCard.propTypes = {
  contactInfo: PropTypes.object,
  attributes: PropTypes.array,
};
