import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const FileInputBox = styled(Box)({
  backgroundColor: '#f6f7f9',
  padding: 10,
  margin: '10px 0',
  borderRadius: 10,
});

export const FileInputText = styled('span')({
  color: '#797777',
  padding: 10,
  margin: '10px 0',
});
