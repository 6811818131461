import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import { Required } from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { BoxInput, ErrorMsg, TextInputField } from 'components/Styled';
import { hasValue } from 'helpers';
import { useFormValidationProcess, useIsRequired } from 'hooks';

export const CurrencyInput = ({
  label,
  disabled,
  input,
  meta,
  autoFocus,
  tooltip,
  readOnly,
  textAlign,
  required,
  fullWidth = true,
  shrinkLabel,
}) => {
  let error =
    meta.submitError && !meta.dirtySinceLastSubmit ? meta.submitError : false;
  const [shrink, setShrink] = useState(!!shrinkLabel);
  const [value, setValue] = useState('');

  const inputElementRef = useRef(null);

  useEffect(() => {
    if (input.value) {
      setShrink(true);
    }
    setValue(formatCurrency(input.value));
  }, [input.value]);

  const formatNumber = (n) => {
    const isNegative = n.indexOf('-') === 0; // Check if the number is negative
    let number = n.replace(/\D/g, ''); // Remove all non-digit characters
    number = number.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
    return isNegative ? '-' + number : number; // Prepend '-' if the number was negative
  };

  const formatCurrency = (val, blur) => {
    if (val === '') return '';
    let inputVal = val;

    // Preserve the negative sign if present
    const isNegative = inputVal.indexOf('-') === 0;
    inputVal = isNegative ? inputVal.substring(1) : inputVal; // Remove the negative sign for processing

    if (inputVal.indexOf('.') >= 0) {
      let [leftSide, rightSide] = inputVal.split('.');

      leftSide = formatNumber(leftSide);
      rightSide = formatNumber(rightSide);

      if (blur === 'blur') {
        rightSide += '00';
      }

      rightSide = rightSide.substring(0, 2);
      inputVal = `${isNegative ? '-' : ''}${leftSide}.${rightSide}`; // Re-add the negative sign if needed
    } else {
      inputVal = formatNumber(inputVal);
      if (blur === 'blur') {
        inputVal += '.00';
      }
      inputVal = isNegative ? '-' + inputVal : inputVal; // Re-add the negative sign if needed
    }

    return inputVal;
  };

  const handleChange = (event) => {
    const cursorPositionBeforeChange = inputElementRef.current.selectionStart;
    const oldValue = value;
    const newValue = event.target.value;

    // Format the new value
    const formattedValue = formatCurrency(newValue);

    // Update the value state and form
    setValue(formattedValue);
    input.onChange(formattedValue.replace(/[$,]/g, '')); // Remove $ and commas for the form value

    // Determine if a comma or dollar sign was added or removed
    const oldFormattedValue = formatCurrency(oldValue);
    const addedCommasAndDollar = formattedValue.replace(/[^$,]/g, '');
    const oldCommasAndDollar = oldFormattedValue.replace(/[^$,]/g, '');
    const netFormattingChars =
      addedCommasAndDollar.length - oldCommasAndDollar.length;

    // Calculate the new cursor position
    let cursorPositionAfterChange = cursorPositionBeforeChange;
    if (
      netFormattingChars > 0 &&
      cursorPositionBeforeChange > oldValue.indexOf('$')
    ) {
      // Adjust cursor for added formatting chars
      cursorPositionAfterChange += netFormattingChars;
    } else if (
      netFormattingChars < 0 &&
      cursorPositionBeforeChange > oldValue.length
    ) {
      // Adjust cursor for removed formatting chars
      cursorPositionAfterChange += netFormattingChars;
    }

    // Ensure cursor position is within valid range
    cursorPositionAfterChange = Math.min(
      formattedValue.length,
      cursorPositionAfterChange,
    );
    cursorPositionAfterChange = Math.max(0, cursorPositionAfterChange);

    setTimeout(() => {
      if (inputElementRef.current) {
        inputElementRef.current.setSelectionRange(
          cursorPositionAfterChange,
          cursorPositionAfterChange,
        );
      }
    }, 0);
  };

  const isFilled = hasValue(input.value);
  let isRequired = (useIsRequired(input.name) && !isFilled) || required;
  const validateFormData = useFormValidationProcess();

  const handleBlur = async (event, input) => {
    await validateFormData();
    setValue(formatCurrency(value, 'blur'));
    !event.target.value && setShrink(false);
    input.onBlur(event);
  };

  return (
    <BoxInput>
      <TextInputField
        {...input}
        inputRef={inputElementRef}
        size={'small'}
        className={isFilled ? 'filled' : ''}
        label={
          <>
            {label}
            {isRequired ? <Required /> : ''}
          </>
        }
        error={!!error}
        disabled={disabled}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        InputLabelProps={{
          shrink: shrink,
        }}
        InputProps={{
          startAdornment: shrink ? (
            <InputAdornment position="start">$</InputAdornment>
          ) : null,
          readOnly: readOnly,
          autoComplete: 'off',
          'data-lpignore': 'true',
          inputProps: { style: { textAlign: textAlign } },
        }}
        type="text"
      />
      {tooltip && <FieldTooltip tooltip={tooltip} />}
      {error && <ErrorMsg component={'span'}>{error}</ErrorMsg>}
    </BoxInput>
  );
};

CurrencyInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.object,
  iconStart: PropTypes.string,
  autoFocus: PropTypes.bool,
  tooltip: PropTypes.object,
  readOnly: PropTypes.bool,
  textAlign: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  shrinkLabel: PropTypes.bool,
};
