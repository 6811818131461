import React from 'react';
import PropTypes from 'prop-types';
import { useTaskDueDate } from 'hooks';

export const TaskDueOn = ({ value }) => {
  const dueOnElement = useTaskDueDate(value, 'long');

  return <>{dueOnElement}</>;
};

TaskDueOn.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    tasks: PropTypes.number,
  }),
};
