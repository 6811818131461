import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { REPORT_LIST, REPORT_UPDATE } from 'consts';
import ReportConfig from 'pages/ReportPages/DetailsReportPage/ReportConfig';
import { useGetReportQuery } from 'redux/rtk-query';
import ReportTable from './ReportTable';

export const DetailsReportPage = () => {
  const { reportId } = useParams();
  const { data: reportData } = useGetReportQuery(reportId, { skip: !reportId });

  return (
    <>
      <LayoutHeader title={reportData?.data?.name}>
        <CreateButton path={`/${REPORT_UPDATE(reportId)}`} title="Edit" />
        <CreateButton path={`/${REPORT_LIST}`} title="List" />
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReportConfig reportId={reportId} />
          </Grid>
          <Grid item xs={12}>
            <ReportTable reportId={reportId} />
          </Grid>
        </Grid>
      </PageMainContent>
      <Outlet />
    </>
  );
};
