import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { EmailsList } from 'components/ModelViews/Emails';

export const EmailCard = ({ transactionId, ...props }) => {
  return (
    <AbeCard title={'Emails'} {...props}>
      <Divider />
      <EmailsList transactionId={transactionId} />
    </AbeCard>
  );
};

EmailCard.propTypes = {
  transactionId: PropTypes.string,
};
