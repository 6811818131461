import { useCallback, useEffect, useMemo, useState } from 'react';

export function useTableData(
  dataFetchHookOrTuple, // Accepts either a query hook or a mutation tuple
  initialColumns,
  initialOrderBy,
  initialOrder = 'asc',
  initialRowsPerPage = 10,
  extraParams = {},
  dataHookOptions = {},
) {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [filterValues, setFilterValues] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [search, setSearch] = useState('');
  const [visibleColumns, setVisibleColumns] = useState(
    initialColumns.map((cell) => ({
      ...cell,
      isVisible: cell.isVisible ?? false,
    })),
  );

  const toggleColumnVisibility = (columnId) => {
    setVisibleColumns((cols) =>
      cols.map((col) =>
        col.id === columnId ? { ...col, isVisible: !col.isVisible } : col,
      ),
    );
  };

  const resetColumns = () => {
    setVisibleColumns(initialColumns);
  };

  // Determine if we're handling a mutation or query
  const isMutation = Array.isArray(dataFetchHookOrTuple);
  let trigger, result;
  if (isMutation) {
    [trigger, result] = dataFetchHookOrTuple;
  }

  // Memoize query parameters to prevent unnecessary re-renders
  const queryParams = useMemo(
    () => ({
      page,
      limit: rowsPerPage,
      search,
      order: order.toUpperCase(),
      orderBy,
      column: visibleColumns
        .filter((col) => col.isVisible)
        .map((col) => col.id),
      ...filterValues,
      ...extraParams,
    }),
    [
      page,
      rowsPerPage,
      search,
      order,
      orderBy,
      visibleColumns,
      filterValues,
      extraParams,
    ],
  );

  // If it's a mutation, trigger it when queryParams change
  useEffect(() => {
    if (isMutation) {
      trigger(queryParams, dataHookOptions);
    }
  }, [queryParams]);

  // Initialize variables for result data and fetching state
  let data, isFetching, otherFetchHookProps;

  if (isMutation) {
    data = result.data;
    isFetching = result.isLoading || result.isFetching;
    // eslint-disable-next-line no-unused-vars
    const { data: _ignored, isFetching: __ignored, ...rest } = result;
    otherFetchHookProps = rest;
  } else {
    // For queries, call the hook directly with the parameters
    const resultFromQuery = dataFetchHookOrTuple(queryParams, dataHookOptions);
    data = resultFromQuery.data;
    isFetching = resultFromQuery.isFetching || resultFromQuery.isLoading;
    // eslint-disable-next-line no-unused-vars
    const { data: _ignored, isFetching: __ignored, ...rest } = resultFromQuery;
    otherFetchHookProps = rest;
  }

  const totalPages = data?.meta
    ? data.meta.totalPages
    : data?.data?.meta?.totalPages;
  const totalCount = data?.meta ? data.meta.total : data?.data?.meta?.total;
  const rows = data?.data?.items || data?.data || [];
  const otherData = data?.meta
    ? data.meta.otherData
    : data?.data?.meta?.otherData;

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (search) {
      setPage(1);
    }
  }, [search]);

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  return {
    order,
    orderBy,
    filterValues,
    page,
    rowsPerPage,
    search,
    totalPages,
    totalCount,
    rows,
    otherData,
    visibleColumns,
    toggleColumnVisibility,
    resetColumns,
    loading,
    setFilterValues,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    ...otherFetchHookProps,
  };
}
