import { styled } from '@mui/system';

export const TaskSectionHeader = styled('div')({
  fontSize: 13,
  padding: 3,
  display: 'flex',
  alignItems: 'center',
  '& button': {
    marginRight: 4,
  },
});
