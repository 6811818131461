export const INVOICE_TEMPLATE_STATUS = {
  DRAFT: 'Draft',
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
};

export const INVOICE_TYPES = {
  MARKETING_PHOTOS_AND_TOURS: 'Marketing Photos & Tours',
  MARKETING_YARD_SIGN: 'Marketing Yard Sign',
  MARKETING_PROMO_ITEM: 'Marketing Promo Item',
  MARKETING_OTHER: 'Marketing Other',
  MARKETING_FUND: 'Marketing Fund',
  MARKETING_SERVICES: 'Marketing Services',
  MGMT_REAL_ESTATE_SERVICE: 'Mgmt Real Estate Service',
  MGMT_ESCROW_FUNDS: 'Mgmt Escrow Funds',
  TENANT_CHARGE: 'Tenant Charge',
  COMMISSION: 'Commission',
  MARKETING_DESIGN: 'Marketing Design',
  MGMT_WORK_ORDER: 'Mgmt Work Order',
  MGMT_MAINTENANCE_REQUEST: 'Mgmt Maintenance Request',
  OWNER_CHARGE: 'Owner Charge',
  MGMT_DEBIT_CREDIT: 'Mgmt Debit/Credit',
};

export const INVOICE_PAYMENT_TYPES = {
  RECEIVABLE: 'Receivable',
  PAYABLE: 'Payable',
};
