import queryString from 'query-string';
import { endpoints } from 'consts';

export const cappingUrlBuilder = {
  getList: function ({ page = 1, limit = 15, search } = {}) {
    return `${endpoints.cappings}?page=${page}&limit=${limit}${
      search ? `&search=${search}` : ''
    }`;
  },
  createCapping: function () {
    return endpoints.cappings;
  },
  getCapping: ({ id }) => {
    return `${endpoints.cappings}/${id}/details`;
  },
  getDefaultCapping: (params) => {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.cappings}/default?${urlParams}`;
  },
};
