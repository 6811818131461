import React from 'react';
import {
  faHeader,
  faBold,
  faCheck,
  faUnderline,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { MenuItemButton } from 'components/Common';
import ColorPickerMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/ColorPickerMenuItem';
import { colors } from 'consts';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

export const HeaderMenuItem = () => {
  const dispatch = useDispatch();
  const activeItem = useSelector(selectCurrentItem);

  const handleToggleSwitch = () => {
    if (activeItem?.id) {
      dispatch(
        updateItemCM({
          itemId: activeItem.id,
          properties: { header: !activeItem.header },
        }),
      );
    }
  };

  const handleUnderlineToggle = () => {
    if (activeItem?.id) {
      dispatch(
        updateItemCM({
          itemId: activeItem.id,
          properties: {
            style: {
              ...activeItem.style,
              underline: !activeItem.style.underline,
            },
          },
        }),
      );
    }
  };

  return (
    <Box
      sx={{
        outline: `2px solid ${colors.DARK_BLUE_1}`,
        padding: activeItem.header ? '2px' : 0,
        outlineOffset: '-2px',
      }}
    >
      <MenuItemButton
        icon={
          <FontAwesomeIcon
            fontSize={14}
            icon={activeItem.header ? faHeader : faBold}
          />
        }
        label={
          <Grid container alignItems={'center'}>
            <Grid item xs={9}>
              {activeItem.header ? 'Header' : 'Body'}
            </Grid>
            <Grid item xs={3}>
              <Switch
                size="small"
                color="primary"
                checked={activeItem.header || false}
                onChange={handleToggleSwitch}
              />
            </Grid>
          </Grid>
        }
      />
      {activeItem.header && !activeItem.multiline && (
        <>
          <MenuItemButton
            icon={<FontAwesomeIcon fontSize={14} icon={faUnderline} />}
            onClick={handleUnderlineToggle}
            label={
              <Grid container>
                <Grid item xs={11}>
                  Underline
                </Grid>
                <Grid item xs={1}>
                  {activeItem.style.underline && (
                    <FontAwesomeIcon fontSize={14} icon={faCheck} />
                  )}
                </Grid>
              </Grid>
            }
          />
          {activeItem.style.underline && (
            <>
              <ColorPickerMenuItem
                showThemeColor
                styleProperty={'underlineColor'}
                label={'Underline Color'}
                icon={faUnderline}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
