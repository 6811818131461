import React from 'react';
import PropTypes from 'prop-types';

export const TransactionMlsImage = ({ imgUrl }) => {
  return (
    <img
      style={{
        // clipPath: "inset(0px 0px 50px 0px)",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        width: '100%',
      }}
      src={imgUrl}
    />
  );
};

TransactionMlsImage.propTypes = {
  imgUrl: PropTypes.string,
};
