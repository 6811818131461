import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { GoogleAddressLookup, TextInput } from 'components/Fields';

export const AddressBox = ({ input }) => (
  <Grid container>
    <Grid item xs={12}>
      <Field label="Street 1" name={`${input.name}.street1`}>
        {(props) => (
          <div>
            <GoogleAddressLookup
              onPlaceSelected={input.onChange}
              {...props}
              name={`${input.name}.street1`}
              id={`${input.name}.street1`}
              key={`${input.name}.street1`}
            />
          </div>
        )}
      </Field>
    </Grid>
    <Grid item xs={12}>
      <Field
        name={`${input.name}.street2`}
        label="Address Line 2"
        component={TextInput}
      />
    </Grid>
    <Grid item xs={6}>
      <Field name={`${input.name}.city`} label="City" component={TextInput} />
    </Grid>
    <Grid item xs={6}>
      <Field name={`${input.name}.state`} label="State" component={TextInput} />
    </Grid>
    <Grid item xs={6}>
      <Field
        name={`${input.name}.zipCode`}
        label="Zip Code"
        component={TextInput}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        name={`${input.name}.country`}
        label="Country"
        component={TextInput}
      />
    </Grid>
  </Grid>
);

AddressBox.propTypes = {
  input: PropTypes.object,
};
