import { Popper as MuiPopper, styled } from '@mui/material';

export const Popper = styled(MuiPopper)({
  zIndex: 10,
  borderRadius: '8px',
});

export const SlideContainer = styled('div')(({ width, height }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: width + 'px',
  height: height + 'px',
  transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out',
  minWidth: '50px',
  minHeight: '50px',
}));

export const SlideContent = styled('div')(({ slideIn, fromRight }) => ({
  overflow: 'hidden',
  boxSizing: 'border-box', // Include padding in the width calculation
  whiteSpace: 'nowrap', // Prevent content wrapping
  transform: slideIn
    ? 'translateX(0)'
    : fromRight
      ? 'translateX(100%)'
      : 'translateX(-100%)',
  transition: 'transform 0.3s ease-in-out',
  position: 'absolute',
  top: '0',
  left: '0',
}));
