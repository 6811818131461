import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

// Create a context with an initial value (in this case, an empty function)
const DrawerContext = createContext({
  closeDrawer: () => {},
});

// Custom hook to use the DrawerContext
export const useDrawerContext = () => useContext(DrawerContext);

// Provider component to wrap around the SideDrawer
export const DrawerContextProvider = ({ children, closeDrawer }) => {
  return (
    <DrawerContext.Provider value={{ closeDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

DrawerContextProvider.propTypes = {
  children: PropTypes.node,
  closeDrawer: PropTypes.func,
};
