import React, { useEffect, useState } from 'react';
import { faPencilAlt, faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from '@mui/material';
import { MenuItemButton } from 'components/Common';
import { CM_ITEM_TYPES } from 'consts';
import { selectCurrentItem, itemsSelectorCM } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

export const TextLabelMenuItem = () => {
  const dispatch = useDispatch();
  const activeItem = useSelector(selectCurrentItem);
  const items = useSelector(itemsSelectorCM); // Assuming you have a selector for all items

  const [label, setLabel] = useState('');
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    if (activeItem?.id) {
      setLabel(activeItem.textLabel || '');
    }
  }, [activeItem]);

  const handleSave = () => {
    if (activeItem?.id) {
      dispatch(
        updateItemCM({
          itemId: activeItem.id,
          properties: { textLabel: label },
        }),
      );
    }
  };

  const canUseItemLabel = () => {
    // Check if the label is unique among items
    return !items.some(
      (item) => item.textLabel === label && item.id !== activeItem.id,
    );
  };

  useEffect(() => {
    if (label && label !== activeItem?.textLabel && canUseItemLabel()) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [label, activeItem]);

  const shouldShow = () => {
    // Show for specific item types
    return [
      CM_ITEM_TYPES.text.fillnow,
      CM_ITEM_TYPES.text.list,
      CM_ITEM_TYPES.image.attachment,
      CM_ITEM_TYPES.image.svgImage,
    ].includes(activeItem?.type);
  };

  if (!shouldShow()) return null;

  return (
    <MenuItemButton
      sx={{ pt: 0 }}
      icon={<FontAwesomeIcon icon={faPencilAlt} />}
      label={
        <Input
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          inputProps={{ 'aria-label': 'description' }}
          endAdornment={
            <Button
              disabled={!canSave}
              style={{ minWidth: 30 }}
              onClick={handleSave}
              variant="contained"
              color="primary"
              size="small"
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          }
        />
      }
    />
  );
};
