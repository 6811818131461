// ReportChart.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faMap } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IconButton, Box } from '@mui/material';
import BarChartHorizontal from 'pages/ReportPages/DetailsReportPage/charts/BarChartHorizontal';
import BarChartStacked from 'pages/ReportPages/DetailsReportPage/charts/BarChartStacked';
import BarChartVertical from 'pages/ReportPages/DetailsReportPage/charts/BarChartVertical';
import PieChart from 'pages/ReportPages/DetailsReportPage/charts/PieChart';
import { useGetChartDataQuery, useGetChartQuery } from 'redux/rtk-query';

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const colorPalette = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
];

function toOpaque(colorString) {
  if (!colorString) return 'rgba(0,0,0,1)';
  if (colorString.startsWith('#')) return colorString;
  const match = colorString.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/,
  );
  if (match) {
    const [, r, g, b] = match;
    return `rgba(${r}, ${g}, ${b}, 1)`;
  }
  return 'rgba(0,0,0,1)';
}

function hexToRgba(hexColor, alpha = 0.6) {
  const hex = hexColor.replace(/^#/, '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Parse a path, keeping double underscores __ together.
function parsePathStr(pathStr) {
  if (!pathStr) return [];
  const parts = [];
  let buffer = '';
  for (let i = 0; i < pathStr.length; i++) {
    const char = pathStr[i];
    const nextChar = pathStr[i + 1];
    if (char === '_' && nextChar === '_') {
      buffer += '__';
      i++; // skip next
    } else if (char === '_') {
      parts.push(buffer);
      buffer = '';
    } else {
      buffer += char;
    }
  }
  if (buffer) parts.push(buffer);
  return parts;
}

function getNestedValue(obj, pathStr) {
  if (!obj || !pathStr) return undefined;
  const keys = parsePathStr(pathStr);
  return keys.reduce((acc, key) => {
    if (acc === null) return undefined;
    return acc[key];
  }, obj);
}

// --- Helpers for year-month
function parseYearMonth(ymStr) {
  // e.g. "2025-01" => new Date(2025, 0, 1)
  const [yyyy, mm] = ymStr.split('-');
  return new Date(+yyyy, +mm - 1, 1);
}

function formatAxisLabel(date) {
  // e.g. show the year only on January, or "Jan", "Feb", etc.
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = date.getFullYear();
  const month = date.getMonth();
  if (month === 0) {
    return `${year} ${monthNames[month]}`; // e.g. "2025 Jan"
  }
  return monthNames[month]; // "Feb", "Mar", etc.
}

function formatFullMonthYear(date) {
  // e.g. "January 2025"
  const fullMonthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${fullMonthNames[date.getMonth()]} ${date.getFullYear()}`;
}

function getAllMonthsInRange(startDate, endDate) {
  const result = [];
  let current = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  while (current <= endDate) {
    result.push(new Date(current));
    current.setMonth(current.getMonth() + 1); // increment by 1 month
  }
  return result;
}

export const ReportChart = ({ reportId, chartId }) => {
  const [showLegend, setShowLegend] = useState(false);

  const handleToggleLegend = () => {
    setShowLegend((prev) => !prev);
  };
  const { data: chartResponse } = useGetChartQuery({ reportId, chartId });
  const { data: chartDataResponse } = useGetChartDataQuery({
    reportId,
    chartId,
    limit: 100,
  });

  if (!chartResponse || !chartDataResponse) {
    return <div>Loading...</div>;
  }

  const chart = chartResponse.data;
  const chartData = chartDataResponse.data;
  const chartType = chart.type;
  const datasets = chart.datasets || [];

  // Build field keys
  const getFieldKey = (column) => {
    if (!column) return '';
    let fieldKey = column.visualPath.join('_');
    if (column.dateSeparation) {
      fieldKey += `__${column.dateSeparation}`;
    } else if (column.groupingFunction) {
      fieldKey += `__${column.groupingFunction}`;
    }
    return fieldKey;
  };

  const xAxisDataset = datasets.find((d) => d.axis === 'X');
  const yAxisDataset = datasets.find((d) => d.axis === 'Y');
  const xFieldKey = getFieldKey(xAxisDataset?.column);
  const yFieldKey = getFieldKey(yAxisDataset?.column);

  // 1) Gather raw pairs: { ymString: "2025-01", value: 62000 } or just strings
  const rawPairs = chartData.map((item) => {
    const rawLabel = getNestedValue(item, xFieldKey) || '';
    const rawVal = parseFloat(getNestedValue(item, yFieldKey) || 0);
    return { rawLabel, rawVal };
  });

  // 2) Check if it's a Year&Month scenario
  const isYearMonth = xFieldKey.includes('__Year&Month');

  let finalDates = []; // array of Date objects (for tooltip)
  let finalLabels = []; // array of strings for the axis
  let finalValues = [];

  if (isYearMonth) {
    // Filter valid "YYYY-MM"
    const validEntries = rawPairs.filter((p) =>
      /^\d{4}-\d{2}$/.test(p.rawLabel),
    );
    if (validEntries.length === 0) {
      // fallback: just use raw label
      finalLabels = rawPairs.map((p, i) => p.rawLabel || `Unknown ${i}`);
      finalValues = rawPairs.map((p) => p.rawVal);
    } else {
      // parse each date
      const dateVals = validEntries.map((e) => ({
        date: parseYearMonth(e.rawLabel),
        val: e.rawVal,
      }));
      // find min & max
      let minDate = dateVals[0].date;
      let maxDate = dateVals[0].date;
      for (let i = 1; i < dateVals.length; i++) {
        if (dateVals[i].date < minDate) minDate = dateVals[i].date;
        if (dateVals[i].date > maxDate) maxDate = dateVals[i].date;
      }
      // generate all months in range
      const allMonths = getAllMonthsInRange(minDate, maxDate);

      // build map => "YYYY-MM" => sum of values
      const mapMonthVals = {};
      dateVals.forEach((dv) => {
        const ymKey = dv.date.toISOString().slice(0, 7); // e.g. "2025-01"
        if (!mapMonthVals[ymKey]) mapMonthVals[ymKey] = 0;
        mapMonthVals[ymKey] += dv.val; // or keep track if multiple
      });

      // fill final arrays
      finalDates = allMonths; // array of Date objects
      finalLabels = allMonths.map((d) => formatAxisLabel(d));
      finalValues = allMonths.map((d) => {
        const ymKey = d.toISOString().slice(0, 7);
        return mapMonthVals[ymKey] || 0;
      });
    }
  } else if (xFieldKey.includes('__Month')) {
    // Filter valid month entries: expecting "1" to "12" or "01" to "12"
    const monthRegex = /^(0?[1-9]|1[0-2])$/;
    const validEntries = rawPairs.filter((p) => monthRegex.test(p.rawLabel));

    // Define full month names
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    // Create a list of all months 1 through 12
    const allMonths = Array.from({ length: 12 }, (_, i) => i + 1);

    // Build a mapping from month number to sum of values
    const mapMonthVals = {};
    validEntries.forEach((e) => {
      const m = parseInt(e.rawLabel, 10);
      if (!mapMonthVals[m]) mapMonthVals[m] = 0;
      mapMonthVals[m] += e.rawVal;
    });

    // Populate finalLabels with month names and finalValues with corresponding sums
    finalLabels = allMonths.map((m) => monthNames[m - 1]);
    finalValues = allMonths.map((m) => mapMonthVals[m] || 0);

    // No specific date context for months, so assign nulls to finalDates
    finalDates = finalLabels.map(() => null);
  } else {
    // not year&month => original approach
    finalLabels = rawPairs.map((p) =>
      p.rawLabel.trim() === '' ? 'Unknown' : p.rawLabel.replace(/&nbsp;/g, ' '),
    );
    finalValues = rawPairs.map((p) => p.rawVal);
  }

  // If we didn't fill finalDates for non-Year&Month, let's do a dummy
  if (!isYearMonth) {
    // create a parallel array of null or some placeholder
    finalDates = finalLabels.map(() => null);
  }

  // Build colors
  const backgroundColors = finalLabels.map((_, i) => {
    const hex = colorPalette[i % colorPalette.length];
    return hexToRgba(hex, 0.9);
  });

  // Build data for Chart.js
  const data = {
    labels: finalLabels, // shorter axis labels
    datasets: [
      {
        label: yAxisDataset?.column?.name || 'Value',
        data: finalValues,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        ...(chartType === 'Circle Chart Pie Charts' && {
          borderColor: '#fff',
          borderWidth: 1,
        }),
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: chartType !== 'Circle Chart Pie Charts',
      },
      y: {
        display: chartType !== 'Circle Chart Pie Charts',
      },
    },
    plugins: {
      legend: {
        display: showLegend,
        position: 'right',
      },
      tooltip: {
        position: 'nearest',
        backgroundColor: (ctx) => {
          const tooltip = ctx.tooltip;
          if (!tooltip || !tooltip.dataPoints?.length) return 'rgba(0,0,0,0.8)';
          const dp = tooltip.dataPoints[0];
          const sliceColor = dp.dataset.backgroundColor[dp.dataIndex];
          return toOpaque(sliceColor);
        },
        borderColor: '#fff', // or "white"
        borderWidth: 2,
        titleColor: '#fff',
        bodyColor: '#fff',
        displayColors: false,

        // Here's the custom callback for the title => show full month/year
        callbacks: {
          title: (tooltipItems) => {
            if (!tooltipItems.length) return '';
            const idx = tooltipItems[0].dataIndex;
            const d = finalDates[idx];
            if (!d) {
              // not a year-month, fallback to the label
              return tooltipItems[0].label || '';
            }
            // e.g. "October 2024"
            return formatFullMonthYear(d);
          },
          // The label is the numeric value
          label: (tooltipItem) => {
            const val = tooltipItem.formattedValue;
            const tooltipLabel =
              yAxisDataset?.column?.groupingFunction === 'Count'
                ? 'Count'
                : yAxisDataset?.column?.name || 'Value';
            return `${tooltipLabel}: ${val}`;
          },
        },
      },
    },
  };

  // Render the correct chart
  const renderChart = () => {
    switch (chartType) {
      case 'Bar Chart Horizontal':
        return <BarChartHorizontal data={data} options={options} />;
      case 'Bar Chart Vertical':
        return <BarChartVertical data={data} options={options} />;
      case 'Circle Chart Pie Charts':
        return <PieChart data={data} options={options} />;
      case 'Bar Chart Stacked':
        return <BarChartStacked data={data} options={options} />;
      default:
        return <div>Chart type not supported.</div>;
    }
  };

  return (
    <Box
      sx={{
        height: '400px',
        width: '100%',
        justifyItems: 'center',
        position: 'relative',
        '& .MuiIconButton-root': {
          opacity: showLegend ? 1 : 0,
          transition: 'opacity 0.3s',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          boxShadow: 2,
        },
        '&:hover .MuiIconButton-root': {
          opacity: 1,
        },
      }}
    >
      <IconButton
        onClick={handleToggleLegend}
        style={{
          marginRight: '1rem',
          position: 'absolute',
          right: 20,
          top: 15,
        }}
      >
        <FontAwesomeIcon icon={faMap} />
      </IconButton>
      {renderChart()}
    </Box>
  );
};

ReportChart.propTypes = {
  reportId: PropTypes.string.isRequired,
  chartId: PropTypes.number.isRequired,
};

export default ReportChart;
