import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { RoleNameWrapper } from '../styled';

export function RoleName({
  editingName,
  inputRef,
  roleName,
  onBlur,
  onChange,
  value,
  onKeyDown,
}) {
  return (
    <RoleNameWrapper>
      {editingName ? (
        <input
          ref={inputRef}
          style={{ width: '100%' }}
          value={value}
          type={'text'}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      ) : (
        <Typography variant={'body1'}>{roleName}</Typography>
      )}
    </RoleNameWrapper>
  );
}

RoleName.propTypes = {
  editingName: PropTypes.bool,
  inputRef: PropTypes.any,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  roleName: PropTypes.string,
};
