import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Content = styled(Box)({
  width: '600px',
  height: 'fit-content',
  minHeight: '70vh',
  background: '#FFFFFF',
  boxShadow: `0px 11px 15px rgba(0, 0, 0, 0.1),
  0px 9px 46px rgba(0, 0, 0, 0.06),
  0px 24px 38px rgba(0, 0, 0, 0.04)`,
  borderRadius: '8px',
  outline: 'none',
});

export const HeadingSection = styled(Box)({
  padding: '12px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E8E8E8',
});

export const HeaderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const Header = styled(Typography)({
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '28px',
  color: '#1C1C1C',
});

export const SubHeader = styled(Typography)({
  fontWeight: '500',
  fontSize: '1.3rem',
  lineHeight: '20px',
  color: '#565656',
  marginTop: '8px',
});

export const ListBox = styled(Box)({
  display: 'flex',
  gap: '12px',
  margin: '16px',
});

export const ActiveUser = styled(Typography)({
  fontWeight: '500',
  fontSize: '1.6rem',
  lineHeight: '24px',
  padding: '4px 12px',
  color: '#0085FF',
  textAlign: 'center',
  background:
    'linear-gradient(0deg, rgba(0, 133, 255, 0.1), rgba(0, 133, 255, 0.1)), #FFFFFF',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
  borderRadius: '40px',
});

export const InactiveUser = styled(ActiveUser)({
  color: '#565656',
  background: '#F7F7F7',
});
