import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Typography } from '@mui/material';
import { pathArrayToPathLabel } from 'helpers/report';

export const Path = ({ name }) => (
  <Field name={`${name}.visualPath`}>
    {({ input }) => (
      <Typography component={'div'} sx={{ whiteSpace: 'no-wrap' }}>
        {pathArrayToPathLabel(input.value)}
      </Typography>
    )}
  </Field>
);

Path.propTypes = {
  name: PropTypes.string.isRequired,
};
