import React from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Button, ListItem, ListItemIcon } from '@mui/material';
import { Checkbox, FontAwesomeIconComp } from 'components/Common';
import { ChecklistRow } from './styled';

export const AddCheckListItem = ({ addNewItem }) => {
  return (
    <ListItem disablePadding>
      <ChecklistRow dense>
        <ListItemIcon style={{ justifyContent: 'center', minWidth: 30 }}>
          <Checkbox edge="start" disabled tabIndex={-1} disableRipple />
        </ListItemIcon>
        <Button
          size="small"
          endIcon={<FontAwesomeIconComp icon={faPlus} />}
          onClick={addNewItem}
        >
          Add item
        </Button>
      </ChecklistRow>
    </ListItem>
  );
};

AddCheckListItem.propTypes = {
  addNewItem: PropTypes.func,
};
