import React, { useCallback } from 'react';
import { faTextSize } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, InputAdornment, Box } from '@mui/material';
import { CollapsableMenuGroup } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const FontSizeMenuItem = () => {
  const dispatch = useDispatch();
  const item = useSelector(selectCurrentItem);

  const handleFontSizeChange = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            style: {
              ...item.style,
              fontSize: `${value}px`,
            },
          },
        }),
      );
    },
    [dispatch, item],
  );

  if (!item) return null;

  return (
    <CollapsableMenuGroup
      hideChevron
      label="Font Size"
      icon={<FontAwesomeIcon icon={faTextSize} />}
    >
      <Box padding={2}>
        <TextField
          value={parseInt(item.style.fontSize, 10) || ''}
          onChange={handleFontSizeChange}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
            style: { width: '112px' },
          }}
          variant="outlined"
        />
      </Box>
    </CollapsableMenuGroup>
  );
};

export default React.memo(FontSizeMenuItem);
