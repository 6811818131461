import React from 'react';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';

export function DeleteDocumentButton() {
  return (
    <IconButton size="small" sx={{ ml: 1, p: 0 }}>
      <FontAwesomeIconComp fontSize={14} icon={faTrash} />
    </IconButton>
  );
}
