import React from 'react';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faEye, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { PopperButton } from 'components/Common';
import { AcceptOfferComponent } from 'components/ModelViews/Transactions/OffersCard/components/OfferRow/components/AcceptOfferComponent';
import { UPDATE_OFFER, TRANSACTION_TYPES, VIEW_OFFER } from 'consts';
import { useOpenDrawer } from 'hooks';
import {
  useUpdateTransactionTypeMutation,
  tasksAPI,
  documentsAPI,
  notesAPI,
} from 'redux/rtk-query';

export const MenuItems = () => {
  const openDrawer = useOpenDrawer();

  const [updateTransactionType] = useUpdateTransactionTypeMutation();
  const dispatch = useDispatch();

  const handleReject = async (offerId) => {
    await updateTransactionType({
      id: offerId,
      transactionType: TRANSACTION_TYPES.ARCHIVED_OFFER,
    });
    dispatch(tasksAPI.util.invalidateTags(['Tasks']));
    dispatch(documentsAPI.util.invalidateTags(['Documents']));
    dispatch(notesAPI.util.invalidateTags(['Notes']));
  };

  return [
    {
      name: 'View',
      icon: faEye,
      closeOnClick: true,
      onClick: ({ id }) => {
        openDrawer(VIEW_OFFER(id));
      },
      shouldRender: (item) =>
        item.transactionType !== TRANSACTION_TYPES.ACTIVE_OFFER,
    },
    {
      name: 'Edit',
      icon: faPencil,
      closeOnClick: true,
      onClick: ({ id }) => {
        openDrawer(UPDATE_OFFER(id));
      },
      shouldRender: (item) =>
        item.transactionType !== TRANSACTION_TYPES.ACTIVE_OFFER,
    },
    {
      name: 'Accept Offer',
      icon: faCheckCircle,
      color: 'success',
      content: AcceptOfferComponent,
      shouldRender: (item) =>
        item.transactionType !== TRANSACTION_TYPES.ACTIVE_OFFER,
    },
    {
      name: 'Cancel Offer',
      icon: faTimesCircle,
      onClick: ({ id }) => {
        handleReject(id);
      },
      shouldRender: (item) =>
        item.transactionType === TRANSACTION_TYPES.ACTIVE_OFFER,
    },
  ];
};

export function OfferRowButton({ item }) {
  const menuItems = MenuItems(item);
  return <PopperButton size={'x-small'} menuItems={menuItems} item={item} />;
}

OfferRowButton.propTypes = {
  item: PropTypes.object.isRequired,
};
