// ChipSelect.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { LabeledOutline, ColorChip } from 'components/Common';
import { BoxInput } from 'components/Styled';

/**
 * ChipSelect Component
 * Allows selecting multiple chips.
 *
 * Props:
 * - input: Object containing value (array) and onChange handler from React Final Form.
 * - label: String label for the chip group.
 * - options: Array of option objects { id, name, color }.
 * - size: Size of the chips ('x-small', 'small', 'medium', 'large').
 * - disabled: Boolean to disable the chip selection.
 */
export const ChipSelect = ({
  input,
  label,
  options,
  size = 'small',
  disabled = false,
}) => {
  /**
   * Handles the click event on a chip.
   * Toggles the selection of the chip.
   *
   * @param {string} value - The ID of the clicked option.
   */
  const handleClick = (value) => () => {
    const currentValue = Array.isArray(input.value) ? input.value : [];

    if (currentValue.includes(value)) {
      // Remove the value if it's already selected
      input.onChange(currentValue.filter((item) => item !== value));
    } else {
      // Add the value if it's not selected
      input.onChange([...currentValue, value]);
    }
  };

  // Ensure selectedValues is always an array
  const selectedValues = Array.isArray(input.value) ? input.value : [];

  return (
    <LabeledOutline label={label}>
      <BoxInput px={1} pt={1.5} autoFocus>
        {options.map((option) => (
          <ColorChip
            sx={{ mr: 1, mb: 1 }}
            key={option.id}
            variant={
              selectedValues.includes(option.id) ? 'contained' : 'outlined'
            }
            chipColor={option.color}
            size={size}
            disabled={disabled}
            label={<>{option.name}</>}
            onClick={handleClick(option.id)}
          />
        ))}
      </BoxInput>
    </LabeledOutline>
  );
};

// Define the shape of each option
const optionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
});

// Define PropTypes for ChipSelect
ChipSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(optionShape).isRequired,
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
  disabled: PropTypes.bool,
};

// Define default props
ChipSelect.defaultProps = {
  size: 'small',
  disabled: false,
  label: '',
};
