import React from 'react';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { CollapsableMenuGroup } from 'components/Common';
import GalleryImages from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/MediaLibrary/GalleryImages';
import { useGetFavoritesQuery, useGetProfileQuery } from 'redux/rtk-query';
import { currentDocumentSelectorCM } from 'redux/selectors';

const RecommendedImageMenuItem = () => {
  const currentDocument = useSelector(currentDocumentSelectorCM);
  const { data: userData } = useGetProfileQuery();
  const { data } = useGetFavoritesQuery(
    {
      limit: 50,
      userId: userData?.data?.id,
      documentTemplateId: currentDocument?.id,
    },
    {
      skip: !userData?.data?.id || !currentDocument?.id,
    },
  );

  return (
    <div>
      {data?.data?.items.length > 0 && (
        <CollapsableMenuGroup
          label={'Recommended'}
          icon={<FontAwesomeIcon icon={faStar} fontSize={18} />}
        >
          <GalleryImages
            files={data?.data?.items}
            cols={3}
            onClose={() => {}}
          />
        </CollapsableMenuGroup>
      )}
    </div>
  );
};

export default RecommendedImageMenuItem;
