import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { DocumentsList } from '../DocumentsList';
import DocumentCardToolbar from './components/DocumentCardToolbar/DocumentCardToolbar';
import { DocumentCardProvider } from './context/DocumentCardContext';

export const DocumentCard = ({ transactionId }) => {
  return (
    <DocumentCardProvider>
      <AbeCard
        elevation={2}
        title={'Documents'}
        afterTitle={<DocumentCardToolbar />}
      >
        <Divider />
        <DocumentsList transactionId={transactionId} />
        <Divider />
      </AbeCard>
    </DocumentCardProvider>
  );
};

DocumentCard.propTypes = {
  transactionId: PropTypes.string,
};
