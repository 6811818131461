import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faEye, faEyeSlash, faLock } from '@fortawesome/pro-solid-svg-icons';
import { TextInput } from 'components/Fields';
import { Icon } from 'components/Fields/TextInput/styled';

export const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextInput
      {...props}
      iconStart={<Icon icon={faLock} />}
      type={showPassword ? 'text' : 'password'}
      iconEnd={
        <Icon
          icon={showPassword ? faEyeSlash : faEye}
          onClick={() => setShowPassword(!showPassword)}
        />
      }
    />
  );
};

PasswordInput.defaultProps = {
  size: 'small',
  disabled: false,
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  iconStart: PropTypes.node,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};
