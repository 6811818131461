import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Box, Grid } from '@mui/material';

export const Row = ({ children, ...props }) => (
  <Box width={'100%'} {...props}>
    <Grid container>{children}</Grid>
  </Box>
);

Row.propTypes = {
  children: PropTypes.node.isRequired,
};

export const FormField = ({ label, name, component, size = 12, ...props }) => (
  <Grid item xs={size}>
    <Field label={label} name={name} component={component} {...props} />
  </Grid>
);

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  size: PropTypes.number,
};
