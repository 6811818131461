// PieChart.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ data, options }) => {
  return (
    <Pie
      data={data}
      options={{
        ...options,
        weight: 2000,
        plugins: {
          ...options.plugins,
          datalabels: {
            display: false,
          },
        },
      }}
    />
  );
};

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default PieChart;
