import queryString from 'query-string';
import { endpoints } from 'consts';

export const noteUrlBuilder = {
  createNote: function () {
    return endpoints.notes;
  },
  updateNote: function () {
    return endpoints.notes;
  },
  getNote: function ({ id }) {
    return `${endpoints.notes}/${id}`;
  },
  getNotes: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.notes}/notes?${urlParams}`;
  },
  noteFileUpload: function (uuid) {
    return `${endpoints.notes}/${uuid}/media`;
  },
  noteFileDelete: function (uuid) {
    return `${endpoints.notes}/media/${uuid}`;
  },
  getNoteMedia: function (uuid) {
    return `${endpoints.notes}/${uuid}/media`;
  },
};
