import React from 'react';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { PREVIEW_SCALE } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/PagesNavigation/PageTab';
import { colors } from 'consts';
import { pixelSizeSelector } from 'redux/selectors';
import { addNewPageCM } from 'redux/slices';

const AddPageTab = () => {
  const dispatch = useDispatch();
  const [width, height] = useSelector(pixelSizeSelector);
  const pageWidth = width * PREVIEW_SCALE;
  const pageHeight = height * PREVIEW_SCALE;

  const handleClick = () => {
    dispatch(addNewPageCM());
  };

  return (
    <Grid
      py={1}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      item
    >
      <Box
        sx={{
          width: `${pageWidth}px`,
          position: 'relative',
        }}
      >
        <Box
          position="relative"
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={handleClick}
          sx={{
            width: `${pageWidth}px`,
            height: `${pageHeight}px`,
          }}
        >
          <Tooltip title={'Add Page'} arrow>
            <IconButton>
              <FontAwesomeIcon
                icon={faPlusCircle}
                size={'2x'}
                color={colors.WHITE}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Grid>
  );
};

export default AddPageTab;
