import React from 'react';
import PropTypes from 'prop-types';
import { Box, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Checkbox } from 'components/Common';
import {
  MaterialTableHead,
  TableCell,
  TableHeadDivider,
  TableRow,
} from 'components/Styled';
import { TableHeadFilter } from '../components/TableHeadFilter';

export const TableHead = ({
  order,
  orderBy,
  cells,
  align,
  onRequestSort,
  setSelected,
  selected,
  rowCount,
  menuItems,
  hideHeaderCheckbox,
  setFilterValues,
  filterValues,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const numSelected = selected ? selected.length : 0;
  const showUncheckAll = numSelected > 0 && numSelected < rowCount;
  const showCheckAll = rowCount > 0 && numSelected === rowCount;

  return (
    <MaterialTableHead>
      <TableRow>
        {setSelected && selected && (
          <TableCell align={'center'} width={'1%'}>
            {!hideHeaderCheckbox && (
              <Checkbox
                indeterminate={showUncheckAll}
                checked={showCheckAll}
                onChange={() => {
                  setSelected(
                    !showUncheckAll && !showCheckAll ? 'check' : 'uncheck',
                  );
                }}
                inputProps={{
                  'aria-label': 'select all contacts',
                }}
              />
            )}
          </TableCell>
        )}
        {/*{setSelected && (*/}
        {/*    <TableCell align={"center"} width={"1%"}>*/}
        {/*        {!hideHeaderCheckbox && <Checkbox*/}
        {/*            indeterminate={showUncheckAll}*/}
        {/*            checked={showCheckAll}*/}
        {/*            onChange={setSelected(!showUncheckAll && !showCheckAll ? "check" : "uncheck")}*/}
        {/*            inputProps={{*/}
        {/*                "aria-label": "select all contacts",*/}
        {/*            }}*/}
        {/*        />}*/}
        {/*    </TableCell>*/}
        {/*)}*/}
        {cells.map(
          ({
            id,
            disablePadding,
            label,
            disableSort,
            cellAlign,
            width,
            filterable,
            filterType,
            filterOptions,
            sx,
          }) => (
            <TableCell
              key={id}
              width={width}
              sx={sx}
              align={cellAlign ? cellAlign : align}
              padding={disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === id ? order : false}
            >
              {disableSort ? (
                label
              ) : (
                <TableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : 'desc'}
                  onClick={createSortHandler(id)}
                >
                  {label}
                  {orderBy === id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
              {filterable && (
                <TableHeadFilter
                  id={id}
                  label={label}
                  filterType={filterType}
                  filterOptions={filterOptions}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                />
              )}
              <TableHeadDivider />
            </TableCell>
          ),
        )}
        {menuItems ? <TableCell /> : null}
      </TableRow>
    </MaterialTableHead>
  );
};

TableHead.defaultProps = {
  cells: [],
  align: 'left',
};

TableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  align: PropTypes.string,
  rowCount: PropTypes.number,
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
    }),
  ),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      icon: PropTypes.object,
    }),
  ),
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  onRequestSort: PropTypes.func,
  hideHeaderCheckbox: PropTypes.bool,
  setFilterValues: PropTypes.func,
  filterValues: PropTypes.object,
};
