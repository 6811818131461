import React from 'react';
import PropTypes from 'prop-types';
import { conversion } from 'helpers';

const FullName = ({ value }) => {
  return value ? conversion.getName(value) : <></>;
};

FullName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'firstName',
    value: ['firstName', 'middleName', 'lastName', 'companyName', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    Wrapper: FullName,
  },
];
