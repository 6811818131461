import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Select } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { getSelectOptions } from 'helpers';
import { getLastPathName } from 'helpers/report';
import { DatasetsFields } from 'pages/ReportPages/DetailsReportPage/DatasetsFields';
import {
  useGetColumnsQuery,
  useGetGroupingsQuery,
  useCreateChartMutation,
} from 'redux/rtk-query';

const chartTypes = [
  'Bar Chart Horizontal',
  'Bar Chart Vertical',
  'Bar Chart Stacked',
  'Bar Chart Stacked with Groups',
  'Circle Chart Doughnut Charts',
  'Circle Chart Pie Charts',
  'Line Chart Straight Line',
  'Line Chart Curved Line',
  'Scatter Chart',
];
const ChartForm = ({ reportId, afterSave }) => {
  const initialValues = {
    type: '',
    name: '',
    datasets: [],
    id: reportId,
  };
  const [createChart] = useCreateChartMutation();

  const { data: columnsData } = useGetColumnsQuery(reportId, {
    skip: !reportId,
  });
  const { data: groupingsData } = useGetGroupingsQuery(reportId, {
    skip: !reportId,
  });

  const columns = columnsData?.data || [];
  const groupingPaths =
    groupingsData?.data.map((group) => JSON.stringify(group.visualPath)) || [];
  const tableColumns = columns
    .filter(
      (column) =>
        column.groupingFunction ||
        groupingPaths.includes(JSON.stringify(column.visualPath)),
    )
    .sort((a, b) => a.order - b.order);

  const columnSelectOptions = tableColumns.map((column) => ({
    value: column.id,
    label: getLastPathName(column, true),
  }));
  const onSubmit = async (values) => {
    await createChart({
      ...values,
      reportId,
    }).unwrap();
  };

  const renderFields = () => (
    <>
      <Field
        name={'type'}
        label={'Chart Type'}
        component={Select}
        options={getSelectOptions(chartTypes)}
      />
      <Field
        name={'datasets'}
        component={DatasetsFields}
        columnOptions={columnSelectOptions}
      />
    </>
  );
  return (
    <div>
      <AbeForm
        afterSave={afterSave}
        initialValues={initialValues}
        handleSubmitFunc={onSubmit}
        showAllFieldsButton={false}
      >
        {renderFields}
      </AbeForm>
    </div>
  );
};
export default ChartForm;
ChartForm.propTypes = {
  reportId: PropTypes.string.isRequired,
  afterSave: PropTypes.func,
};
