import React from 'react';
import { faRulerCombined } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import { setShowGrid } from 'redux/slices';

const ShowGridMenuItem = () => {
  const dispatch = useDispatch();
  return (
    <MenuItemButton
      onClick={() => {
        dispatch(setShowGrid());
      }}
      icon={<FontAwesomeIconComp fontSize={18} icon={faRulerCombined} />}
      label={'Grid'}
    />
  );
};

export default ShowGridMenuItem;
