import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { CommissionAfterTitle } from 'components/ModelViews/Commissions/CommissionCard/components/CommissionAfterTitle/CommissionAfterTitle';
import { useGetTransactionQuery } from 'redux/rtk-query';
import { CommissionsList } from '../CommissionsList';

export const CommissionCard = ({ overview, ...props }) => {
  const { contactDetailsId, transactionDetailsId } = useParams();
  const relatedModelId = contactDetailsId || transactionDetailsId;
  const relatedModelType = contactDetailsId ? 'contact' : 'transaction';

  const { data: transactionData } = useGetTransactionQuery({
    id: relatedModelId,
  });

  const afterTitle = useMemo(
    () => (
      <CommissionAfterTitle
        relatedModelType={relatedModelType}
        relatedModelId={relatedModelId}
        overview={overview}
      />
    ),
    [relatedModelType, relatedModelId, overview],
  );

  return (
    <AbeCard title={'Commissions'} afterTitle={afterTitle} {...props}>
      <Divider />
      {transactionData && (
        <CommissionsList
          overview={overview}
          relatedModelType={relatedModelType}
          relatedModel={transactionData.data}
        />
      )}
    </AbeCard>
  );
};

CommissionCard.propTypes = {
  overview: PropTypes.bool,
};
