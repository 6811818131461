import { useSelector } from 'react-redux';
import { currentDocumentSelectorCM } from 'redux/selectors';

export const useItemLabelValue = (currentItem) => {
  const currentDocument = useSelector(currentDocumentSelectorCM);

  if (
    currentDocument &&
    currentDocument.itemGroups &&
    currentDocument.itemGroups[currentItem.page] &&
    currentDocument.itemGroups[currentItem.page][currentItem.modularType] &&
    currentDocument.itemGroups[currentItem.page][currentItem.modularType][
      currentItem.templateId
    ]
  ) {
    return currentDocument.itemGroups[currentItem.page][
      currentItem.modularType
    ][currentItem.templateId].labelValue?.[currentItem.textLabel];
  }

  return null;
};
