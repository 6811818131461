import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/baseQuery';
import { profileUrlBuilder } from './urlBuilder/profile';

export const profileAPI = createApi({
  reducerPath: 'profileAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    getProfile: build.query({
      query: () => ({
        url: profileUrlBuilder.getProfile(),
        method: 'GET',
      }),
      providesTags: ['Profile'],
    }),
  }),
});

export const { useGetProfileQuery } = profileAPI;
