import React from 'react';
import PropTypes from 'prop-types';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { List, ListItem, ListItemText, useTheme } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { PageLink } from 'components/Styled';
import { LEFT_MENU_ITEM_AGENT, LEFT_MENU_ITEM_FULL } from 'consts';
import { getModuleIcon, isAdmin } from 'helpers';
import { UpperListItemButton, UpperListItemIcon } from '../../styled';

export const ModuleMenu = ({ fullMenu, setFullMenu, open }) => {
  const theme = useTheme();
  const isAdminUser = isAdmin();
  const menuItems = isAdminUser ? LEFT_MENU_ITEM_FULL : LEFT_MENU_ITEM_AGENT;
  const moduleMenuItems = menuItems.map((item) => ({
    ...item,
    icon: getModuleIcon(item.module),
  }));
  const permLeftMenuItemInfo = moduleMenuItems.slice(0, 7);
  const menuToDisplay = fullMenu ? moduleMenuItems : permLeftMenuItemInfo;
  return (
    <List disablePadding>
      {menuToDisplay.map((item) => (
        <ListItem
          key={item.module}
          disablePadding
          component={PageLink}
          to={item.refLink}
          sx={{
            '& .MuiTouchRipple-child': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <UpperListItemButton>
            <UpperListItemIcon
              sx={{ margin: open ? '0 10px 0 5px' : null, opacity: 0.75 }}
            >
              <FontAwesomeIconComp
                icon={item.icon.icon}
                color={item.icon.color}
                fontSize={'1.8rem'}
              />
            </UpperListItemIcon>
            <ListItemText
              primary={item.module}
              sx={{ display: open ? 'block' : 'none', width: '10px' }}
            />
          </UpperListItemButton>
        </ListItem>
      ))}
      {isAdminUser && (
        <ListItem disablePadding sx={{ display: open ? 'block' : 'none' }}>
          <UpperListItemButton
            sx={{
              padding: 1,
              mb: 1,
              minHeight: '20px',
            }}
            onClick={() => setFullMenu(!fullMenu)}
          >
            <FontAwesomeIconComp
              icon={faEllipsis}
              color={'#0085FF'}
              size="lg"
            />
          </UpperListItemButton>
        </ListItem>
      )}
    </List>
  );
};

ModuleMenu.propTypes = {
  fullMenu: PropTypes.bool,
  setFullMenu: PropTypes.func,
  open: PropTypes.bool,
};
