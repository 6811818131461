import React, { useCallback } from 'react';
import {
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Box } from '@mui/material';
import { CollapsableMenuGroup } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const TextAlignMenuItem = () => {
  const dispatch = useDispatch();

  const item = useSelector(selectCurrentItem);

  const handleTextAlignClick = useCallback(
    (textAlign) => {
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            style: {
              ...item.style,
              textAlign: textAlign,
            },
          },
        }),
      );
    },
    [dispatch, item],
  );

  if (!item) return null;

  return (
    <CollapsableMenuGroup
      label="Text Align"
      icon={<FontAwesomeIcon icon={faAlignJustify} />}
    >
      <Box
        sx={{
          padding: '12px',
          paddingRight: '24px',
          textAlign: 'center',
        }}
      >
        <ButtonGroup color="primary" size="large">
          <Button
            variant={item.style.textAlign === 'left' ? 'contained' : 'outlined'}
            onClick={() => handleTextAlignClick('left')}
          >
            <FontAwesomeIcon icon={faAlignLeft} />
          </Button>
          <Button
            variant={
              item.style.textAlign === 'center' ? 'contained' : 'outlined'
            }
            onClick={() => handleTextAlignClick('center')}
          >
            <FontAwesomeIcon icon={faAlignCenter} />
          </Button>
          <Button
            variant={
              item.style.textAlign === 'right' ? 'contained' : 'outlined'
            }
            onClick={() => handleTextAlignClick('right')}
          >
            <FontAwesomeIcon icon={faAlignRight} />
          </Button>
        </ButtonGroup>
      </Box>
    </CollapsableMenuGroup>
  );
};

export default React.memo(TextAlignMenuItem);
