import React, { useContext } from 'react';
import { ColorChip } from 'components/Common';
import { STATUSES } from 'consts';
import { getColorByStatus } from 'helpers';
import { DocumentCardContext } from '../../../context/DocumentCardContext';

export function DocumentListFilters() {
  const { statusFilter, setStatusFilter } = useContext(DocumentCardContext);
  const statusOptions = Object.keys(STATUSES).map((key) => STATUSES[key]);

  const handleChipToggle = (e) => {
    const status = e.target.innerText;
    if (statusFilter.includes(status)) {
      setStatusFilter(statusFilter.filter((item) => item !== status));
    } else {
      setStatusFilter([...statusFilter, status]);
    }
  };

  return (
    <>
      {statusOptions.map((status) => (
        <ColorChip
          key={status}
          label={status}
          variant={statusFilter.includes(status) ? 'default' : 'outlined'}
          chipColor={getColorByStatus(status)}
          onClick={handleChipToggle}
          sx={{ mr: 1 }}
        />
      ))}
    </>
  );
}
