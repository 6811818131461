import { createApi } from '@reduxjs/toolkit/query/react';
import { documentTemplateUrlBuilder } from 'redux/rtk-query/urlBuilder/documentTemplates';
import { profileUrlBuilder } from 'redux/rtk-query/urlBuilder/profile';
import { axiosBaseQuery } from 'utils/baseQuery';

export const serviceAPI = createApi({
  reducerPath: 'serviceAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    getSvgContent: build.query({
      query: (args) => ({
        url: profileUrlBuilder.getSvgContent(args),
        method: 'GET',
      }),
    }),
    getMediaLibrary: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getMediaLibrary(args),
        method: 'GET',
      }),
    }),
    getMediaLibraryUrl: build.query({
      query: (args) => ({
        url: documentTemplateUrlBuilder.getMediaLibraryUrl(args),
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetSvgContentQuery,
  useGetMediaLibraryUrlQuery,
  useGetMediaLibraryQuery,
} = serviceAPI;
