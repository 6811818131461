// DesignItem.js

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Image,
  Shape,
  Text,
} from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/fields';
import ItemLabelText from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/ItemLabelText';
import ResizableDraggable from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/ResizableDraggable';
import { imageTypes, shapeTypes, textTypes, CM_ITEM_TYPES } from 'consts';
import { isTemplateViewSelectorCM as isTemplateViewSelector } from 'redux/selectors';

// Helper to get the correct component based on item type
const getItemComponent = (item, preview) => {
  if (imageTypes.includes(item.type)) return <Image itemId={item.id} />;
  if (shapeTypes.includes(item.type)) return <Shape itemId={item.id} />;
  if (textTypes.includes(item.type))
    return <Text itemId={item.id} preview={preview} />;
  return null;
};

// Custom comparison function for React.memo
const areEqual = (prevProps, nextProps) => {
  const { item: prevItem, preview: prevPreview, scale: prevScale } = prevProps;
  const { item: nextItem, preview: nextPreview, scale: nextScale } = nextProps;

  // Compare primitive props
  if (prevPreview !== nextPreview) return false;
  if (prevScale !== nextScale) return false;

  // Shallow compare 'item' properties
  const keysToCompare = [
    'id',
    'type',
    'textLabel',
    'x',
    'y',
    'w',
    'h',
    'backgroundW',
    'backgroundH',
    'textAlign',
    'style',
    'page',
  ];

  for (let key of keysToCompare) {
    if (prevItem[key] !== nextItem[key]) {
      return false;
    }
  }

  // Assuming 'children' are memoized or stable
  return true;
};

const DesignItem = ({ item, preview, scale }) => {
  const isTemplateView = useSelector(isTemplateViewSelector);
  // Memoize the item.style to ensure stable reference
  const memoizedStyle = useMemo(() => ({ ...item.style }), [item.style]);

  // Memoize the field object with stable style
  const memoizedField = useMemo(
    () => ({ ...item, style: memoizedStyle }),
    [item, memoizedStyle],
  );

  // Get the component based on item type
  const component = useMemo(
    () => getItemComponent(item, preview),
    [item, preview],
  );

  return (
    <ResizableDraggable field={memoizedField} scale={scale} preview={preview}>
      {component}
      {isTemplateView && item.textLabel && !preview && (
        <ItemLabelText
          textLabel={item.textLabel}
          insideLabel={
            item.type === CM_ITEM_TYPES.image.property ||
            item.type === CM_ITEM_TYPES.image.attachment
          }
        />
      )}
    </ResizableDraggable>
  );
};

DesignItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    textLabel: PropTypes.string,
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    backgroundW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundH: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textAlign: PropTypes.string,
    style: PropTypes.object,
    page: PropTypes.string,
  }).isRequired,
  preview: PropTypes.bool,
  scale: PropTypes.number,
};

export default React.memo(DesignItem, areEqual);
