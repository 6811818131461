import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Typography } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { conversion } from 'helpers';

export const ActionMap = ({ input }) => {
  const [modules, setModules] = useState(input.value);

  const toggleEnabled = (index, action) => () => {
    let modulesCopy = [...modules];
    const actionIndex = modulesCopy[index]['actions'].findIndex(
      (f) => f.id === action.id,
    );
    let actionCopy = [...modulesCopy[index]['actions']];
    let actionToUpdate = {
      ...actionCopy[actionIndex],
      enabled: !actionCopy[actionIndex].enabled,
    };
    actionCopy[actionIndex] = actionToUpdate;
    modulesCopy[index] = { ...modulesCopy[index], actions: actionCopy };
    setModules(modulesCopy);
  };

  useEffect(() => {
    if (input.value) {
      setModules(input.value);
    }
  }, [input.value]);

  useEffect(() => {
    if (modules) {
      input.onChange(modules);
    }
  }, [modules]);

  return (
    <>
      {modules.map((module, index) => {
        return (
          <React.Fragment key={module.id}>
            <Typography variant={'subtitle1'}>
              {conversion.titleCase(module.name)}
            </Typography>
            <Grid container>
              {module.actions.map((action) => {
                return (
                  <Grid
                    item
                    xs={4}
                    key={`module${module.id}-action${action.id}`}
                  >
                    {action.name}
                    <span onClick={toggleEnabled(index, action)}>
                      <FontAwesomeIconComp
                        color={action.enabled ? 'green' : 'grey'}
                        fontSize={12}
                        icon={faCheckCircle}
                      />
                    </span>
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        );
      })}
    </>
  );
};

ActionMap.propTypes = {
  input: PropTypes.object,
};
