import React from 'react';
import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { ItemCountProvider } from 'contexts';
import { MarketingList } from '../MarketingList';

export const MarketingCard = (props) => {
  return (
    <ItemCountProvider>
      <AbeCard {...props} title={'Marketing'}>
        <Divider />
        <MarketingList />
      </AbeCard>
    </ItemCountProvider>
  );
};

MarketingCard.defaultProps = {};
