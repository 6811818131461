import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

export const FormCondition = ({
  when,
  operator,
  is,
  children,
  show,
  renderWhenHidden,
  showAllFields,
}) => {
  const getValue = (value) => {
    let realValue = value && value.value ? value.value : value;
    let shouldShow = false;
    if (show) {
      shouldShow = true;
    }
    if (operator === 'equals') {
      shouldShow = realValue === is;
    }
    if (operator === 'doesNotEqual') {
      shouldShow = realValue !== is;
    } else if (operator === 'contains') {
      shouldShow = realValue.includes(is);
    } else if (operator === 'oneOf') {
      shouldShow = is.includes(realValue);
    } else if (operator === 'notOneOf') {
      shouldShow = !is.includes(realValue);
    } else if (operator === 'greaterThan') {
      shouldShow = realValue > is;
    } else if (operator === 'isNotEmpty') {
      shouldShow = realValue && realValue !== '';
    }

    if (showAllFields) {
      shouldShow = true;
    }
    if (renderWhenHidden) {
      return (
        <div
          className={'formCondition'}
          style={{ display: shouldShow ? '' : 'none' }}
        >
          {children}
        </div>
      );
    } else {
      return shouldShow && children;
    }
  };

  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => getValue(value)}
    </Field>
  );
};

FormCondition.propTypes = {
  when: PropTypes.string,
  operator: PropTypes.string,
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
  children: PropTypes.node,
  show: PropTypes.bool,
  renderWhenHidden: PropTypes.bool,
  showAllFields: PropTypes.bool,
};
