import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { CollapsableMenuGroup } from 'components/Common';
import ColorPicker from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ColorPicker';
import { selectCurrentItem, defaultColorSelectorCM } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';
import MainframeThemeColorOptionsMenuItem from './MainframeThemeColorOptionsMenuItem';

const colorOptions = [
  '#231F20',
  '#C9222E',
  '#48484A',
  '#B1B3B6',
  '#E6E7E8',
  '#fffffe',
];

const ColorPickerMenuItem = ({
  styleProperty,
  icon,
  label,
  showThemeColor,
  showMainframeColor,
  showCustomColor,
  showExtraWhite,
  showGradient,
  showMainframeThemeColorOptions,
}) => {
  const dispatch = useDispatch();

  const defaultColor = useSelector(defaultColorSelectorCM);
  const item = useSelector(selectCurrentItem);

  const handleColorChange = useCallback(
    (color) => {
      const colorHex = color.hex ? color.hex : color;
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            style: {
              ...item.style,
              [styleProperty]: colorHex,
            },
          },
        }),
      );
    },
    [dispatch, item, styleProperty],
  );

  const handleBorderWidthChange = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            style: {
              ...item.style,
              borderWidth: value,
            },
          },
        }),
      );
    },
    [dispatch, item],
  );

  return (
    <CollapsableMenuGroup
      hideChevron
      label={label}
      icon={<FontAwesomeIcon fontSize={14} icon={icon} />}
    >
      <Box padding={1}>
        {styleProperty === 'borderColor' && (
          <Box marginBottom={3}>
            <TextField
              variant="outlined"
              value={item.style.borderWidth || ''}
              onChange={handleBorderWidthChange}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">px</InputAdornment>
                ),
                style: { width: '112px' },
              }}
            />
          </Box>
        )}
        <Box marginBottom={2}>
          <ColorPicker
            showThemeColor={showThemeColor}
            showMainframeColor={showMainframeColor}
            showCustomColor={showCustomColor}
            showExtraWhite={showExtraWhite}
            showTransparent={styleProperty === 'backgroundColor'}
            showGradient={showGradient}
            colors={colorOptions}
            color={item.style[styleProperty] || '#ffffff'}
            defaultColor={defaultColor}
            onChange={handleColorChange}
          />
          {showMainframeThemeColorOptions &&
            item.style[styleProperty] === 'customColor' && (
              <MainframeThemeColorOptionsMenuItem />
            )}
        </Box>
      </Box>
    </CollapsableMenuGroup>
  );
};

export default React.memo(ColorPickerMenuItem);

ColorPickerMenuItem.propTypes = {
  styleProperty: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string,
  showThemeColor: PropTypes.bool,
  showMainframeColor: PropTypes.bool,
  showCustomColor: PropTypes.bool,
  showExtraWhite: PropTypes.bool,
  showTransparent: PropTypes.bool,
  showGradient: PropTypes.bool,
  showMainframeThemeColorOptions: PropTypes.bool,
};
