import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { RolesField } from '../RolesField';

export const CustomerRolesField = ({ customerType, createType, ...props }) => {
  let representing = useField('representing');
  let representingValue = representing.input.value
    ? representing.input.value
    : '';

  const getActualRole = () => {
    if (
      representingValue.includes('Buyer') ||
      representingValue.includes('Seller')
    ) {
      if (customerType === 'customersType1') {
        return 'Seller';
      } else {
        return 'Buyer';
      }
    } else if (
      representingValue.includes('Landlord') ||
      representingValue.includes('Tenant')
    ) {
      if (customerType === 'customersType1') {
        return 'Landlord';
      } else {
        return 'Tenant';
      }
    } else if (representingValue.includes('Referral')) {
      if (customerType === 'customersType1') {
        return 'Referral';
      }
    }
  };

  const shouldShow = () => {
    if (customerType === 'customersType1') {
      if (
        representingValue.includes('Seller') ||
        representingValue.includes('Landlord') ||
        representingValue.includes('Referral')
      ) {
        return true;
      } else {
        return false;
      }
    } else if (customerType === 'customersType2') {
      if (
        representingValue.includes('Buyer') ||
        representingValue.includes('Tenant')
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {shouldShow() && (
        <RolesField
          {...props}
          createType={createType}
          contactType={'Customer'}
          role={getActualRole()}
        />
      )}
    </>
  );
};

CustomerRolesField.propTypes = {
  customerType: PropTypes.string,
  createType: PropTypes.string,
};
