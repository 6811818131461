import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Divider } from '@mui/material';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import { TablePagination } from 'components/Styled';

export const ImageGalleryPreview = ({
  files = [],
  buttons,
  page,
  onPageChange,
}) => {
  return (
    <Dialog open={files.length > 0} onClose={() => {}}>
      {buttons}
      <AbeFileImage file={files[page - 1]} />
      <Divider />
      <TablePagination
        count={files.length}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        sx={{
          justifyContent: 'center',
        }}
        color="primary"
        onChange={(event, newPage) => {
          onPageChange(newPage);
        }}
      />
    </Dialog>
  );
};

ImageGalleryPreview.propTypes = {
  files: PropTypes.array,
  buttons: PropTypes.node,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
