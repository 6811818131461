import React from 'react';
import { useSelector } from 'react-redux';
import { activeFieldsSelector } from 'redux/selectors';
import {
  GroupToolbar,
  SignatureToolbar,
  StrikethroughToolbar,
  TextToolbar,
} from './components';

export const ActiveFieldMenu = () => {
  const activeFields = useSelector(activeFieldsSelector);
  const showTextToolbar =
    activeFields[0].type === 'text' ||
    activeFields[0].type === 'datePicker' ||
    activeFields[0].type === 'checkbox';
  const showSignatureToolbar =
    activeFields[0].type === 'signatureField' ||
    activeFields[0].type === 'signatureInitialField' ||
    activeFields[0].type === 'signDate' ||
    activeFields[0].type === 'printName';
  const showStrikethroughToolbar = activeFields[0].type === 'strikethrough';

  return (
    <>
      {showSignatureToolbar && <SignatureToolbar />}
      {showTextToolbar && <TextToolbar />}
      {showStrikethroughToolbar && <StrikethroughToolbar />}
      {activeFields.length > 1 && <GroupToolbar />}
    </>
  );
};
