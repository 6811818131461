import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { NumberInput } from 'components/Fields';

export function TeamMemberField({ order }) {
  return (
    <>
      <Divider sx={{ m: 0.5 }} orientation="vertical" flexItem />
      <Box
        sx={{
          width: '80px',
          paddingRight: '20px',
          marginRight: '20px',
        }}
      >
        <Field
          name={`team.${order - 1}.percent`}
          iconEnd={<>%</>}
          numberType={'decimal'}
          textAlign={'right'}
          variant={'standard'}
          disableUnderline
          min={0}
          max={100}
          component={NumberInput}
          fullWidth={false}
        />
      </Box>
    </>
  );
}

TeamMemberField.propTypes = { order: PropTypes.number };
