import React from 'react';
import PropTypes from 'prop-types';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const SmallIconButton = styled(IconButton)(() => ({
  fontSize: '24px',
  padding: '4px 8px',
}));

const PopoverListItem = styled(ListItem)(() => ({
  padding: '0 16px',
  '& .MuiListItemIcon-root': {
    minWidth: '25px',
  },
}));

const ToolbarComponent = ({
  modularType,
  agentView,
  locked,
  handleClose,
  handleLockToggle,
  handleToolbarClick,
  handleAddItemGroup,
  handleDeleteItemGroup,
  itemTemplateId,
  anchorEl,
}) => {
  const open = Boolean(anchorEl);

  if (agentView) {
    return null;
  }

  return (
    <>
      <SmallIconButton onClick={handleToolbarClick}>
        <FontAwesomeIcon icon={faEllipsisH} />
      </SmallIconButton>
      <Popover
        style={{ zIndex: 9999 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {modularType === 'Body' && (
            <PopoverListItem button onClick={handleAddItemGroup}>
              <ListItemText primary="Add" />
            </PopoverListItem>
          )}
          <PopoverListItem
            button
            onClick={() => handleLockToggle(itemTemplateId)}
          >
            <ListItemText primary={locked ? 'Unlock' : 'Lock'} />
          </PopoverListItem>
          <PopoverListItem
            button
            onClick={() => handleDeleteItemGroup(itemTemplateId)}
          >
            <ListItemText primary="Remove" />
          </PopoverListItem>
        </List>
      </Popover>
    </>
  );
};

ToolbarComponent.propTypes = {
  modularType: PropTypes.string,
  agentView: PropTypes.bool,
  locked: PropTypes.bool,
  handleClose: PropTypes.func,
  handleLockToggle: PropTypes.func,
  handleToolbarClick: PropTypes.func,
  handleAddItemGroup: PropTypes.func,
  handleDeleteItemGroup: PropTypes.func,
  itemTemplateId: PropTypes.string,
  anchorEl: PropTypes.object,
};

export default ToolbarComponent;
