import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { AbeForm } from 'components/Forms';
import { useFileUpload } from 'hooks/form/useFileUpload';
import {
  useSettingFileUploadMutation,
  useNoteFileDeleteMutation,
} from 'redux/rtk-query';
import {
  CappingPanel,
  HeadshotPanel,
  OverviewPanel,
  ProfilePanel,
} from './components';

export const SettingForm = (props) => {
  const { handleFileUpload } = useFileUpload(
    useSettingFileUploadMutation,
    useNoteFileDeleteMutation,
  );

  const onSubmit = async (values) => {
    let response = await props.handleSubmitFunc(values);

    if (!response.error) {
      if (values.headshotFile && values.headshotFile[0] instanceof File) {
        handleFileUpload(response.data.id, values.headshotFile, {
          uploadType: 'headshot',
        });
      }
      if (values.iconFile && values.iconFile[0] instanceof File) {
        handleFileUpload(response.data.id, values.iconFile, {
          uploadType: 'icon',
        });
      }
    }
    return response;
  };

  const renderFields = () => (
    <Grid container spacing={2} style={{ padding: '0 16px' }}>
      <Grid item xs={12}>
        {(!props.panelEdit || props.panelEdit === 'Overview') && (
          <OverviewPanel />
        )}
        {props.panelEdit === 'Headshot' && <HeadshotPanel />}
        {props.panelEdit === 'Profile' && <ProfilePanel />}
        {props.panelEdit === 'Compensation' && <CappingPanel />}
      </Grid>
      <Grid item xs={12} md={4}></Grid>
      <Grid item xs={12} md={4}></Grid>
    </Grid>
  );

  return (
    <AbeForm {...props} formId={'setting-form'} handleSubmitFunc={onSubmit}>
      {renderFields}
    </AbeForm>
  );
};

SettingForm.propTypes = {
  afterSave: PropTypes.func,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  nameButton: PropTypes.string,
  isLoading: PropTypes.bool,
  panelEdit: PropTypes.string,
};
