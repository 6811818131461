import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const CornerButtonWrapper = styled('div')({
  right: 0,
  top: 0,
  position: 'absolute',
  margin: '5px',
});

export const RowButtonWrapper = styled('div')({
  right: 0,
  top: 0,
  position: 'absolute',
  margin: '10px 15px',
  display: 'none',
});

export const ButtonSection = styled(Box)(() => ({
  display: 'flex',
  gap: 15,
}));

export const NewButtonBox = styled(Box)({
  right: 10,
});
