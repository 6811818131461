import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/material';
import abeLottie from 'images/abe-lottie.json';
import { LogoBox } from 'pages/LoginPage/components/SignInWithGoogle/styled';

const LoginLoader = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slight transparent background
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          animation: 'fadeIn 1s ease-out',
        }}
      >
        <LogoBox>
          <Player
            autoplay
            loop
            src={abeLottie}
            style={{ height: 150 }}
            speed={3}
          />
        </LogoBox>
      </Box>
    </Box>
  );
};

export default LoginLoader;
