import { createSlice } from '@reduxjs/toolkit';
import { AllListsName } from 'consts';

const initialState = {
  selectedList: AllListsName,
  currentContactList: [],
  selectedContacts: [],
  editingList: false,
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setEditingList: (state, action) => {
      state.editingList = action.payload;
    },
    setSelectedList: (state, action) => {
      state.selectedList = action.payload;
      state.selectedContacts = [];
    },
    setSelectedContacts: (state, action) => {
      state.selectedContacts = action.payload;
    },
    setCurrentListContacts: (state, action) => {
      state.currentContactList = action.payload;
    },
  },
});

export const {
  setSelectedList,
  setSelectedContacts,
  setCurrentListContacts,
  setEditingList,
} = contactsSlice.actions;
export const contactsSliceReducer = contactsSlice.reducer;
