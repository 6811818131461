import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel } from '@mui/material';
import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline';
import { Box } from '@mui/system';
import { LabeledOutlinedWrapper } from './styled';

export const LabeledOutline = ({
  id,
  label,
  children,
  toolbar,
  large,
  error,
  backgroundColor = '#f6f7f9',
  onClick,
}) => {
  return (
    <LabeledOutlinedWrapper fullWidth>
      <InputLabel
        htmlFor={id}
        variant="outlined"
        sx={{
          position: 'absolute',
          //white box shadow
          // boxShadow: '0 0 0 3px 2px white',
          left: large ? -2 : 0,
          top: large ? -3 : 0,
          fontSize: large ? '1.8rem' : '',
          color: error ? '#d32f2f' : '#989898',
          '&:hover': {
            color: '#989898',
          },
        }}
        shrink
      >
        {label}
      </InputLabel>
      {toolbar && (
        <div
          style={{
            position: 'absolute',
            right: -10,
            zIndex: 1,
            top: -10,
            // background: "white"
          }}
        >
          {toolbar}
        </div>
      )}
      <div
        className={'labeled-outline-container'}
        style={{
          position: 'relative',
          backgroundColor: backgroundColor,
          borderRadius: '16px',
          minHeight: 30,
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            p: 0,
          }}
        >
          {children}
          <NotchedOutline
            style={{
              borderRadius: 16,
              fontSize: 18,
              borderWidth: 0,
              borderColor: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.10)',
            }}
            label={label}
            notched={Boolean(true)}
          />
        </Box>
      </div>
    </LabeledOutlinedWrapper>
  );
};

LabeledOutline.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    notchedOutline: PropTypes.string,
    contentWrapper: PropTypes.string,
    inputLabel: PropTypes.string,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  toolbar: PropTypes.node,
  className: PropTypes.string,
  large: PropTypes.bool,
  error: PropTypes.bool,
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
};
