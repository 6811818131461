import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0ProviderWithNavigate } from 'contexts/Auth0ProviderWithNavigate';
import { persistor, store } from 'store';
import 'index.scss';
import { App } from './App';

ReactDOM.createRoot(document.querySelector('#root')).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <App />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
