import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Assuming AbeForm is in the same directory
import { Stepper, Step, StepLabel, Button, Box } from '@mui/material';
import { AbeForm } from 'components/Forms';

export const Wizard = ({
  steps,
  initialValues = {},
  setInitialValues,
  onFinalSubmit,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = async (values) => {
    setInitialValues((prevValues) => ({ ...prevValues, ...values }));

    if (steps[activeStep].onNext) {
      const canProceed = await steps[activeStep].onNext(values);
      if (canProceed?.error) {
        return {
          error: {
            message: canProceed?.error,
          },
        };
      }
      if (isLastStep) {
        onFinalSubmit(canProceed);
        return;
      }
    }
    if (isLastStep) {
      onFinalSubmit(values);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (stepIndex, form, values, showAllFields) => {
    const { render } = steps[stepIndex];
    return render(form, values, showAllFields); // Passing form and values to child components
  };

  if (!steps.length) return null;

  return (
    <Box p={2}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              sx={{
                '& .MuiStepIcon-text': { fill: '#ffffff' },
              }}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <AbeForm
        formId={`wizard-step-${activeStep}`}
        initialValues={initialValues}
        handleSubmitFunc={handleNext}
        showAllFieldsButton={false}
        mutators={{
          clearSubmitError: (args, state) => {
            state.formState.submitError = undefined;
          },
        }}
      >
        {(form, values, showAllFields) => (
          <Box>
            {renderStepContent(activeStep, form, values, showAllFields)}

            {steps[activeStep].onNext && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2,
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>

                <Button type="submit" variant="contained">
                  {isLastStep ? 'Submit' : 'Next'}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </AbeForm>
    </Box>
  );
};

Wizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
      onNext: PropTypes.func,
    }),
  ).isRequired,
  initialValues: PropTypes.object,
  setInitialValues: PropTypes.func,
  onFinalSubmit: PropTypes.func,
};
