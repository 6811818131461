// AdjustImageMenuItem.js

import React, { useState, useEffect, useRef } from 'react';
import {
  faCrop,
  faArrowUpRight,
  faArrowUp,
  faArrowUpLeft,
  faArrowLeft,
  faArrowRight,
  faArrowDownLeft,
  faArrowDownRight,
  faArrowDown,
  faCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Slider, Typography, Grid, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { CollapsableMenuGroup } from 'components/Common';
import { useItemLabelValue } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useItemLabelValue';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const AdjustImageMenuItem = () => {
  const dispatch = useDispatch();
  const activeItem = useSelector(selectCurrentItem);

  const labelValue = useItemLabelValue(activeItem);

  // Refs to hold the original (initial) dimensions
  const originalBackgroundWRef = useRef(0);
  const originalBackgroundHRef = useRef(0);

  // State for zoom levels
  const [zoomLevel, setZoomLevel] = useState(1); // Current zoom level
  const [minZoomLevel, setMinZoomLevel] = useState(1); // Minimum zoom level
  const [maxZoomLevel, setMaxZoomLevel] = useState(3); // Maximum zoom level

  /**
   * useEffect #1: Reset original dimensions when a new image is loaded.
   * This ensures that the initial dimensions are recalculated for the new image.
   */
  useEffect(() => {
    if (labelValue?.fileId) {
      // Reset the original dimensions
      originalBackgroundWRef.current = 0;
      originalBackgroundHRef.current = 0;

      // Optionally, reset zoom levels if needed
      setZoomLevel(1);
      setMinZoomLevel(1);
      setMaxZoomLevel(3);
    }
  }, [labelValue?.fileId]);

  /**
   * useEffect #2: Initialize original dimensions and calculate zoom levels.
   * Runs only when original dimensions are not set (i.e., refs are 0).
   */
  useEffect(() => {
    if (
      activeItem &&
      activeItem.backgroundW &&
      activeItem.backgroundH &&
      originalBackgroundWRef.current === 0 &&
      originalBackgroundHRef.current === 0
    ) {
      // Store the original dimensions
      const initialBackgroundW = parseFloat(activeItem.backgroundW);
      const initialBackgroundH = parseFloat(activeItem.backgroundH);
      originalBackgroundWRef.current = initialBackgroundW;
      originalBackgroundHRef.current = initialBackgroundH;

      // Reset zoom level to 100%
      setZoomLevel(1);

      // Calculate the minimum and maximum zoom levels based on container and image dimensions
      const containerW = parseFloat(activeItem.w);
      const containerH = parseFloat(activeItem.h);

      // Avoid division by zero
      if (initialBackgroundW > 0 && initialBackgroundH > 0) {
        const scaleWidth = containerW / initialBackgroundW;
        const scaleHeight = containerH / initialBackgroundH;

        // Minimum zoom level is the maximum of these scales (to fill either width or height)
        const minZoom = Math.max(scaleWidth, scaleHeight);
        const maxZoom = minZoom * 3; // Maximum zoom is 5 times the minimum

        setMinZoomLevel(minZoom);
        setMaxZoomLevel(maxZoom);
      } else {
        // If initial dimensions are zero or invalid, set default zoom levels
        setMinZoomLevel(1);
        setMaxZoomLevel(3);
      }
    }
  }, [
    activeItem,
    activeItem?.backgroundW,
    activeItem?.backgroundH,
    originalBackgroundWRef,
    originalBackgroundHRef,
  ]);

  /**
   * useEffect #3: Recalculate zoom levels when background dimensions change.
   * This ensures that zoom limits are updated based on any changes to backgroundW and backgroundH.
   * Avoids infinite loops by not modifying backgroundW or backgroundH within this effect.
   */
  useEffect(() => {
    if (
      activeItem &&
      activeItem.backgroundW &&
      activeItem.backgroundH &&
      originalBackgroundWRef.current > 0 &&
      originalBackgroundHRef.current > 0
    ) {
      const containerW = parseFloat(activeItem.w);
      const containerH = parseFloat(activeItem.h);

      // Recalculate zoom levels based on original dimensions
      const scaleWidth = containerW / originalBackgroundWRef.current;
      const scaleHeight = containerH / originalBackgroundHRef.current;

      const minZoom = Math.max(scaleWidth, scaleHeight);
      const maxZoom = minZoom * 3;

      setMinZoomLevel(minZoom);
      setMaxZoomLevel(maxZoom);
    }
  }, [
    activeItem?.backgroundW,
    activeItem?.backgroundH,
    activeItem?.w,
    activeItem?.h,
    originalBackgroundWRef,
    originalBackgroundHRef,
  ]);

  /**
   * Handler for zoom level changes.
   * Updates the zoom level state and dispatches an action to update the item's dimensions.
   */
  const handleZoomChange = (event, newValue) => {
    const newZoomLevel = newValue; // Zoom level is the scale factor
    setZoomLevel(newZoomLevel);

    // Calculate new dimensions while maintaining aspect ratio
    const newBackgroundW = originalBackgroundWRef.current * newZoomLevel;
    const newBackgroundH = originalBackgroundHRef.current * newZoomLevel;

    // Dispatch action to update the item's dimensions
    dispatch(
      updateItemCM({
        itemId: activeItem.id,
        properties: {
          backgroundW: newBackgroundW,
          backgroundH: newBackgroundH,
        },
      }),
    );
  };

  /**
   * Conditional rendering: Ensure that the component renders only when necessary data is available.
   */
  if (!activeItem || minZoomLevel === null || maxZoomLevel === null) {
    return null;
  }

  /**
   * Parsing dimensions to numbers with fallbacks.
   */
  const parsedBackgroundW = parseFloat(activeItem.backgroundW) || 0;
  const parsedBackgroundH = parseFloat(activeItem.backgroundH) || 0;
  const parsedContainerWidth = parseFloat(activeItem.w) || 0;
  const parsedContainerHeight = parseFloat(activeItem.h) || 0;

  /**
   * Calculating differences between container and background dimensions.
   * These diffs are used to determine the possible range for image positioning.
   */
  const diffX = parsedContainerWidth - parsedBackgroundW;
  const diffY = parsedContainerHeight - parsedBackgroundH;

  // Calculate minX and maxX based on horizontal differences
  let minX, maxX;
  if (diffX <= 0) {
    // Image is wider than container
    minX = diffX;
    maxX = 0;
  } else {
    // Image is narrower than container
    minX = 0;
    maxX = diffX;
  }

  // Calculate minY and maxY based on vertical differences
  let minY, maxY;
  if (diffY <= 0) {
    // Image is taller than container
    minY = diffY;
    maxY = 0;
  } else {
    // Image is shorter than container
    minY = 0;
    maxY = diffY;
  }

  // Calculate center positions for both axes
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  /**
   * Handler for changing the image position based on alignment.
   * Updates the positionX and positionY properties of the active item.
   */
  const handlePositionChange = (hAlign, vAlign) => {
    let newPositionX, newPositionY;

    // Determine newPositionX based on horizontal alignment
    if (hAlign === 'left') {
      newPositionX = maxX;
    } else if (hAlign === 'center') {
      newPositionX = centerX;
    } else if (hAlign === 'right') {
      newPositionX = minX;
    }

    // Determine newPositionY based on vertical alignment
    if (vAlign === 'top') {
      newPositionY = maxY;
    } else if (vAlign === 'center') {
      newPositionY = centerY;
    } else if (vAlign === 'bottom') {
      newPositionY = minY;
    }

    // Dispatch the position update
    dispatch(
      updateItemCM({
        itemId: activeItem.id,
        properties: {
          positionX: newPositionX,
          positionY: newPositionY,
        },
      }),
    );
  };

  /**
   * Array of position options for user interaction.
   * Each option includes a label, horizontal and vertical alignment, and an associated icon.
   */
  const positionOptions = [
    { label: 'Top Left', hAlign: 'left', vAlign: 'top', icon: faArrowUpLeft },
    { label: 'Top Center', hAlign: 'center', vAlign: 'top', icon: faArrowUp },
    {
      label: 'Top Right',
      hAlign: 'right',
      vAlign: 'top',
      icon: faArrowUpRight,
    },
    {
      label: 'Middle Left',
      hAlign: 'left',
      vAlign: 'center',
      icon: faArrowLeft,
    },
    { label: 'Center', hAlign: 'center', vAlign: 'center', icon: faCircle },
    {
      label: 'Middle Right',
      hAlign: 'right',
      vAlign: 'center',
      icon: faArrowRight,
    },
    {
      label: 'Bottom Left',
      hAlign: 'left',
      vAlign: 'bottom',
      icon: faArrowDownLeft,
    },
    {
      label: 'Bottom Center',
      hAlign: 'center',
      vAlign: 'bottom',
      icon: faArrowDown,
    },
    {
      label: 'Bottom Right',
      hAlign: 'right',
      vAlign: 'bottom',
      icon: faArrowDownRight,
    },
  ];

  return (
    <CollapsableMenuGroup
      icon={<FontAwesomeIcon icon={faCrop} />}
      label={'Adjust Image'}
    >
      <Box p={2}>
        {/* Zoom Level Slider */}
        <Typography gutterBottom>Size</Typography>
        <Box px={1}>
          <Slider
            value={zoomLevel}
            step={0.01}
            min={minZoomLevel}
            max={maxZoomLevel}
            onChange={handleZoomChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${Math.round(value * 100)}%`}
          />
        </Box>

        {/* Position Controls */}
        <Typography gutterBottom>Position</Typography>
        <Grid container>
          {positionOptions.map((pos, index) => (
            <Grid item xs={4} paddingLeft={0} key={index} textAlign={'center'}>
              <Tooltip title={pos.label} placement="top">
                <Button
                  variant="outlined"
                  sx={{ p: 1, m: 1 }}
                  onClick={() => handlePositionChange(pos.hAlign, pos.vAlign)}
                  size="small"
                  aria-label={pos.label}
                >
                  <FontAwesomeIcon icon={pos.icon} fontSize={16} />
                </Button>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    </CollapsableMenuGroup>
  );
};

export default AdjustImageMenuItem;
