import { useEffect, useState } from 'react';
import {
  useGetContactTasksQuery,
  useGetMyTasksQuery,
  useGetTransactionTasksQuery,
} from 'redux/rtk-query';

export function useTaskData({
  relatedModelType,
  uuid,
  type,
  searchValue,
  showCompleted,
  page,
  rowsPerPage,
}) {
  const [taskData, setTaskData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // Setting up query parameters based on the component's props
  const commonParams = {
    uuid,
    taskType: 'Task',
    type,
    status: showCompleted
      ? ['New', 'In Progress', 'Completed']
      : ['New', 'In Progress'],
    search: searchValue,
    page,
    limit: rowsPerPage,
  };

  // Conditional fetching based on related model type
  const contactTasksQuery = useGetContactTasksQuery(
    { ...commonParams },
    { skip: !uuid || relatedModelType !== 'contact' },
  );
  const transactionTasksQuery = useGetTransactionTasksQuery(
    { ...commonParams },
    { skip: !uuid || relatedModelType !== 'transaction' },
  );
  const myTasksQuery = useGetMyTasksQuery(
    { ...commonParams },
    {
      skip:
        relatedModelType !== 'contact' && relatedModelType !== 'transaction',
    },
  );

  // Consolidating data from the query based on the related model type
  useEffect(() => {
    let activeQuery;

    switch (relatedModelType) {
      case 'contact':
        activeQuery = contactTasksQuery;
        break;
      case 'transaction':
        activeQuery = transactionTasksQuery;
        break;
      default:
        activeQuery = myTasksQuery;
    }

    if (activeQuery.isSuccess && activeQuery.data) {
      setTaskData(activeQuery.data.data.items);
      setTotalPages(activeQuery.data.data.meta.totalPages);
      setTotalCount(activeQuery.data.data.meta.total);
    }
  }, [
    contactTasksQuery,
    transactionTasksQuery,
    myTasksQuery,
    relatedModelType,
  ]);

  return {
    taskData,
    totalPages,
    totalCount,
  };
}
