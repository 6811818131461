import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const HoverReveal = ({ children, hoverContent, sx = {}, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <Box
        {...props}
        className="hover-reveal"
        sx={{
          position: 'relative',
          display: 'flex',
          ...sx,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Main Content */}
        {children}

        {/* Hover Content */}
        <div
          className="hover-content"
          style={{
            opacity: isHovered ? 1 : 0,
            transition: 'opacity 0.3s',
            pointerEvents: isHovered ? 'auto' : 'none',
            position: 'absolute',
            top: 16,
            right: 8,
          }}
        >
          {hoverContent}
        </div>
      </Box>
    </div>
  );
};

HoverReveal.propTypes = {
  children: PropTypes.node.isRequired,
  hoverContent: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default HoverReveal;
