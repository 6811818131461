import React from 'react';
import { Table } from 'components/Common';
import { useTableData } from 'hooks';
import { useGetReportsListQuery } from 'redux/rtk-query';
import { Cells, MenuItems } from './config';

export const ReportsTable = () => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    search,
    totalCount,
    loading,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    rows,
  } = useTableData(useGetReportsListQuery, Cells, 'updatedAt', 'desc', 10);

  return (
    <>
      <Table
        handleSearch={setSearch}
        search={search}
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={Cells}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
