import { getRoleNameFromWords } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/roles';

export const currentDocumentSelector = (state) =>
  state.documentEditorReducer.currentDocument;
export const isTemplateViewSelector = (state) =>
  state.documentEditorReducer.templateView;
export const htmlTemplateSelector = (state) =>
  state.documentEditorReducer.currentDocument.htmlTemplate;
export const signedStatusSelector = (state) =>
  state.documentEditorReducer.signedStatus;
export const autofillDataSelector = (state) =>
  state.documentEditorReducer.autofillData;
export const activeFieldIdsSelector = (state) =>
  state.documentEditorReducer.activeFieldIds;
export const activeFieldsSelector = (state) => {
  const fields = state.documentEditorReducer.currentDocument.htmlTemplate;
  const activeFieldIds = activeFieldIdsSelector(state);
  return fields.filter((field) => activeFieldIds.includes(field.id));
};
export const isActiveFieldSelector = (fieldId) => (state) => {
  const activeFieldIds = activeFieldIdsSelector(state);
  return activeFieldIds.includes(fieldId);
};
export const isFieldVisibleSelector = (fieldId) => (state) => {
  // TODO: Implement isVisible for Document Templates

  const roles = roleSelector(state);
  const field = htmlTemplateSelector(state).find(
    (field) => field.id === fieldId,
  );
  if (!field.role) return true;
  return roles.some((role) => getRoleNameFromWords(role) === field.role);
};
export const scaleSelector = (state) => state.documentEditorReducer.scale;
export const roleSelector = (state) =>
  state.documentEditorReducer.currentDocument.roles;
export const fieldTemplateSelector = (state) =>
  state.documentEditorReducer.fieldsTemplates;
export const placingItemSelector = (state) =>
  state.documentEditorReducer.placingItem;
export const activeMenuSelector = (state) =>
  state.documentEditorReducer.activeMenu;
export const hasErrorSelector = (state) => {
  return state.documentEditorReducer.bannerMessages.find(
    (message) => message.severity === 'error',
  );
};
export const bannerMessagesSelector = (state) =>
  state.documentEditorReducer.bannerMessages;
export const isPreviewModeSelector = (state) => {
  return state.documentEditorReducer.bannerMessages.find(
    (message) =>
      message.severity === 'info' && message.bannerMessage === 'Preview Mode',
  );
};
export const hasUndoHistorySelector = (state) =>
  state.documentEditorReducer.undoStack.length > 0;
export const hasRedoHistorySelector = (state) =>
  state.documentEditorReducer.redoStack.length > 0;
