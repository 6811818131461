import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import LoginLoader from 'components/ProtectedRoute/LoginLoader';

/**
 * Protects a route by checking the Auth0 authentication status.
 * If the user isn't authenticated, it triggers `loginWithRedirect`.
 */
export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  // While Auth0 is still checking if the user is authenticated,
  // you can show a loading spinner or “Loading” text.
  if (isLoading) {
    return <LoginLoader />;
  }

  // If Auth0 says the user is not authenticated, redirect to login.
  // The `appState.returnTo` ensures they come back to this route after login.
  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: location.pathname + location.search,
      },
    });
    // Return null so we don't render the protected content yet.
    return null;
  }

  // If authenticated, render the protected content.
  return <>{children}</>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};
