import React from 'react';
import PropTypes from 'prop-types';
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableRow, TableCell, IconButton, Collapse, Box } from '@mui/material';
import { colors } from 'consts';
import { colorToRgba } from 'helpers/colors';
import { getNestedValue } from 'helpers/report';
import { GroupDetails } from 'pages/ReportPages/DetailsReportPage/GroupDetails';

const ReportTableRow = ({
  row,
  tableColumns,
  drillDownColumns,
  isExpanded,
  onToggleExpand,
  reportId,
}) => {
  return (
    <>
      {drillDownColumns.length > 0 ? (
        <TableRow
          sx={{
            backgroundColor: isExpanded
              ? colorToRgba(colors.LIGHT_BLUE_2, 0.1)
              : 'white',
          }}
        >
          <TableCell
            padding={'checkbox'}
            sx={{
              textAlign: 'center',
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={onToggleExpand}
            >
              {isExpanded ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretRight} />
              )}
            </IconButton>
          </TableCell>
          {tableColumns &&
            tableColumns.map((column) => (
              <TableCell key={column.id}>
                {getNestedValue(row, column)}
              </TableCell>
            ))}
        </TableRow>
      ) : (
        <TableRow>
          {tableColumns.map((column) => (
            <TableCell key={column.id}>{getNestedValue(row, column)}</TableCell>
          ))}
        </TableRow>
      )}
      {isExpanded && (
        <TableRow
          className={'group-details-row'}
          sx={{
            backgroundColor: isExpanded
              ? colorToRgba(colors.LIGHT_BLUE_2, 0.1)
              : 'white',
          }}
        >
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={tableColumns?.length + 1}
          >
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <GroupDetails
                  reportId={reportId}
                  row={row}
                  columns={drillDownColumns}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ReportTableRow;

ReportTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  tableColumns: PropTypes.array.isRequired,
  drillDownColumns: PropTypes.array,
  isExpanded: PropTypes.bool.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
};
