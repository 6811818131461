import { endpoints } from 'consts';

export const settingUrlBuilder = {
  getList: function ({ page = 1, limit = 15, search } = {}) {
    return `${endpoints.settings}?page=${page}&limit=${limit}${
      search ? `&search=${search}` : ''
    }`;
  },
  createSetting: function () {
    return endpoints.settings;
  },
  getSetting: ({ id }) => {
    return `${endpoints.settings}/${id}/details`;
  },
  getSettingMedia: function (id) {
    return `${endpoints.settings}/${id}/media`;
  },
  getSettingFile: function ({ id, uuid }) {
    return `${endpoints.settings}/${id}/media/${uuid}`;
  },
  settingFileUpload: function (uuid) {
    return `${endpoints.settings}/${uuid}/media`;
  },
};
