import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { FieldTooltip, ModalSelectField } from 'components/Fields';
import { BoxInput } from 'components/Styled';
import { conversion } from 'helpers';
import { ContactsTable } from './components/ContactsTable';

export const Contact = ({
  // onChange,
  // value,

  label,
  // placeholder = null,
  // multiple = false,
  // disabled = false,
  // hinttext = '',
  // options = {},
  contactType,
  input,
  ...other
}) => {
  let tooltip = false;
  let form = useForm();
  const field = form.getFieldState(input.name);
  if (field && field.data && field.data.tooltip) {
    tooltip = field.data.tooltip;
  }

  const getStartAdornment = () => {
    if (tooltip) {
      return <FieldTooltip tooltip={tooltip} />;
    }
    return '';
  };

  const onTableRowClick = (row) => {
    input.onChange({
      id: row.id,
      name: conversion.getName(row),
    });
  };

  return (
    <BoxInput>
      <ModalSelectField
        {...input}
        startAdornment={getStartAdornment()}
        name={input.name}
        label={label}
        title={label}
        onTableRowClick={onTableRowClick}
        ModalContent={ContactsTable}
        modalProps={{
          type: contactType,
          title: label,
        }}
        {...other}
      />
    </BoxInput>
  );
};

Contact.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  hinttext: PropTypes.string,
  options: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  contactType: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
