import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { axiosBaseQuery } from 'utils/baseQuery';
import { companyUrlBuilder } from './urlBuilder/companies';

export const companiesAPI = createApi({
  reducerPath: 'companiesAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Companies'],
  endpoints: (build) => ({
    getCompanies: build.query({
      query: (args) => ({
        url: companyUrlBuilder.getList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Companies'],
    }),
  }),
});

export const { useGetCompaniesQuery } = companiesAPI;
