import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { TextInput } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { ActionMap } from './components/ActionMap';

const GroupForm = (props) => {
  const renderFields = () => (
    <Grid container mt={1} spacing={2}>
      <Grid item xs={6}>
        <FormPanel startOpen label={'Overview'}>
          <Field name="name" label="Name" component={TextInput} />
        </FormPanel>
      </Grid>
      <Grid item xs={12}>
        <FormPanel label={'Permissions'}>
          <Field name="schema" label="Permissions" component={ActionMap} />
        </FormPanel>
      </Grid>
    </Grid>
  );

  return (
    <AbeForm handleSubmitFunc={props.handleSubmit} {...props}>
      {renderFields}
    </AbeForm>
  );
};

export default GroupForm;

GroupForm.propTypes = {
  title: PropTypes.string,
  nameButton: PropTypes.string,
  submitError: PropTypes.string,
  handleSubmit: PropTypes.func,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  afterSave: PropTypes.func,
};
