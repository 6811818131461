import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CollapsableMenuGroup } from 'components/Common';
import GradientPicker from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/GradientPicker';

const GradientPickerMenuItem = ({ styleProperty, icon, label }) => {
  return (
    <CollapsableMenuGroup
      hideChevron
      label={label}
      icon={<FontAwesomeIcon fontSize={14} icon={icon} />}
    >
      <GradientPicker styleProperty={styleProperty} />
    </CollapsableMenuGroup>
  );
};

export default React.memo(GradientPickerMenuItem);

GradientPickerMenuItem.propTypes = {
  styleProperty: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string,
};
