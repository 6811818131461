import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SideDrawer } from 'components/Common';
import { STATUSES, TYPES } from 'consts';
import { addModelAccessValues } from 'helpers';
import { useDrawer } from 'hooks';
import {
  useCreateDocumentTemplateMutation,
  useGetDocumentTemplateQuery,
} from 'redux/rtk-query';
import { ClickMarketingForm } from '../ClickMarketingForm/ClickMarketingForm';
import { DocumentTemplateForm } from '../DocumentTemplateForm/DocumentTemplateForm';

export const DocumentTemplateCreatePage = ({ params, path }) => {
  const [initialValues, setInitialValues] = useState({
    categories: [],
  });
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false, path);
  const paramName = Object.keys(params)[0];

  const parentId = params[paramName];
  const type = params.type;

  const [createDocumentTemplate] = useCreateDocumentTemplateMutation();

  //useGetDocumentTemplateQuery to get the parent template
  const { data, isSuccess } = useGetDocumentTemplateQuery(
    { id: parentId },
    { skip: !parentId },
  );

  useEffect(() => {
    const parentTemplate = data?.data?.aliasDocument
      ? data.data.aliasDocument
      : (data?.data ?? null);
    setInitialValues({
      parentTemplate: parentTemplate,
      status: STATUSES.DRAFT,
      cmBlockType: parentId === 'clickMarketing' ? type : null,
      fileType: parentId !== 'clickMarketing' ? 'E-sign' : 'Click Marketing',
      type: parentId === 'clickMarketing' ? TYPES.FILE : type,
      categories: [],
      aspectRatio: '',
    });
    openDrawer();
  }, [isSuccess]);

  const handleSubmit = async (values) => {
    return await createDocumentTemplate({
      ...values,
      parentTemplateId: values?.parentTemplate?.id,
      ...addModelAccessValues(values.modelAccess),
    });
  };

  const afterSave = () => {
    closeDrawer();
  };

  console.log(parentId);
  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      {parentId === 'clickMarketing' ? (
        <ClickMarketingForm
          title={'Create Click Marketing Template'}
          initialValues={initialValues}
          handleSubmitFunc={handleSubmit}
          afterSave={afterSave}
        />
      ) : (
        <DocumentTemplateForm
          title={'Create E-sign Template'}
          initialValues={initialValues}
          handleSubmitFunc={handleSubmit}
          afterSave={afterSave}
        />
      )}
    </SideDrawer>
  );
};

DocumentTemplateCreatePage.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
  type: PropTypes.string,
};
