import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from 'components/Fields/Autocomplete';
import { useLoadMoreOptions } from 'hooks';
import { useGetDocumentTemplatesListQuery } from 'redux/rtk-query';

const getUserOption = ({ id, name }) => ({
  value: id,
  label: name,
  key: id,
});

export const DocumentTemplateAutocomplete = (props) => {
  const documentTemplates = useLoadMoreOptions(
    useGetDocumentTemplatesListQuery,
    getUserOption,
  );
  return (
    <Autocomplete
      {...props}
      options={documentTemplates.options}
      size={'small'}
      onOpen={documentTemplates.handleOpen}
      loadMoreResults={documentTemplates.handleMore}
      isLoading={documentTemplates.isFetching}
      handleSearch={documentTemplates.handleSearch}
    />
  );
};

DocumentTemplateAutocomplete.defaultProps = {
  freeSolo: false,
  onOpen: null,
  isLoading: false,
};

DocumentTemplateAutocomplete.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  freeSolo: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  iconStart: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  handleSearch: PropTypes.func,
};
