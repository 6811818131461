import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmMenuItem, FileUploadDrop } from 'components/Common';
import { STATUSES } from 'consts';
import {
  useUpdateDocumentMutation,
  useUploadFilesMutation,
} from 'redux/rtk-query';

export const UploadCompletedPdfComponent = ({
  item,
  onBackToMenu,
  handleCloseMenu,
  triggerResize,
}) => {
  // State variables
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  // Mutation hook
  const [postFile] = useUploadFilesMutation();
  const [updateDocument] = useUpdateDocumentMutation();

  // Function to process file upload
  const processFileUpload = useCallback((files) => {
    const validFiles = [];
    const invalidFiles = [];
    setUploading(true);

    // Iterate through files to validate and categorize them
    Array.from(files).forEach((file) => {
      if (['application/pdf'].includes(file.type)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });

    // Set rejected files
    if (invalidFiles.length > 0) {
      setRejectedFiles(invalidFiles);
    }

    // Simulate file upload progress
    if (validFiles.length > 0) {
      const interval = setInterval(() => {
        setUploadProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(interval);
            setUploading(false);
            setUploadedFiles((oldFiles) => [...oldFiles, ...validFiles]);
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);
    }
  }, []);

  // Function to handle confirmation
  const handleConfirm = async () => {
    // Create FormData
    const formData = createFormData();
    // Post file
    await postFile(formData);
    // Update document
    await updateDocument({ id: item.id, status: STATUSES.COMPLETED });
    // Navigate back
    handleCloseMenu(true);
  };

  // Function to create FormData
  const createFormData = () => {
    const formData = new FormData();
    formData.append('id', item.id);
    uploadedFiles.forEach((file) => {
      formData.append('files', file);
    });
    return formData;
  };

  return (
    <ConfirmMenuItem
      title="Upload Competed PDF"
      message={
        <FileUploadDrop
          uploading={uploading}
          uploadedFiles={uploadedFiles}
          rejectedFiles={rejectedFiles}
          uploadProgress={uploadProgress}
          onFileChange={processFileUpload}
          triggerResize={triggerResize}
        />
      }
      onConfirm={handleConfirm}
      onCancel={onBackToMenu}
      confirmText={'Continue'}
      confirmColor={'warning'}
      cancelText={'Go Back'}
    />
  );
};

// PropTypes
UploadCompletedPdfComponent.propTypes = {
  item: PropTypes.object.isRequired,
  onBackToMenu: PropTypes.func.isRequired,
  triggerResize: PropTypes.func.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
};
