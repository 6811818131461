import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { FileChip, FontAwesomeIconComp } from 'components/Common';
import { UPDATE_NOTE } from 'consts';
import { conversion } from 'helpers';
import { useGetNoteMediaQuery } from 'redux/rtk-query';
import { LeftInfo, MainContent, MainSummary, RightInfo } from './styled';

export const NoteSummary = ({ note }) => {
  const [noteFiles, setNoteFiles] = useState([]);
  const [showFiles, setShowFiles] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: files, isSuccess } = useGetNoteMediaQuery(note.uuid, {
    skip: !note.filesCount || !showFiles,
  });

  useEffect(() => {
    if (isSuccess) {
      setNoteFiles(files.data);
    }
  }, [isSuccess]);

  const handleEdit = () => {
    navigate(`${pathname}/${UPDATE_NOTE(note.id)}`);
  };

  return (
    <MainContent>
      <MainSummary onClick={handleEdit}>{note.description}</MainSummary>
      <LeftInfo>
        by {conversion.getName(note.createdBy)}
        {note.filesCount > 0 && (
          <IconButton
            style={{ marginRight: 6 }}
            onClick={() => {
              setShowFiles(!showFiles);
            }}
            size={'x-small'}
          >
            <FontAwesomeIconComp icon={faPaperclip} fontSize={12} />
          </IconButton>
        )}
        {showFiles &&
          noteFiles.map((noteFile) => {
            return <FileChip key={noteFile.id} file={noteFile} />;
          })}
      </LeftInfo>
      <RightInfo>{new Date(note.createdAt).toLocaleDateString()}</RightInfo>
    </MainContent>
  );
};

NoteSummary.propTypes = {
  note: PropTypes.object,
};
