import { nanoid } from 'nanoid';
import { CM_ITEM_TYPES } from 'consts';
import {
  updatePageElements,
  moveItem,
  updateItemLabel,
  arrangeGroupLeft,
  arrangeGroupRight,
  arrangeGroupTop,
  arrangeGroupBottom,
  arrangeGroupCenter,
  arrangeGroupMiddle,
} from 'redux/slices/clickMarketing/helpers';

export const fieldsReducer = {
  // *** Active Fields ***
  setActiveFieldCM: (state, action) => {
    const payload = action.payload;
    if (Array.isArray(payload)) {
      state.activeFieldIds = payload;
    } else if (typeof payload === 'string') {
      state.activeFieldIds = [payload];
    } else {
      state.activeFieldIds = [];
    }
  },
  addItemCM: (state, action) => {
    console.log('Adding item to CM', action);
    const itemSettings = action.payload;
    const newItem = { id: nanoid(), ...itemSettings };
    state.currentDocument.htmlTemplate.items.push(newItem);
    const activePage = state.activePage;

    // Add the new item to the current active page's elements
    if (
      activePage &&
      state.currentDocument.htmlTemplate.options.pages[activePage]
    ) {
      state.currentDocument.htmlTemplate.options.pages[
        activePage
      ].elements.push(newItem.id);
    }
    updateItemLabel(state, newItem);
    // Mark the document as needing saving
    state.needsSaving = true;
  },
  // *** Item Updates ***
  deleteItemCm: (state, action) => {
    const itemId = action.payload;
    const itemToDelete = state.currentDocument.htmlTemplate.items.find(
      (item) => item.id === itemId,
    );
    if (!itemToDelete) return;

    // Capture the current state for undo
    const previousState = JSON.parse(JSON.stringify(state.currentDocument));
    state.undoStack.push(previousState);

    // Clear redo stack
    state.redoStack = [];

    // Remove the item from the items array
    state.currentDocument.htmlTemplate.items =
      state.currentDocument.htmlTemplate.items.filter(
        (item) => item.id !== itemId,
      );

    // Remove the item from the activePage elements array
    if (state.currentDocument.htmlTemplate.options.pages[state.activePage]) {
      state.currentDocument.htmlTemplate.options.pages[
        state.activePage
      ].elements = state.currentDocument.htmlTemplate.options.pages[
        state.activePage
      ].elements.filter((element) => element !== itemId);
    }

    // Remove the item from the itemGroups
    const activePageGroups =
      state.currentDocument?.itemGroups?.[state.activePage] || {};
    for (let modularType in activePageGroups) {
      for (let templateId in activePageGroups[modularType]) {
        const itemGroup = activePageGroups[modularType][templateId];
        if (itemGroup.items.includes(itemId)) {
          const newItems = itemGroup.items.filter((id) => id !== itemId);
          activePageGroups[modularType][templateId] = {
            ...itemGroup,
            items: newItems,
          };
        }
      }
    }

    // Save the updated activePageGroups back into itemGroups
    if (state.currentDocument.itemGroups) {
      state.currentDocument.itemGroups[state.activePage] = activePageGroups;
    }

    // Reset `currentItem`
    state.activeFieldIds = null;

    // Mark document as needing saving
    state.needsSaving = true;
  },
  updateItemCM: (state, action) => {
    const { itemId, properties: newProperties } = action.payload;
    const itemToUpdate = state.currentDocument.htmlTemplate.items.find(
      (item) => item.id === itemId,
    );
    if (!itemToUpdate) return;

    // Merge new properties
    const updatedItem = {
      ...itemToUpdate,
      ...newProperties,
      style: newProperties.style
        ? { ...itemToUpdate.style, ...newProperties.style }
        : itemToUpdate.style,
    };

    // Specific updates for Property Photos
    if (
      updatedItem.type === CM_ITEM_TYPES.image.property &&
      newProperties.imageNumber !== undefined &&
      state.currentDocument.cmBlockType !== CM_ITEM_TYPES.modular.Template
    ) {
      updatedItem.textLabel = `Property Photo ${newProperties.imageNumber + 1}`;
    }

    // Update the item in the items array
    state.currentDocument.htmlTemplate.items =
      state.currentDocument.htmlTemplate.items.map((item) =>
        item.id === itemId ? updatedItem : item,
      );

    // Update itemGroups
    const activePageGroups =
      state.currentDocument?.itemGroups?.[state.activePage] || {};
    for (let modularType in activePageGroups) {
      for (let templateId in activePageGroups[modularType]) {
        const itemGroup = activePageGroups[modularType][templateId];
        if (itemGroup.items.includes(itemId)) {
          const newLabelValue = { ...itemGroup.labelValue };
          const newListItems = { ...itemGroup.listItems };

          // Update labelValue
          if (updatedItem.textLabel && newProperties.value !== undefined) {
            newLabelValue[updatedItem.textLabel] = updatedItem.value;
          }

          // Update imageNumber for modular templates
          if (
            newProperties.imageNumber !== undefined &&
            state.currentDocument.cmBlockType === CM_ITEM_TYPES.modular.Template
          ) {
            const oldLabelValue = newLabelValue[updatedItem.textLabel] || {};
            newLabelValue[updatedItem.textLabel] = {
              imageNumber:
                newProperties.imageNumber ?? oldLabelValue.imageNumber,
            };
          }

          // Update fileId and croppingData
          if (
            updatedItem.textLabel &&
            (newProperties.fileId !== undefined ||
              newProperties.positionX !== undefined ||
              newProperties.positionY !== undefined ||
              newProperties.backgroundW !== undefined ||
              newProperties.backgroundH !== undefined)
          ) {
            const oldLabelValue = newLabelValue[updatedItem.textLabel] || {};
            const croppingData = oldLabelValue?.croppingData || {};

            const fileId = newProperties.fileId ?? oldLabelValue.fileId;

            // Update croppingData for the activeStyle
            const activeStyle = itemGroup.activeStyle;
            croppingData[activeStyle] = {
              positionX:
                newProperties.positionX !== undefined
                  ? newProperties.positionX
                  : croppingData[activeStyle]?.positionX,
              positionY:
                newProperties.positionY !== undefined
                  ? newProperties.positionY
                  : croppingData[activeStyle]?.positionY,
              backgroundW:
                newProperties.backgroundW !== undefined
                  ? newProperties.backgroundW
                  : croppingData[activeStyle]?.backgroundW,
              backgroundH:
                newProperties.backgroundH !== undefined
                  ? newProperties.backgroundH
                  : croppingData[activeStyle]?.backgroundH,
            };

            newLabelValue[updatedItem.textLabel] = {
              fileId: fileId,
              croppingData: croppingData,
            };
          }

          // Update list items
          if (newProperties.listItems && updatedItem.listItems !== undefined) {
            newListItems[updatedItem.textLabel] = updatedItem.listItems;
          }

          // Update itemGroup
          activePageGroups[modularType][templateId] = {
            ...itemGroup,
            labelValue: newLabelValue,
            listItems: newListItems,
          };
        }
      }
    }

    // Save the updated activePageGroups back into itemGroups
    if (state.currentDocument.itemGroups) {
      state.currentDocument.itemGroups[state.activePage] = activePageGroups;
    }

    // Mark document as needing saving
    state.needsSaving = true;
  },
  // *** Move Items ***
  moveToBackCM: (state, action) => {
    const currentItem = action.payload; // Expecting payload to have currentItem id

    if (!currentItem) return;

    state.currentDocument.htmlTemplate.items = moveItem(
      state.currentDocument.htmlTemplate.items,
      currentItem,
      true,
    );
    state.currentDocument.htmlTemplate.options.pages = updatePageElements(
      state.currentDocument.htmlTemplate.options.pages,
      state.activePage,
      currentItem,
      true,
    );

    state.needsSaving = true;
  },
  moveToFrontCM: (state, action) => {
    const currentItem = action.payload; // Expecting payload to have currentItem id

    if (!currentItem) return;

    state.currentDocument.htmlTemplate.items = moveItem(
      state.currentDocument.htmlTemplate.items,
      currentItem,
      false,
    );
    state.currentDocument.htmlTemplate.options.pages = updatePageElements(
      state.currentDocument.htmlTemplate.options.pages,
      state.activePage,
      currentItem,
      false,
    );

    state.needsSaving = true;
  },
  copyItemToPage: (state, action) => {
    console.log('Copying item to page');
    const { item, page } = action.payload;

    if (item && page) {
      // Capture the current state for undo
      const previousState = JSON.parse(JSON.stringify(state.currentDocument));
      state.undoStack.push(previousState);

      // Clear redo stack
      state.redoStack = [];

      // Clone the original item with a new ID
      const itemCopy = { ...item, id: nanoid() };

      // Add the cloned item to the items array
      state.currentDocument.htmlTemplate.items.push(itemCopy);

      // Add the new item's ID to the specified page's elements array
      if (state.currentDocument.htmlTemplate.options.pages[page]) {
        state.currentDocument.htmlTemplate.options.pages[page].elements.push(
          itemCopy.id,
        );
      } else {
        // Optionally, create the page if it doesn't exist
        state.currentDocument.htmlTemplate.options.pages[page] = {
          elements: [itemCopy.id],
        };
        console.warn(`Page "${page}" did not exist and has been created.`);
      }

      // Reset `currentItem` and `copyItemToPage`
      state.currentItem = null;
      state.copyItemToPage = {
        item: null,
        page: null,
      };

      // Mark the document as needing saving
      state.needsSaving = true;
    } else {
      // If either `item` or `page` is missing, set `copyItemToPage` to the payload
      state.copyItemToPage = {
        item: action.payload,
        page: null,
      };
    }
  },
  // In your reducer or wherever arrangeGroupCM is defined
  arrangeGroupCM: (state, action) => {
    const direction = action.payload.direction;
    const group = action.payload.group || state.activeFieldIds;

    // Get the items to arrange
    const itemsToArrange = state.currentDocument.htmlTemplate.items.filter(
      (item) => group.includes(item.id),
    );

    if (itemsToArrange.length === 0) {
      return state;
    }
    console.log('Arranging group', direction);

    switch (direction) {
      case 'left':
        return arrangeGroupLeft(state, itemsToArrange);
      case 'right':
        return arrangeGroupRight(state, itemsToArrange);
      case 'top':
        return arrangeGroupTop(state, itemsToArrange);
      case 'bottom':
        return arrangeGroupBottom(state, itemsToArrange);
      case 'center':
        return arrangeGroupCenter(state, itemsToArrange);
      case 'middle':
        return arrangeGroupMiddle(state, itemsToArrange);
      default:
        console.warn('Invalid direction specified for alignment');
        return state;
    }
  },
};
