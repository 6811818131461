import React from 'react';
import { faFontCase } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CollapsableMenuGroup } from 'components/Common';
import FontOptionsList from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/FontOptionsList';
import FontOptionMenuItem from './FontOptionMenuItem';

const FontFamilyMenuItem = () => {
  return (
    <CollapsableMenuGroup
      hideChevron
      label="Font Family"
      icon={<FontAwesomeIcon icon={faFontCase} />}
    >
      <div>
        <FontOptionMenuItem font="Theme" fontName="themeFont" />
        <FontOptionMenuItem font="Handwritten" fontName="scriptFont" />
        <CollapsableMenuGroup
          label="Custom Font"
          icon={<FontAwesomeIcon icon={faFontCase} />}
        >
          <FontOptionsList />
        </CollapsableMenuGroup>
      </div>
    </CollapsableMenuGroup>
  );
};

export default React.memo(FontFamilyMenuItem);
