import React from 'react';
import PropTypes from 'prop-types';
import { conversion } from 'helpers';

export const AgentName = ({ value }) => {
  const valueToUse = value?.owner || value;
  return valueToUse ? conversion.getName(valueToUse) : <></>;
};

AgentName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
