import React from 'react';
import {
  faPieChart,
  faChartBar,
  faChartLine,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { colors } from 'consts';
import { SalesPipelineCard } from './components/SalesPipelineCard';

export const HomePage = () => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <SalesPipelineCard />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        {/*<TaskCard type={'Task'} view={'sections'} />*/}
        {/*<MyTaskCard type={'Timeframe'} />*/}
        {/*add MUI placeholder for reports make it cute with a fontawesome icon*/}
        <Box
          sx={{
            height: '300px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 4,
            border: '4px dashed #e0e0e0',
            flexDirection: 'column',
          }}
        >
          <Typography variant={'h2'}>Cute Reports Coming Soon!</Typography>
          <Box
            display={'flex'}
            gap={3}
            p={2}
            sx={{
              opacity: 0.6,
            }}
          >
            <FontAwesomeIcon
              icon={faChartBar}
              size={'3x'}
              color={colors.PURPLE}
            />

            <FontAwesomeIcon
              icon={faChartLine}
              size={'3x'}
              color={colors.GREEN}
            />
            <FontAwesomeIcon
              icon={faPieChart}
              size={'3x'}
              color={colors.BLUE_1}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
