import React from 'react';
import { Field } from 'react-final-form';
import { Box, Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  Contact,
  CurrencyInput,
  NumberInput,
  Select,
  TextInput,
} from 'components/Fields';
import { ButtonFileInput } from 'components/Fields/ButtonFileInput';
import { InvoiceCategoriesAutocomplete } from 'components/Fields/InvoiceCategoriesAutocomplete';
import { AbeForm } from 'components/Forms';
import {
  INVOICE_PAYMENT_TYPES,
  INVOICE_TEMPLATE_STATUS,
  INVOICE_TYPES,
  PRICE_FREQUENCY,
} from 'consts';
import { getSelectOptions } from 'helpers/form';
import { useGetInvoiceTemplateMediaQuery } from 'redux/rtk-query';

export const InvoiceTemplateForm = ({ ...props }) => {
  const renderFields = () => (
    <Box p={2}>
      <FormPanel startOpen>
        <Grid item xs={12}>
          <Field label="Name" name={'name'} component={TextInput} />
          <Field
            name="type"
            label="Type"
            options={getSelectOptions(INVOICE_TYPES)}
            component={Select}
          />
          <Field
            name="paymentType"
            label="Payment Type"
            options={getSelectOptions(INVOICE_PAYMENT_TYPES)}
            component={Select}
          />
          <Field name="paidTo" label="Paid To" component={Contact} />
          <Field
            name="categories"
            label="Categories"
            multiple
            component={InvoiceCategoriesAutocomplete}
          />
          <Field
            name="status"
            label="Status"
            options={getSelectOptions(INVOICE_TEMPLATE_STATUS)}
            component={Select}
          />
          <Field
            name="priceFrequency"
            label="Price Frequency"
            options={getSelectOptions(PRICE_FREQUENCY)}
            component={Select}
          />
          <Field label="Cost" name={'cost'} component={CurrencyInput} />
          <Field
            label="Sell Price"
            name={'sellPrice'}
            component={CurrencyInput}
          />
          <Field
            label="Commission"
            name={'commission'}
            component={NumberInput}
            numberType={'decimal'}
          />
          <Field
            name="publicDescription"
            label="Public Description"
            component={TextInput}
          />
          <Field
            name="systemDescription"
            label="System Description"
            component={TextInput}
          />
          <Field name="website" label="Website" component={TextInput} />
          <Field
            name="attachments"
            component={(props) => (
              <ButtonFileInput
                {...props}
                dropZoneId={'invoice-template-form'}
                useGetMediaQuery={useGetInvoiceTemplateMediaQuery}
                identifier={'id'}
              />
            )}
            dropZoneId={'invoice-form'}
          />
        </Grid>
      </FormPanel>
    </Box>
  );

  return (
    <AbeForm formId={'invoice-template-form'} {...props}>
      {renderFields}
    </AbeForm>
  );
};
