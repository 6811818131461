import React, { useCallback } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import uuid from 'react-uuid';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, FormControl, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { LabeledOutline } from 'components/Common';
import { Testimonial } from './components/Testimonial';

export const Testimonials = () => {
  const form = useForm();
  const handleSave = useCallback(
    (id, customerName, description) => {
      const updatedTestimonials = form
        .getState()
        .values.testimonials.map((testimonial) => {
          if (testimonial.id === id) {
            return { ...testimonial, customerName, description };
          }
          return testimonial;
        });
      form.change('testimonials', updatedTestimonials);
    },
    [form],
  );

  const handleDelete = useCallback(
    (id) => {
      const updatedTestimonials = form
        .getState()
        .values.testimonials.filter((testimonial) => testimonial.id !== id);
      form.change('testimonials', updatedTestimonials);
    },
    [form],
  );

  return (
    <FieldArray name="testimonials">
      {({ fields }) => (
        <LabeledOutline label={'Testimonials'}>
          <Box px={2}>
            <FormControl fullWidth>
              <Grid container spacing={2} p={1}>
                {fields.map((name, index) => {
                  const testimonial = fields.value[index];
                  return (
                    <Grid item xs={6} key={testimonial.id} marginBottom={2}>
                      <Testimonial
                        testimonial={testimonial}
                        onSave={handleSave}
                        onDelete={handleDelete}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 2,
                }}
              >
                <Tooltip
                  title={
                    fields.length >= 4
                      ? 'Maximum of 4 testimonials allowed'
                      : ''
                  }
                  arrow
                >
                  <span>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        fields.push({
                          customerName: '',
                          description: '',
                          id: uuid(),
                        })
                      }
                      startIcon={<AddIcon />}
                      disabled={fields.length >= 4} // Disable the button if there are 4 or more testimonials
                    >
                      Add Testimonial
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </FormControl>
          </Box>
        </LabeledOutline>
      )}
    </FieldArray>
  );
};

Testimonials.propTypes = {};
