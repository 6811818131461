import React from 'react';
import PropTypes from 'prop-types';
import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import { SvgImageComponent } from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/fields/components/SvgImageComponent';
import { logoComponents } from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';
import { CM_ITEM_TYPES } from 'consts';
import useGetMediaUrl from 'hooks/useGetMediaUrl';
import { autofillDataSelectorCM } from 'redux/selectors';
import { addItemCM } from 'redux/slices';
import { addItem } from 'redux/slices/clickMarketing/helpers';

const ImagePlaceholderSelection = ({ onClose }) => {
  const dispatch = useDispatch();
  const placeholderTypes = [
    CM_ITEM_TYPES.image.svgImage,
    CM_ITEM_TYPES.image.property,
    CM_ITEM_TYPES.image.agentIcon,
    CM_ITEM_TYPES.image.logo.svgLogo,
    CM_ITEM_TYPES.image.logo.svgIcon,
    CM_ITEM_TYPES.image.agent,
    CM_ITEM_TYPES.image.agent2,
    CM_ITEM_TYPES.image.attachment,
  ];

  const autoFillData = useSelector(autofillDataSelectorCM);
  const { data: fileData } = useGetMediaUrl(
    autoFillData.agentIcon,
    'id',
    autoFillData.agentSettingId,
  );

  const getImageComponent = (type) => {
    switch (type) {
      case CM_ITEM_TYPES.image.logo.svgLogo:
      case CM_ITEM_TYPES.image.logo.svgIcon:
        return <SvgImageComponent SvgComponent={logoComponents[type]} />;
      case CM_ITEM_TYPES.image.svgImage:
        return (
          <div
            style={{
              pointerEvents: 'none',
              height: '100%',
              width: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              padding: 10,
              backgroundColor: 'grey',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: -2,
              position: 'relative',
            }}
          >
            <Box width={150}>
              <Typography gutterBottom={3} color={'#FFFFFF'} variant="body1">
                SVG Image
              </Typography>
              <Box width={105}>
                <SvgImageComponent
                  SvgComponent={logoComponents[type]}
                  fillColor={'#FFFFFF'}
                />
              </Box>
            </Box>
          </div>
        );

      case CM_ITEM_TYPES.image.attachment: {
        return (
          <div
            style={{
              pointerEvents: 'none',
              height: '100%',
              width: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              padding: 10,
              backgroundColor: 'grey',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: -2,
              position: 'relative',
            }}
          >
            <Box>
              <Typography gutterBottom={3} color={'#FFFFFF'} variant="body1">
                Library Image
              </Typography>
              <FontAwesomeIcon color={'#FFFFFF'} icon={faImage} size="5x" />
            </Box>
          </div>
        );
      }

      case CM_ITEM_TYPES.image.agentIcon:
        if (fileData?.data?.url) {
          return <SvgImageComponent SvgComponent={fileData.data.url} />;
        }
        return null;

      case CM_ITEM_TYPES.image.agent:
      case CM_ITEM_TYPES.image.agent2: {
        const agentImage =
          (type === CM_ITEM_TYPES.image.agent && autoFillData.agentHeadshot) ||
          (type === CM_ITEM_TYPES.image.agent2 && autoFillData.agent2Headshot);

        if (agentImage) {
          return (
            <AbeFileImage
              file={agentImage}
              identifierValue={autoFillData.agentSettingId}
              identifier="id"
            />
          );
        }
        return null;
      }

      case CM_ITEM_TYPES.image.property: {
        const imageSrc = autoFillData.propertyPhotos?.[0];
        return imageSrc ? (
          <img src={imageSrc} style={{ width: '100%', height: 'auto' }} />
        ) : (
          <div>No Image Available</div>
        );
      }

      default:
        return <img style={{ width: '100%', height: '100%' }} />;
    }
  };

  const handleClick = (type) => async () => {
    const itemSettings = await dispatch(addItem(type));
    dispatch(addItemCM(itemSettings));
    onClose();
  };

  return (
    <Grid container p={2} spacing={2}>
      {placeholderTypes.map((type) => (
        <Grid item xs={4} key={type} alignContent={'center'}>
          <Button onClick={handleClick(type)}>{getImageComponent(type)}</Button>
        </Grid>
      ))}
    </Grid>
  );
};

ImagePlaceholderSelection.propTypes = {
  onClose: PropTypes.func,
};

export default ImagePlaceholderSelection;
