import React from 'react';
import PropTypes from 'prop-types';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  Autocomplete,
  CompanyUnitsAutocomplete,
  ContactAutocomplete,
  Select,
  Setting,
  TextInput,
} from 'components/Fields';
import { Icon } from 'components/Fields/TextInput/styled';
import { AbeForm } from 'components/Forms';
import { CONTACT_TYPES, USER_STATUSES } from 'consts';
import { useLoadHierarchyOptions, useLoadMoreOptions } from 'hooks';
import {
  useGetCurrenciesListQuery,
  useGetGroupsQuery,
  useGetLanguagesListQuery,
  useGetLocalesListQuery,
  useGetRolesQuery,
  useGetTimezonesListQuery,
} from 'redux/rtk-query';
import { GridBox } from './styled';

const UserForm = (props) => {
  const roles = useLoadHierarchyOptions(useGetRolesQuery);

  const groups = useLoadMoreOptions(useGetGroupsQuery);

  const languages = useLoadMoreOptions(useGetLanguagesListQuery);
  const locales = useLoadMoreOptions(useGetLocalesListQuery);
  const timezones = useLoadMoreOptions(useGetTimezonesListQuery);
  const currencies = useLoadMoreOptions(useGetCurrenciesListQuery);

  const renderFields = () => (
    <Grid container mt={1} spacing={2}>
      <Grid item xs={6}>
        <FormPanel label={'Overview'}>
          <Field name="username" label="Username" component={TextInput} />
          <Field
            label={'Contact'}
            name={'contact'}
            component={ContactAutocomplete}
            type={CONTACT_TYPES.COMPANY_AGENT.value}
          />
          <Field name="setting" label="Setting" component={Setting} />

          <GridBox>
            <Field name="firstName" label="First Name" component={TextInput} />
            <Field
              name="middleName"
              label="Middle Name"
              component={TextInput}
            />
          </GridBox>
          <GridBox>
            <Field name="lastName" label="Last Name" component={TextInput} />
            <Field name="nickname" label="Nickname" component={TextInput} />
          </GridBox>

          <Field
            name="email"
            label="Email"
            component={TextInput}
            iconStart={<Icon icon={faEnvelope} />}
          />
          <Field
            name="roles"
            label="Roles"
            options={roles}
            component={Autocomplete}
            multiple
          />

          <Field
            name="groups"
            label="Groups"
            options={groups.options}
            component={Autocomplete}
            onOpen={groups.handleOpen}
            loadMoreResults={groups.handleMore}
            isLoading={groups.isFetching}
            handleSearch={groups.handleSearch}
            multiple
          />
          <Field
            name="companyStructureUnits"
            label="Company Structure"
            component={CompanyUnitsAutocomplete}
            multiple
          />
          <Field
            name="status"
            label="Status"
            options={USER_STATUSES}
            component={Select}
          />
        </FormPanel>
      </Grid>
      <Grid item xs={6}>
        <FormPanel label={'Language & Time'}>
          <Field
            name="language"
            label="Language"
            options={languages.options}
            component={Autocomplete}
            onOpen={languages.handleOpen}
            loadMoreResults={languages.handleMore}
            isLoading={languages.isFetching}
            handleSearch={languages.handleSearch}
          />
          <Field
            name="locale"
            label="Locale"
            options={locales.options}
            component={Autocomplete}
            onOpen={locales.handleOpen}
            loadMoreResults={locales.handleMore}
            isLoading={locales.isFetching}
            handleSearch={locales.handleSearch}
          />
          <Field
            name="timezone"
            label="Time Zone"
            options={timezones.options}
            component={Autocomplete}
            onOpen={timezones.handleOpen}
            loadMoreResults={timezones.handleMore}
            isLoading={timezones.isFetching}
            handleSearch={timezones.handleSearch}
          />
          <Field
            name="currency"
            label="Currency"
            options={currencies.options}
            component={Autocomplete}
            onOpen={currencies.handleOpen}
            loadMoreResults={currencies.handleMore}
            isLoading={currencies.isFetching}
            handleSearch={currencies.handleSearch}
          />
        </FormPanel>
        <FormPanel label={'Security'}>
          <Field name="password" label="Password" component={TextInput} />
          <Field
            name="confirm"
            label="Confirm Password"
            component={TextInput}
          />
        </FormPanel>
      </Grid>
    </Grid>
  );

  return (
    <AbeForm handleSubmitFunc={props.handleSubmit} {...props}>
      {renderFields}
    </AbeForm>
  );
};

export default UserForm;
UserForm.propTypes = {
  title: PropTypes.string,
  nameButton: PropTypes.string,
  submitError: PropTypes.string,
  handleSubmit: PropTypes.func,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  afterSave: PropTypes.func,
  initialValues: PropTypes.object,
};
