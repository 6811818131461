import { trackFieldChange } from 'redux/slices/documentEditor/helpers';

export const applyAndTrackChangesCM = (state, modifyFunction) => {
  let changes = []; // Initialize an array to capture changes

  state.currentDocument.htmlTemplate.items =
    state.currentDocument.htmlTemplate.items.map((field) => {
      if (state.activeFieldIds.includes(field.id)) {
        const before = { ...field }; // Capture the state before modification

        modifyFunction(field); // Apply the modification passed as modifyFunction

        const after = { ...field }; // Capture the state after modification
        changes.push({ fieldId: field.id, before, after }); // Track the change

        return field; // Return the modified field
      }
      return field; // Return unmodified fields as-is
    });

  // If there are changes, track them
  if (changes.length > 0) {
    trackFieldChange(state, changes);
  }
};
