import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid, useMediaQuery } from '@mui/material';
import {
  CommissionCard,
  DocumentCard,
  EmailCard,
  MarketingCard,
  NoteCard,
  RolesCard,
  TaskCard,
  TimeframeCard,
} from 'components/ModelViews';
import { OffersCard } from 'components/ModelViews/Transactions/OffersCard';
import { RelatedTransactionsCard } from 'components/ModelViews/Transactions/RelatedTransactionsCard';
import { PM_FILE_OPTIONS, STATUS_OPTIONS, TRANSACTION_TYPES } from 'consts';
import { useGetTransactionQuery } from 'redux/rtk-query';
import PropertyMgmtInvoicesCards from './components/PropertyMgmtInvoicesCards/PropertyMgmtInvoicesCards';

export const TransactionDashboard = ({ id }) => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  let { transactionDetailsId } = useParams();
  const transactionId = id?.toString() || transactionDetailsId;
  const { data: transactionData } = useGetTransactionQuery(
    { id: transactionId },
    { skip: !transactionId },
  );

  const transaction = transactionData?.data || {};

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <TimeframeCard startClosed transactionId={id} />
        <TaskCard startClosed transactionId={id} />
        {transaction.transactionType === TRANSACTION_TYPES.PRIMARY && (
          <MarketingCard startClosed />
        )}
        <NoteCard startClosed transactionId={id} />
        <EmailCard startClosed transactionId={id} />
        {transaction.transactionType === TRANSACTION_TYPES.PRIMARY && (
          <CommissionCard startClosed={isSm} />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        {transaction.status === STATUS_OPTIONS.PROPERTY_MGMT.value && (
          <PropertyMgmtInvoicesCards />
        )}
        {(transaction.pmFile ===
          PM_FILE_OPTIONS.LEASE_FOR_PROPERTY_MGMT.value ||
          transaction.status === STATUS_OPTIONS.PROPERTY_MGMT.value) && (
          <RelatedTransactionsCard />
        )}
        <DocumentCard transactionId={id} />
        {transaction.transactionType === TRANSACTION_TYPES.PRIMARY && (
          <OffersCard />
        )}
        <RolesCard transactionId={id} />
      </Grid>
    </Grid>
  );
};

TransactionDashboard.propTypes = {
  id: PropTypes.string,
};
