import { current } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

export const groupsReducer = {
  // *** Item Group Management ***
  addItemGroupCM: (state, action) => {
    const {
      pageElements,
      templateId,
      templateName,
      pageCount,
      activeStyle,
      modularType,
      page,
      pagesWithAgentIcon,
      pagesWithTeamName,
    } = action.payload;

    // Initialize items array
    const items = state.currentDocument.htmlTemplate.items || [];

    // Process new items
    const groupItems = pageElements.map((item) => {
      const itemId = nanoid();
      const newItem = { ...item, id: itemId, modularType, page, templateId };
      items.push(newItem);
      return itemId;
    });

    // Update pages
    const pages = state.currentDocument.htmlTemplate.options.pages || {};
    if (page) {
      const currentPage = pages[page] || { elements: [] };
      currentPage.elements = Array.from(
        new Set([...(currentPage.elements || []), ...groupItems]),
      );
      pages[page] = currentPage;
    }

    // Update itemGroups
    const itemGroups = state.currentDocument.itemGroups || {};
    const activePageItemGroups = itemGroups[page] || {
      Header: {},
      Body: {},
      Footer: {},
    };
    const modularTypeGroups = activePageItemGroups[modularType] || {};

    modularTypeGroups[templateId] = {
      id: templateId,
      name: templateName,
      items: [...(modularTypeGroups[templateId]?.items || []), ...groupItems],
      pageCount,
      activeStyle,
      page,
      labelValue: modularTypeGroups[templateId]?.labelValue || {},
      listItems: modularTypeGroups[templateId]?.listItems || {},
      locked: modularTypeGroups[templateId]?.locked || false,
      hidden: modularTypeGroups[templateId]?.hidden || [],
      pagesWithAgentIcon: pagesWithAgentIcon || [],
      pagesWithTeamName: pagesWithTeamName || [],
    };

    // Merge modularTypeGroups back
    activePageItemGroups[modularType] = modularTypeGroups;
    itemGroups[page] = {
      ...activePageItemGroups,
      [modularType]: modularTypeGroups,
    };

    // Update state
    state.currentDocument.htmlTemplate.items = items;
    state.currentDocument.htmlTemplate.options.pages = pages;
    state.currentDocument.itemGroups = itemGroups;

    // Mark document as needing saving
    state.needsSaving = true;
  },
  deleteItemGroupCM: (state, action) => {
    const { templateId, modularType, removeGroup } = action.payload;
    const activePage = state.activePage;
    const model = state.currentDocument;

    console.log(templateId, current(model.itemGroups[activePage]));
    // Validate existence of necessary nested objects
    if (
      !model ||
      !model.itemGroups ||
      !model.itemGroups[activePage] ||
      !model.itemGroups[activePage][modularType] ||
      !model.itemGroups[activePage][modularType][templateId]
    ) {
      console.warn('Invalid state structure or missing templateId');
      state.isDeleting = false; // Reset the flag
      return;
    }

    // Extract the items to remove
    const itemsToRemove =
      model.itemGroups[activePage][modularType][templateId].items;

    // Remove items from the items array
    state.currentDocument.htmlTemplate.items =
      state.currentDocument.htmlTemplate.items.filter(
        (item) => !itemsToRemove.includes(item.id),
      );

    // Remove the templateId from itemGroups or clear its items
    if (removeGroup) {
      // Remove the entire group
      delete state.currentDocument.itemGroups[activePage][modularType][
        templateId
      ];

      // Clean up modularType if no more groups
      if (
        Object.keys(state.currentDocument.itemGroups[activePage][modularType])
          .length === 0
      ) {
        delete state.currentDocument.itemGroups[activePage][modularType];
      }

      // Clean up activePage if no more modularTypes
      if (
        Object.keys(state.currentDocument.itemGroups[activePage]).length === 0
      ) {
        delete state.currentDocument.itemGroups[activePage];
      }
    } else {
      // Just clear the items array of the group
      state.currentDocument.itemGroups[activePage][modularType][
        templateId
      ].items = [];
    }

    // Update the page's elements to remove the items
    if (
      activePage &&
      state.currentDocument.htmlTemplate.options.pages[activePage]
    ) {
      const currentPage =
        state.currentDocument.htmlTemplate.options.pages[activePage];
      currentPage.elements = currentPage.elements.filter(
        (itemId) => !itemsToRemove.includes(itemId),
      );
    }

    // Mark the document as needing saving
    state.needsSaving = true;
  },
};
