import React from 'react';
import PropTypes from 'prop-types';
import { faContactCard } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { PageLink } from 'components/Styled';
import { DETAILS_SETTING } from 'consts';

const SettingName = ({ value }) => {
  return value ? (
    <PageLink to={`/${DETAILS_SETTING(value.id)}`}>
      <div>{value.name} </div>
    </PageLink>
  ) : (
    <></>
  );
};

SettingName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'name',
    value: ['name', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    Wrapper: SettingName,
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();

  return [
    {
      name: 'Details',
      icon: faContactCard,
      onClick: (item) => {
        navigate(`/${DETAILS_SETTING(item.id)}`);
      },
    },
  ];
};
