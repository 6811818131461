import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, Divider } from '@mui/material';
import { FontAwesomeIconComp, SkeletonSummary } from 'components/Common';
import { ToolbarRow } from 'components/Common/Table/TableToolbar/styled';
import { InfiniteScroll } from 'components/InfiniteScroll';
import { ListPagination } from 'components/ListPagination';
import { SearchInput } from 'components/SearchInput';
import { ListBox, NewButtonBox } from 'components/Styled';
import { CREATE_NOTE } from 'consts';
import { useItemCount } from 'contexts/ItemCountProvider';
import { useOpenDrawer } from 'hooks';
import { useDebounce } from 'hooks/utils/useDebounce';
import {
  useGetContactNotesQuery,
  useGetTransactionNotesQuery,
} from 'redux/rtk-query';
import { NoteSummary } from './components/NoteSummary';

export const NotesList = ({
  relatedModel,
  relatedModelType,
  infiniteScroll = false,
}) => {
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(infiniteScroll ? 20 : 5);
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 500);
  const { data: contactTaskData, isFetching: isFetchingContactNotes } =
    useGetContactNotesQuery(
      {
        uuid: relatedModel.uuid,
        search: debouncedValue,
        page: page,
        limit: rowsPerPage,
      },
      { skip: !relatedModel.uuid || relatedModelType !== 'contact' },
    );
  const { data: transactionTaskData, isFetching: isFetchingTransactionNotes } =
    useGetTransactionNotesQuery(
      {
        uuid: relatedModel.uuid,
        search: debouncedValue,
        page: page,
        limit: rowsPerPage,
      },
      { skip: !relatedModel.uuid || relatedModelType !== 'transaction' },
    );

  useEffect(() => {
    if (infiniteScroll) {
      setData([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (infiniteScroll) {
      if (contactTaskData) {
        setData(contactTaskData);
      }
      if (transactionTaskData) {
        setData(contactTaskData);
      }
    } else {
      setData(transactionTaskData || contactTaskData || []);
    }
  }, [transactionTaskData, contactTaskData]);

  // const noteData = transactionTaskData || contactTaskData;
  const AllNotes = data;
  const isFetchingNotes = isFetchingContactNotes || isFetchingTransactionNotes;
  const items = AllNotes?.data?.items;
  const totalPages = AllNotes?.data?.meta.totalPages;
  const totalCount = AllNotes?.data?.meta.total;

  const { setItemCount } = useItemCount();
  const openDrawer = useOpenDrawer();

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const createNote = useCallback(() => {
    openDrawer(CREATE_NOTE(relatedModelType, relatedModel.id));
  }, [relatedModel]);

  useEffect(() => {
    setItemCount(totalCount);
  }, [totalCount]);

  return (
    <>
      <ToolbarRow width="100%" justifyContent="space-between">
        <SearchInput changeHandler={setSearchValue} />
        <NewButtonBox>
          <Button
            size={'small'}
            variant="text"
            onClick={createNote}
            endIcon={<FontAwesomeIconComp size="sm" icon={faCirclePlus} />}
          >
            New
          </Button>
        </NewButtonBox>
      </ToolbarRow>
      <Divider />
      <InfiniteScroll
        onBottomReached={handleChangePage}
        page={page}
        totalPages={totalPages}
        isFetching={isFetchingNotes}
      >
        <ListBox>
          {!AllNotes ? (
            <SkeletonSummary />
          ) : items && items.length ? (
            items.map((note, index) => (
              <React.Fragment key={note.id}>
                <NoteSummary note={note} />
                {index + 1 < items.length && <Divider />}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
          <Box style={{ textAlign: 'center' }}>
            {!infiniteScroll && totalPages > 0 && (
              <ListPagination
                totalCount={totalCount}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5]}
              />
            )}
          </Box>
        </ListBox>
      </InfiniteScroll>
    </>
  );
};

NotesList.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  relatedModel: PropTypes.object,
  relatedModelType: PropTypes.string,
  infiniteScroll: PropTypes.bool,
};
