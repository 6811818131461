import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { ReportFilters } from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFilters';
import { useGetFiltersQuery } from 'redux/rtk-query';

export const ReportFiltersPage = () => {
  const form = useForm();
  const id = form.getState().values.id;

  const { data: filters } = useGetFiltersQuery(id, { skip: !id });

  useEffect(() => {
    if (filters) {
      // Map the filters data
      const mappedFilters = mapApiDataToFormData(filters?.data);
      // Set the 'filters' field in the form state
      form.change('filters', mappedFilters);
    }
  }, [filters, form]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReportFilters form={form} />
      </Grid>
    </Grid>
  );
};

function mapApiDataToFormData(apiData) {
  const result = [];

  // Process childGroups at the root level
  if (apiData.childGroups && apiData.childGroups.length > 0) {
    apiData.childGroups.forEach((group) => {
      const formGroup = {
        groupingCondition: group.groupingCondition || 'And',
        filters: [],
      };

      // Process filters within the group
      if (group.filters && group.filters.length > 0) {
        group.filters.forEach((filter) => {
          formGroup.filters.push({
            name: filter.name,
            path: filter.visualPath || filter.path || [],
            condition: filter.condition || 'Equals',
            value: filter.value || '',
            isEditable: filter.isEditable || false,
            visualPath: filter.visualPath || [],
            conditionType: 'And', // Default conditionType
            type: filter.type,
            enum: filter.enum,
          });
        });
      }

      // Process childGroups within the group
      if (group.childGroups && group.childGroups.length > 0) {
        group.childGroups.forEach((childGroup) => {
          // If the childGroup has a groupingCondition of 'Or' and contains filters
          if (
            childGroup.groupingCondition === 'Or' &&
            childGroup.filters &&
            childGroup.filters.length > 0
          ) {
            childGroup.filters.forEach((filter) => {
              formGroup.filters.push({
                name: filter.name,
                path: filter.visualPath || filter.path || [],
                condition: filter.condition || 'Equals',
                value: filter.value || '',
                isEditable: filter.isEditable || false,
                visualPath: filter.visualPath || [],
                conditionType: 'Or',
                type: filter.type,
                enum: filter.enum,
              });
            });
          } else {
            // If there are nested groups (unlikely in your case), handle recursively
            // You can adjust this part based on your application's needs
          }
        });
      }

      // Adjust conditionType for filters beyond the first one
      formGroup.filters.forEach((filter, index) => {
        if (index === 0) {
          filter.conditionType = null; // First filter has no conditionType
        } else if (!filter.conditionType) {
          filter.conditionType = formGroup.groupingCondition || 'And';
        }
      });

      result.push(formGroup);
    });
  }

  return result;
}
