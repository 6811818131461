export const defaultValues = {
  name: '',
  settingType: '',
  agentStatus: '',
  // agentContact: null,
  officeSetting: null,
  assistantSetting: null,
  teamSetting: null,
  connectionSetting: null,
  brokerSetting: null,
  officeAdminSetting: null,
  marketingCoordinatorSetting: null,
  complianceCoordinatorSetting: null,
  hireDate: null,
  dehireDate: null,
  anniversaryDate: null,
  hireAnniversaryDate: null,
  licenseDate: null,
  licenseNumber: null,
  headshotFile: null,
  iconFile: null,
  achievements: [],
  residentialServices: [],
  commercialServices: [],
  languages: [],
  companyStructureUnits: [],
  shareWith: [],
  agentId: null,
};
