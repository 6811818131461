import React from 'react';
import PropTypes from 'prop-types';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { Card, Divider } from '@mui/material';
import { AbeCard, FontAwesomeIconComp } from 'components/Common';
import { DetailsBox, DetailsWrapper } from 'components/Styled';
import { colors } from 'consts';
import { BottomCard } from './components/BottomCard';
import { TopCard } from './components/TopCard';

export const TransactionDetails = ({ transactionInfo }) => {
  return (
    <DetailsWrapper>
      <Card>
        {transactionInfo && (
          <DetailsBox nopadding={'true'}>
            <TopCard transactionInfo={transactionInfo} />
          </DetailsBox>
        )}
      </Card>
      <Card>
        {transactionInfo && (
          <AbeCard
            elevation={2}
            icon={
              <FontAwesomeIconComp
                icon={faInfoCircle}
                color={colors.LIGHT_BLUE_1}
              />
            }
            title={'Details'}
          >
            <Divider />
            <DetailsBox style={{ alignItems: 'flex-start', padding: 0 }}>
              <BottomCard transactionInfo={transactionInfo} />
            </DetailsBox>
          </AbeCard>
        )}
      </Card>
    </DetailsWrapper>
  );
};

TransactionDetails.propTypes = {
  transactionInfo: PropTypes.object,
};
