import React from 'react';
import { Outlet } from 'react-router-dom';
import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { ButtonSection, PageMainContent } from 'components/Styled';
import { REPORT_CREATE } from 'consts';
import { ReportsTable } from './components/ReportsTable';

export const ReportsListPage = () => {
  return (
    <>
      <LayoutHeader title={'Reports'}>
        <ButtonSection>
          <CreateButton path={`/${REPORT_CREATE}`} title="Create" />
        </ButtonSection>
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <ReportsTable />
      </PageMainContent>
      <Outlet />
    </>
  );
};
