import {
  faLocationCircle as faLocationCircleLight,
  faExclamationCircle,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCircleCheck as faCircleCheckLight,
  faCircleMinus as faCircleMinusLight,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleCheck,
  faCircleMinus,
  faLocationCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { colors } from '../consts/colors';

export function getIconByStatus(status) {
  switch (status) {
    case 'Unmodified Draft':
      return faCircleMinusLight;
    case 'Draft':
      return faCircleMinus;
    case 'In Progress':
      return faLocationCircleLight;
    case 'In Progress Completed':
      return faLocationCircle;
    case 'Completed':
      return faCircleCheckLight;
    case 'Approved':
      return faCircleCheck;
    case 'Voided':
      return faTimesCircle;
    case 'Revise':
      return faExclamationCircle;
    default:
      return faCircleMinus;
  }
}

export function getColorByStatus(status) {
  switch (status) {
    case 'Draft':
    case 'Voided':
    case 'Unmodified Draft':
    case 'Draft Offer':
    case 'Archived Offer':
      return '#A9A7A4';
    case 'In Progress':
    case 'In Progress Completed':
      return '#F6C244';
    case 'Completed':
    case 'Approved':
    case 'Active Offer':
      return '#4CAF50';
    case 'Revise':
      return colors.RED;
    default:
      return '#FFC107';
  }
}

export function getStatus(item) {
  const isUnmodifiedDraft = (item) => {
    // check if createAt and updatedAt are within 3 seconds of each other
    if (item.status === 'Draft') {
      const createdAt = new Date(item.createdAt);
      const updatedAt = new Date(item.updatedAt);
      const timeDiff = Math.abs(updatedAt - createdAt); // Time difference in milliseconds

      return timeDiff <= 3000; // 3000 milliseconds equals 3 seconds
    }
    return false;
  };
  if (isUnmodifiedDraft(item)) {
    return 'Unmodified Draft';
  }
  if (item.status === 'Draft') {
    return 'Draft';
  }
  if (item.status === 'In Progress' && item.eSigningCompleted) {
    return 'In Progress Completed';
  }
  if (item.status === 'In Progress') {
    return 'In Progress';
  }
  if (item.status === 'Completed' && !item.closedApproved) {
    return 'Completed';
  }
  if (item.status === 'Completed' && item.closedApproved) {
    return 'Approved';
  }
  if (item.status === 'Voided') {
    return 'Voided';
  }
  if (item.status === 'Revise') {
    return 'Revise';
  }
  return 'Draft';
}
