import React from 'react';
import PropTypes from 'prop-types';
import { Chip as MaterialChip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'consts';

const StyledChip = styled(MaterialChip, {
  shouldForwardProp: (prop) => prop !== 'chipColor', // Prevent chipColor from being passed to the DOM
})(({ chipColor = colors.WHITE, variant }) => ({
  color: 'white',
  backgroundColor: `${chipColor} !important`,
  borderColor: colors.LIGHT_BLACK,
  fontSize: '1.4rem',
  '&:hover': {
    backgroundColor: chipColor,
    filter: 'brightness(120%)',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: chipColor,
    borderColor: chipColor,
  },
  ...(variant === 'outlined' && {
    color: chipColor === colors.WHITE ? colors.LIGHT_BLACK : chipColor,

    border: `1px solid ${chipColor === colors.WHITE ? colors.LIGHT_BLACK : chipColor}`,
    backgroundColor: 'white',
    '& .MuiChip-icon': {
      color: colors.LIGHT_BLACK,
    },
    '& .MuiChip-deleteIcon': {
      color: colors.LIGHT_BLACK,
    },
  }),
  ...(variant !== 'outlined' && {
    '& .MuiChip-icon': {
      color: 'white',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  }),
}));

export const ColorChip = (props) => {
  return <StyledChip {...props} />;
};

ColorChip.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
};
