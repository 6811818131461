import React from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { Select } from 'components/Fields';
import { useTransactionOptions } from 'hooks';

export const RepresentingField = () => {
  const form = useForm();
  const { representingOptions } = useTransactionOptions(form);

  return (
    <Grid item xs={12}>
      <Field
        label="Representing"
        name={'representing'}
        component={Select}
        options={representingOptions}
        tooltip={{
          title: (
            <>
              The customer type represented <br /> on the transaction.
            </>
          ),
        }}
      />
    </Grid>
  );
};
