import React from 'react';
import { faSparkles } from '@fortawesome/pro-light-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { List } from '@mui/material';
import {
  CollapsableMenuGroup,
  FontAwesomeIconComp,
  MenuItemButton,
} from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const AutofillMenuItem = () => {
  const activeField = useSelector(selectCurrentItem);
  const dispatch = useDispatch();
  const handleAutofillSelect = (autofill) => {
    dispatch(
      updateItemCM({ itemId: activeField.id, properties: { autofill } }),
    );
  };

  const autofillOptions = [
    {
      label: 'Property',
      options: [
        { label: 'Full Address', value: 'fullAddress' },
        { label: 'Street Address', value: 'streetAddress' },
        { label: 'City, State, Zip', value: 'cityStateZip' },
        { label: 'Beds', value: 'beds' },
        { label: 'Baths', value: 'baths' },
        { label: 'Square Feet', value: 'sqft' },
        { label: 'List Price', value: 'listPrice' },
        { label: 'Status', value: 'status' },
      ],
    },
    {
      label: 'Agent 1',
      options: [
        { label: 'Name', value: 'agentName' },
        { label: 'Title', value: 'agentTitle' },
        { label: 'E-Mail', value: 'agentEmail' },
        { label: 'Phone', value: 'agentPhone' },
        { label: 'Team Name', value: 'agentTeam' },
        { label: 'Bio', value: 'agentBio' },
      ],
      nestedGroups: [
        {
          label: 'Testimonials',
          options: [
            {
              label: 'Testimonial 1 Content',
              value: 'agentTestimonialContent1',
            },
            {
              label: 'Testimonial 1 Customer Name',
              value: 'agentTestimonialCustomer1',
            },
            {
              label: 'Testimonial 2 Content',
              value: 'agentTestimonialContent2',
            },
            {
              label: 'Testimonial 2 Customer Name',
              value: 'agentTestimonialCustomer2',
            },
            {
              label: 'Testimonial 3 Content',
              value: 'agentTestimonialContent3',
            },
            {
              label: 'Testimonial 3 Customer Name',
              value: 'agentTestimonialCustomer3',
            },
            {
              label: 'Testimonial 4 Content',
              value: 'agentTestimonialContent4',
            },
            {
              label: 'Testimonial 4 Customer Name',
              value: 'agentTestimonialCustomer4',
            },
          ],
        },
      ],
    },
    {
      label: 'Agent 2',
      options: [
        { label: 'Name', value: 'agent2Name' },
        { label: 'Title', value: 'agent2Title' },
        { label: 'E-Mail', value: 'agent2Email' },
        { label: 'Phone', value: 'agent2Phone' },
        // Assuming no nested groups for Agent 2, but can be added similarly to Agent 1
      ],
    },
  ];

  const renderOption = (option) => (
    <MenuItemButton
      dense
      key={option.value}
      label={option.label}
      onClick={() => handleAutofillSelect(option.value)}
      isSelected={activeField?.autofill === option.value}
    />
  );

  const renderGroup = (group) => (
    <CollapsableMenuGroup key={group.label} label={group.label}>
      {group.options.map(renderOption)}
      {group.nestedGroups?.map(renderGroup)}
    </CollapsableMenuGroup>
  );

  return (
    <>
      <CollapsableMenuGroup
        label={'AutoFill'}
        icon={<FontAwesomeIconComp fontSize={18} icon={faSparkles} />}
      >
        <List component="div" disablePadding>
          {autofillOptions.map((group) =>
            group.options ? renderGroup(group) : renderOption(group),
          )}
        </List>
      </CollapsableMenuGroup>
    </>
  );
};

export default AutofillMenuItem;
