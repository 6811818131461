import React from 'react';
import { faArrowLeftLong } from '@fortawesome/pro-solid-svg-icons';
import {
  Box,
  Button,
  ListItem as ListItemComponent,
  ListItemButton,
  ListItemIcon,
  Toolbar,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { FontAwesomeIconComp } from 'components/Common';

const drawerWidthOpen = 220;
const drawerWidthClosed = 80;

export const BoxDrawer = styled(Box)({
  width: '85px',
});

const openedMixin = (theme) => ({
  borderRight: 'none',
  width: drawerWidthOpen,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  borderRight: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerWidthClosed,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidthOpen,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'absolute',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const ToolbarWithLogo = styled(Toolbar)({
  minHeight: '56px!Important',
  justifyContent: 'center',
});

export const UpperListItemButton = styled(ListItemButton)({
  minHeight: 48,
  px: 2.5,
  borderRadius: '24px',
  maxWidth: '85%',
  margin: 'auto',
  '&:hover': {
    background: 'rgba(0, 133, 255, 0.1)',
  },
});

export const UpperListItemIcon = styled(ListItemIcon)({
  minWidth: 0,
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
});

export const ListItem = styled(ListItemComponent)({
  color: '#565656',
  '&:hover': {
    'svg path': {
      fill: '#0085FF',
    },
  },
});

export const BackBtn = styled(Button)({
  fontSize: '13px',
  lineHeight: '20px',
  width: 'fit-content',
  minWidth: '188px',
  padding: '3px 16px',
  alignSelf: 'center',
});

export const LeftIcon = () => (
  <FontAwesomeIconComp
    icon={faArrowLeftLong}
    style={{ width: '16px', height: '16px' }}
  />
);
