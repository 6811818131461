import React from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CurrencyInput,
  DatePicker,
  Select,
  TextInput,
} from 'components/Fields';
import { useTransactionOptions } from 'hooks';

export const PropertyMgmtAgreementPanel = () => {
  const form = useForm();
  const { yesOrNoOptions } = useTransactionOptions(form);

  return (
    <FormPanel label={'Property Mgmt Agreement'}>
      <Grid item xs={12}>
        <Field
          label="Pm Begin"
          name={'pmBegin'}
          component={DatePicker}
          tooltip={{
            title:
              'This is the commencement of the <b>management agreement</b>, not the lease.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Maintenance Limit"
          name={'maintenanceLimit'}
          component={CurrencyInput}
          tooltip={{
            title:
              'The maximum amount that can be spent on a single invoice before owner approval is required. The management agreement has this at $250 by default.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Maintenance Reserve"
          name={'maintenanceReserve'}
          component={CurrencyInput}
          tooltip={{
            title:
              'The amount of funds held in escrow to cover unexpected expenses or expenses that exceed rent amount. The management agreement has this at $250 by default. By entering a number here, the amount will be deducted from owner proceeds to fund the maintenance reserve account.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="New Lease Percent"
          name={'newLeasePercent'}
          type={'number'}
          component={TextInput}
          tooltip={{
            title: 'Enter the commission percentage on new leases.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="New Lease Flat Rate"
          name={'newLeaseFlatRate'}
          component={CurrencyInput}
          tooltip={{
            title: 'Enter the flat rate on new leases.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Lease Renewal Percent"
          name={'leaseRenewalPercent'}
          type={'number'}
          component={TextInput}
          tooltip={{
            title: 'Enter the commission percentage on a lease renewal.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Lease Renewal Flat Rate"
          name={'leaseRenewalFlatRate'}
          component={CurrencyInput}
          tooltip={{
            title: 'Enter the flat rate on a lease renewal.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Late Fee Split"
          name={'lateFeeSplit'}
          type={'number'}
          component={TextInput}
          tooltip={{
            title: 'Enter the percentage the company receives from late fees.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Nsf Fee Split"
          name={'nsfFeeSplit'}
          type={'number'}
          component={TextInput}
          tooltip={{
            title: 'Enter the percentage the company recieves from NSF fees.',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label="Commission Terms"
          name={'commissionTerms'}
          component={Select}
          options={yesOrNoOptions}
          tooltip={{
            title:
              'Do you charge the owner a management fee in addition when a lease fee is paid? By default, the management agreement has the owner paying a management fee in the same month that a lease fee is charged.',
          }}
        />
      </Grid>
    </FormPanel>
  );
};

PropertyMgmtAgreementPanel.propTypes = {};
