import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DetailsPanels } from 'components/Common';
import PhotoSetButton from 'pages/TransactionsPages/DetailsTransactionPage/components/TransactionDetails/components/TopCard/PhotoSetButton';
import { TransactionStatusChip } from 'pages/TransactionsPages/TransactionsPage/styled';
import { useGetMlsListingQuery, useGetParcelQuery } from 'redux/rtk-query';
import {
  BedBathSqftYearBuilt,
  TransactionMlsImage,
  TransactionPlaceholderImage,
} from './components';

export const TopCard = ({ transactionInfo }) => {
  const { data: mlsListingData, isFetching: isMlsListingFetching } =
    useGetMlsListingQuery(
      { id: transactionInfo.mlsListing },
      { skip: typeof transactionInfo.mlsListing !== 'string' },
    );

  const { data: parcelData, isFetching: isParcelFetching } = useGetParcelQuery(
    { hjid: transactionInfo.parcel },
    { skip: typeof transactionInfo.parcel !== 'string' },
  );

  const hasMlsListing = mlsListingData && mlsListingData.data;

  const translatedLabels = {
    mlsListing: 'MLS',
  };

  return (
    <>
      <Box position={'relative'} display={'flex'} width={'100%'}>
        <span
          style={{
            position: 'absolute',
            margin: 8,
          }}
        >
          <TransactionStatusChip>
            {transactionInfo.status}
          </TransactionStatusChip>
        </span>
        <span
          style={{
            position: 'absolute',
            right: 0,
            padding: 8,
          }}
        >
          <PhotoSetButton />
        </span>
        {!hasMlsListing || isParcelFetching || isMlsListingFetching ? (
          <TransactionPlaceholderImage />
        ) : (
          <TransactionMlsImage imgUrl={mlsListingData?.data?.image} />
        )}
      </Box>
      {mlsListingData?.data?.hjid && parcelData?.data?.apn ? (
        <Grid container>
          <BedBathSqftYearBuilt transactionInfo={transactionInfo} />
          <Divider sx={{ width: '100%' }} />
          <DetailsPanels
            translatedLabels={translatedLabels}
            data={{
              mlsListing: mlsListingData?.data?.hjid,
              parcel: parcelData?.data?.apn,
            }}
            panels={{
              '': ['mlsListing', 'parcel'],
            }}
          />
        </Grid>
      ) : (
        /// if no mls linked show No MLS Linked if no parcel linked show No Parcel Linked if both arent linked show No MLS or Parcel Linked
        <Box pb={2}>
          <Typography variant={'body1'}>
            {hasMlsListing && !parcelData?.data?.apn && <>No Parcel Linked</>}
            {!hasMlsListing && parcelData?.data?.apn && <>No MLS Linked</>}
            {!hasMlsListing && !parcelData?.data?.apn && (
              <>No MLS or Parcel Linked</>
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

TopCard.propTypes = {
  transactionInfo: PropTypes.object,
};
