import { STATUS_OPTIONS } from 'consts';
import { RIGHT_OPTIONS } from 'consts/rightsPermissions';

export const defaultValues = {
  status: STATUS_OPTIONS.DRAFT.value,
  roles: [],
  rolesStr: [],
  saleType: [],
  team: [],
  representing: null,
  teamSharing: 'No',
  companyStructureUnits: [],
  timeframes: [],
  shareWithOption: RIGHT_OPTIONS.agentOnly.value,
  parcel: null,
  modelAccess: {
    owner: null,
    sharedWithUsers: [],
    sharedWithUnits: [],
  },
};
//
// export default {
//     type: "",
//     officePhone: "",
//     officeFax: "",
//     mobilePhone: "",
//     email: null,
//     secondaryEmail: null,
//     list: [],
//     birthday: null,
//     anniversaryDate: null,
//     homeAnniversary: null,
//     primaryAddress: {},
//     secondaryAddress: {},
//     description: "",
//     suffix: null,
//     firstName: null,
//     middleName: null,
//     lastName: null,
//     nickname: null,
//     communication: [],
//     contactClassification: [],
//     company: null,
//     jobTitle: "",
//     source: null,
//     tags: [],
//     relationships: [],
//     contactStatus: null,
//     vendorApproved: null,
//     mlsId: null,
//     licenseNumber: null,
//     agent: null,
//     vendorTypeId: null,
//     vendor1099: null,
//     agentId: null,
//     shareWith: [],
//     companyStructureUnits: [],
//     shareWithOption: null,
// };
