import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FontAwesomeIconComp = (props) => (
  <FontAwesomeIcon
    fontSize={props.fontSize ? props.fontSize : '1.4rem'}
    {...props}
  />
);

FontAwesomeIconComp.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
