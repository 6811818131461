import React, { useCallback } from 'react';
import {
  faBorderCenterH,
  faBorderTop,
  faBorderBottom,
  faGripLines,
  faMinus,
  faTextHeight as faLineHeight,
} from '@fortawesome/pro-light-svg-icons'; // Adjust the import based on your project structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  TextField,
  Button,
  ButtonGroup,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { CollapsableMenuGroup, MenuItemButton } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

export const SingleOrMultiMenuItem = () => {
  const dispatch = useDispatch();
  const item = useSelector(selectCurrentItem);

  // If no item is selected, return null
  if (!item) {
    return null;
  }

  const style = item.style || {};

  // Handle toggle between single and multiple lines
  const handleMultilineToggle = useCallback(() => {
    const newValue = !item.multiline;

    const updatedStyle = { ...style };

    if (newValue) {
      // When setting to multiline
      updatedStyle.underline = null;
    } else {
      // When setting to single line
      updatedStyle.lineHeight = null;
      updatedStyle.alignment = null;
    }

    dispatch(
      updateItemCM({
        itemId: item.id,
        properties: {
          multiline: newValue,
          style: updatedStyle,
        },
      }),
    );
  }, [dispatch, item, style]);

  // Handle line height change
  const handleLineHeightChange = useCallback(
    (event) => {
      const value = event.target.value;
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            style: {
              ...style,
              lineHeight: value,
            },
          },
        }),
      );
    },
    [dispatch, item, style],
  );

  // Handle alignment change
  const handleAlignmentClick = useCallback(
    (alignment) => {
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            style: {
              ...style,
              alignment: alignment,
            },
          },
        }),
      );
    },
    [dispatch, item, style],
  );

  return (
    <Box
      sx={{
        border: item.multiline ? '2px solid #097CC7' : 'none',
      }}
    >
      <MenuItemButton
        onClick={handleMultilineToggle}
        icon={
          <FontAwesomeIcon
            icon={item.multiline ? faGripLines : faMinus}
            style={{ fontWeight: 900 }}
          />
        }
        label={
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <Typography variant="body1">
                {item.multiline ? 'Multiple Lines' : 'Single Line'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Switch
                size="small"
                color="primary"
                checked={item.multiline}
                onChange={handleMultilineToggle}
              />
            </Grid>
          </Grid>
        }
      />
      {item.multiline && (
        <>
          <CollapsableMenuGroup
            label="Alignment"
            icon={<FontAwesomeIcon icon={faBorderCenterH} />}
          >
            <Box
              sx={{
                padding: '12px',
                paddingRight: '24px',
                textAlign: 'center',
              }}
            >
              <ButtonGroup color="primary" size="large">
                <Button
                  variant={
                    style.alignment === 'flex-start' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleAlignmentClick('flex-start')}
                >
                  <FontAwesomeIcon
                    icon={faBorderTop}
                    style={{ fontWeight: 900 }}
                  />
                </Button>
                <Button
                  variant={
                    style.alignment === 'center' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleAlignmentClick('center')}
                >
                  <FontAwesomeIcon
                    icon={faBorderCenterH}
                    style={{ fontWeight: 900 }}
                  />
                </Button>
                <Button
                  variant={
                    style.alignment === 'flex-end' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleAlignmentClick('flex-end')}
                >
                  <FontAwesomeIcon
                    icon={faBorderBottom}
                    style={{ fontWeight: 900 }}
                  />
                </Button>
              </ButtonGroup>
            </Box>
          </CollapsableMenuGroup>
          <CollapsableMenuGroup
            label="Line Height"
            icon={<FontAwesomeIcon icon={faLineHeight} />}
          >
            <Box
              sx={{
                padding: '12px',
                paddingRight: '24px',
                textAlign: 'center',
              }}
            >
              <TextField
                value={style.lineHeight || 1}
                onChange={handleLineHeightChange}
                type="number"
                variant="outlined"
                inputProps={{
                  step: 0.1,
                }}
              />
            </Box>
          </CollapsableMenuGroup>
        </>
      )}
    </Box>
  );
};
