import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Card, Grid } from '@mui/material';
import { Box } from '@mui/system';
import HoverReveal from 'components/Common/HoverReveal/HoverReveal';
import { Path, Condition, Value } from './';

const Trigger = ({ name, onRemove }) => {
  return (
    <Grid item xs position="relative">
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    position: 'absolute',*/}
      {/*    zIndex: 1,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {showCondition && <FilterCompareCondition name={name} />}*/}
      {/*</Box>*/}
      <HoverReveal
        hoverContent={
          <IconButton color="error" onClick={onRemove}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        }
        sx={{
          flexDirection: 'column',
        }}
      >
        <Card sx={{ px: 2, py: 1 }}>
          <Box display={'flex'} alignItems={'center'} sx={{ mr: 3 }}>
            <Path name={name} />
            <Condition name={name} />
            <Value name={name} />
          </Box>
        </Card>
      </HoverReveal>
    </Grid>
  );
};

export default Trigger;

Trigger.propTypes = {
  name: PropTypes.string.isRequired,

  onRemove: PropTypes.func.isRequired,
};
