export const hasValue = (value) => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  if (typeof value === 'object' && value !== null) {
    if (value.id || value.day || value.month || value.year) {
      return true;
    }
  } else if (
    value !== undefined &&
    value !== null &&
    value !== '' &&
    value !== [] &&
    value !== {} &&
    value !== false
  ) {
    return true;
  } else {
    return false;
  }
};

export const getError = (meta) => {
  // Synchronization error (from validation function)
  if (meta.error && meta.touched) {
    return meta.error;
  }

  // Submission error that hasn't been addressed yet
  if (meta.submitError && !meta.dirtySinceLastSubmit) {
    return meta.submitError;
  }

  if (meta.data?.error) {
    return meta.data.error;
  }

  // No error, or conditions for showing error are not met
  return undefined;
};

export const getSelectOptions = (options) => {
  return Object.values(options).map((label) => ({
    value: label,
    label,
  }));
};

export const getSelectOptionsAlt = (options) => {
  return Object.keys(options).map((key) => {
    return options[key];
  });
};

export const handleResetRequired = (form) => {
  form.batch(() => {
    Object.values(form.getRegisteredFields()).forEach((field) => {
      form.registerField(
        field,
        (fieldState) => {
          // eslint-disable-next-line no-unused-vars
          const { required } = fieldState.data;
        },
        { data: true },
      );
      form.mutators.setFieldData(field, {
        required: null,
        error: null,
      });
    });
  });
};

export const getIdOrValue = (obj) => obj?.id || obj?.value || null;

export const extractIdsOrValues = (array) =>
  array && array[0] !== null ? array.map((item) => item.id || item.value) : [];

export const addModelAccessValues = (modelAccess) => {
  return {
    shareWith: modelAccess?.sharedWithUsers || [],
    agentId: modelAccess?.owner?.id,
    companyStructureUnits: modelAccess?.sharedWithUnits || [],
    shareWithOption: modelAccess?.sharedWithUnits
      ? 'sharedWithGroup'
      : modelAccess?.sharedWithUsers
        ? 'agents'
        : 'agentOnly',
    everyone: modelAccess?.everyone,
  };
};
