import React from 'react';
import { faAsterisk } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@mui/material';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import { activeFieldsSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';

export function Required() {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const selectedField = activeFields[0];

  return (
    <MenuItemButton
      label={'Required'}
      icon={<FontAwesomeIconComp fontSize={18} icon={faAsterisk} />}
      rightAction={
        <Switch
          checked={selectedField.required === 'yes'}
          onChange={(e) => {
            dispatch(
              updateField({
                properties: { required: e.target.checked ? 'yes' : 'no' },
              }),
            );
          }}
        />
      }
    />
  );
}
