// clickMarketingSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { documentReducer } from 'redux/slices/clickMarketing/reducers/document';
import { fieldsReducer } from 'redux/slices/clickMarketing/reducers/fields';
import { groupsReducer } from 'redux/slices/clickMarketing/reducers/groups';
import { uiReducer } from 'redux/slices/clickMarketing/reducers/ui'; // Adjust the import path as needed

const initialState = {
  undoStack: [],
  redoStack: [],
  agentView: false,
  isDeleting: false,
  needsSaving: false,
  showGrid: false,
  importing: false,
  scale: 1,
  copyItemToPage: {
    item: null,
    page: null,
  },
  cropping: false,
  cropperProps: {
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  },
  activeMenu: 'primary',
  activePage: null,
  bannerMessages: [],
  templateView: false,
  preview: false,
  currentDocument: {
    htmlTemplate: {
      items: [],
      options: {
        pageOrder: [],
        pages: {},
      },
    },
    itemGroups: {},
  },
  activeFieldIds: [],
  autofillData: {
    fullAddress: '',
    streetAddress: '',
    cityStateZip: '',
    beds: '',
    baths: '',
    sqft: '',
    listPrice: '',
    status: '',
    defaultColor: '#4a494b',
    customColor: '#4a494b',
    themeFonts: {},
    defaultThemeFonts: {},
    agentName: '',
    agentTitle: '',
    agentPhone: '',
    agentEmail: '',
    agentTeam: '',
    agentHeadshot: null,
    agentSettingId: '',
    agent2Name: '',
    agent2Title: '',
    agent2Phone: '',
    agent2Email: '',
    agent2Team: '',
    agent2Headshot: null,
    agent2SettingId: '',
    logos: {
      svgImage: null,
      svgLogo: null,
      svgIcon: null,
    },
    propertyPhotos: [],
  },
  disableScriptFont: false,
};

export const clickMarketingSlice = createSlice({
  name: 'clickMarketing',
  initialState,
  reducers: {
    reset: () => initialState,
    ...uiReducer,
    ...documentReducer,
    ...fieldsReducer,
    ...groupsReducer,
  },
});

export const {
  reset,
  setCropping,
  setImporting,
  setShowGrid,
  changeThemeFonts,
  changeDefaultThemeFonts,
  toggleDisableScriptFont,
  setDocumentDataCM,
  setAutofillDataCM,
  changeThemeColor,
  changeDefaultThemeColor,
  setScaleCM,
  setIsDeleting,
  setActiveFieldCM,
  addNewPageCM,
  addItemCM,
  sortPagesCM,
  setActivePageCM,
  deleteItemCm,
  updateItemCM,
  addItemGroupCM,
  deleteItemGroupCM,
  moveToBackCM,
  moveToFrontCM,
  copyItemToPage,
  arrangeGroupCM,
} = clickMarketingSlice.actions;

export const clickMarketingSliceReducer = clickMarketingSlice.reducer;
