import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { SideDrawer } from 'components/Common';
import ClickMarketingEditor from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingEditor';
import { ClickMarketingGallery } from 'components/ModelViews/Marketing/MarketingCreateView/components/ClickMarketingGallery';
import { useDrawer } from 'hooks';
import { importingSelector, aspectRatioSelectorCM } from 'redux/selectors';
import { setImporting } from 'redux/slices';

export function ClickMarketingEditorDrawer({
  documentData,
  headerHeight,
  templateView = false,
}) {
  const importing = useSelector(importingSelector);
  const aspectRatio = useSelector(aspectRatioSelectorCM);
  const dispatch = useDispatch();

  const { isOpen, openDrawer, closeDrawer } = useDrawer(!!importing);

  useEffect(() => {
    if (importing) {
      openDrawer();
    }
  }, [importing]);

  const cancelImport = () => {
    dispatch(setImporting(null));
  };

  const handleClose = () => {
    closeDrawer(cancelImport);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        maxHeight: `calc(100vh - ${headerHeight}px)`,
        position: 'relative',
        width: '100%',
      }}
    >
      {documentData && (
        <ClickMarketingEditor
          documentData={documentData}
          headerHeight={headerHeight}
          templateView={templateView}
        />
      )}
      <SideDrawer isOpen={isOpen} width={'80%'}>
        <ClickMarketingGallery
          handleClose={handleClose}
          modularType={importing}
          aspectRatio={aspectRatio}
        />
      </SideDrawer>
    </Box>
  );
}

ClickMarketingEditorDrawer.propTypes = {
  documentData: PropTypes.object.isRequired,
  headerHeight: PropTypes.number,
  templateView: PropTypes.bool,
};
