import React from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem } from '@mui/material';
import { ColorChip } from 'components/Common';
import {
  DocumentItemName,
  DocumentRowMenuWrapper,
  DocumentItemBox,
} from 'components/ModelViews/Documents/DocumentsList/styled';
import { OfferRowButton } from 'components/ModelViews/Transactions/OffersCard/components/OfferRow/components/OfferRowButton';
import { REPRESENTING_OPTIONS } from 'consts';
import { conversion, getColorByStatus } from 'helpers';

export const OfferRow = ({ offer }) => {
  const label = offer.transactionType.replace(' Offer', '');
  // const isActive = offer.transactionType === TRANSACTION_TYPES.ACTIVE_OFFER;

  return (
    <ListItem>
      <DocumentItemBox>
        <DocumentItemName className={'document-name'}>
          <Typography variant="body1" component={'span'}>
            {offer.representing === REPRESENTING_OPTIONS.SELLER.value
              ? conversion.getCustomersString(offer.rolesStr, 'Buyer')
              : offer.name}
          </Typography>
          <Typography variant="caption" component={'span'}>
            {` - ${conversion.getCustomersString(offer.roles, 'Cooperating Agent')}`}
          </Typography>
        </DocumentItemName>
        <ColorChip
          size={'small'}
          label={label}
          variant={'contained'}
          chipColor={getColorByStatus(offer.transactionType)}
          sx={{ ml: 1 }}
        />
        <DocumentRowMenuWrapper className={'document-row-menu'}>
          <OfferRowButton item={offer} />
        </DocumentRowMenuWrapper>
      </DocumentItemBox>
    </ListItem>
  );
};

OfferRow.propTypes = {
  offer: PropTypes.object.isRequired,
};
