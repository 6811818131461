import React from 'react';
import PropTypes from 'prop-types';
import { faFolder, faFolderOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIconComp } from 'components/Common';
import { transactionColors } from 'consts';

export const TransactionFolderIcon = ({ model, opened = false }) => {
  const iconColor = transactionColors[model.status];
  return (
    <FontAwesomeIconComp
      icon={opened ? faFolderOpen : faFolder}
      color={iconColor}
    />
  );
};

TransactionFolderIcon.propTypes = {
  model: PropTypes.object.isRequired,
  opened: PropTypes.bool,
};
