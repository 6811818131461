import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ConfirmMenuItem } from 'components/Common';
import { TRANSACTION_TYPES } from 'consts';
import {
  useUpdateTransactionTypeMutation,
  tasksAPI,
  documentsAPI,
  notesAPI,
} from 'redux/rtk-query';

export const AcceptOfferComponent = ({
  onBackToMenu,
  handleCloseMenu,
  item,
}) => {
  const [updateTransactionType] = useUpdateTransactionTypeMutation();
  const dispatch = useDispatch();

  const onConfirm = async () => {
    await updateTransactionType({
      id: item.id,
      transactionType: TRANSACTION_TYPES.ACTIVE_OFFER,
    });
    dispatch(tasksAPI.util.invalidateTags(['Tasks']));
    dispatch(documentsAPI.util.invalidateTags(['Documents']));
    dispatch(notesAPI.util.invalidateTags(['Notes']));
    handleCloseMenu(true);
  };

  return (
    <ConfirmMenuItem
      title="Accept Offer"
      message={
        'Are you sure you want to accept this offer? This will automatically reject all other offers'
      }
      onConfirm={onConfirm}
      onCancel={onBackToMenu}
      confirmText={'Confirm'}
      confirmColor={'error'}
      cancelText={'Go Back'}
    />
  );
};

AcceptOfferComponent.propTypes = {
  onBackToMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  item: PropTypes.object,
};
