import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const PasswordBox = styled(Box)({
  marginBottom: '40px',
  maxWidth: '464px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});
