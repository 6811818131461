import React from 'react';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

export const CustomBreadcrumbs = ({ path, onNavigate, onHomeClick }) => {
  const navigate = (id, index) => {
    onNavigate(id, index);
  };

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ padding: 1 }}
    >
      <Typography>
        <Link
          underline="hover"
          color="inherit"
          href="#/"
          fontWeight={path.length === 0 ? 600 : 400}
          onClick={(event) => {
            event.preventDefault();
            onHomeClick();
          }}
          sx={{ cursor: 'pointer' }}
        >
          Home
        </Link>
      </Typography>
      {path.map((item, index) => (
        <Box key={item.id} sx={{ display: 'flex', alignItems: 'center' }}>
          {index < path.length - 1 ? (
            <Link
              underline="hover"
              color="inherit"
              onClick={(event) => {
                event.preventDefault();
                navigate(item.id, index);
              }}
              sx={{ cursor: 'pointer' }}
            >
              {item.name}
            </Link>
          ) : (
            <Typography color={'inherit'} fontWeight={600}>
              {item.name}
            </Typography>
          )}
        </Box>
      ))}
    </Breadcrumbs>
  );
};

CustomBreadcrumbs.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onNavigate: PropTypes.func.isRequired,
  onHomeClick: PropTypes.func.isRequired,
};
