import React from 'react';
import { FormSpy } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { Contact, CurrencyInput, NumberInput, Select } from 'components/Fields';
import { FormCondition, FormField, Row } from 'components/Forms';
import { CONTACT_TYPES } from 'consts';

const YES_OR_NO = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];
const updateRoles = (values, setFieldValue) => {
  const referralAgent = values.referralAgent;
  let roles = values.roles || [];

  const referralAgentRoleIndex = roles.findIndex(
    (role) => role.role === 'Referral',
  );
  if (referralAgent && referralAgent.id) {
    if (referralAgentRoleIndex === -1) {
      // Add "Referral" role
      roles.push({
        role: 'Referral',
        contact: referralAgent,
        order: 1,
      });
    } else {
      // Update the existing "Referral" role
      roles[referralAgentRoleIndex].contact = referralAgent;
    }
  } else {
    if (referralAgentRoleIndex !== -1) {
      // Remove "Referral" role
      roles.splice(referralAgentRoleIndex, 1);
    }
  }

  setFieldValue('roles', roles);
};

export const TeamShareAndReferralPanel = () => {
  return (
    <FormCondition when="addressConfirmed" operator="equals" is={true}>
      <FormPanel label={'Referral'}>
        <Grid container>
          <Row>
            <FormField
              label="Outside Referral"
              name={'outsideReferral'}
              component={Select}
              options={YES_OR_NO}
              tooltip={{
                title:
                  'When paying a referral commission to another brokerage.',
              }}
            />
          </Row>
          <FormCondition when="outsideReferral" operator="equals" is={'Yes'}>
            <Row>
              <FormField
                label="Referral Agent"
                name={'referralAgent'}
                component={Contact}
                contactType={CONTACT_TYPES.COMPANY_REFERRAL_AGENT.value}
              />
            </Row>
            <Row>
              <FormField
                label="Referral Percentage"
                name={'referralCommission'}
                iconEnd={<>%</>}
                textAlign={'right'}
                component={NumberInput}
                numberType={'decimal'}
                min={0}
                max={100}
                size={6}
              />
              <FormField
                label="Referral Flat Rate"
                name={'referralFlatRate'}
                component={CurrencyInput}
                size={6}
              />
            </Row>
          </FormCondition>
        </Grid>
        <FormSpy subscription={{ values: true }}>
          {({ values, form }) => {
            React.useEffect(() => {
              updateRoles(values, form.change);
            }, [values.referralAgent]);

            return null;
          }}
        </FormSpy>
      </FormPanel>
    </FormCondition>
  );
};
