import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faRotateLeft, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Dialog, Grid, Typography } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common/';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import { FileDeleteIcon, FilePreviewBox, ThumbnailWrapper } from './styled';

export const FilePreview = ({
  file,
  removeFile,
  identifier,
  identifierValue,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  const fileType = file.mimetype
    ? file.mimetype.split('/')[1].toUpperCase()
    : file.type.split('/')[1].toUpperCase();
  return (
    <>
      <FilePreviewBox removed={file.removed}>
        <FileDeleteIcon color={'primary'} onClick={removeFile(file)}>
          <FontAwesomeIconComp
            icon={file.removed ? faRotateLeft : faTimesCircle}
            size="sm"
          />
        </FileDeleteIcon>
        <ThumbnailWrapper
          onClick={() => {
            setShowPreview(!showPreview);
          }}
        >
          <AbeFileImage
            file={file}
            identifierValue={identifierValue}
            identifier={identifier}
          />
        </ThumbnailWrapper>
        <Grid item xs={8} container>
          <Grid justifyItems={'flex-start'} item container direction="column">
            <Grid item style={{ margin: 10, display: 'grid' }}>
              <Typography
                alignSelf={'flex-end'}
                noWrap
                variant="body1"
                gutterBottom
              >
                {file.originalname ? file.originalname : file.name}
              </Typography>
              <Typography
                alignSelf={'flex-start'}
                color={'grey'}
                variant="body1"
              >
                {fileType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </FilePreviewBox>
      <Dialog
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
      >
        <div>
          <AbeFileImage
            file={file}
            identifierValue={identifierValue}
            identifier={identifier}
          />
        </div>
      </Dialog>
    </>
  );
};

FilePreview.propTypes = {
  file: PropTypes.object,
  removeFile: PropTypes.func,
  identifierValue: PropTypes.string,
  identifier: PropTypes.string,
};
