import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemButton, Typography } from '@mui/material';
import {
  getFontFamily,
  getFontWeight,
  getFontStyle,
} from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const FontOptionMenuItem = ({ fontFamilyGroup, fontName, font }) => {
  const dispatch = useDispatch();
  const item = useSelector(selectCurrentItem);

  const handleFontTypeChange = useCallback(() => {
    dispatch(
      updateItemCM({
        itemId: item.id,
        properties: {
          style: {
            ...item.style,
            fontFamily: fontName,
          },
        },
      }),
    );
  }, [dispatch, item, fontName]);

  if (!item) return null;

  const isSelected = item.style.fontFamily === fontName;

  return (
    <ListItemButton selected={isSelected} onClick={handleFontTypeChange}>
      <Typography
        style={{
          fontFamily: fontFamilyGroup
            ? fontFamilyGroup
            : getFontFamily(fontName, item.header),
          fontWeight: fontFamilyGroup ? getFontWeight(font) : null,
          fontStyle: getFontStyle(font),
        }}
      >
        {font}
      </Typography>
    </ListItemButton>
  );
};

export default React.memo(FontOptionMenuItem);

FontOptionMenuItem.propTypes = {
  fontFamilyGroup: PropTypes.string,
  fontName: PropTypes.string,
  font: PropTypes.string,
};
