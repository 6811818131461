// ColorPicker.js

import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { faRainbow, faBrush, faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactColor from 'react-color';
import { useSelector } from 'react-redux';
import { Button, Paper, Tooltip, Collapse } from '@mui/material';
import { Box } from '@mui/system'; // Assuming this component exists
import GradientPicker from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/GradientPicker';
import { ReactComponent as MIcon } from 'components/ModelViews/Documents/ClickMarketingViewer/assets/Micon.svg';
import { agentViewSelector } from 'redux/selectors'; // Adjust as needed

const getLuminance = (color) => {
  try {
    const c = color.substring(1);
    const rgb = parseInt(c, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    return 0.299 * r + 0.587 * g + 0.114 * b;
  } catch (e) {
    return 0;
  }
};

const ColorPicker = ({
  colors,
  color,
  defaultColor,
  onChange,
  showThemeColor = false,
  showMainframeColor = false,
  showTransparent = false,
  showCustomColor = false,
  showGradient = false,
  showExtraWhite = false,
  size = 45,
}) => {
  const agentView = useSelector(agentViewSelector);
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [showGradientPicker, setShowGradientPicker] = useState(false);

  const luminance = useMemo(() => getLuminance(color), [color]);
  const iconColor = luminance < 128 ? '#f0f0f0' : '#6c6868';

  const isCustomColorSelected = useMemo(
    () => color?.startsWith('#') && !colors.includes(color),
    [color, colors],
  );

  const customColorStyle = useMemo(
    () => ({
      border: isCustomColorSelected
        ? '3px solid #3f51b5'
        : '1px solid rgba(0,0,0,0.12)',
      height: size,
      width: size,
      minWidth: size,
      marginRight: '3px',
      backgroundColor: isCustomColorSelected ? color : '#D3D3D3',
    }),
    [isCustomColorSelected, size, color],
  );

  const handleColorChange = useCallback(
    (newColor) => {
      onChange(newColor);
    },
    [onChange],
  );

  return (
    <>
      {showGradientPicker ? (
        <GradientPicker
          styleProperty="background"
          onDelete={() => setShowGradientPicker(false)}
        />
      ) : (
        <>
          <Paper elevation={0}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& button': {
                  margin: 0.5,
                },
              }}
            >
              {/* Theme Color Button */}
              {showMainframeColor && (
                <Tooltip title="Mainframe Theme" arrow placement="top">
                  <Button
                    onClick={() => handleColorChange('mainframeTheme')}
                    style={{
                      padding: '8px',
                      border:
                        color === 'mainframeTheme'
                          ? '3px solid #3f51b5'
                          : '1px solid rgba(0,0,0,0.12)',
                      height: size,
                      width: size,
                      minWidth: size,
                      marginRight: '3px',
                      backgroundColor: '#fff',
                    }}
                  >
                    <MIcon />
                  </Button>
                </Tooltip>
              )}

              {/* Theme Color Button */}
              {showThemeColor && (
                <Tooltip title="Theme Color" arrow placement="top">
                  <Button
                    onClick={() => handleColorChange('customColor')}
                    style={{
                      border:
                        color === 'customColor'
                          ? '3px solid #3f51b5'
                          : '1px solid rgba(0,0,0,0.12)',
                      height: size,
                      width: size,
                      minWidth: size,
                      marginRight: '3px',
                      backgroundColor: '#fff',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBrush}
                      style={{ color: '#3f51b5' }}
                    />
                  </Button>
                </Tooltip>
              )}

              {/* Custom Color Button */}
              {showCustomColor && (
                <Tooltip title="Custom Color" arrow placement="top">
                  <Button
                    onClick={() => setShowColorPalette(!showColorPalette)}
                    style={customColorStyle}
                  >
                    <FontAwesomeIcon
                      icon={faRainbow}
                      style={{
                        color: isCustomColorSelected ? iconColor : '#6c6868',
                      }}
                    />
                  </Button>
                </Tooltip>
              )}

              {/* Transparent Button */}
              {showTransparent && (
                <Tooltip title="Transparent" arrow placement="top">
                  <Button
                    onClick={() => handleColorChange('transparent')}
                    style={{
                      border:
                        color === 'transparent'
                          ? '3px solid #3f51b5'
                          : '1px solid rgba(0,0,0,0.12)',
                      height: size,
                      width: size,
                      minWidth: size,
                      marginRight: '3px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <div className="checkered-background" />
                  </Button>
                </Tooltip>
              )}

              {/* Gradient Button */}
              {showGradient && (
                <Tooltip title="Gradient" arrow placement="top">
                  <Button
                    onClick={() => {
                      setShowGradientPicker(!showGradientPicker);
                      handleColorChange('gradient');
                    }}
                    style={{
                      background:
                        'linear-gradient(to bottom right, red, yellow)',
                      border:
                        color === 'gradient'
                          ? '3px solid #3f51b5'
                          : '1px solid rgba(0,0,0,0.12)',
                      height: size,
                      width: size,
                      minWidth: size,
                      marginRight: '3px',
                    }}
                  />
                </Tooltip>
              )}

              {/* Color Options */}
              {colors.map((colorOption) => (
                <Tooltip
                  key={colorOption}
                  title={colorOption}
                  arrow
                  placement="top"
                >
                  <Button
                    onClick={() => handleColorChange(colorOption)}
                    style={{
                      border:
                        color === colorOption
                          ? '3px solid #3f51b5'
                          : '1px solid rgba(0,0,0,0.12)',
                      height: size,
                      width: size,
                      minWidth: size,
                      marginRight: '3px',
                      backgroundColor: colorOption,
                    }}
                  >
                    {agentView &&
                      (color === colorOption ||
                        defaultColor === colorOption) && (
                        <FontAwesomeIcon
                          icon={
                            colorOption === defaultColor ? faStarSolid : faStar
                          }
                          color={
                            colorOption === defaultColor ? 'yellow' : '#ffffff'
                          }
                        />
                      )}
                  </Button>
                </Tooltip>
              ))}

              {/* Extra White Color */}
              {showExtraWhite && (
                <Tooltip title="White Color" arrow placement="top">
                  <Button
                    onClick={() => handleColorChange('#ffffff')}
                    style={{
                      border:
                        color === '#ffffff'
                          ? '3px solid #3f51b5'
                          : '1px solid rgba(0,0,0,0.12)',
                      height: size,
                      width: size,
                      minWidth: size,
                      marginRight: '3px',
                      backgroundColor: '#ffffff',
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          </Paper>

          {/* Custom Color Palette */}
          <Collapse in={showColorPalette} timeout="auto" unmountOnExit>
            <ReactColor
              width={200}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disableAlpha={true}
              color={color}
              onChange={handleColorChange}
              onChangeComplete={handleColorChange}
            />
          </Collapse>
        </>
      )}
    </>
  );
};

ColorPicker.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string.isRequired,
  defaultColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showMainframeColor: PropTypes.bool,
  showThemeColor: PropTypes.bool,
  showTransparent: PropTypes.bool,
  showCustomColor: PropTypes.bool,
  showGradient: PropTypes.bool,
  showExtraWhite: PropTypes.bool,
  size: PropTypes.number,
};

export default React.memo(ColorPicker);
