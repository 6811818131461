import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from 'consts/colors';

export const TextInputField = styled(TextField)({
  color: '#565656',
  '& [data-lastpass-icon-root]': {
    display: 'none',
  },
  '& label': {
    fontWeight: '500',
    color: '#565656',
    fontSize: '1.4rem',
    zIndex: 1,
  },
  '& div.MuiOutlinedInput-root': {
    color: '#565656',
    borderRadius: '8px',
    '& input': {
      borderRadius: '8px',
    },
  },
});

export const ExtraTextForInput = styled('span')({
  padding: '2px 10px',
  borderLeft: '1px solid lightgrey',
  margin: 6,
  paddingRight: 0,
  marginRight: 0,
  color: colors.BLACK,
});
