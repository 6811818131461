import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { axiosBaseQuery } from 'utils/baseQuery';
import { settingUrlBuilder } from './urlBuilder/settings';

export const settingsAPI = createApi({
  reducerPath: 'settingsAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Settings', 'Files'],
  endpoints: (build) => ({
    getSettingsList: build.query({
      query: (args) => ({
        url: settingUrlBuilder.getList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Settings'],
    }),
    createSetting: build.mutation({
      query: (body) => ({
        url: settingUrlBuilder.createSetting(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Settings'],
    }),
    updateSetting: build.mutation({
      query: (body) => ({
        url: settingUrlBuilder.createSetting(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Settings'],
    }),
    getSetting: build.query({
      query: (args) => ({
        url: settingUrlBuilder.getSetting(args),
        method: 'GET',
      }),
      providesTags: ['Settings'],
    }),
    getSettingMedia: build.query({
      query: (args) => ({
        url: settingUrlBuilder.getSettingMedia(args),
        method: 'GET',
      }),
      providesTags: ['Files'],
    }),
    getSettingMediaUrl: build.query({
      query: (body) => ({
        url: settingUrlBuilder.getSettingFile(body),
        method: 'GET',
      }),
      providesTags: ['Files'],
    }),
    settingFileUpload: build.mutation({
      query: ({ uuid, files, extraData }) => {
        for (const [key, value] of Object.entries(extraData)) {
          files.append(key, value);
        }

        return {
          url: settingUrlBuilder.settingFileUpload(uuid),
          method: 'POST',
          data: files,
        };
      },
      invalidatesTags: ['Files', 'Settings'],
    }),
  }),
});

export const {
  useGetSettingsListQuery,
  useCreateSettingMutation,
  useUpdateSettingMutation,
  useGetSettingQuery,
  useGetSettingMediaQuery,
  useGetSettingMediaUrlQuery,
  useSettingFileUploadMutation,
} = settingsAPI;
