import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Table } from 'components/Common';
import { ColumnManager } from 'components/Common/Table/components/ColumnManager';
import { isAdmin } from 'helpers';
import { useTableData } from 'hooks';
import { useGetProfileQuery, useGetTransactionsQuery } from 'redux/rtk-query';
import { selectedContactsSelector } from 'redux/selectors';
import { setSelectedContacts } from 'redux/slices';
import { Cells, MenuItems } from './config';

export const TransactionsTable = () => {
  const dispatch = useDispatch();
  let { listId } = useParams();

  const selected = useSelector(selectedContactsSelector);
  const { data: { data: { permissions } = false } = {} } = useGetProfileQuery();

  const {
    order,
    orderBy,
    filterValues,
    page,
    rowsPerPage,
    search,
    loading,
    setFilterValues,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    totalCount,
    rows,
    visibleColumns,
    resetColumns,
    toggleColumnVisibility,
  } = useTableData(useGetTransactionsQuery, Cells, 'updatedAt', 'desc', 10);

  useEffect(() => {
    if (listId) {
      setPage(1);
    }
  }, [listId]);

  const getClickHandler = (id) => () => {
    if (id === 'check') {
      const allRowIds = rows.map((row) => row.id);
      dispatch(setSelectedContacts(allRowIds));
    } else if (id === 'uncheck') {
      dispatch(setSelectedContacts([]));
    } else {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      dispatch(setSelectedContacts(newSelected));
    }
  };

  const showCheckbox = isAdmin(permissions) || parseInt(listId);

  return (
    <Box width={'100%'}>
      <Table
        handleSearch={setSearch}
        search={search}
        afterSearch={
          <ColumnManager
            columns={visibleColumns}
            setColumns={toggleColumnVisibility}
            resetColumns={resetColumns}
          />
        }
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={visibleColumns.filter((col) => col.isVisible)}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setSelected={showCheckbox ? getClickHandler : null}
        selected={selected}
        setFilterValues={setFilterValues}
        filterValues={filterValues}
      />
    </Box>
  );
};
