// src/hooks/useBackendLogin.js

import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useExchangeAuth0TokenMutation } from 'redux/rtk-query';
import { setUserLogin } from 'redux/slices';

/**
 * Automatically logs a user into your backend
 * once they're authenticated with Auth0.
 */
export function useBackendLogin() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [hasCalledBackend, setHasCalledBackend] = useState(false);

  const dispatch = useDispatch();
  const [exchangeToken, { data, isLoading, isSuccess, error }] =
    useExchangeAuth0TokenMutation();

  useEffect(() => {
    // Only call the backend once per session
    if (!isAuthenticated || hasCalledBackend) return;

    async function handleBackendLogin() {
      try {
        // 1) Get the Auth0 access token
        const auth0Token = await getAccessTokenSilently();

        // 2) Make the API call to your NestJS backend
        //    'unwrap()' returns the raw data or throws an error
        const response = await exchangeToken(auth0Token).unwrap();

        console.log('handleBackendLogin', response?.data);
        // 3) Store the backend token (and any extra user data) in Redux
        dispatch(setUserLogin(response?.data));
      } catch (err) {
        console.error('Error exchanging token with backend:', err);
      } finally {
        setHasCalledBackend(true);
      }
    }

    handleBackendLogin();
  }, [
    isAuthenticated,
    hasCalledBackend,
    getAccessTokenSilently,
    exchangeToken,
    dispatch,
  ]);

  return { data, isLoading, isSuccess, error };
}
