import { colors } from 'consts';

export const getRoleColor = (roleIndex) => {
  let output = '#ccc';
  let colorKeys = Object.keys(colors);
  if (typeof colors[colorKeys[roleIndex]] !== 'undefined') {
    output = colors[colorKeys[roleIndex]];
  }
  return output;
};

export const getRolesByType = (roles, roleType) => {
  if (!roles) return [];
  return roles.filter((role) => role.role === roleType);
};
