import React from 'react';
import { faIndent } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import { activeFieldsSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';

export function TextIndent() {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const selectedField = activeFields[0];

  return (
    <MenuItemButton
      label={'Text Indent'}
      icon={<FontAwesomeIconComp fontSize={18} icon={faIndent} />}
      rightAction={
        <TextField
          size="small"
          sx={{ width: '80px', p: 0 }}
          type="number"
          value={
            selectedField.textIndent ? parseFloat(selectedField.textIndent) : 0
          }
          inputProps={{ step: 1 }}
          onChange={(e) => {
            dispatch(
              updateField({
                properties: { textIndent: e.target.value },
              }),
            );
          }}
        />
      }
    />
  );
}
