import { useState, useEffect } from 'react';

export const useFileUpload = (useFileUploadMutation, useFileDeleteMutation) => {
  const [filesUploadData, setFilesUploadData] = useState({
    id: '',
    files: [],
    extraData: {},
  });
  const [fileUpload] = useFileUploadMutation();
  const [fileDelete] = useFileDeleteMutation();

  useEffect(() => {
    const removedFiles = filesUploadData.files.filter((file) => file.removed);
    removedFiles.forEach((removedFile) => {
      if (removedFile.uuid) {
        fileDelete({ id: removedFile.uuid });
      }
    });

    if (filesUploadData.files.length) {
      const formData = new FormData();
      filesUploadData.files.forEach((file) => {
        if (!file.id && !file.removed) {
          console.log('file', file);
          formData.append('files', file);
        }
      });

      console.log('formData', formData.has('files'));
      if (formData.has('files')) {
        fileUpload({
          uuid: filesUploadData.id,
          files: formData,
          extraData: filesUploadData.extraData,
        });
      }
    }
  }, [filesUploadData, fileDelete, fileUpload]);

  const handleFileUpload = (id, files, extraData = {}) => {
    setFilesUploadData({ id, files, extraData });
  };

  return { handleFileUpload };
};
