import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SideDrawer } from 'components/Common';
import { omitNull } from 'helpers';
import { useDrawer } from 'hooks';
import { useGetSettingQuery, useUpdateSettingMutation } from 'redux/rtk-query';
import { defaultValues } from '../SettingForm/defaultValues';
import { processFormData } from '../SettingForm/formData';
import { SettingForm } from '../SettingForm/SettingForm';

export const EditSettingPage = ({ params, path }) => {
  const paramName = Object.keys(params)[0];

  const [initialValues, setInitialValues] = useState({});
  const { isOpen, closeDrawer } = useDrawer(true, path);

  const [updateSetting] = useUpdateSettingMutation();
  const { data: settingData, isSuccess } = useGetSettingQuery({
    id: params[paramName],
  });

  useEffect(() => {
    if (isSuccess) {
      let data = { ...settingData.data };
      data.agent = data.modelAccess?.owner;
      data.shareWithOption;
      data.shareWith = data.modelAccess?.sharedWithUsers;
      data.companyStructureUnits = data.modelAccess?.sharedWithUnits;
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull(data),
      });
    }
  }, [isSuccess]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    const setting = await updateSetting(formData);
    return setting;
  };

  const afterSave = () => {
    closeDrawer();
  };

  return (
    <SideDrawer width={'65%'} isOpen={isOpen}>
      <SettingForm
        panelEdit={params['panelEdit']}
        title={'Edit Setting'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditSettingPage.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
};
