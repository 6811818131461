import React from 'react';
import PropTypes from 'prop-types';
import { faEdit, faHeart } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { EDIT_DOCUMENT, colors } from 'consts';
import { useOpenDrawer } from 'hooks';
import { useCreateDocumentMutation } from 'redux/rtk-query';
import { currentDocumentSelectorCM } from 'redux/selectors';

const PreviewToolbar = ({ onClose }) => {
  const currentDocument = useSelector(currentDocumentSelectorCM);
  const [createDocument] = useCreateDocumentMutation();
  const openDrawer = useOpenDrawer();
  let { transactionDetailsId } = useParams();
  const routeParams = useParams();

  const toggleHeart = () => {};

  const createClickMarketing = async () => {
    await createDocument({
      name: currentDocument.name,
      status: 'Draft',
      fileType: 'Click Marketing',
      // htmlTemplate: currentDocument.htmlTemplate,
      // itemGroups: currentDocument.itemGroups,
      documentTemplateId: currentDocument.id,
      transactionId: transactionDetailsId
        ? parseInt(transactionDetailsId)
        : null,
    }).then((res) => {
      if (res.data) {
        onClose();
        openDrawer(EDIT_DOCUMENT(res.data?.data?.id), routeParams['*']);
      }
    });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        justifyContent={'space-between'}
      >
        <Typography variant="h4">{currentDocument.name}</Typography>
        <Box display="flex" gap={1}>
          <IconButton
            sx={{
              border: `1px ${colors.LIGHT_BLACK} solid`,
            }}
            onClick={createClickMarketing}
          >
            <FontAwesomeIcon icon={faEdit} size={'1x'} />
          </IconButton>
          <IconButton
            sx={{
              border: `1px ${colors.LIGHT_BLACK} solid`,
            }}
            onClick={toggleHeart}
          >
            <FontAwesomeIcon icon={faHeart} size={'1x'} />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default PreviewToolbar;

PreviewToolbar.propTypes = {
  onClose: PropTypes.func,
};
