import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { MenuItemButton } from 'components/Common';
import { RoleHeadIcon } from 'components/Icons';
import { iconAndLabel } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/field';
import { getRoleAndOrderFromRoleName } from 'components/ModelViews/Documents/DocumentPdfViewer/utils/roles';
import { activeFieldsSelector } from 'redux/selectors';
import {
  DeleteField,
  DuplicateOnAllPages,
  Required,
  RoleAssignment,
} from './menuItems';

export const SignatureToolbar = () => {
  const activeFields = useSelector(activeFieldsSelector);

  const { role, order } = getRoleAndOrderFromRoleName(activeFields[0].role);

  const label = `Assigned to: ${role} ${order}`;

  return (
    <>
      <MenuItemButton
        icon={iconAndLabel[activeFields[0].type].icon}
        label={iconAndLabel[activeFields[0].type].label}
        isSelected
      />
      <Box mt={1}>
        <MenuItemButton
          isSelected
          label={label}
          icon={
            <RoleHeadIcon
              solid
              role={{
                role: role,
                order: order,
              }}
            />
          }
        />
        <Box p={2}>
          <RoleAssignment />
        </Box>
        <DuplicateOnAllPages />
        <Required />
        <DeleteField />
      </Box>
    </>
  );
};
