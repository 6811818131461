import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { SideDrawer, SkeletonSummary } from 'components/Common';
import {
  ButtonSection,
  Header,
  HeadingSection,
  ListBox,
} from 'components/Styled';
import {
  useAddToListMutation,
  useGetContactListsQuery,
  useRemoveFromListMutation,
} from 'redux/rtk-query';
import { currentListContactsSelector } from 'redux/selectors';
import { setCurrentListContacts } from 'redux/slices';
import { CreateNewListInput, ListAndFilter } from './components';
import { AddingNewListBox, AddListLink } from './styled';

export const AddToListModal = ({ closeHandler, open, contactId, uuid }) => {
  const [showListInput, setShowListInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const { data, isSuccess, isFetching } = useGetContactListsQuery(
    { uuid: uuid },
    {
      refetchOnMountOrArgChange: true,
      skip: !contactId,
    },
  );
  const [checkedLists, setCheckedLists] = useState([]);
  const [addToList] = useAddToListMutation();
  const [removeFromList] = useRemoveFromListMutation();
  const currentListContacts = useSelector((state) =>
    currentListContactsSelector(state),
  );

  const dispatch = useDispatch();

  const addToListHandler = useCallback(() => {
    addToList({ listsIds: checkedLists, contactsIds: [contactId] });
    const currentListIds = data.data.items.map((list) => list.id);
    currentListIds.map((currentListId) => {
      if (!checkedLists.includes(currentListId)) {
        removeFromList({ listId: currentListId, contactsIds: [contactId] });
      }
    });
    let currentListContactsCopy = [...currentListContacts];
    let objIndex = currentListContactsCopy.findIndex(
      (obj) => obj.id === contactId,
    );
    let objCopy = { ...currentListContactsCopy[objIndex] };
    objCopy.lists = checkedLists.length;
    currentListContactsCopy[objIndex] = objCopy;
    dispatch(setCurrentListContacts(currentListContactsCopy));
    closeHandler(false);
  }, [checkedLists, data]);

  const checkListHandler = (id) => (newId) => {
    const addId = id ? id : newId;
    const selectedIndex = checkedLists.indexOf(addId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(checkedLists, addId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(checkedLists.slice(1));
    } else if (selectedIndex === checkedLists.length - 1) {
      newSelected = newSelected.concat(checkedLists.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        checkedLists.slice(0, selectedIndex),
        checkedLists.slice(selectedIndex + 1),
      );
    }
    setCheckedLists(newSelected);
  };

  useEffect(() => {
    if (isFetching) {
      setLoading(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess) {
      const currentListIds = data.data.items.map((list) => list.id);
      setCheckedLists(currentListIds);
      setLoading(false);
    }
  }, [isSuccess, data]);

  return (
    <SideDrawer isOpen={open}>
      <HeadingSection>
        <div>
          <Header variant="h4">Manage Lists</Header>
        </div>
        <ButtonSection>
          <Button variant="outlined" onClick={() => closeHandler(false)}>
            Cancel
          </Button>
          <Button onClick={addToListHandler} variant="contained">
            Update
          </Button>
        </ButtonSection>
      </HeadingSection>

      <ListBox>
        {loading ? (
          <SkeletonSummary />
        ) : (
          <ListAndFilter
            checkedLists={checkedLists}
            checkListHandler={checkListHandler}
          />
        )}
        <AddingNewListBox>
          {showListInput ? (
            <CreateNewListInput
              checkedLists={checkedLists}
              setCheckedLists={setCheckedLists}
              showListHandler={setShowListInput}
            />
          ) : (
            <AddListLink onClick={() => setShowListInput(true)}>
              Create new list +
            </AddListLink>
          )}
        </AddingNewListBox>
      </ListBox>
    </SideDrawer>
  );
};

AddToListModal.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  contactId: PropTypes.number,
  uuid: PropTypes.string,
};
