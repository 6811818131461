import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const TaskBox = styled(Box)(({ iscompleted }) => ({
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'space-between',
  '& span': {
    textDecoration: iscompleted === 'true' ? 'line-through' : '',
  },
}));
