import { Status } from 'components/Common/Table/components';

export const Cells = [
  {
    id: 'name',
    value: 'name',
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'username',
    value: 'username',
    disablePadding: false,
    label: 'Username',
  },
  {
    id: 'status',
    value: 'status',
    disablePadding: false,
    label: 'Status',
    Wrapper: Status,
  },
];
