import React from 'react';
import { faFontCase, faBorderStyle } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { CollapsableMenuGroup, FontAwesomeIconComp } from 'components/Common';
import AutofillMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/AutofillMenuItem';
import ColorPickerMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/ColorPickerMenuItem';
import FontFamilyMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/FontFamilyMenuItem';
import FontSizeMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/FontSizeMenuItem';
import { HeaderMenuItem } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/HeaderMenuItem';
import OptionsMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/OptionsMenuItem';
import { SingleOrMultiMenuItem } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/SingleOrMultiMenuItem';
import TextAlignMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/TextAlignMenuItem';
import { TextLabelMenuItem } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/TextLabelMenuItem';
import { CM_ITEM_TYPES } from 'consts';
import { selectCurrentItem } from 'redux/selectors';

export const TextToolbar = () => {
  const activeField = useSelector(selectCurrentItem);
  return (
    <>
      <CollapsableMenuGroup
        isSelected
        icon={<FontAwesomeIconComp icon={faFontCase} fontSize={18} />}
        label={'Text Properties'}
      >
        {activeField.type === CM_ITEM_TYPES.text.autofill && (
          <AutofillMenuItem />
        )}
        <TextLabelMenuItem />
        <HeaderMenuItem />
        <SingleOrMultiMenuItem />
        <ColorPickerMenuItem
          showThemeColor
          showCustomColor
          showExtraWhite
          styleProperty={'color'}
          label={'Color'}
          icon={faBorderStyle}
        />
        <FontFamilyMenuItem />
        <FontSizeMenuItem />
        <TextAlignMenuItem />
      </CollapsableMenuGroup>
      <OptionsMenuItem />
    </>
  );
};
