import React from 'react';
import PropTypes from 'prop-types';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { Rating } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';

export const VendorRating = ({ score }) => {
  const hotIcons = {
    1: {
      icon: <FontAwesomeIconComp icon={faStar} />,
      label: 'Bad',
    },
    2: {
      icon: <FontAwesomeIconComp icon={faStar} />,
      label: 'Okay',
    },
    3: {
      icon: <FontAwesomeIconComp icon={faStar} />,
      label: 'Great',
    },
    4: {
      icon: <FontAwesomeIconComp icon={faStar} />,
      label: 'Amazing',
    },
    5: {
      icon: <FontAwesomeIconComp icon={faStar} />,
      label: 'Exceptional',
    },
  };

  function IconContainerHot(props) {
    const { value, ...other } = props;
    return <span {...other}>{hotIcons[value].icon}</span>;
  }

  IconContainerHot.propTypes = {
    value: PropTypes.number.isRequired,
  };

  return (
    <span>
      <Rating
        IconContainerComponent={IconContainerHot}
        getLabelText={(value) => hotIcons[value].label}
        sx={{
          fontSize: 16,
          marginRight: -1,
          '& label > .MuiRating-icon': { paddingRight: 1 },
        }}
        name="customized-color"
        defaultValue={score}
        precision={1}
        max={5}
      />
    </span>
  );
};

VendorRating.propTypes = {
  score: PropTypes.string,
};
