import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  faExclamationCircle,
  faSignature,
  faTimes,
  faUndo,
} from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { RoleHeadIcon } from 'components/Icons';
import { getCombinedRoles } from 'components/ModelViews/Documents/DocumentPdfViewer/hooks/getCombinedRoles';
import {
  getRoleLabel,
  partyTypeCount,
  sortRoles,
} from 'components/ModelViews/Documents/DocumentPdfViewer/utils/roles';
import { conversion } from 'helpers';
import { activeMenuSelector } from 'redux/selectors';
import { updateRoleParty } from 'redux/slices';

const PartyHeader = ({
  type,
  setCollapseList,
  collapseList,
  partiesLength,
}) => {
  const roleTypes = {
    cc: {
      title: 'CC Roles',
      tooltip: 'CC Roles will receive a copy of the document once it is signed',
    },
    uploader: {
      title: 'Uploader Roles',
      tooltip: null,
    },
    inactive: {
      title: 'Inactive Roles',
      tooltip: 'Inactive Roles have no affiliation with this document',
    },
  };

  let { title = 'Signer Roles', tooltip = null } = roleTypes[type] || {};

  return (
    <ListSubheader
      component="div"
      style={{
        backgroundColor: '#f7fbfd',
        borderTop: '1px solid #e6e7e8',
        borderBottom: '1px solid #e6e7e8',
      }}
      onClick={() => {
        if (type === 'inactive') {
          setCollapseList(!collapseList);
        }
      }}
    >
      <Grid container>
        <Grid item xs={6} style={{ lineHeight: '40px' }}>
          <Tooltip
            arrow
            title={tooltip}
            disableHoverListener={tooltip === null}
          >
            <span>
              <Typography
                component={'span'}
                variant={'body1'}
                style={{ marginRight: '6px' }}
              >
                {title}
              </Typography>
              <Badge
                sx={{
                  ml: 1,
                  mt: -0.5,
                  '& .MuiBadge-badge': {
                    color: '#fff',
                  },
                }}
                badgeContent={partiesLength}
                color="primary"
              ></Badge>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </ListSubheader>
  );
};

PartyHeader.propTypes = {
  type: PropTypes.string,
  setCollapseList: PropTypes.func,
  collapseList: PropTypes.bool,
  partiesLength: PropTypes.number,
};

const PartyName = ({ party }) => {
  const name = conversion.getName(party.contact);
  const roleLabel = getRoleLabel(party);
  const removingMessage = party.removing ? (
    <div style={{ color: '#c9232d', fontSize: '12px' }}>
      <FontAwesomeIconComp
        icon={faExclamationCircle}
        style={{ marginRight: '5px', fontSize: '10px', fontWeight: 200 }}
      />
      Fields will be removed upon save
    </div>
  ) : null;

  return (
    <ListItemText
      primary={name}
      secondary={
        <>
          <Typography
            variant="body2"
            noWrap
            style={{ color: 'rgb(51, 51, 51)' }}
          >
            {roleLabel}
          </Typography>
          {removingMessage}
        </>
      }
    />
  );
};

PartyName.propTypes = {
  party: PropTypes.object,
};

const PartyButtons = ({ type, party }) => {
  const dispatch = useDispatch();
  const roles = getCombinedRoles() || [];
  const activeMenu = useSelector(activeMenuSelector);

  const showUploadButton = partyTypeCount('signer', roles) === 0;
  const showSignerButton =
    partyTypeCount('uploader', roles) === 0 &&
    activeMenu !== 'finalizeDocument';
  let showRemoveButton = true;
  if (type === 'signer' && activeMenu === 'finalizeDocument') {
    showRemoveButton = false;
  }

  //TODO update roles with dispatch(setDocumentRoles(roles))
  const handleRoleChange = (party, partyType) => {
    let newParty = { ...party, type: partyType };
    dispatch(updateRoleParty({ party: newParty }));
  };

  const handleRoleRemoving = (party) => {
    let newParty = { ...party, removing: !party.removing };
    dispatch(updateRoleParty({ party: newParty }));
  };

  return (
    <ListItemSecondaryAction>
      {type === 'inactive' ? (
        <>
          <Button
            size="small"
            sx={{ minWidth: '20px', marginRight: '4px', padding: '0px 5px' }}
            variant="outlined"
            onClick={() => {
              handleRoleChange(party, 'cc');
            }}
          >
            CC
          </Button>
          {showSignerButton && (
            <>
              <Button
                size="small"
                sx={{
                  minWidth: '20px',
                  marginRight: '4px',
                  padding: '0px 5px',
                }}
                variant="outlined"
                onClick={() => {
                  handleRoleChange(party, 'signer');
                }}
              >
                <span>
                  <FontAwesomeIconComp icon={faSignature} />
                </span>
              </Button>
            </>
          )}
          {showUploadButton && (
            <>
              <Button
                size="small"
                sx={{
                  minWidth: '20px',
                  marginRight: '4px',
                  padding: '0px 5px',
                }}
                variant="outlined"
                onClick={() => {
                  handleRoleChange(party, 'uploader');
                }}
              >
                <span>
                  <i
                    className="fa fa-upload"
                    style={{
                      fontWeight: '200px',
                      fontSize: '12px',
                    }}
                  ></i>
                </span>
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          {showRemoveButton && (
            <>
              <IconButton
                onClick={() => {
                  if (type === 'cc') {
                    handleRoleChange(party, 'inactive');
                  } else {
                    handleRoleRemoving(party);
                  }
                }}
              >
                {party.removing ? (
                  <FontAwesomeIconComp
                    icon={faUndo}
                    fontSize="16px"
                    style={{ color: '#c9232f' }}
                  />
                ) : (
                  <FontAwesomeIconComp
                    icon={faTimes}
                    fontSize="12px"
                    style={{ color: '#c9232f' }}
                  />
                )}
              </IconButton>
            </>
          )}
        </>
      )}
    </ListItemSecondaryAction>
  );
};

PartyButtons.propTypes = {
  type: PropTypes.string,
  party: PropTypes.object,
  onRoleChange: PropTypes.func,
};

export const PartiesList = (props) => {
  let [collapseList, setCollapseList] = useState(
    props.collapseList ? props.collapseList : false,
  );
  const roles = getCombinedRoles() || [];

  let type = props.type;

  const parties = useMemo(() => {
    const filteredRoles = roles.filter((role) => role.type === type);
    return sortRoles(filteredRoles);
  }, [roles, type]);

  return (
    <>
      <List
        subheader={
          <PartyHeader
            type={type}
            setCollapseList={setCollapseList}
            collapseList={collapseList}
            partiesLength={parties.length}
          />
        }
      >
        <>
          {(collapseList !== true || props.collapseList === false) &&
            parties.map((party, index) => {
              let key = `party-${index}`;
              return (
                <ListItemButton style={{ padding: '0 15px' }} key={key}>
                  <ListItemIcon sx={{ minWidth: '35px' }}>
                    <RoleHeadIcon style={{ padding: '2px 4px' }} role={party} />
                  </ListItemIcon>
                  <PartyName party={party} />
                  <PartyButtons type={type} party={party} />
                </ListItemButton>
              );
            })}
        </>
      </List>
    </>
  );
};

PartiesList.propTypes = {
  collapseList: PropTypes.bool,
  model: PropTypes.object,
  onRoleChange: PropTypes.func,
  roles: PropTypes.object,
  toolbarType: PropTypes.string,
  type: PropTypes.string,
};
