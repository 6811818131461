import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faTrash, faTrashUndo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import DesignItems from 'components/ModelViews/Documents/ClickMarketingViewer/DesignItems';
import {
  pixelSizeSelector,
  copyItemToPageSelector,
  pageObjectById,
  activePageCM,
} from 'redux/selectors';
import { setActivePageCM, copyItemToPage } from 'redux/slices';

export const PREVIEW_SCALE = 0.2;

const PageTab = ({ pageId }) => {
  const dispatch = useDispatch();
  const activePageId = useSelector(activePageCM);
  const pageTabObject = useSelector(pageObjectById(pageId));
  const copyToPage = useSelector(copyItemToPageSelector);
  const [width, height] = useSelector(pixelSizeSelector);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const boxRef = useRef(null);

  const pageWidth = width * PREVIEW_SCALE;
  const pageHeight = height * PREVIEW_SCALE;

  const handleClick = useCallback(() => {
    if (copyToPage.item && !copyToPage.page) {
      dispatch(
        copyItemToPage({
          item: copyToPage.item,
          page: pageId,
        }),
      );
    } else {
      dispatch(setActivePageCM(pageId));
    }
  }, [dispatch, pageId, copyToPage]);

  useEffect(() => {
    const observerCallback = ([entry]) => {
      if (entry.isIntersecting) {
        if (!isVisible) setIsVisible(true);
        if (!isLoaded) setIsLoaded(true);
      } else {
        if (isVisible) setIsVisible(false);
      }
    };

    const observerOptions = {
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, [isVisible, isLoaded]);

  return (
    <Grid
      py={1}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      item
    >
      <Box
        onClick={handleClick}
        sx={{
          position: 'relative',
          width: `${pageWidth}px`,
          height: `${pageHeight}px`,
          boxShadow: activePageId === pageId ? '0 0 0 4px #097CC7' : 'none',
          overflow: 'hidden',
          cursor: 'pointer',

          /* Fade/slide in from the left on hover */
          '&:hover .toolbar-buttons': {
            opacity: 1,
            transform: 'translateX(0)',
            pointerEvents: 'auto',
          },
        }}
      >
        {/* The Toolbar */}
        <Box
          className="toolbar-buttons"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1, // space between icons
            padding: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 100,
            opacity: 0,
            transform: 'translateX(-100%)',
            pointerEvents: 'none',
            transition: 'opacity 0.3s ease, transform 0.3s ease',
          }}
        >
          {!pageTabObject.hidden ? (
            <>
              <IconButton>
                <FontAwesomeIcon color="white" icon={faCopy} />
              </IconButton>
              <IconButton>
                <FontAwesomeIcon color="white" icon={faTrash} />
              </IconButton>
            </>
          ) : (
            <IconButton>
              <FontAwesomeIcon color="white" icon={faTrashUndo} />
            </IconButton>
          )}
        </Box>

        {/* The Page Preview */}
        <Box
          ref={boxRef}
          sx={{
            opacity: pageTabObject.hidden ? 0.5 : 1,
            width: '100%',
            height: '100%',
            background: 'white',
            pointerEvents: 'none',
          }}
        >
          {(isVisible || isLoaded) && (
            <Box sx={{ transform: 'scale(0.2)', transformOrigin: 'left' }}>
              <DesignItems pageId={pageId} preview scale={PREVIEW_SCALE} />
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

PageTab.propTypes = {
  pageId: PropTypes.string.isRequired,
};

export default React.memo(PageTab);
