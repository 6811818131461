import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

export const CommentCount = ({ value }) => {
  return (
    <>
      <Button style={{ minWidth: 20 }} fullWidth size={'small'}>
        {value}
      </Button>
    </>
  );
};

CommentCount.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    tasks: PropTypes.number,
  }),
  id: PropTypes.number,
};
