import React from 'react';
import { Outlet } from 'react-router-dom';
import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { TEMPLATE_MANAGER } from 'consts';
import { DocumentsTable } from './components';

export const DocumentsPage = () => {
  return (
    <>
      <LayoutHeader title={'Documents'}>
        <CreateButton
          path={`/${TEMPLATE_MANAGER('documentTemplates', 'home')}`}
          title={'Template Manager'}
          icon={null}
        />
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <DocumentsTable />
      </PageMainContent>
      <Outlet />
    </>
  );
};
