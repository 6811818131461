export const CONTACT_TYPES = {
  LEAD: {
    value: 'Lead',
    label: 'Lead',
  },
  CUSTOMER: {
    value: 'Customer',
    label: 'Customer',
  },
  COOPERATING_AGENT: {
    value: 'Cooperating Agent',
    label: 'Cooperating Agent',
  },
  BROKERAGE: {
    value: 'Brokerage',
    label: 'Brokerage',
  },
  CLOSING_ESCROW_AGENT: {
    value: 'Closing/Escrow Agent',
    label: 'Closing/Escrow Agent',
  },
  LENDER: {
    value: 'Lender',
    label: 'Lender',
  },
  VENDOR: {
    value: 'Vendor',
    label: 'Vendor',
  },
  COMPANY_AGENT: {
    value: 'Company Agent',
    label: 'Company Agent',
  },
  COMPANY_ADMIN: {
    value: 'Company Admin',
    label: 'Company Admin',
  },
  COMPANY_REFERRAL_AGENT: {
    value: 'Company Referral Agent',
    label: 'Company Referral Agent',
  },
};

export const SOURCES = {
  COLD_CALLING: 'Cold Calling',
  DIRECT_MAILER: 'Direct Mailer',
  EMAIL_CAMPAIGN: 'Email Campaign',
  INTERNET: 'Internet',
  NETWORKING: 'Networking',
  OFFICE_CALL: 'Office Call',
  OFFICE_REFERRAL: 'Office Referral',
  OPEN_HOUSE: 'Open House',
  PAST_CUSTOMER: 'Past Customer',
  REFERRAL: 'Referral',
  RELOCATION: 'Relocation',
  SIGN_CALL: 'Sign Call',
  SPHERE_OF_INFLUENCE: 'Sphere of Influence',
  WALK_IN: 'Walk In',
  WORD_OF_MOUTH: 'Word of Mouth',
};

export const INTERNET_SOURCES = [
  {
    value: 'Realtor.com',
    label: 'Realtor.com',
  },
  {
    value: 'Zillow',
    label: 'Zillow',
  },
  {
    value: 'Trulia',
    label: 'Trulia',
  },
  {
    value: 'Company Website',
    label: 'Company Website',
  },
  {
    value: 'Agent Website',
    label: 'Agent Website',
  },
  {
    value: 'Instagram',
    label: 'Instagram',
  },
  {
    value: 'Facebook',
    label: 'Facebook',
  },
  {
    value: 'Twitter',
    label: 'Twitter',
  },
  {
    value: 'HotPads',
    label: 'HotPads',
  },
  {
    value: 'Pinterest',
    label: 'Pinterest',
  },
  {
    value: 'Postlets',
    label: 'Postlets',
  },
  {
    value: 'Craigslist',
    label: 'Craigslist',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const PREFERRED_COMMUNICATIONS = [
  {
    value: 'Phone Calls',
    label: 'Phone calls',
  },
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'Text',
    label: 'Text',
  },
  {
    value: 'Social Media',
    label: 'Social Media',
  },
];

export const RELATIONSHIP_TYPES = [
  {
    value: 'WIFE_HUSBAND',
    label: 'Wife / Husband',
  },
  {
    value: 'MOTHER_DAUGHTER',
    label: 'Mother / Daughter',
  },
  {
    value: 'MOTHER_SON',
    label: 'Mother / Son',
  },
  {
    value: 'FATHER_DAUGHTER',
    label: 'Father / Daughter',
  },
  {
    value: 'FATHER_SON',
    label: 'Father / Son',
  },
  {
    value: 'BROTHER_SISTER',
    label: 'Brother / Sister',
  },
  {
    value: 'BUSINESS_PARTNER',
    label: 'Business Partner / Business Partner',
  },
  {
    value: 'PARTNER',
    label: 'Partner / Partner',
  },
];

export const CONTACT_STATUSES = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
  {
    value: 'Pre-Hire',
    label: 'Pre-Hire',
  },
];

export const VENDORS_APPROVED = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
  {
    value: 'Pending',
    label: 'Pending',
  },
  {
    value: 'Rejected',
    label: 'Rejected',
  },
];

export const VENDORS_1099 = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
];

export const AllListsName = {
  name: 'All Contacts',
  uuid: 'All Contacts',
  id: null,
  type: 'contact',
};
export const AllCustomersName = {
  name: 'Customers',
  uuid: 'Customers',
  id: null,
  type: CONTACT_TYPES.CUSTOMER.value,
};
export const AllLeadsName = {
  name: 'Leads',
  uuid: 'Leads',
  id: null,
  type: CONTACT_TYPES.LEAD.value,
};
