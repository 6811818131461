import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { TextInputField } from 'components/Styled';

export const CommissionLevel = ({ level, onUpdate, onDelete }) => {
  const handleDelete = () => {
    onDelete(level.id);
  };

  const handleChange = (field, value) => {
    onUpdate(level.id, field, value);
  };

  return (
    <Box display={'flex'} gap={2} alignItems={'center'}>
      <TextInputField
        label="Currency"
        size={'small'}
        value={level.amount}
        onChange={(e) => handleChange('amount', e.target.value)}
        fullWidth
      />
      <TextInputField
        label="Number"
        size={'small'}
        value={level.percentage}
        onChange={(e) => handleChange('percentage', e.target.value)}
        fullWidth
      />
      <Button
        size={'small'}
        variant="outlined"
        color="error"
        onClick={handleDelete}
      >
        Delete
      </Button>
    </Box>
  );
};

CommissionLevel.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
