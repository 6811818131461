import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { activeFieldIdsSelector, activeMenuSelector } from 'redux/selectors';
import {
  ActiveFieldMenu,
  FieldManagerMenu,
  PrimaryMenu,
  RolesMenu,
} from './components';

export const DocumentEditorMenu = ({ headerHeight }) => {
  const activeMenu = useSelector(activeMenuSelector);
  const activeFieldIds = useSelector(activeFieldIdsSelector);

  return (
    <Box
      sx={{
        width: '300px', // width of the menu
        background: 'white', // background color of the menu
        height: `calc(100vh - ${headerHeight}px)`,
        overflowY: 'scroll',
      }}
    >
      {activeFieldIds.length === 0 ? (
        <>
          {activeMenu === 'primary' && <PrimaryMenu />}
          {(activeMenu === 'roles' || activeMenu === 'finalizeDocument') && (
            <RolesMenu />
          )}
          {activeMenu === 'fieldManager' && <FieldManagerMenu />}
        </>
      ) : (
        <ActiveFieldMenu />
      )}
    </Box>
  );
};

DocumentEditorMenu.propTypes = {
  headerHeight: PropTypes.number,
};
