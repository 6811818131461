import React from 'react';
import {
  faCalendar,
  faCheckSquare,
  faICursor,
  faSignature,
  faStrikethrough,
  faUserTag,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { conversion } from 'helpers';
import { updateField } from 'redux/slices';
import { ScaleText } from '../components/DocumentItem/components/ScaleText';

export const iconAndLabel = {
  signatureField: {
    label: 'Signature',
    icon: (
      <FontAwesomeIcon
        icon={faSignature}
        style={{
          border: '1px solid',
          borderRadius: '3px',
          padding: '1px 10px',
        }}
      />
    ),
  },
  signatureInitialField: {
    label: 'Initial',
    icon: (
      <FontAwesomeIcon
        icon={faSignature}
        style={{
          border: '1px solid',
          borderRadius: '3px',
          padding: '1px',
        }}
      />
    ),
  },
  printName: {
    label: 'Print Name',
    icon: <FontAwesomeIcon fontSize={18} icon={faUserTag} />,
  },
  signDate: {
    label: 'Auto-Image',
    icon: (
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon
          fontSize={'11px'}
          style={{ bottom: -7, position: 'absolute' }}
          icon={faSignature}
          size="xs"
        />
        <FontAwesomeIcon icon={faCalendar} size="lg" />
      </span>
    ),
  },
  datePicker: {
    label: 'Date',
    icon: <FontAwesomeIcon fontSize={18} icon={faCalendar} />,
  },
  text: {
    label: 'Text',
    icon: <FontAwesomeIcon fontSize={18} icon={faICursor} />,
  },
  checkbox: {
    label: 'Checkbox',
    icon: <FontAwesomeIcon fontSize={18} icon={faCheckSquare} />,
  },
  strikethrough: {
    label: 'Strikethrough',
    icon: <FontAwesomeIcon icon={faStrikethrough} />,
  },
};

export const isFillNow = (item) => {
  if (
    item.autoFill &&
    item.autoFill.length > 0 &&
    item.autoFill !== 'assignNone'
  ) {
    return false;
  }
  return !(item.role && item.role.length > 0);
};

export const isAutoFill = (field) => {
  return !!(
    field.autoFill &&
    field.autoFill.length > 0 &&
    field.autoFill !== 'assignNone'
  );
};

export const findRole = (documentRoles, field) => {
  return documentRoles.find((role) => {
    let realRole = role.role === 'Cooperating Agent' ? 'Coop Agent' : role.role;
    let camelCaseRole = conversion.wordsToCamelCase(realRole);
    let expectedRoleName = camelCaseRole + role.order;
    return (
      field.role === expectedRoleName ||
      (role.order === 1 && field.role === camelCaseRole)
    );
  });
};

export const getSignatureByRole = (field, documentRoles) => {
  // let width  = parseFloat(field.width.replace('px', ''))

  let height = parseFloat(field.height.replace('px', ''));
  const role = findRole(documentRoles, field);
  if (role) {
    if (field.value === 'signed') {
      let maxFontSize = height;
      let signatureInfo =
        field.type === 'signatureField'
          ? role.signatureString[0]['textSig']
          : role.initialString[0]['textSig'];
      let signatureString = signatureInfo['sigTextString'];
      let fontFamily =
        signatureInfo['fontFamily'] === 'Pacifico'
          ? 'Cookie'
          : signatureInfo['fontFamily'];

      return (
        <div
          style={{
            color: '#145394',
            whiteSpace: 'nowrap',
            zIndex: '9999999',
            height: '100%',
            width: '100%',
            lineHeight:
              fontFamily === 'Open Sans' ? maxFontSize + 'px' : height + 'px',
            position: 'absolute',
            pointerEvents: 'none',
            textAlign: 'center',
            fontFamily: fontFamily,
            top: 0,
            left: 0,
            bottom: 5,
            margin: 0,
            padding: 0,
          }}
        >
          {signatureString}
        </div>
      );
    } else {
      return (
        <ScaleText maxFontSize={height * 0.5}>
          {conversion.getName(role.contact)}
        </ScaleText>
      );
    }
  }
};

export const getIsMultiline = (field) => {
  let fontSize = field.fontSize ? field.fontSize : '14px';
  return parseFloat(field.height) >= parseFloat(fontSize) * 2;
};

export const getClippingPath = (field) => {
  let width = field.textIndent ? parseFloat(field.textIndent) : 0;
  let fontSize = field.fontSize ? parseFloat(field.fontSize) : 14;
  let lineHeight =
    field['line-height'] && parseFloat(field['line-height']) > 0
      ? parseFloat(field['line-height'])
      : 135;
  let height = fontSize * (lineHeight / 100);
  return `polygon(0 ${height + 'px'}, ${width + 'px'} ${height + 'px'}, ${
    width + 'px'
  } 0%, 100% 0, 100% 100%, 0 100%)`;
};

export const adjustTextSizeAndCheckOverflow = (
  content,
  fieldDimensions,
  dispatch,
) => {
  const virtualElement = document.createElement('div');
  document.body.appendChild(virtualElement);

  // Apply styles to mimic the field's styles
  Object.assign(virtualElement.style, {
    position: 'absolute',
    visibility: 'hidden',
    height: 'auto',
    fontSize: fieldDimensions.fontSize + 'px',
    fontFamily: 'Arial',
    fontWeight: '600',
    lineHeight: fieldDimensions.lineHeight || '135%',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  });

  virtualElement.textContent = content;
  const isOverflowing =
    virtualElement.offsetWidth > fieldDimensions.width ||
    virtualElement.offsetHeight > fieldDimensions.height;

  document.body.removeChild(virtualElement);

  let textFillAlert = false;
  let adjustedFontSize = fieldDimensions.fontSize;

  if (isOverflowing) {
    if (fieldDimensions.fontSize === 10) {
      textFillAlert = true;
    }
    adjustedFontSize = Math.max(10, adjustedFontSize - 1) + 'px'; // Ensure minimum fontSize is 10px

    dispatch &&
      dispatch(
        updateField({
          id: fieldDimensions.id,
          properties: { fontSize: adjustedFontSize },
        }),
      );
  }

  return { adjustedContent: content, textFillAlert };
};

export const isFieldVisible = (field, roles) => {
  const role = findRole(roles, field);
  return role && role.type === 'signer';
  // const isVisible = (item) => {
  //   if(reduxState.model.modelClassName === "DocumentTemplates" && (item.role === "sellerAgent" || item.role === "buyerAgent")){
  //     return true;
  //   }
  //   if (item.role && reduxState.model.tempRoles && reduxState.model.tempRoles[item.role] && reduxState.model.tempRoles[item.role].type === "signer") {
  //     return true;
  //   }
  //   if (item.role && !reduxState.model.roles[item.role] || (reduxState.model.roles[item.role] && reduxState.model.roles[item.role].type !== "signer")) {
  //     return false;
  //   }
  //   return true;
  // }

  //TODO: Implement for Document Templates
};
