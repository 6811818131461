import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from 'consts';
import { useCreateGroupMutation } from 'redux/rtk-query';
import GroupForm, { defaultValues } from '../GroupForm';

export const CreateGroupPage = () => {
  const [createGroup] = useCreateGroupMutation();
  const navigate = useNavigate();

  const onSubmit = ({ ...values }) => {
    return createGroup({
      ...values,
    });
  };

  const afterSave = () => {
    navigate(ADMIN_ROUTES.GROUPS.path);
  };

  return (
    <GroupForm
      initialValues={defaultValues}
      handleSubmit={onSubmit}
      title={'Create Group'}
      afterSave={afterSave}
    />
  );
};
