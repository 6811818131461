export const uiReducer = {
  setCropping: (state, action) => {
    state.cropping = action.payload;
  },
  setShowGrid: (state) => {
    state.showGrid = !state.showGrid;
  },
  toggleDisableScriptFont: (state) => {
    state.disableScriptFont = !state.disableScriptFont;
  },
  setImporting: (state, action) => {
    state.importing = action.payload;
  },
};
