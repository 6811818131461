import React from 'react';
import PropTypes from 'prop-types';
import FontOptionGroup from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/FontOptionGroup';
import FontOptionMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/FontOptionMenuItem';

function FontOption({ fontFamily, fontFamilyGroup }) {
  return (
    <>
      {Object.getPrototypeOf(fontFamily) !== Object.prototype ? (
        fontFamily.map((font) => {
          let fontName = fontFamilyGroup ? fontFamilyGroup + ' ' + font : font;
          let key = `font-option-${font}-${fontFamilyGroup}`;
          return (
            <FontOptionMenuItem
              key={key}
              fontName={fontName}
              fontFamilyGroup={fontFamilyGroup}
              font={font}
            />
          );
        })
      ) : (
        <>
          <FontOptionGroup fontFamily={fontFamily} />
        </>
      )}
    </>
  );
}

export default FontOption;

FontOption.propTypes = {
  fontFamily: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  ]).isRequired,
  fontFamilyGroup: PropTypes.string,
};
