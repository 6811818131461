import { ButtonGroup, Button, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const ListInput = styled(TextField)({
  width: '100%',
  height: '28px',

  '& div.MuiOutlinedInput-root': {
    borderRadius: '8px',
    height: '28px',
  },
});
export const CreateCancelBtns = styled(ButtonGroup)({
  height: '28px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  borderTopLeftRadius: '0',
  borderBottomLeftRadius: '0',
});

const ComonBtn = styled(Button)({
  textTransform: 'none',
  fontWeight: '500',
  fontSize: '13px',
  lineHeight: '20px',
  padding: '4px 8px',
  width: '60px',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
});

export const CancelBtn = styled(ComonBtn)({
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
});
export const CreateBtn = styled(ComonBtn)({
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  background: '#0085FF',
});
