import { endpoints } from 'consts';

export const profileUrlBuilder = {
  getProfile: function () {
    return endpoints.profile;
  },
  getSvgContent: function (svgUrl) {
    return `${endpoints.svgContent}?svgUrl=${svgUrl}`;
  },
};
