import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ListItem, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Box, styled } from '@mui/system';
import { StyledBadge } from 'components/Styled';
import { activityFiltersSelector } from 'redux/selectors';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: '#f5f5f9',
    color: '#f5f5f9',
  },
  [`& .${tooltipClasses.arrow}:before`]: {
    border: '2px solid #E6E8ED',
  },
}));

export const CurrentActivityFilters = () => {
  const activityFilters = useSelector(activityFiltersSelector);

  const filterTypeDisplay = () => {
    const filterTypeCapitalized =
      activityFilters.filterType.charAt(0).toUpperCase() +
      activityFilters.filterType.slice(1);
    return (
      <StyledBadge
        badgeContent={activityFilters.filterValues.length}
        color="secondary"
      >
        {filterTypeCapitalized}
      </StyledBadge>
    );
  };

  return (
    <Box display={'inline-flex'}>
      {activityFilters.filterValues && (
        <StyledTooltip
          placement="bottom-end"
          title={
            <>
              {activityFilters.filterValues.map((filterValue) => (
                <ListItem key={filterValue.value}>
                  <Typography>{filterValue.label}</Typography>
                </ListItem>
              ))}
            </>
          }
          arrow
        >
          <Box display={'inline-flex'}>
            {activityFilters.filterValues && (
              <Typography>{filterTypeDisplay()}</Typography>
            )}
          </Box>
        </StyledTooltip>
      )}
    </Box>
  );
};

CurrentActivityFilters.propTypes = {
  activityFilters: PropTypes.shape({
    filterType: PropTypes.string.isRequired,
    filterValues: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
};
