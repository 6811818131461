import React from 'react';
import { Field, useField } from 'react-final-form';
import { Select } from 'components/Fields';
import { getSelectOptions } from 'helpers/form';

const MODULE_RELATION_TYPES = {
  Transaction: {
    SELLER: 'Seller',
    BUYER: 'Buyer',
    SELLER_AND_BUYER: 'Seller & Buyer',
    LANDLORD: 'Landlord',
    TENANT: 'Tenant',
    LANDLORD_AND_TENANT: 'Landlord & Tenant',
    REFERRAL: 'Referral',
    NO_PARTY: 'No party',
  },
  Contact: {
    LEAD: 'Lead',
    CUSTOMER: 'Customer',
    COOPERATING_AGENT: 'Cooperating Agent',
    BROKERAGE: 'Brokerage',
    CLOSING_ESCROW_AGENT: 'Closing/Escrow Agent',
    LENDER: 'Lender',
    VENDOR: 'Vendor',
    COMPANY_AGENT: 'Company Agent',
    COMPANY_ADMIN: 'Company Admin',
    COMPANY_REFERRAL_AGENT: 'Company Referral Agent',
  },
};

export const ModuleRelationType = () => {
  const moduleClassNameValue = useField('moduleClassName').input.value;
  const label =
    moduleClassNameValue === 'Transaction' ? 'Representing' : 'Contact Type';
  const options = MODULE_RELATION_TYPES[moduleClassNameValue];

  return (
    <>
      {moduleClassNameValue && (
        <Field
          name="moduleRelationType"
          label={label}
          options={getSelectOptions(options)}
          component={Select}
        />
      )}
    </>
  );
};
