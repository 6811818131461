import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { FieldTooltip, ModalSelectField } from 'components/Fields';
import { BoxInput } from 'components/Styled';
import { useGetParcelQuery } from 'redux/rtk-query';
import { ParcelsTable } from './components/ParcelsTable';

export const Parcel = ({ label, input, meta }) => {
  let error = '';

  if (meta.error || meta.submitError) {
    error = meta.submitError || meta.error;
  }

  let tooltip = false;
  let form = useForm();
  const field = form.getFieldState(input.name);
  const parcel = form.getState().values.parcel;

  const { data: parcelData, isSuccess: isParcelSuccess } = useGetParcelQuery(
    { hjid: parcel },
    { skip: typeof parcel !== 'string' },
  );

  useEffect(() => {
    if (isParcelSuccess) {
      input.onChange({
        id: parcelData.data.hjid,
        name: parcelData.data.apn,
        addressDetails: parcelData.data.addressDetails,
      });
    }
  }, [isParcelSuccess]);

  const onTableRowClick = (row) => {
    input.onChange({
      id: row.hjid,
      name: row.apn,
      addressDetails: row.addressDetails,
    });
  };

  if (field && field.data && field.data.tooltip) {
    tooltip = field.data.tooltip;
  }

  const getStartAdornment = () => {
    if (tooltip) {
      return <FieldTooltip tooltip={tooltip} />;
    }
    return '';
  };

  return (
    <BoxInput>
      <ModalSelectField
        startAdornment={getStartAdornment()}
        setValue={(value) => {
          input.onChange({
            id: value.hjid,
            name: value.apn,
          });
        }}
        onTableRowClick={onTableRowClick}
        name={input.name}
        error={!!error}
        label={label}
        title={label}
        ModalContent={ParcelsTable}
      />
    </BoxInput>
  );
};

Parcel.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  hinttext: PropTypes.string,
  options: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
};
