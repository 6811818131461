import React from 'react';
import PropTypes from 'prop-types';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { RowButtonWrapper } from 'components/Styled';
import { setEditingNote } from 'redux/slices';
import { MainContent, MainSummary } from './styled';

export const ListRow = ({ list }) => {
  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(setEditingNote(list));
  };

  return (
    <MainContent>
      <MainSummary>{list.name}</MainSummary>
      <RowButtonWrapper className={'showOnHover'}>
        <IconButton onClick={handleEdit} size={'small'}>
          <FontAwesomeIconComp icon={faEdit} size="sm" />
        </IconButton>
      </RowButtonWrapper>
    </MainContent>
  );
};

ListRow.propTypes = {
  list: PropTypes.object,
};
