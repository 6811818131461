import React from 'react';
import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { ButtonSection, PageMainContent } from 'components/Styled';
import { ADMIN_ROUTES } from 'consts/routes';
import { GroupsTable } from './GroupsTable';

export const GroupsPage = () => (
  <>
    <LayoutHeader title={'Groups'}>
      <ButtonSection>
        <CreateButton path={ADMIN_ROUTES.CREATE_GROUP.path} title="New Group" />
      </ButtonSection>
    </LayoutHeader>
    <PageMainContent maxWidth={false} component="main" disableGutters>
      <GroupsTable />
    </PageMainContent>
  </>
);
