// BarChartHorizontal.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

// Helper to format large numbers
function shortNumber(value) {
  const num = Number(value) || 0;
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + 'M';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + 'k';
  }
  return String(num);
}

const BarChartHorizontal = ({ data, options }) => {
  const horizontalOptions = {
    indexAxis: 'y',
    ...options,
    plugins: {
      ...options.plugins,
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#ffffff',
        formatter: (value) => shortNumber(value),
        display: (ctx) => {
          const { chart, dataIndex, datasetIndex } = ctx;
          const meta = chart.getDatasetMeta(datasetIndex);
          const bar = meta.data[dataIndex];
          if (!bar) return false;

          // 1) Check the actual value
          const dataset = chart.data.datasets[datasetIndex];
          const value = dataset.data[dataIndex];
          // If 0 (or falsy), hide the label
          if (!value) return false;

          // 2) Check the bar's width in pixels
          const barWidth = Math.abs(bar.base - bar.x);
          // If < 15px, hide the label
          return barWidth > 15;
        },
      },
    },
  };

  return <Bar data={data} options={horizontalOptions} />;
};

BarChartHorizontal.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default BarChartHorizontal;
