import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PageContainer, PageMainContent } from 'components/Styled';
import { isAuthenticatedSelector } from 'redux/selectors';

export const CreateLayout = ({ children }) => {
  const isAuthenticated = useSelector((state) =>
    isAuthenticatedSelector(state),
  );

  return isAuthenticated ? (
    <PageContainer disableGutters maxWidth={false}>
      <PageMainContent component="main" disableGutters>
        {children}
      </PageMainContent>
    </PageContainer>
  ) : null;
};

CreateLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
