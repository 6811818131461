import React from 'react';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/system';
import { MenuButton, MenuItemButton, ColorChip } from 'components/Common';
import { colors } from 'consts';
import { useGetCategoriesListQuery } from 'redux/rtk-query';

const StyledBox = styled(Box)(({ theme }) => ({
  maxHeight: '300px',
  overflowY: 'auto',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

export const CategoriesDropDown = ({ categories, setCategories }) => {
  const { data } = useGetCategoriesListQuery({
    page: 1,
    limit: 100,
    order: 'asc',
    orderBy: 'name',
    parentId: 15,
  });

  const isColorSelected = (category) => categories.includes(category.id);

  return (
    <MenuButton
      fullWidth
      color={'info'}
      sx={{ padding: 0, width: '100%' }}
      size={'x-small'}
      title={
        <MenuItemButton
          noHover
          label={
            categories.length > 0
              ? `${categories.length} ${categories.length > 1 ? 'Categories' : 'Category'}`
              : 'All Categories'
          }
          sx={{ width: '100%' }}
        />
      }
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <StyledBox>
        <ColorChip
          chipColor={colors.LIGHT_BLACK}
          label={'All Categories'}
          variant={categories.length === 0 ? 'primary' : 'outlined'}
          onClick={() => {
            setCategories([]);
          }}
        />
        {data?.data?.map((category) => (
          <ColorChip
            key={category.id}
            chipColor={category.color}
            label={category.name}
            variant={isColorSelected(category) ? 'primary' : 'outlined'}
            onClick={() => {
              if (categories.includes(category.id)) {
                setCategories(categories.filter((id) => id !== category.id));
              } else {
                setCategories([...categories, category.id]);
              }
            }}
          />
        ))}
      </StyledBox>
    </MenuButton>
  );
};

CategoriesDropDown.propTypes = {
  categories: PropTypes.array,
  setCategories: PropTypes.func,
};
