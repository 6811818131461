import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

const UserContext = createContext(null);

export const useUserData = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserData must be used within a UserProvider');
  }
  return context;
};

export const UserProvider = ({ children }) => {
  const { user: data, isLoading, error: isError } = useAuth0();

  console.log('UserProvider', data);
  const getUserName = () => {
    if (!data) {
      return '';
    }

    const { firstName, lastName } = data;

    // Prioritize firstName, fallback to lastName, then default to 'User'
    if (firstName) {
      return firstName;
    } else if (lastName) {
      return lastName;
    }

    return 'User';
  };

  const getUserRoles = () => {
    if (!data || !data.roles) {
      return [];
    }
    return data.roles;
  };

  const hasRole = useMemo(() => {
    return (role) => {
      return data?.roles?.includes(role) ?? false;
    };
  }, [data]);

  return (
    <UserContext.Provider
      value={{
        user: data,
        isLoading,
        isError,
        getUserName,
        getUserRoles,
        hasRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};
