// Updated Text.js

import React, {
  useCallback,
  useMemo,
  useState,
  useLayoutEffect,
  useRef,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import ContentEditable from 'react-contenteditable';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { ViewportHoverContext } from 'components/ModelViews/Documents/ClickMarketingViewer/ClickMarketingViewer';
import TextUnderline from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/fields/components/TextUnderline';
import { useCurrentItem } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useCurrentItem';
import useIsActive from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useIsActive';
import { useItemLabelValue } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useItemLabelValue';
import {
  getFontWeight,
  getFontFamily,
} from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';
import { CM_ITEM_TYPES } from 'consts';
import {
  cmBlockTypeSelectorCM,
  customColorSelectorCM,
  autofillDataSelectorCM,
  isTemplateViewSelectorCM as isTemplateViewSelector,
  isPreviewModeSelector,
} from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const isValueEmpty = (value) => {
  if (
    value === null ||
    value.trim() === '' ||
    value.includes('(NONE)') ||
    value.includes('Select Auto-Fill') ||
    parseInt(value) === 0 ||
    value.trim() === '&nbsp' ||
    value.trim() === 'Fill Now' ||
    value.trim() === '?' ||
    value.trim() === '&nbsp;'
  ) {
    return true;
  } else {
    return false;
  }
};

export const Text = React.memo(({ itemId, preview }) => {
  const currentItem = useCurrentItem(itemId);
  const autoFillData = useSelector(autofillDataSelectorCM);
  const customColor = useSelector(customColorSelectorCM);
  const isViewportHovered = useContext(ViewportHoverContext);
  const cmBlockType = useSelector(cmBlockTypeSelectorCM);
  const isTemplateView = useSelector(isTemplateViewSelector);
  const isPreviewMode = useSelector(isPreviewModeSelector);
  const isActive = useIsActive(itemId);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [fontSize, setFontSize] = useState(
    parseFloat(currentItem.style.fontSize) || 16,
  ); // Initialize fontSize
  const measurementRef = useRef(null); // Ref for the hidden measurement div
  const wasActive = useRef(false);

  const labelValue = useItemLabelValue(currentItem); // Use the new hook

  const getColor = useCallback(
    (color) => {
      if (
        customColor === 'mainframeTheme' &&
        (color === '#ffffff' || color === 'customColor')
      ) {
        return '#231F20';
      }
      if (color === 'customColor') {
        return customColor ? customColor : '#48484A';
      }
      return color;
    },
    [customColor, currentItem.style?.mainframeThemeColor, currentItem.type],
  );

  // Initialize value based on item type and autofill data
  useEffect(() => {
    let initialValue = currentItem.type === 'autofill' ? '' : currentItem.value;

    // Use labelValue if it exists
    if (labelValue) {
      initialValue = labelValue;
    } else if (currentItem.type === 'autofill') {
      if (
        currentItem.autofill &&
        (!currentItem.value === 'Select Auto-Fill' ||
          !isTemplateView ||
          cmBlockType === CM_ITEM_TYPES.modular.Template)
      ) {
        initialValue = autoFillData[currentItem.autofill] || '';
      }
    } else if (
      currentItem.type === CM_ITEM_TYPES.text.fillnow &&
      isValueEmpty(initialValue)
    ) {
      initialValue = 'Fill Now';
    }
    setValue(initialValue.toString());
  }, [currentItem, autoFillData, labelValue]);

  // Memoize text styles to prevent recalculation on each render
  const textStyle = useMemo(() => {
    const style = {
      outlineStyle: 'none',
      fontSize: `${fontSize}px`, // Use the local fontSize state
      fontWeight: getFontWeight(
        currentItem.style.fontFamily,
        currentItem.header,
        autoFillData,
      ),
      color: getColor(currentItem.style.color),
      lineHeight: parseInt(currentItem.h, 10) + 'px',
      // currentItem.style?.underline === true
      //   ? 'normal'
      //   : parseInt(currentItem.h, 10) + 'px',
      fontFamily: getFontFamily(
        currentItem.style.fontFamily,
        currentItem.header,
        autoFillData,
      ),
      whiteSpace: currentItem.multiline ? 'break-spaces' : 'pre',
    };

    if (currentItem.multiline) {
      style.width = '100%';
      style.height = '100%';
      style.display = 'block';
      style.textAlign = currentItem.style?.textAlign || 'left';
      style.alignContent = currentItem.style?.alignment || 'flex-start';
      style.lineHeight = currentItem.style?.lineHeight
        ? currentItem.style.lineHeight
        : 1;
    } else {
      style.display = 'inline-block';
      style.textAlign = currentItem.style?.textAlign || 'left';
      style.alignContent = 'center';
    }

    return style;
  }, [
    fontSize,
    currentItem.style,
    currentItem.multiline,
    currentItem.header,
    autoFillData,
    getColor,
  ]);

  // Debounced function to update item value in the store
  const debouncedUpdate = useMemo(() => {
    const handler = debounce((id, value) => {
      dispatch(updateItemCM({ itemId: id, properties: { value } }));
    }, 500);
    return handler;
  }, [dispatch]);

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  // Handle content changes
  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      debouncedUpdate(itemId, newValue);
    },
    [debouncedUpdate, itemId],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (!currentItem.multiline && e.key === 'Enter') {
        e.preventDefault();
      }
    },
    [currentItem.multiline],
  );

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');

    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    // Move the caret after the inserted text node
    range.setStartAfter(textNode);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }, []);

  // Font fitting logic using useLayoutEffect and refs
  useLayoutEffect(() => {
    const adjustFontSize = () => {
      const maxWidth = parseFloat(currentItem.w);
      const maxHeight = parseFloat(currentItem.h);

      const virtualContent = measurementRef.current;
      if (!virtualContent) return;

      // Apply textStyle to virtualContent
      const virtualStyle = { ...textStyle };

      let newFontSize = parseFloat(currentItem.style.fontSize) || 16;
      virtualStyle.fontSize = `${newFontSize}px`;

      for (const [key, value] of Object.entries(virtualStyle)) {
        virtualContent.style[key] = value;
      }

      virtualContent.innerHTML = value || '';

      const originalFontSize = newFontSize;

      if (currentItem.multiline) {
        virtualContent.style.width = `${maxWidth}px`;
        virtualContent.style.height = 'auto';

        let targetHeight = virtualContent.offsetHeight;

        if (targetHeight > maxHeight - 5) {
          while (targetHeight > maxHeight && newFontSize > 1) {
            newFontSize -= 0.5;
            virtualContent.style.fontSize = `${newFontSize}px`;
            targetHeight = virtualContent.offsetHeight;
          }
        } else if (targetHeight < maxHeight - 10) {
          while (
            targetHeight < maxHeight - 10 &&
            newFontSize < originalFontSize
          ) {
            newFontSize += 0.5;
            virtualContent.style.fontSize = `${newFontSize}px`;
            targetHeight = virtualContent.offsetHeight;
          }
        }
      } else {
        virtualContent.style.width = 'auto';
        virtualContent.style.height = 'auto';
        virtualContent.style.whiteSpace = 'nowrap';

        let targetWidth = virtualContent.offsetWidth;

        if (targetWidth > maxWidth - 5) {
          while (targetWidth > maxWidth && newFontSize > 1) {
            newFontSize -= 0.5;
            virtualContent.style.fontSize = `${newFontSize}px`;
            targetWidth = virtualContent.offsetWidth;
          }
        } else if (targetWidth < maxWidth - 10) {
          while (
            targetWidth < maxWidth - 10 &&
            newFontSize < originalFontSize
          ) {
            newFontSize += 0.5;
            virtualContent.style.fontSize = `${newFontSize}px`;
            targetWidth = virtualContent.offsetWidth;
          }
        }
      }

      newFontSize = Math.round(newFontSize * 100) / 100;

      if (newFontSize !== fontSize) {
        setFontSize(newFontSize);
      }
    };

    adjustFontSize();
  }, [
    currentItem.multiline,
    currentItem.w,
    currentItem.h,
    value,
    textStyle,
    fontSize,
    currentItem.style.fontSize,
  ]);

  useEffect(() => {
    if (wasActive.current && !isActive) {
      // Update the item in Redux store when the component loses focus
      dispatch(
        updateItemCM({
          itemId,
          properties: { value, 'style.fontSize': fontSize.toString() },
        }),
      );
    }
    wasActive.current = isActive;
  }, [isActive, value, fontSize, dispatch, itemId]);

  useEffect(() => {
    if (
      (!isActive &&
        cmBlockType === CM_ITEM_TYPES.modular.Template &&
        !preview) ||
      (!isTemplateView && !isPreviewMode)
    ) {
      if (!isViewportHovered && value === 'Fill Now') {
        setValue('');
      } else if (isViewportHovered && isValueEmpty(value)) {
        setValue('Fill Now');
      }
    }
  }, [isViewportHovered, isActive, value, cmBlockType, preview]);

  return (
    <>
      <Box
        sx={{
          display: currentItem.style.underline ? 'inline-flex' : 'flex',
          position: 'relative',
          height: '100%',
          justifyContent:
            currentItem.style.textAlign === 'left'
              ? 'flex-start'
              : currentItem.style.textAlign === 'right'
                ? 'flex-end'
                : 'center',
        }}
      >
        <ContentEditable
          tabIndex="0"
          id={`contenteditable-${itemId}`}
          tagName="div"
          onPaste={handlePaste}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          style={textStyle}
          html={value}
        />
        {currentItem.style.underline && (
          <>
            <TextUnderline item={currentItem} />
          </>
        )}
      </Box>
      {/* Hidden div for measuring text dimensions */}
      <div
        ref={measurementRef}
        style={{
          position: 'absolute',
          left: '-9999px',
          top: '-9999px',
          visibility: 'hidden',
        }}
      ></div>
      {currentItem.type === CM_ITEM_TYPES.text.fillnow &&
        value === 'Fill Now' &&
        !preview && (
          <FontAwesomeIcon
            className={'show-on-hover'}
            icon={faPencil}
            style={{
              color: 'white',
              fontWeight: '400',
              backgroundColor: 'blue',
              borderRadius: 15,
              padding: 5,
              position: 'absolute',
              top: -5,
              right: -5,
            }}
          />
        )}
    </>
  );
});

Text.propTypes = {
  itemId: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

Text.displayName = 'Text';

export default Text;
