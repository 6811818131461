import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { List, ListItem } from '@mui/material';

export const ProfileBulletListDisplay = ({ list }) => {
  return (
    <>
      {list && (
        <List sx={{ pl: 3 }}>
          {list.map((item) => (
            <ListItem
              sx={{
                color: '#565656',
                padding: 0,
                listStyleType: 'disc',
                display: 'list-item',
              }}
              key={item.id}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item.label),
                }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

ProfileBulletListDisplay.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};
