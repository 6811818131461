import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creating: false,
  editing: false,
};

export const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setCreatingTask: (state, action) => {
      state.creating = action.payload;
    },
    setEditingTask: (state, action) => {
      state.editing = action.payload;
    },
  },
});

export const { setCreatingTask, setEditingTask } = taskSlice.actions;
export const tasksSliceReducer = taskSlice.reducer;
