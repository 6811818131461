import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';
import { Popper, SlideContainer, SlideContent } from './styled';

const StyledPopper = styled(Popper)({
  zIndex: 2000,
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
});

const Tab = styled('div')(({ placement }) => ({
  width: '0',
  height: '0',
  borderStyle: 'solid',
  position: 'absolute',
  right: '2px',

  '&::after': {
    content: '""',
    position: 'absolute',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    right: '-15px',
    zIndex: -1,

    // Adjustments for top and bottom placement of the shadow
    ...(placement.startsWith('bottom') && {
      top: '-1px',
      borderWidth: '0 15px 15px 15px',
      borderColor: 'transparent transparent rgba(0, 0, 0, 0.1) transparent',
    }),
    ...(placement.startsWith('top') && {
      bottom: '-4px',
      borderWidth: '15px 15px 0 15px',
      borderColor: 'rgba(0, 0, 0, 0.1) transparent transparent transparent',
    }),
  },
  ...(placement.startsWith('bottom') && {
    top: '-3px',
    borderWidth: '0 15px 15px 15px',
    borderColor: 'transparent transparent white transparent',
  }),
  ...(placement.startsWith('top') && {
    bottom: '-3px',
    borderWidth: '15px 15px 0 15px',
    borderColor: 'white transparent transparent transparent',
  }),
}));

const ICON_COLOR = '#969696';

export const PopperButton = ({
  buttonIcon = faEllipsis,
  size = 'medium',
  variant = 'standard',
  menuItems,
  item,
  children,
  startingContent = 'menu',
  sx = {},
}) => {
  const [open, setOpen] = useState(false);
  const [slideIn, setSlideIn] = useState(true);
  const [currentContent, setCurrentContent] = useState(startingContent);
  const [contentWidth, setContentWidth] = useState(null);
  const [contentHeight, setContentHeight] = useState(null);
  const anchorRef = useRef(null);
  const contentRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        if (currentContent === 'menu' && menuRef.current) {
          setContentWidth(menuRef.current.offsetWidth);
          setContentHeight(menuRef.current.offsetHeight);
        } else if (contentRef.current) {
          setContentWidth(contentRef.current.offsetWidth);
          setContentHeight(contentRef.current.offsetHeight);
        }
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [currentContent, open]);

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = (forceClose = false) => {
    if (currentContent === 'menu' || forceClose === true) {
      setSlideIn(true);
      setCurrentContent('menu');
      setOpen(false);
    }
  };

  function handleListKeyDown(event) {
    if (currentContent !== 'menu') return;
    if (event.key === 'Tab') {
      event.preventDefault();
    }
    setOpen(false);
  }

  const handleBackToMenu = () => {
    setSlideIn(true);
    setCurrentContent('menu');
  };

  return (
    <>
      <Button
        variant={variant}
        ref={anchorRef}
        size={size}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setOpen(!open);
        }}
        sx={sx}
      >
        {children ? (
          children
        ) : (
          <FontAwesomeIconComp icon={buttonIcon} color={ICON_COLOR} />
        )}
      </Button>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={'bottom-end'}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'right top' : 'right top',
              borderRadius: '8px',
            }}
          >
            <StyledPaper
              elevation={4}
              sx={placement.startsWith('bottom') ? { mt: 1 } : { mb: 1 }}
            >
              {placement && <Tab placement={placement} />}
              <ClickAwayListener onClickAway={handleClose}>
                <SlideContainer width={contentWidth} height={contentHeight}>
                  <SlideContent ref={menuRef} slideIn={slideIn}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {menuItems.map(
                        (
                          {
                            name,
                            icon,
                            onClick,
                            shouldRender,
                            divider,
                            color = ICON_COLOR,
                            content,
                            closeOnClick,
                            menuSx,
                            ...rest
                          },
                          index,
                        ) =>
                          (!shouldRender || shouldRender(item)) && (
                            <span key={index}>
                              <MenuItem
                                sx={menuSx}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (content) {
                                    setSlideIn(false);
                                    setCurrentContent(() => content);
                                  } else {
                                    if (closeOnClick) {
                                      handleClose(true);
                                    }
                                    onClick(item);
                                  }
                                }}
                              >
                                {icon && (
                                  <ListItemIcon sx={{ color: 'green' }}>
                                    <FontAwesomeIconComp
                                      fixedWidth
                                      icon={icon}
                                      color={color}
                                    />
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  variant="span"
                                  sx={{ minWidth: '10px' }}
                                  {...rest}
                                >
                                  {typeof name === 'function'
                                    ? name(item)
                                    : name}
                                </ListItemText>
                              </MenuItem>
                              {divider && (
                                <Divider
                                  sx={{ width: '85%', margin: '0 auto' }}
                                />
                              )}
                            </span>
                          ),
                      )}
                    </MenuList>
                  </SlideContent>
                  <SlideContent ref={contentRef} slideIn={!slideIn} fromRight>
                    {currentContent !== 'menu' &&
                      React.createElement(currentContent, {
                        item,
                        handleCloseMenu: handleClose,
                        onBackToMenu: handleBackToMenu,
                        triggerResize: () => {
                          setContentWidth(contentRef.current.offsetWidth);
                          setContentHeight(contentRef.current.offsetHeight);
                        },
                      })}
                  </SlideContent>
                </SlideContainer>
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
};

PopperButton.propTypes = {
  startingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  item: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    name: PropTypes.string,
  }),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
      onClick: PropTypes.func,
      shouldRender: PropTypes.func,
      divider: PropTypes.bool,
      content: PropTypes.func,
      closeOnClick: PropTypes.bool,
      color: PropTypes.string,
      menuSx: PropTypes.object,
    }),
  ),
  buttonIcon: PropTypes.object,
  size: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
};
