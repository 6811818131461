import React from 'react';
import PropTypes from 'prop-types';

export const Strikeout = ({ field }) => {
  // Default dimensions for the SVG, can be adjusted as needed
  const { height } = field;

  // Check if the field's height is less than 20 to decide on the strikethrough style
  const isLine = parseFloat(height) <= 20;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none" // This ensures the SVG scales correctly
      >
        {isLine ? (
          // Render a horizontal line if height is less than 20
          <line
            x1="0"
            y1="50%"
            x2="100%"
            y2="50%"
            stroke="red"
            strokeWidth="4"
          />
        ) : (
          // Render an "X" if height is equal or more than 20
          <>
            <line
              x1="2"
              y1="2"
              x2="100%"
              y2="calc(100% - 4px)"
              stroke="red"
              strokeWidth="4"
            />
            <line
              x1="100%"
              y1="2"
              x2="2"
              y2="calc(100% - 4px)"
              stroke="red"
              strokeWidth="4"
            />
          </>
        )}
      </svg>
    </div>
  );
};

Strikeout.propTypes = {
  field: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
};
