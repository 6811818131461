import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { axiosBaseQuery } from 'utils/baseQuery';
import { commissionUrlBuilder } from './urlBuilder/commissions';

export const commissionsAPI = createApi({
  reducerPath: 'commissionsAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Commissions'],
  endpoints: (build) => ({
    getCommissionsList: build.query({
      query: (args) => ({
        url: commissionUrlBuilder.getList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Commissions'],
    }),
    updateCommission: build.mutation({
      query: (body) => ({
        url: commissionUrlBuilder.createCommission(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Commissions'],
    }),
    getCommission: build.query({
      query: (args) => ({
        url: commissionUrlBuilder.getCommission(args),
        method: 'GET',
      }),
      providesTags: ['Commissions'],
    }),
  }),
});

export const {
  useGetCommissionsListQuery,
  useUpdateCommissionMutation,
  useGetCommissionQuery,
} = commissionsAPI;
