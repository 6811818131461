import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/Common';
import { useGetSettingsListQuery } from 'redux/rtk-query';
import { Cells } from './config';

const RowsPerPageOptions = [15, 25, 50];

export const SettingsTable = ({ onRowClick, type, title }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [search, setSearch] = useState('');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const orderByKey = orderBy
    .split(/\.?(?=[A-Z])/)
    .join('_')
    .toLowerCase();
  const { data, isLoading } = useGetSettingsListQuery({
    page: page,
    limit: limit,
    search: search,
    order: orderByKey,
    type: type,
  });

  const rows = useMemo(() => data?.data?.items || [], [data]);
  const totalPages = useMemo(() => data?.data?.meta?.totalPages, [data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Table
        title={title}
        order={order}
        orderBy={orderBy}
        onRowClick={onRowClick}
        handleSearch={setSearch}
        search={search}
        isLoading={isLoading}
        rows={rows}
        totalPages={totalPages}
        cells={Cells}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={RowsPerPageOptions}
        setPage={setPage}
        handleRequestSort={handleRequestSort}
        setRowsPerPage={setLimit}
      />
    </>
  );
};

SettingsTable.propTypes = {
  onRowClick: PropTypes.func,
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  title: PropTypes.string,
};
