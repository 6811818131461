import { Box, styled } from '@mui/material';

export const EmptyBox = styled(Box)({
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  '& .svg-inline--fa': {
    fontSize: 20,
    marginRight: 10,
  },
});
