import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import {
  Divider,
  Button,
  Collapse,
  Typography,
  IconButton,
} from '@mui/material';
import { AbeCard } from 'components/Common';
import { CREATE_OFFER, TRANSACTION_TYPES } from 'consts';
import { useGetOffersQuery } from 'redux/rtk-query';
import { OfferRow } from './components';

export const OffersCard = (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { transactionDetailsId } = useParams();

  const { data: offersData } = useGetOffersQuery({
    id: transactionDetailsId,
  });

  const [archivedOpen, setArchivedOpen] = useState(false); // State for collapsed section

  // Handle grouping by transactionType
  const activeOffer = offersData?.data?.find(
    (offer) => offer.transactionType === TRANSACTION_TYPES.ACTIVE_OFFER,
  );
  const draftOffers =
    offersData?.data?.filter(
      (offer) => offer.transactionType === TRANSACTION_TYPES.DRAFT_OFFER,
    ) || [];
  const archivedOffers =
    offersData?.data?.filter(
      (offer) => offer.transactionType === TRANSACTION_TYPES.ARCHIVED_OFFER,
    ) || [];

  const handleOfferCreate = () => {
    navigate(`${pathname}${CREATE_OFFER}`);
  };

  const toggleArchivedOffers = () => {
    setArchivedOpen(!archivedOpen);
  };

  return (
    <AbeCard
      title={'Offers'}
      afterTitle={
        <Button variant="text" size="small" onClick={handleOfferCreate}>
          + Add
        </Button>
      }
      {...props}
    >
      <Divider />

      {/* Active Offer Section */}
      {activeOffer && (
        <>
          <OfferRow offer={activeOffer} />
          <Divider />
        </>
      )}

      {/* Draft Offers Section */}
      {draftOffers.length > 0 && (
        <>
          {draftOffers.map((offer) => (
            <OfferRow key={offer.id} offer={offer} />
          ))}
          <Divider />
        </>
      )}

      {/* Archived Offers Section */}
      {archivedOffers.length > 0 && (
        <>
          <Typography pl={2} variant="caption">
            Archived Offers
            <IconButton onClick={toggleArchivedOffers} size="x-small">
              {archivedOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Typography>
          <Collapse in={archivedOpen}>
            <>
              {archivedOffers.map((offer) => (
                <OfferRow key={offer.id} offer={offer} />
              ))}
            </>
          </Collapse>
        </>
      )}
    </AbeCard>
  );
};
