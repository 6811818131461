import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRemoveFromListMutation } from 'redux/rtk-query';
import { selectedContactsSelector } from 'redux/selectors';
import { setSelectedContacts } from 'redux/slices';
import { ToolbarAction, ToolbarButton, XIcon } from '../styled';

export const RemoveFromList = () => {
  let { listId } = useParams();
  const selectedContacts = useSelector(selectedContactsSelector);
  const [removeFromList] = useRemoveFromListMutation();

  const removeFromListHandler = () => {
    const copySelectedContacts = selectedContacts;
    setSelectedContacts([]);
    removeFromList({
      listId: parseInt(listId),
      contactsIds: copySelectedContacts,
    });
  };
  return (
    <>
      <ToolbarAction
        title="Remove from List"
        sx={{ marginRight: '20px' }}
        onClick={removeFromListHandler}
      >
        <ToolbarButton color="error" endIcon={<XIcon />}>
          Remove from List
        </ToolbarButton>
      </ToolbarAction>
    </>
  );
};
