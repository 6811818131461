import { useGetMediaLibraryUrlQuery } from 'redux/rtk-query';

const useGetMediaUrl = (file) => {
  // Ensure file and its properties are safely accessed
  const uuid = file?.uuid || null;
  // const destination = file?.destination || null;

  // Determine if the queries should be skipped
  const shouldSkip = !uuid;

  // Call all hooks unconditionally
  // const documentTemplateResult = useGetDocumentTemplateMediaUrlQuery(
  //   {
  //     [identifier]: identifierValue,
  //     uuid,
  //   },
  //   { skip: shouldSkip || destination !== 'documentTemplate' },
  // );
  //
  // const invoiceTemplateResult = useGetInvoiceTemplateMediaUrlQuery(
  //   {
  //     [identifier]: identifierValue,
  //     uuid,
  //   },
  //   { skip: shouldSkip || destination !== 'invoiceTemplate' },
  // );
  //
  // const settingResult = useGetSettingMediaUrlQuery(
  //   {
  //     [identifier]: identifierValue,
  //     uuid,
  //   },
  //   { skip: shouldSkip || destination !== 'setting' },
  // );

  const mediaLibraryResult = useGetMediaLibraryUrlQuery(uuid, {
    skip: shouldSkip,
  });

  // // Select the appropriate result based on destination
  // if (destination === 'invoiceTemplate') {
  //   return invoiceTemplateResult;
  // } else if (destination === 'setting') {
  //   return settingResult;
  // } else if (destination === 'documentTemplate') {
  //   return documentTemplateResult;
  // } else
  if (mediaLibraryResult) {
    return mediaLibraryResult;
  }

  // Default return if destination doesn't match any case
  return { data: null };
};

export default useGetMediaUrl;
