import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import { EmptyResults, SkeletonSummary } from 'components/Common';
import { ToolbarRow } from 'components/Common/Table/TableToolbar/styled';
import { ListPagination } from 'components/ListPagination';
import NewMarketingButton from 'components/ModelViews/Marketing/MarketingList/NewMarketingButton';
import { SearchInput } from 'components/SearchInput';
import { ListBox } from 'components/Styled';
import { useItemCount } from 'contexts';
import { useGetDocumentsListQuery } from 'redux/rtk-query';
import { MarketingItem } from './components/MarketingItem';

// eslint-disable-next-line no-unused-vars
export const MarketingList = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // eslint-disable-next-line no-unused-vars
  const [searchValue, setSearchValue] = useState('');
  const params = useParams();
  const { setItemCount } = useItemCount();
  const relatedModelId = params.contactDetailsId || params.transactionDetailsId;
  const relatedModelType = params.contactDetailsId ? 'contact' : 'transaction';
  const { data } = useGetDocumentsListQuery({
    page: page,
    limit: 10,
    fileType: 'Click Marketing',
    orderBy: 'createdAt',
    [`${relatedModelType}Id`]: parseInt(relatedModelId),
  });

  const AllMarketing = data?.data;
  const totalPages = data?.meta?.totalPages;
  const totalCount = data?.meta?.total;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    setItemCount(totalCount);
  }, [totalCount]);

  return (
    <>
      <ToolbarRow width="100%" justifyContent="space-between">
        <SearchInput changeHandler={setSearchValue} />
        <NewMarketingButton />
      </ToolbarRow>
      <Divider />
      <ListBox>
        {!AllMarketing ? (
          <SkeletonSummary />
        ) : AllMarketing && AllMarketing.length ? (
          AllMarketing.map((marketing) => (
            <React.Fragment key={marketing.id}>
              <MarketingItem marketing={marketing} />
            </React.Fragment>
          ))
        ) : (
          <EmptyResults />
        )}
        <Box style={{ textAlign: 'center' }}>
          {totalPages > 0 && (
            <ListPagination
              totalCount={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[5]}
            />
          )}
        </Box>
      </ListBox>
    </>
  );
};

MarketingList.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  relatedModel: PropTypes.object,
  relatedModelType: PropTypes.string,
};
