import React from 'react';
import { Field } from 'react-final-form';
import { Box, Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { CategoriesAutocomplete, Select, TextInput } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { CATEGORY_STATUS } from 'consts';
import { getSelectOptions } from 'helpers/form';

export const DocumentCategoryForm = ({ ...props }) => {
  const renderFields = () => (
    <Box p={2}>
      <FormPanel startOpen>
        <Grid item xs={12}>
          <Field label="Name" name={'name'} component={TextInput} />
          <Field label="Color" name={'color'} component={TextInput} />
          <Field
            label={'Parent Category'}
            name={'parentCategory'}
            component={CategoriesAutocomplete}
          />
          <Field
            name="description"
            label={'Description'}
            component={TextInput}
            multiline
          />
          <Field
            name={'status'}
            label={'Status'}
            component={Select}
            options={getSelectOptions(CATEGORY_STATUS)}
          />
        </Grid>
      </FormPanel>
    </Box>
  );

  return <AbeForm {...props}>{renderFields}</AbeForm>;
};
