import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  DocumentCard,
  EmailCard,
  NoteCard,
  RolesCard,
  TaskCard,
  TimeframeCard,
} from 'components/ModelViews';
import { OffersCard } from 'components/ModelViews/Transactions/OffersCard';
import { TRANSACTION_TYPES } from 'consts';
import { useGetTransactionQuery } from 'redux/rtk-query';

export const OfferDashboard = ({ id }) => {
  let { transactionDetailsId } = useParams();
  const transactionId = id?.toString() || transactionDetailsId;
  const { data: transactionData } = useGetTransactionQuery(
    { id: transactionId },
    { skip: !transactionId },
  );

  const transaction = transactionData?.data || {};

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <DocumentCard transactionId={id} />
        <TimeframeCard startClosed transactionId={id} />
        <TaskCard startClosed transactionId={id} />
        <NoteCard startClosed transactionId={id} />
        <EmailCard startClosed transactionId={id} />
        {transaction.transactionType === TRANSACTION_TYPES.PRIMARY && (
          <OffersCard />
        )}
        <RolesCard transactionId={id} />
      </Grid>
    </Grid>
  );
};

OfferDashboard.propTypes = {
  id: PropTypes.string,
};
