import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ConfirmationButton } from 'components/Common/ConfirmationButton';
import { TRANSACTION_TYPES } from 'consts';
import {
  useUpdateTransactionTypeMutation,
  tasksAPI,
  documentsAPI,
  notesAPI,
} from 'redux/rtk-query';

export const AcceptOfferButton = ({ offer, handleClose }) => {
  const [updateTransactionType] = useUpdateTransactionTypeMutation();
  const dispatch = useDispatch();

  const handleAccept = async () => {
    await updateTransactionType({
      id: offer.id,
      transactionType: TRANSACTION_TYPES.ACTIVE_OFFER,
    });
    dispatch(tasksAPI.util.invalidateTags(['Tasks']));
    dispatch(documentsAPI.util.invalidateTags(['Documents']));
    dispatch(notesAPI.util.invalidateTags(['Notes']));
    handleClose();
  };
  return (
    <ConfirmationButton
      label={'Accept Offer'}
      confirmTitle={'Accept Offer'}
      confirmMessage={
        'Are you sure you want to accept this offer? This will automatically reject all other offers'
      }
      handleConfirm={handleAccept}
      color={'success'}
    />
  );
};

AcceptOfferButton.propTypes = {
  offer: PropTypes.object,
  handleClose: PropTypes.func,
};
