import {
  checkForMissingSignerRolesWithoutFields,
  removeFieldsWithMissingRoles,
  updateRoleInArray,
} from '../helpers';

export const rolesReducer = {
  updateRoleParty(state, action) {
    const { party } = action.payload;
    state.previousRoles = state.previousRoles.length
      ? state.previousRoles
      : [...state.currentDocument.roles];
    state.currentDocument.roles = updateRoleInArray(
      state.currentDocument.roles,
      party,
    );
  },

  cancelRoleUpdate(state) {
    if (state.previousRoles.length > 0) {
      state.currentDocument.roles = state.previousRoles;
      state.previousRoles = [];
    }
    state.signedStatus = null;
  },

  saveRoles(state) {
    state.currentDocument.roles = state.currentDocument.roles.filter(
      (role) => !role.removing,
    );
    checkForMissingSignerRolesWithoutFields(state);
    removeFieldsWithMissingRoles(state);
    state.previousRoles = [];
  },

  updateRoleRemoving(state, action) {
    const { party } = action.payload;
    const roleIndex = state.currentDocument.roles.findIndex(
      (role) => role.role === party.role,
    );
    if (roleIndex !== -1) {
      state.currentDocument.roles.splice(roleIndex, 1);
    }
  },

  updateRoleFiller: (state, action) => {
    const roleWithFields = action.payload;
    state.currentDocument.htmlTemplate = state.currentDocument.htmlTemplate.map(
      (field) => {
        if (
          field.role &&
          (field.type === 'text' ||
            field.type === 'date' ||
            field.type === 'checkbox')
        ) {
          field.role = roleWithFields;
        }
        return field;
      },
    );
  },
};

export const {
  updateRoleParty,
  cancelRoleUpdate,
  saveRoles,
  updateRoleRemoving,
  updateRoleFiller,
} = rolesReducer;
