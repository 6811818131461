import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import {
  defaultValues,
  processFormData,
  TaskForm,
} from 'components/ModelViews/Tasks';
import { RIGHT_OPTIONS } from 'consts';
import { conversion } from 'helpers';
import { useDrawer, useFetchRelatedModel } from 'hooks';
import {
  contactsAPI,
  transactionsAPI,
  useCreateTaskMutation,
} from 'redux/rtk-query';

export const CreateTaskModal = ({ params, path }) => {
  const [initialValues, setInitialValues] = useState({});

  const [createTask] = useCreateTaskMutation();
  const dispatch = useDispatch();
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false, path);
  const { pathname } = useLocation();

  const response = useFetchRelatedModel(
    params['relatedType'],
    params['relatedTypeId'],
  );
  const taskType = pathname.includes('timeframeCreate') ? 'Timeframe' : 'Task';

  const relatedModel = response.data?.data;

  const handleClose = () => {
    closeDrawer();
  };

  const afterSave = () => {
    handleClose();
  };

  useEffect(() => {
    if (relatedModel) {
      setInitialValues({
        ...defaultValues,
        agent: null,
        shareWithOption: RIGHT_OPTIONS.agentOnly.value,
        [`${params['relatedType']}Id`]: relatedModel.id,
        type: taskType,
      });
      openDrawer();
    }
  }, [relatedModel]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    const task = await createTask(formData);
    if (params['relatedType'] === 'contact') {
      dispatch(contactsAPI.util.invalidateTags(['Contacts']));
    }
    if (params['relatedType'] === 'transaction') {
      dispatch(transactionsAPI.util.invalidateTags(['Transactions']));
    }
    return task;
  };

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      <TaskForm
        title={`Create ${taskType}`}
        subTitle={conversion.getName(relatedModel)}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

CreateTaskModal.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  uuid: PropTypes.string,
  subTitle: PropTypes.string,
  params: PropTypes.object,
  path: PropTypes.string,
};
