import React from 'react';
import { faLineHeight } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import { activeFieldsSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';

export function LineHeight() {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const selectedField = activeFields[0];

  return (
    <MenuItemButton
      label={'Line Height'}
      icon={<FontAwesomeIconComp fontSize={18} icon={faLineHeight} />}
      rightAction={
        <TextField
          size={'small'}
          sx={{ width: '80px', p: 0 }}
          type="number"
          value={selectedField['line-height'] || 135}
          inputProps={{ min: 10, step: 1 }}
          onChange={(e) => {
            dispatch(
              updateField({
                properties: { ['line-height']: e.target.value },
              }),
            );
          }}
        />
      }
    />
  );
}
