import React from 'react';
import { Table } from 'components/Common';
import { ColumnManager } from 'components/Common/Table/components/ColumnManager';
import { useTableData } from 'hooks';
import { useGetInvoiceTemplatesQuery } from 'redux/rtk-query';
import { Cells, MenuItems } from './config';

export const InvoiceTemplatesTable = () => {
  const {
    order,
    orderBy,
    filterValues,
    page,
    rowsPerPage,
    search,
    loading,
    setFilterValues,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    totalCount,
    rows,
    visibleColumns,
    toggleColumnVisibility,
    resetColumns,
  } = useTableData(useGetInvoiceTemplatesQuery, Cells, 'name', 'asc');

  return (
    <>
      <Table
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleSearch={setSearch}
        search={search}
        afterSearch={
          <ColumnManager
            columns={visibleColumns}
            setColumns={toggleColumnVisibility}
            resetColumns={resetColumns}
          />
        }
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={visibleColumns.filter((col) => col.isVisible)}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};
