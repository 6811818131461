import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { selectedContactsSelector } from 'redux/selectors';
import { AddExistingContact } from './AddExistingContact';
import { RemoveFromList } from './RemoveFromList';

export const TableToolbar = () => {
  const { listId: selectedListId } = useParams();
  const selectedContacts = useSelector(selectedContactsSelector);
  const numSelected = selectedContacts ? selectedContacts.length : 0;
  return (
    <Box display="inline-block">
      {!!parseInt(selectedListId) && numSelected > 0 && <RemoveFromList />}
      {!!parseInt(selectedListId) && !numSelected && <AddExistingContact />}
    </Box>
  );
};
