export const conditionalInvalidateTags = async (
  queryFulfilled,
  dispatch,
  api,
  tags,
) => {
  try {
    const result = await queryFulfilled;
    if (result.data && result.data.statusCode === 201) {
      dispatch(api.util.invalidateTags(tags));
    }
  } catch (err) {
    console.log(err);
  }
};
