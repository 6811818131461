import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { LabeledOutline } from 'components/Common';
import { BoxInput, ExtraTextForInput } from 'components/Styled';
import { hasValue } from 'helpers';
import {
  DayPickerField,
  MonthPickerField,
  YearPickerField,
} from './components';

export const YearOptionalDatePicker = ({ input, label }) => {
  const [age, setAge] = useState(null);
  const [dateUpcoming, setDateUpcoming] = useState('');

  function getDaysTilLabel(differenceInDays) {
    let dateDisplay;
    if (differenceInDays === 0) {
      dateDisplay = 'Today';
    } else if (differenceInDays === 1) {
      dateDisplay = 'Tomorrow';
    } else {
      dateDisplay = `In ${differenceInDays} days`;
    }
    return dateDisplay;
  }

  useEffect(() => {
    if (input.value?.year && input.value?.month && input.value?.day) {
      const ageDifMs =
        Date.now() -
        new Date(
          input.value.year,
          input.value.month - 1,
          input.value.day,
        ).getTime();
      const ageDate = new Date(ageDifMs);
      setAge(Math.abs(ageDate.getUTCFullYear() - 1970));
      if (
        new Date().getMonth() + 1 === input.value.month &&
        new Date().getDate() <= input.value.day &&
        new Date().getDate() + 30 >= input.value.day
      ) {
        const currentDate = new Date();
        const differenceInDays = input.value.day - currentDate.getDate();
        const dateDisplay = getDaysTilLabel(differenceInDays);
        setDateUpcoming(dateDisplay);
      } else if (
        new Date().getMonth() + 2 === input.value.month &&
        new Date().getDate() > input.value.day
      ) {
        const dateDisplay = getDaysTilLabel(
          30 - new Date().getDate() + input.value.day,
        );
        setDateUpcoming(dateDisplay);
      } else {
        setDateUpcoming('');
      }
    } else {
      setAge(null);
    }
  }, [input.value]);

  const isFilled = hasValue(input.value);

  return (
    <BoxInput mb={1} mt={1}>
      <Grid container>
        <Grid item xs={12} className={isFilled ? 'filled' : ''}>
          <LabeledOutline label={label}>
            <Field {...input} component={MonthPickerField} />
            <Field {...input} component={DayPickerField} />
            <Field {...input} component={YearPickerField} />
            {age && <ExtraTextForInput>{age} years</ExtraTextForInput>}
            {dateUpcoming && (
              <ExtraTextForInput>{dateUpcoming}</ExtraTextForInput>
            )}
          </LabeledOutline>
        </Grid>
      </Grid>
    </BoxInput>
  );
};

YearOptionalDatePicker.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
};
