import {
  getRoleLabel,
  getRoleNameFromWords,
} from 'components/ModelViews/Documents/DocumentPdfViewer/utils/roles';

export const updateRoleInArray = (rolesArray, roleToUpdate) => {
  const roleIndex = rolesArray.findIndex(
    (role) =>
      role.role === roleToUpdate.role && role.order === roleToUpdate.order,
  );

  if (roleIndex === -1) {
    rolesArray.push(roleToUpdate);
  } else if (roleToUpdate.type === 'inactive') {
    rolesArray.splice(roleIndex, 1);
  } else {
    rolesArray[roleIndex] = roleToUpdate;
  }

  return rolesArray;
};

export const getMissingSignerRoleFields = (document) => {
  const signerRoles = document.roles
    .filter((role) => role.type === 'signer')
    .map((role) => role);

  const signatureFields = document.htmlTemplate
    .filter(
      (field) =>
        field.role &&
        (field.type === 'signatureField' ||
          field.type === 'signatureInitialField'),
    )
    .map((field) => field.role);

  return signerRoles.filter((role) => {
    return !signatureFields.includes(getRoleNameFromWords(role));
  });
};

export const removeFieldsWithMissingRoles = (state) => {
  state.currentDocument.htmlTemplate =
    state.currentDocument.htmlTemplate.filter((field) => {
      if (field.role) {
        return state.currentDocument.roles.some(
          (role) => getRoleNameFromWords(role) === field.role,
        );
      }
      return true;
    });
};

export const checkForMissingSignerRolesWithoutFields = (state) => {
  const missingSignerRoleFields = getMissingSignerRoleFields(
    state.currentDocument,
  );

  if (missingSignerRoleFields.length) {
    state.bannerMessages.push({
      severity: 'error',
      bannerMessage: `The following signer roles do not have a signature field: ${missingSignerRoleFields
        .map((role) => getRoleLabel(role))
        .join(', ')}`,
    });
  } else {
    state.bannerMessages = state.bannerMessages.filter(
      (message) =>
        !message.bannerMessage.includes(
          'The following signer roles do not have a signature field:',
        ),
    );
  }
};
