import { Dialog } from '@mui/material';
import { styled } from '@mui/system';

export const BaseDialogWrapper = styled(Dialog)({
  borderRadius: 10,
});

BaseDialogWrapper.defaultProps = {
  fullWidth: false,
  maxWidth: 'sm',
};
