import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'react-final-form';
import { Box, Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CheckboxField,
  Select,
  TextInput,
  ChipSelect,
} from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { ASPECT_RATIO } from 'consts';
import DateFields from 'pages/DocumentTemplatePages/ClickMarketingForm/DateFields';
import { useGetCategoriesListQuery } from 'redux/rtk-query';

export const ClickMarketingForm = ({ ...props }) => {
  const { data } = useGetCategoriesListQuery({
    page: 1,
    limit: 100,
    order: 'asc',
    orderBy: 'name',
    parentId: 15,
  });

  console.log(data);
  const renderFields = () => (
    <Box p={2}>
      <FormPanel startOpen>
        <Grid item xs={12}>
          <Field label="Name" name={'name'} component={TextInput} />
          <Field
            name="aspectRatio"
            label="Aspect Ratio"
            options={ASPECT_RATIO}
            component={Select}
          />
          <Field
            name="description"
            label="Keywords"
            multiline
            helperText={
              <Box display={'flex'} alignItems={'center'}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  fontSize={10}
                  style={{ marginRight: 3 }}
                />
                These should be comma seperated values used for searching Click
                Marketing items.
              </Box>
            }
            component={TextInput}
          />
          <Field
            name="transparentBackground"
            label="Transparent Background?"
            component={CheckboxField}
          />
          <Field
            name="categories"
            label="Categories"
            multiple
            options={data?.data || []}
            component={ChipSelect}
          />
          <DateFields />
        </Grid>
      </FormPanel>
    </Box>
  );

  return <AbeForm {...props}>{renderFields}</AbeForm>;
};
