import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateField } from 'redux/slices';

export const Checkbox = ({ field }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    // Toggle the checked state of the checkbox
    const newCheckedState = !field.value;
    dispatch(
      updateField({
        fieldId: field.id,
        properties: { value: newCheckedState },
      }),
    );
  };
  return (
    <input
      type="checkbox"
      checked={field.value}
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        top: 3,
        left: 3,
        margin: 0,
        width: parseFloat(field.width),
        height: parseFloat(field.height),
        WebkitTransform: 'translate3d(0, 0, 0)',
      }}
      onChange={handleClick}
    />
  );
};

Checkbox.propTypes = {
  field: PropTypes.object.isRequired,
};
