import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { htmlTemplateSelector, roleSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';
import { isFieldVisible } from '../utils/field';

export const useCheckCollisions = (field) => {
  const dispatch = useDispatch();
  const roles = useSelector(roleSelector);
  const htmlTemplate = useSelector(htmlTemplateSelector);

  //
  // const checkCollision = () => {
  //   if (reduxState.item) {
  //     let item = reduxState.item;
  //     let checkForTypes = ["signatureField", "signatureInitialField"];
  //     if (checkForTypes.indexOf(reduxState.item.type) > -1) {
  //       let collisions = [];
  //       let itemArea = {
  //         top: parseFloat(item.top),
  //         left: parseFloat(item.left),
  //         bottom: parseFloat(item.top) + parseFloat(item.height),
  //         right: parseFloat(item.left) + parseFloat(item.width)
  //       }
  //       Object.values(reduxState.items).map(itemB => {
  //         if (checkForTypes.indexOf(itemB.type) > -1) {
  //           if (isVisible(itemB) && itemB.pageContainer == item.pageContainer) {
  //             if (itemB.id !== reduxState.item.id) {
  //               let collision = false;
  //               let itemBCollisions = [];
  //               if (typeof itemB.collisions !== "undefined") {
  //                 itemBCollisions = [...itemB.collisions];
  //               }
  //               let itemBArea = {
  //                 top: parseFloat(itemB.top),
  //                 left: parseFloat(itemB.left),
  //                 bottom: parseFloat(itemB.top) + parseFloat(itemB.height),
  //                 right: parseFloat(itemB.left) + parseFloat(itemB.width)
  //               }
  //               if (itemArea.left >= itemBArea.left
  //                 && itemArea.left <= itemBArea.right
  //                 && itemArea.top >= itemBArea.top
  //                 && itemArea.top <= itemBArea.bottom) {
  //                 collision = true;
  //               } else if (itemArea.right <= itemBArea.right
  //                 && itemArea.right >= itemBArea.left
  //                 && itemArea.top >= itemBArea.top
  //                 && itemArea.top <= itemBArea.bottom) {
  //                 collision = true;
  //               }
  //               else if (itemArea.left >= itemBArea.left
  //                 && itemArea.left <= itemBArea.right
  //                 && itemArea.bottom >= itemBArea.top
  //                 && itemArea.bottom <= itemBArea.bottom) {
  //                 collision = true;
  //               } else if (itemArea.right <= itemBArea.right
  //                 && itemArea.right >= itemBArea.left
  //                 && itemArea.bottom >= itemBArea.top
  //                 && itemArea.bottom <= itemBArea.bottom) {
  //                 collision = true;
  //               }
  //               else if (itemBArea.left >= itemArea.left
  //                 && itemBArea.left <= itemArea.right
  //                 && itemBArea.top >= itemArea.top
  //                 && itemBArea.top <= itemArea.bottom) {
  //                 collision = true;
  //               } else if (itemBArea.right <= itemArea.right
  //                 && itemBArea.right >= itemArea.left
  //                 && itemBArea.top >= itemArea.top
  //                 && itemBArea.top <= itemArea.bottom) {
  //                 collision = true;
  //               }
  //               else if (itemBArea.left >= itemArea.left
  //                 && itemBArea.left <= itemArea.right
  //                 && itemBArea.bottom >= itemArea.top
  //                 && itemBArea.bottom <= itemArea.bottom) {
  //                 collision = true;
  //               } else if (itemBArea.right <= itemArea.right
  //                 && itemBArea.right >= itemArea.left
  //                 && itemBArea.bottom >= itemArea.top
  //                 && itemBArea.bottom <= itemArea.bottom) {
  //                 collision = true;
  //               }
  //
  //               if (collision) {
  //                 collisions.push(itemB.id);
  //                 if (itemBCollisions.indexOf(item.id) === -1) {
  //                   itemBCollisions.push(item.id);
  //                 }
  //               }
  //               else {
  //                 let collisionIndex = itemBCollisions.indexOf(item.id);
  //                 if (collisionIndex > -1) {
  //                   itemBCollisions.splice(collisionIndex, 1);
  //                 }
  //               }
  //               dispatch(updateItemProperty(itemB.id, "collisions", itemBCollisions));
  //
  //             }
  //           }
  //         }
  //       });
  //
  //       dispatch(updateItemProperty(item.id, "collisions", collisions));
  //
  //
  //     }
  //   }
  // }

  const checkCollision = () => {
    if (field) {
      let item = field;
      let checkForTypes = ['signatureField', 'signatureInitialField'];
      if (checkForTypes.indexOf(field.type) > -1) {
        let collisions = [];
        let itemArea = {
          top: parseFloat(item.top),
          left: parseFloat(item.left),
          bottom: parseFloat(item.top) + parseFloat(item.height),
          right: parseFloat(item.left) + parseFloat(item.width),
        };
        htmlTemplate.map((itemB) => {
          if (checkForTypes.indexOf(itemB.type) > -1) {
            if (
              isFieldVisible(itemB, roles) &&
              itemB.pageContainer === item.pageContainer
            ) {
              if (itemB.id !== field.id) {
                let collision = false;
                let itemBCollisions = [];
                if (typeof itemB.collisions !== 'undefined') {
                  itemBCollisions = [...itemB.collisions];
                }
                let itemBArea = {
                  top: parseFloat(itemB.top),
                  left: parseFloat(itemB.left),
                  bottom: parseFloat(itemB.top) + parseFloat(itemB.height),
                  right: parseFloat(itemB.left) + parseFloat(itemB.width),
                };
                if (
                  itemArea.left >= itemBArea.left &&
                  itemArea.left <= itemBArea.right &&
                  itemArea.top >= itemBArea.top &&
                  itemArea.top <= itemBArea.bottom
                ) {
                  collision = true;
                } else if (
                  itemArea.right <= itemBArea.right &&
                  itemArea.right >= itemBArea.left &&
                  itemArea.top >= itemBArea.top &&
                  itemArea.top <= itemBArea.bottom
                ) {
                  collision = true;
                } else if (
                  itemArea.left >= itemBArea.left &&
                  itemArea.left <= itemBArea.right &&
                  itemArea.bottom >= itemBArea.top &&
                  itemArea.bottom <= itemBArea.bottom
                ) {
                  collision = true;
                } else if (
                  itemArea.right <= itemBArea.right &&
                  itemArea.right >= itemBArea.left &&
                  itemArea.bottom >= itemBArea.top &&
                  itemArea.bottom <= itemBArea.bottom
                ) {
                  collision = true;
                } else if (
                  itemBArea.left >= itemArea.left &&
                  itemBArea.left <= itemArea.right &&
                  itemBArea.top >= itemArea.top &&
                  itemBArea.top <= itemArea.bottom
                ) {
                  collision = true;
                } else if (
                  itemBArea.right <= itemArea.right &&
                  itemBArea.right >= itemArea.left &&
                  itemBArea.top >= itemArea.top &&
                  itemBArea.top <= itemArea.bottom
                ) {
                  collision = true;
                } else if (
                  itemBArea.left >= itemArea.left &&
                  itemBArea.left <= itemArea.right &&
                  itemBArea.bottom >= itemArea.top &&
                  itemBArea.bottom <= itemArea.bottom
                ) {
                  collision = true;
                } else if (
                  itemBArea.right <= itemArea.right &&
                  itemBArea.right >= itemArea.left &&
                  itemBArea.bottom >= itemArea.top &&
                  itemBArea.bottom <= itemArea.bottom
                ) {
                  collision = true;
                }

                if (collision) {
                  collisions.push(itemB.id);
                  if (itemBCollisions.indexOf(item.id) === -1) {
                    itemBCollisions.push(item.id);
                  }
                } else {
                  let collisionIndex = itemBCollisions.indexOf(item.id);
                  if (collisionIndex > -1) {
                    itemBCollisions.splice(collisionIndex, 1);
                  }
                }
                dispatch(
                  updateField({
                    fieldId: itemB.id,
                    properties: { collisions: itemBCollisions },
                  }),
                );
              }
            }
          }
        });

        dispatch(
          updateField({
            fieldId: item.id,
            properties: { collisions },
          }),
        );
      }
    }
  };

  useEffect(() => {
    checkCollision();
  }, [field.left, field.top, field.width, field.height]);

  return checkCollision;
};
