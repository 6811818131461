import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ReportChart } from 'pages/ReportPages/DetailsReportPage/ReportChart';
import { useGetChartListQuery } from 'redux/rtk-query';

export const ChartView = ({ reportId }) => {
  const { data: charts } = useGetChartListQuery(reportId);

  const size = charts?.meta.total === 1 ? 12 : 6;
  return (
    <>
      <Grid container>
        {charts?.data.map((chart) => (
          <Grid item key={chart.id} xs={size}>
            <ReportChart reportId={reportId} chartId={chart.id} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ChartView.propTypes = {
  reportId: PropTypes.string.isRequired,
};
