import React from 'react';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';
import { colors } from 'consts';

export const MenuItemIcon = styled(ListItemIcon)(({ theme }) => ({
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontSize: 18,
  minWidth: 40,
  '& span': {
    borderColor: theme.palette.primary.main,
  },
}));

const MenuItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'selectedColor' && prop !== 'noHover',
})(({ selected, selectedColor = colors.MUTED_BLUE, noHover }) => ({
  backgroundColor: selected ? selectedColor : 'inherit',
  color: '#fff',
  '&.Mui-selected': {
    backgroundColor: selectedColor,
  },
  '&.Mui-selected:hover': {
    backgroundColor: selectedColor,
    color: '#fff',
  },
  '& i': {
    color: selectedColor,
  },
  '& .MuiListItemIcon-root': {
    color: selected ? '#fff' : selectedColor,
  },
  '& .MuiTypography-root': {
    color: selected ? '#fff' : selectedColor,
  },
  // Conditionally apply hover styles
  ...(noHover
    ? {
        '&:hover': {
          backgroundColor: 'inherit',
          color: 'inherit',
        },
        '&:hover .MuiListItemIcon-root': {
          color: 'inherit',
        },
        '&:hover .MuiListItemText-primary': {
          color: '#fff',
        },
      }
    : {
        '&:hover': {
          backgroundColor: selectedColor,
          color: '#fff',
        },
        '&:hover .MuiListItemIcon-root': {
          color: '#fff',
        },
        '&:hover .MuiListItemText-primary': {
          color: '#fff',
        },
      }),
}));

export const MenuItemButton = ({
  icon,
  label,
  secondaryLabel,
  onClick = () => {},
  isSelected = false,
  isGroup = false,
  rightAction,
  dense = false,
  selectedColor,
  noHover = false,
  ...other
}) => {
  return (
    <>
      {dense ? (
        <MenuItem
          {...other}
          dense
          selected={isSelected}
          onClick={onClick}
          selectedColor={selectedColor}
          noHover={noHover}
        >
          {icon && (
            <MenuItemIcon>
              {icon.prefix ? (
                <FontAwesomeIconComp icon={icon} fontSize={'1.8rem'} />
              ) : (
                icon
              )}
            </MenuItemIcon>
          )}
          <ListItemText primary={label} secondary={secondaryLabel} />
          {isGroup && (
            <MenuItemIcon>
              <FontAwesomeIconComp
                icon={isSelected ? faChevronUp : faChevronDown}
              />
            </MenuItemIcon>
          )}
        </MenuItem>
      ) : (
        <MenuItem
          {...other}
          selected={isSelected}
          onClick={onClick}
          selectedColor={selectedColor}
          noHover={noHover}
        >
          {icon && (
            <MenuItemIcon>
              {icon.prefix ? (
                <FontAwesomeIconComp icon={icon} fontSize={'1.8rem'} />
              ) : (
                icon
              )}
            </MenuItemIcon>
          )}
          <ListItemText
            color={'primary'}
            primary={label}
            sx={{
              ml: 2,
              '& span': {
                color: isSelected ? '#fff' : selectedColor,
              },
            }}
          />
          {isGroup && (
            <MenuItemIcon>
              <FontAwesomeIconComp
                icon={isSelected ? faChevronUp : faChevronDown}
              />
            </MenuItemIcon>
          )}
          {rightAction && rightAction}
        </MenuItem>
      )}
    </>
  );
};

MenuItemButton.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondaryLabel: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  dense: PropTypes.bool,
  isGroup: PropTypes.bool,
  rightAction: PropTypes.object,
  selectedColor: PropTypes.string,
  noHover: PropTypes.bool, // Add noHover to propTypes
};
