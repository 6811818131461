// utils/invalidateTags.js
import { apiSlices } from 'store';

export const invalidateAllTags = (dispatch) => {
  apiSlices.forEach((apiSlice) => {
    dispatch(apiSlice.util.resetApiState());
  });
};

export const resetAllApiSlices = (dispatch) => {
  apiSlices.forEach((apiSlice) => {
    dispatch(apiSlice.util.resetApiState());
  });
};
