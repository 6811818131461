import React from 'react';
import PropTypes from 'prop-types';
import { DocumentPdfViewer } from 'components/ModelViews';

export const ClosingStatementPreview = ({ closingStatementData }) => {
  return (
    <div style={{ height: '84vh', overflowY: 'auto' }}>
      {closingStatementData && (
        <DocumentPdfViewer documentData={closingStatementData} preview />
      )}
    </div>
  );
};

ClosingStatementPreview.propTypes = {
  closingStatementData: PropTypes.object,
};
