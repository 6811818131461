import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIconComp } from 'components/Common';
import { getColorByStatus, getIconByStatus, getStatus } from 'helpers';

export const DocumentItemIcon = ({ item }) => {
  let status = getStatus(item);
  let icon = getIconByStatus(status);
  let color = getColorByStatus(status);
  return <FontAwesomeIconComp icon={icon} color={color} />;
};

DocumentItemIcon.propTypes = {
  item: PropTypes.object,
};
