import React from 'react';
import { faFill, faBorderStyle } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';
import BorderRadiusMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/BorderRadiusMenuItem';
import ColorPickerMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/ColorPickerMenuItem';
import LineHeightWidthMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/LineHeightWidthMenuItem';
import OpacityMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/OpacityMenuItem';
import OptionsMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/OptionsMenuItem';
import { CM_ITEM_TYPES } from 'consts';
import { selectCurrentItem } from 'redux/selectors';

export const ShapeToolbar = () => {
  const currentItem = useSelector(selectCurrentItem);
  const showBorderColor = currentItem.type !== CM_ITEM_TYPES.shapes.line;
  const showBorderRadius = currentItem.type === CM_ITEM_TYPES.shapes.rectangle;

  return (
    <>
      <ColorPickerMenuItem
        showGradient
        showThemeColor
        showCustomColor
        showMainframeThemeColorOptions
        styleProperty={'backgroundColor'}
        label={'Fill'}
        icon={faFill}
      />
      {showBorderColor && (
        <ColorPickerMenuItem
          showCustomColor
          showThemeColor
          showMainframeThemeColorOptions
          styleProperty={'borderColor'}
          label={'Border'}
          icon={faBorderStyle}
        />
      )}
      {showBorderRadius && <BorderRadiusMenuItem />}
      <OpacityMenuItem />
      <LineHeightWidthMenuItem />
      <Divider />
      <OptionsMenuItem />
    </>
  );
};
