import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { EmptyResults, SkeletonSummary } from 'components/Common';
import { ListPagination } from 'components/ListPagination';
import { CommissionSummary } from 'components/ModelViews';
import { ListBox } from 'components/Styled';
import { STATUS_OPTIONS } from 'consts';
import { useTableData } from 'hooks';
import { useGetTransactionCommissionsQuery } from 'redux/rtk-query';
import CommissionTopInfo from './components/CommissionTopInfo';

export const CommissionsList = ({ relatedModel, overview }) => {
  const {
    loading,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    totalPages,
    totalCount,
    rows,
  } = useTableData(
    useGetTransactionCommissionsQuery,
    [],
    'updatedAt',
    'asc',
    5,
    {
      uuid: relatedModel.uuid,
      period: relatedModel.period,
    },
  );

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <ListBox>
        {relatedModel.status !== STATUS_OPTIONS.PROPERTY_MGMT.value &&
          !overview && (
            <>
              <CommissionTopInfo
                loading={loading}
                rows={rows}
                relatedModel={relatedModel}
              />
              <Divider />
            </>
          )}
        {loading ? (
          <SkeletonSummary />
        ) : rows && rows.length ? (
          rows.map((commission, index) => (
            <React.Fragment key={commission.id}>
              <CommissionSummary commission={commission} overview={overview} />
              {index + 1 < rows.length && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <EmptyResults />
        )}
        <Box style={{ textAlign: 'center' }}>
          {totalPages > 0 && (
            <ListPagination
              totalCount={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[5]}
            />
          )}
        </Box>
      </ListBox>
    </>
  );
};

CommissionsList.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  relatedModel: PropTypes.object,
  relatedModelType: PropTypes.string,
  overview: PropTypes.bool,
};
