import React from 'react';
import { useSelector } from 'react-redux';
import { pixelSizeSelector } from 'redux/selectors';

export const GridCoordinates = () => {
  const [width, height] = useSelector(pixelSizeSelector);
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const gridSize = 20; // Given the grid size is 20px x 20px

  // Use Math.ceil to always round up
  const stepsX = Math.ceil(width / gridSize);
  const stepsY = Math.ceil(height / gridSize);

  const renderCoordinate = (coordinate, axis) => (
    <div
      className="coordinate"
      style={{
        position: 'absolute',
        [axis === 'x' ? 'left' : 'top']:
          `${axis === 'x' ? halfWidth + coordinate : halfHeight + coordinate}px`,
      }}
    >
      {(coordinate / gridSize) * 5}
    </div>
  );

  return (
    <>
      <div className="gridOverlay">
        <div className="grid-coordinates">
          <div className="x-coords">
            {Array.from({ length: stepsX }, (_, i) => {
              const coordinate = (i - stepsX / 2) * gridSize;
              return coordinate % (2 * gridSize) === 0
                ? renderCoordinate(coordinate, 'x')
                : null;
            })}
          </div>
          <div className="y-coords">
            {Array.from({ length: stepsY }, (_, i) => {
              const coordinate = (i - stepsY / 2) * gridSize;
              return coordinate % (2 * gridSize) === 0
                ? renderCoordinate(coordinate, 'y')
                : null;
            })}
          </div>
        </div>
      </div>
      <div className="center-line vertical"></div>
      <div className="center-line horizontal"></div>
    </>
  );
};
