import React from 'react';
import { faHouse } from '@fortawesome/pro-duotone-svg-icons';
import { Box } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';

export const TransactionPlaceholderImage = () => {
  return (
    <Box
      display={'flex'}
      height={90}
      alignItems={'center'}
      textAlign={'center'}
      justifyContent={'center'}
      flex={1}
      sx={{ opacity: 0.5 }}
    >
      <Box>
        <FontAwesomeIconComp color={'#000'} icon={faHouse} size={'3x'} />
      </Box>
    </Box>
  );
};

TransactionPlaceholderImage.propTypes = {};
