// BarChartVertical.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Tooltip,
  Legend,
);

const BarChartVertical = ({ data, options }) => {
  return (
    <Bar
      data={data}
      options={{
        ...options,
        plugins: {
          ...options.plugins,
          datalabels: {
            display: false,
          },
        },
      }}
    />
  );
};

BarChartVertical.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default BarChartVertical;
