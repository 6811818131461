import React from 'react';
import PropTypes from 'prop-types';
import { SearchInput } from 'components/SearchInput';
import { ToolbarContainer, ToolbarRow, ToolbarTitle } from './styled';

export const TableToolbar = ({
  title,
  handleSearch,
  toolbarActions,
  afterSearch,
}) => (
  <ToolbarContainer>
    {(title || toolbarActions) && (
      <ToolbarRow flex="none" whiteSpace={'nowrap'}>
        {title && <ToolbarTitle>{title}</ToolbarTitle>}
        {toolbarActions && toolbarActions}
      </ToolbarRow>
    )}
    <ToolbarRow width="100%" pl={1} justifyContent="space-between">
      <SearchInput changeHandler={handleSearch} />
      {afterSearch && afterSearch}
    </ToolbarRow>
  </ToolbarContainer>
);

TableToolbar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleSearch: PropTypes.func,
  toolbarActions: PropTypes.node,
  afterSearch: PropTypes.node,
  totalCount: PropTypes.number,
};
