// Value.js
import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import { Select, TextInput } from 'components/Fields';
import { getSelectOptions } from 'helpers';

export const Value = ({ name }) => {
  const filterType = useField(`${name}.type`).input.value;
  // Access the 'condition' field value
  const {
    input: { value: condition },
  } = useField(`${name}.condition`, { subscription: { value: true } });

  // Access the 'enum' field value
  const {
    input: { value: fieldEnum },
  } = useField(`${name}.enum`, { subscription: { value: true } });

  const isOneOfCondition = condition === 'One Of';
  const hasEnumValues =
    (Array.isArray(fieldEnum) && fieldEnum.length > 0) ||
    filterType === 'boolean';

  const enumValues = fieldEnum || ['Yes', 'No'];

  if (hasEnumValues) {
    // Use Select component with enum values
    return (
      <div style={{ marginLeft: '8px', minWidth: '100px' }}>
        <Field
          name={`${name}.value`}
          key={`${name}.value-${isOneOfCondition ? 'multi' : 'single'}`}
          label={isOneOfCondition ? 'Values' : 'Value'}
          defaultValue={isOneOfCondition ? [] : ''}
          multiple={isOneOfCondition}
          component={Select}
          options={getSelectOptions(enumValues)}
          renderValue={(selected) =>
            isOneOfCondition && Array.isArray(selected)
              ? selected.join(', ')
              : selected
          }
        />
      </div>
    );
  } else {
    // Use TextField for other cases
    if (filterType === 'date') return;
    return (
      <div style={{ marginLeft: '8px' }}>
        <Field
          name={`${name}.value`}
          key={`${name}.value-text`}
          component={TextInput}
        />
      </div>
    );
  }
};

Value.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Value;
