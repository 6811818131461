import React from 'react';
import {
  faPlus,
  faFont,
  faImage,
  faShapes,
  faCircleXmark,
} from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import {
  CollapsableMenuGroup,
  FontAwesomeIconComp,
  MenuItemButton,
  SideDrawer,
} from 'components/Common';
import ImagePlaceholderSelection from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ImagePlaceholderSelection';
import AddLabeledItemMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/AddLabeledItemMenuItem';
import { Header, HeadingSection } from 'components/Styled';
import { CM_ITEM_TYPES } from 'consts';
import { addItemCM } from 'redux/slices';
import { addItem } from 'redux/slices/clickMarketing/helpers';

export const AddItemsMenuGroup = () => {
  const dispatch = useDispatch();
  const [showImagePlaceholder, setShowImagePlaceholder] = React.useState(false);

  const addField = async (type) => {
    const itemSettings = await dispatch(addItem(type));
    dispatch(addItemCM(itemSettings));
  };
  return (
    <>
      <CollapsableMenuGroup
        hideChevron
        label={'Add Item'}
        icon={<FontAwesomeIconComp fontSize={18} icon={faPlus} />}
      >
        <CollapsableMenuGroup
          dense
          hideChevron
          label={'Text'}
          icon={<FontAwesomeIconComp fontSize={18} icon={faFont} />}
          sx={{ paddingLeft: 1.5, '& .MuiListItemText-dense': { pl: 1 } }}
        >
          <MenuItemButton
            dense
            label={'Fill Now'}
            onClick={() => {
              addField(CM_ITEM_TYPES.text.fillnow);
            }}
          />
          <MenuItemButton
            dense
            label={'Auto-fill'}
            onClick={() => {
              addField(CM_ITEM_TYPES.text.autofill);
            }}
          />
        </CollapsableMenuGroup>
        <MenuItemButton
          dense
          icon={<FontAwesomeIconComp fontSize={18} icon={faImage} />}
          label={'Image'}
          sx={{ paddingLeft: 1.5, '& .MuiListItemText-dense': { pl: 1 } }}
          onClick={() => {
            setShowImagePlaceholder(true);
          }}
        />
        <CollapsableMenuGroup
          dense
          hideChevron
          label={'Shape'}
          icon={<FontAwesomeIconComp fontSize={18} icon={faShapes} />}
          sx={{ paddingLeft: 1.5, '& .MuiListItemText-dense': { pl: 1 } }}
        >
          <MenuItemButton
            dense
            label={'Rectangle'}
            onClick={() => {
              addField(CM_ITEM_TYPES.shapes.rectangle);
            }}
          />
          <MenuItemButton
            dense
            label={'Circle'}
            onClick={() => {
              addField(CM_ITEM_TYPES.shapes.circle);
            }}
          />
          <MenuItemButton
            dense
            label={'Line'}
            onClick={() => {
              addField(CM_ITEM_TYPES.shapes.line);
            }}
          />
        </CollapsableMenuGroup>
        <AddLabeledItemMenuItem />
      </CollapsableMenuGroup>
      <SideDrawer
        width={'40%'}
        isOpen={showImagePlaceholder}
        onClose={() => setShowImagePlaceholder(false)}
      >
        <HeadingSection>
          <Header variant="h4">Select Image Type</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowImagePlaceholder(false)}
          />
        </HeadingSection>
        <ImagePlaceholderSelection
          onClose={() => setShowImagePlaceholder(false)}
        />
      </SideDrawer>
    </>
  );
};
