import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmMenuItem } from 'components/Common';

export const CancelEsignComponent = ({ onBackToMenu }) => {
  return (
    <ConfirmMenuItem
      title={'Cancel E-sign?'}
      message={'Are you sure you want to cancel e-signing for this document?'}
      onConfirm={onBackToMenu}
      onCancel={onBackToMenu}
      confirmText={'Confirm'}
      confirmColor={'error'}
      cancelText={'Go Back'}
    />
  );
};

CancelEsignComponent.propTypes = {
  onBackToMenu: PropTypes.func,
};
