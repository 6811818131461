import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useGetMediaUrl from 'hooks/useGetMediaUrl';

function AbeFileImage({ file, identifierValue, identifier, style }) {
  const [fileSrc, setFileSrc] = useState(null);

  useEffect(() => {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const { data: fileData } = useGetMediaUrl(file, identifier, identifierValue);
  useEffect(() => {
    if (fileData && fileData.data) {
      setFileSrc(fileData.data.url);
    }
  }, [fileData]);

  return (
    <>
      {fileSrc ? (
        <img width={'100%'} src={fileSrc} alt={file.name} style={style} />
      ) : null}
    </>
  );
}

export default AbeFileImage;

AbeFileImage.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  identifierValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  identifier: PropTypes.string,
  style: PropTypes.object,
};
