import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';

function ItemLabelText({ textLabel, insideLabel }) {
  return (
    <Box
      className={`show-on-hover text-label ${insideLabel ? 'insideLabel' : ''}`}
    >
      {textLabel}
    </Box>
  );
}

ItemLabelText.propTypes = {
  textLabel: PropTypes.string,
  insideLabel: PropTypes.bool,
};

export default ItemLabelText;
