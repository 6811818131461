import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import { SelectPlaceholderText } from 'components/Styled';

export const MonthPickerField = ({
  input,
  availablePeriods = [],
  year,
  sx = {},
}) => {
  const monthOptions = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];

  const handleMonthChange = (event) => {
    input.onChange({
      ...input.value,
      month: event.target.value,
    });
  };

  const isMonthDisabled = (month) => {
    return !availablePeriods.some((p) => p.month === month && p.year === year);
  };

  return (
    <>
      <Select
        {...input}
        size="small"
        displayEmpty
        IconComponent={() => null}
        MenuProps={{
          sx: {
            maxHeight: 300,
          },
        }}
        sx={{
          border: 0,
          maxHeight: 200,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '& .MuiOutlinedInput-input': {
            padding: 0,
            paddingRight: '0 !important',
            textAlign: 'center',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          ...sx,
        }}
        onChange={handleMonthChange}
        value={input.value?.month || ''}
      >
        <MenuItem value="">
          <SelectPlaceholderText>Month</SelectPlaceholderText>
        </MenuItem>
        {monthOptions.map(({ value, label }) => {
          return (
            <MenuItem
              key={value}
              value={value}
              disabled={isMonthDisabled(value)}
            >
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

MonthPickerField.propTypes = {
  input: PropTypes.object,
  availablePeriods: PropTypes.array,
  year: PropTypes.number,
  sx: PropTypes.object,
};
