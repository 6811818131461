import React from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { Select } from 'components/Fields';
import { useTransactionOptions } from 'hooks';

export const StatusField = () => {
  const form = useForm();
  const { statusOptions } = useTransactionOptions(form);
  return (
    <Grid item xs={12}>
      <Field
        label="Status"
        name={'status'}
        component={Select}
        options={statusOptions}
        tooltip={{
          title:
            'Choose the status of the account you are entering. Each status requires different documents (except Referrals do not require documents).',
        }}
      />
    </Grid>
  );
};
