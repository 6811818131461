import React from 'react';
import PropTypes from 'prop-types';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BoxInput, ErrorMsg, TextInputField } from 'components/Styled';
import { isAdmin } from 'helpers';

export const DatePicker = ({
  label,
  size,
  inputFormat,
  input,
  meta,
  readOnlyWhenNotEmpty,
}) => (
  <BoxInput>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        label={label}
        size={size}
        readOnly={!isAdmin() && readOnlyWhenNotEmpty && input.value}
        error={meta.error && meta.touched}
        fullWidth
        inputFormat={inputFormat}
        {...input}
        value={input.value || null}
        renderInput={(params) => <TextInputField size={size} {...params} />}
      />
    </LocalizationProvider>
    {meta.error && meta.touched && (
      <ErrorMsg component={'span'}>{meta.error}</ErrorMsg>
    )}
  </BoxInput>
);

DatePicker.defaultProps = {
  size: 'small',
  inputFormat: 'MM/DD/YYYY',
};

DatePicker.propTypes = {
  readOnlyWhenNotEmpty: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  inputFormat: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};
