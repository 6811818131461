import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DetailsPanels } from 'components/Common';
import { CardContentRow } from 'components/Styled';
import { conversion } from 'helpers';

const getPercentageAndFlatRate = (percentage, flatRate) => {
  let commission = percentage || 0;
  let bonusOrFlatRate = flatRate || 0;

  if (commission === 0 && bonusOrFlatRate === 0) {
    return '0% + 0';
  }
  if (parseInt(commission) === 0) {
    return `${conversion.formatCurrency(bonusOrFlatRate)}`;
  }
  if (parseInt(bonusOrFlatRate) === 0) {
    return `${conversion.formatPercentage(commission)}`;
  }
  return `${conversion.formatPercentage(
    commission,
  )} + ${conversion.formatCurrency(bonusOrFlatRate)}`;
};

const formatTypes = {
  contractPrice: 'currency',
  grossCommission: 'currency',
  grossCommissionAfterReferral: 'currency',
};

const translatedLabels = {
  percentageAndFlatRate: 'Percentage and/or Flat Rate',
  referralPercentageAndFlatRate: 'Referral Percentage and/or Flat Rate',
};

function CommissionTopInfo({ rows, relatedModel, loading }) {
  const panelAttributes = useMemo(() => {
    const attributes = [
      'contractPrice',
      'percentageAndFlatRate',
      'grossCommission',
    ];

    if (
      parseFloat(relatedModel.referralCommission) > 0 ||
      parseFloat(relatedModel.referralFlatRate) > 0
    ) {
      attributes.push(
        'grossCommissionAfterReferral',
        'referralPercentageAndFlatRate',
      );
    }

    return attributes;
  }, [relatedModel]);

  const combinedData = useMemo(
    () => ({
      contractPrice: rows[0]?.contractPrice || 0,
      grossCommission: rows[0]?.grossCommission || 0,
      grossCommissionAfterReferral: rows[0]?.grossCommissionAfterReferral || 0,
      percentageAndFlatRate: getPercentageAndFlatRate(
        relatedModel.commission,
        relatedModel.bonusOrFlatRate,
      ),
      referralPercentageAndFlatRate: getPercentageAndFlatRate(
        relatedModel.referralCommission,
        relatedModel.referralFlatRate,
      ),
    }),
    [rows, relatedModel],
  );

  if (loading) {
    return null;
  }

  return (
    <CardContentRow>
      <Box display="flex" gap={1} flexDirection="column">
        <DetailsPanels
          data={combinedData}
          formatTypes={formatTypes}
          translatedLabels={translatedLabels}
          panels={{ '': panelAttributes }}
        />
      </Box>
    </CardContentRow>
  );
}

CommissionTopInfo.propTypes = {
  loading: PropTypes.bool,
  rows: PropTypes.array,
  relatedModel: PropTypes.object,
};

export default CommissionTopInfo;
