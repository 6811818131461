import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/system';
import { MarketingCreateView } from 'components/ModelViews/Marketing/MarketingCreateView';
import { ClickMarketingGallery } from 'components/ModelViews/Marketing/MarketingCreateView/components/ClickMarketingGallery';
import { PageMainContent } from 'components/Styled';

export const MarketingPage = () => {
  const [createType, setCreateType] = React.useState(null);

  return (
    <>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <Box width={'100%'}>
          <ClickMarketingGallery />
        </Box>
      </PageMainContent>
      <Outlet />
      <MarketingCreateView
        onClose={() => setCreateType(null)}
        createType={createType}
      />
    </>
  );
};
