import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmMenuItem } from 'components/Common';
import { STATUSES } from 'consts';
import { useUpdateDocumentMutation } from 'redux/rtk-query';

export const UnVoidComponent = ({ onBackToMenu, handleCloseMenu, item }) => {
  const [updateDocument] = useUpdateDocumentMutation();

  const onConfirm = () => {
    updateDocument({ id: item.id, status: STATUSES.DRAFT });
    handleCloseMenu(true);
  };

  return (
    <ConfirmMenuItem
      title="Un-Void"
      message={'Are you sure you want to un-void this document?'}
      onConfirm={onConfirm}
      onCancel={onBackToMenu}
      confirmText={'Confirm'}
      confirmColor={'error'}
      cancelText={'Go Back'}
    />
  );
};

UnVoidComponent.propTypes = {
  onBackToMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  item: PropTypes.object,
};
