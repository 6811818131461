import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { useGetSvgContentQuery } from 'redux/rtk-query';
import { activePageCM, customColorSelectorCM } from 'redux/selectors';

export const SvgImageComponent = ({ SvgComponent, fillColor }) => {
  const svgRef = useRef(null);
  const customColor = useSelector(customColorSelectorCM);
  const activePageId = useSelector(activePageCM);

  const isUrl = typeof SvgComponent === 'string';
  console.log('SvgComponent', SvgComponent);

  const getFillColor = useCallback(() => {
    if (fillColor === 'light' && customColor === 'mainframeTheme') {
      return null;
    }
    switch (fillColor) {
      case 'dark':
        return '#231F20';
      case 'light':
        return '#fff';
      case 'themeColor':
        return customColor;
      case 'color':
        return null;
      default:
        return fillColor;
    }
  }, [fillColor, customColor]);

  const fillColorHex = getFillColor();

  // Use RTK Query to fetch SVG content when SvgComponent is a URL
  const {
    data: svgContent,
    error,
    isLoading,
  } = useGetSvgContentQuery(SvgComponent, { skip: !isUrl });

  useEffect(() => {
    if (svgRef.current) {
      const svgElement = svgRef.current;
      svgElement.querySelectorAll('svg').forEach((svg) => {
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '100%');
      });
      // Get all elements that can be filled in the SVG
      const elements = svgElement.querySelectorAll(
        'path, rect, circle, polygon, polyline, line, ellipse',
      );

      elements.forEach((element) => {
        if (fillColorHex) {
          // If we have a fillColorHex, override the fill
          if (!element.hasAttribute('data-original-fill')) {
            element.setAttribute(
              'data-original-fill',
              element.getAttribute('fill') || 'none',
            );
          }
          element.setAttribute('fill', fillColorHex);
        } else {
          // No fillColorHex, restore original fill if stored
          if (element.hasAttribute('data-original-fill')) {
            element.setAttribute(
              'fill',
              element.getAttribute('data-original-fill'),
            );
            element.removeAttribute('data-original-fill'); // Optional: Clean up the attribute
          }
        }
      });
    }
  }, [fillColorHex, svgContent, activePageId]);

  if (isUrl && isLoading) {
    return <></>;
  }

  if (isUrl && error) {
    return <div>Error loading SVG</div>;
  }

  return (
    <>
      {isUrl ? (
        svgContent && (
          <Box
            sx={{ width: '100%', height: '100%', display: 'flex' }}
            ref={svgRef}
            dangerouslySetInnerHTML={{ __html: svgContent }}
          />
        )
      ) : (
        <SvgComponent
          ref={svgRef}
          style={{ width: '100%', height: '100%', display: 'flex' }}
        />
      )}
    </>
  );
};

SvgImageComponent.propTypes = {
  SvgComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string])
    .isRequired,
  fillColor: PropTypes.string,
};
