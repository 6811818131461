import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { UPDATE_TRANSACTION } from 'consts';

export function TransactionOptionsButton() {
  let { transactionDetailsId } = useParams();
  const navigate = useNavigate();
  return (
    <Button
      size="small"
      onClick={() => {
        navigate(UPDATE_TRANSACTION(transactionDetailsId));
      }}
    >
      Edit
    </Button>
  );
}
