import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Box, styled } from '@mui/system';
import { MenuButton, MenuItemButton } from 'components/Common';

const StyledBox = styled(Box)(({ theme }) => ({
  maxHeight: '300px',
  overflowY: 'auto',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const sizes = {
  Square: '1:1',
  Letter: '8.5:11',
  'Letter Landscape': '11:8.5',
  Story: '9:16',
  'Cover Photo': '42:20',
};

export const SizesDropDown = ({ selectedSizes, setSizes }) => {
  return (
    <MenuButton
      fullWidth
      color={'info'}
      sx={{ padding: 0, width: '100%' }}
      size={'x-small'}
      title={
        <MenuItemButton
          noHover
          label={
            selectedSizes.length > 0
              ? `${selectedSizes.length} ${selectedSizes.length > 1 ? 'Sizes' : 'Size'}`
              : 'All Sizes'
          }
          sx={{ width: '100%' }}
        />
      }
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <StyledBox>
        {Object.entries(sizes).map(([key, value]) => (
          <Button
            key={key}
            size={'small'}
            onClick={() => {
              if (selectedSizes.includes(value)) {
                setSizes(selectedSizes.filter((size) => size !== value));
              } else {
                setSizes([...selectedSizes, value]);
              }
            }}
            variant={selectedSizes.includes(value) ? 'contained' : 'text'}
            color={selectedSizes.includes(value) ? 'info' : 'primary'}
          >
            {key}
          </Button>
        ))}
      </StyledBox>
    </MenuButton>
  );
};

SizesDropDown.propTypes = {
  selectedSizes: PropTypes.array,
  setSizes: PropTypes.func,
};
