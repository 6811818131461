import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import { AbeCard, ModuleIcon } from 'components/Common';
import { InvoiceRow } from 'pages/TransactionsPages/DetailsTransactionPage/components/TransactionDashboard/components/PropertyMgmtInvoicesCards/components/components';
import { useGetTransactionInvoicesQuery } from 'redux/rtk-query';

export const CommissionInvoices = () => {
  const { transactionDetailsId } = useParams();

  const { data: transactionInvoiceData } = useGetTransactionInvoicesQuery(
    {
      id: transactionDetailsId,
      type: ['Commission'],
      limit: 100,
    },
    { skip: !transactionDetailsId },
  );

  const transactionInvoices = transactionInvoiceData?.data?.items || [];

  return (
    <>
      <AbeCard
        icon={<ModuleIcon module={'Invoices'} />}
        title={'Commission Invoices'}
      >
        <Divider />
        <Box>
          {transactionInvoices.length > 0 && (
            <Box px={1.5} pt={1}>
              {transactionInvoices.map((invoice) => {
                return (
                  <Box key={invoice.id} py={0.5}>
                    <InvoiceRow invoice={invoice} showPaidTo />
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </AbeCard>
    </>
  );
};
