import React from 'react';
import {
  faDownload,
  faFolder,
  faLoader,
  faPaperPlane,
  faRedo,
  faSave,
  faUndo,
} from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  CollapsableMenuGroup,
  FontAwesomeIconComp,
  MenuItemButton,
} from 'components/Common';
import {
  hasRedoHistorySelector,
  hasUndoHistorySelector,
  isTemplateViewSelector,
} from 'redux/selectors';
import { redo, saveHtmlTemplate, setMenu, undo } from 'redux/slices';

export const FileMenuGroup = () => {
  const dispatch = useDispatch();
  const hasUndoHistory = useSelector(hasUndoHistorySelector);
  const hasRedoHistory = useSelector(hasRedoHistorySelector);
  const isTemplateView = useSelector(isTemplateViewSelector);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleSaveDocument = async () => {
    setIsSaving(true);
    await dispatch(saveHtmlTemplate());
    setIsSaving(false);
  };

  return (
    <CollapsableMenuGroup
      label={'File'}
      icon={<FontAwesomeIconComp fontSize={18} icon={faFolder} />}
    >
      {!isTemplateView && (
        <MenuItemButton
          icon={<FontAwesomeIconComp fontSize={18} icon={faPaperPlane} />}
          label={'Ready to Sign'}
          onClick={() => {
            dispatch(setMenu('finalizeDocument'));
          }}
        />
      )}
      <MenuItemButton
        icon={
          isSaving ? (
            <FontAwesomeIconComp icon={faLoader} spin />
          ) : (
            <FontAwesomeIconComp fontSize={18} icon={faSave} />
          )
        }
        label={'Save'}
        onClick={handleSaveDocument}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp fontSize={18} icon={faDownload} />}
        label={'Download'}
        onClick={() => {}}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp fontSize={18} icon={faUndo} />}
        label={'Undo'}
        disabled={!hasUndoHistory}
        onClick={() => {
          dispatch(undo());
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp fontSize={18} icon={faRedo} />}
        label={'Redo'}
        disabled={!hasRedoHistory}
        onClick={() => {
          dispatch(redo());
        }}
      />
    </CollapsableMenuGroup>
  );
};
