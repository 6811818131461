// src/features/userLoginSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {}, // optional user info from your backend
  isAuthenticated: false,
  tokens: {
    access: '',
    refresh: '',
  },
};

export const userLoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserLogin: (state, action) => {
      // Example payload shape: { token: '...', refresh: '...', userInfo: {...} }
      state.isAuthenticated = !!action.payload.token;
      state.tokens.access = action.payload.token;
      state.tokens.refresh = action.payload.refresh || '';
      state.userInfo = action.payload.userInfo || {};
    },
    setUserLogout: (state) => {
      state.isAuthenticated = false;
      state.tokens.access = '';
      state.tokens.refresh = '';
      state.userInfo = {};
    },
    setGoogleUserInfo: (state, action) => {
      state.googleUserInfo = action.payload.Av;
    },
  },
});
export const { setUserLogin, setUserLogout, setGoogleUserInfo } =
  userLoginSlice.actions;
export const userLoginSliceReducer = userLoginSlice.reducer;
