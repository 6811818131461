import React from 'react';
import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { ButtonSection, PageMainContent } from 'components/Styled';
import { ADMIN_ROUTES } from 'consts/routes';
import { CompanyUnitsTable } from './CompanyUnitsTable';

export const CompanyUnitsPage = () => (
  <>
    <LayoutHeader title={'Company Units'}>
      <ButtonSection>
        <CreateButton
          path={ADMIN_ROUTES.CREATE_COMPANY_UNIT.path}
          title="New Company Unit"
        />
      </ButtonSection>
    </LayoutHeader>
    <PageMainContent maxWidth={false} component="main" disableGutters>
      <CompanyUnitsTable />
    </PageMainContent>
  </>
);
