import { endpoints } from 'consts';

export const companyUnitUrlBuilder = {
  getCompanyUnits: function () {
    return endpoints.companyUnitsList;
  },
  getCompanyUnit: function (id) {
    return `${endpoints.companyUnit}/${id}`;
  },
  createCompanyUnit: function () {
    return endpoints.companyUnit;
  },
  editCompanyUnit: function () {
    return endpoints.companyUnit;
  },
  deleteCompanyUnit: function (id) {
    return `${endpoints.companyUnit}/${id}`;
  },
  getCompanyUnitUsers: function (id) {
    return `${endpoints.companyUnit}/${id}/users`;
  },
};
