import React from 'react';
import PropTypes from 'prop-types';
import {
  faEllipsis,
  faPen,
  faStar,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import {
  useAddToFavouriteMutation,
  useDeleteListMutation,
  useRemoveFromFavoriteMutation,
} from 'redux/rtk-query';
import { setEditingList } from 'redux/slices';
import {
  ListTab,
  ListTabCount,
  ListTabMenu,
  ListTabText,
  Popper,
} from '../../styled';

export const ListTabComponent = ({
  isFavourite,
  name,
  uuid,
  getClickHandler,
  id,
  contactCount,
  type,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [addFavorite] = useAddToFavouriteMutation();
  const [removeFavorite] = useRemoveFromFavoriteMutation();
  const [remove] = useDeleteListMutation();
  const { listId } = useParams();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleRemove = (e) => {
    e.stopPropagation();
    removeFavorite({
      contactsListsIds: [parseInt(listId)],
    });
    setOpen(false);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    dispatch(setEditingList(uuid));
    setOpen(false);
  };

  const handleAdd = (e) => {
    e.stopPropagation();
    addFavorite({
      contactsListsIds: [parseInt(listId)],
    });
    setOpen(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    remove({
      uuid: uuid,
    });
    navigate('/contacts/list/contact/');
    setOpen(false);
  };

  const menuItems = [
    {
      name: isFavourite ? 'Remove from Favorite' : 'Add to Favorite',
      icon: faStar,
      onClick: isFavourite ? handleRemove : handleAdd,
    },
    { name: 'Rename', icon: faPen, onClick: handleEdit },
    { name: 'Delete', icon: faTrashCan, onClick: handleDelete },
  ];

  return (
    <ListTab
      isSelected={
        parseInt(listId) === parseInt(id) ||
        (!type && !listId && !id) ||
        (type && listId === type)
      }
      onClick={() => getClickHandler(name, uuid)}
    >
      <ListTabText variant="h6">{name}</ListTabText>
      <ListTabCount component="span">{contactCount}</ListTabCount>
      {!!parseInt(id) && (
        <ListTabMenu>
          <Button
            component={'div'}
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <FontAwesomeIconComp icon={faEllipsis} color="#969696" />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                  borderRadius: '8px',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {menuItems.map((item) => (
                        <MenuItem key={item.name} onClick={item.onClick}>
                          <FontAwesomeIconComp
                            icon={item.icon}
                            color="#969696"
                          />
                          <Typography variant="span" sx={{ ml: '8px' }}>
                            {item.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ListTabMenu>
      )}
    </ListTab>
  );
};

ListTabComponent.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  isFavourite: PropTypes.bool,
  name: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  getClickHandler: PropTypes.func.isRequired,
  contactCount: PropTypes.number,
};
