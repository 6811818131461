import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { AbeCard, ShowMoreCollapse } from 'components/Common';
import { conversion } from 'helpers';
import { useGetTransactionQuery } from 'redux/rtk-query';
import { RoleRow } from './components/RoleRow';

export const RolesCard = ({ transactionId, ...props }) => {
  const [customerRoles, setCustomerRoles] = useState([]);
  const [dataOnlyRoles, setDataOnlyRoles] = useState([]);
  const [otherRoles, setOtherRoles] = useState([]);
  const { transactionDetailsId } = useParams();

  const {
    data: {
      data: {
        roles: transactionRoles = null,
        rolesStr: transactionStrRoles = null,
      } = {},
    } = {},
  } = useGetTransactionQuery(
    { id: transactionId || transactionDetailsId },
    { skip: !transactionDetailsId && !transactionId },
  );

  useEffect(() => {
    if (transactionRoles) {
      const sortedRoles = [...transactionRoles].sort((a, b) => {
        if (a.role === b.role) {
          return a.order - b.order; // Sort by order if types are the same
        }
        return a.role.localeCompare(b.role); // Sort by role
      });

      const sortedStrRoles = [...transactionStrRoles].sort((a, b) => {
        if (a.role === b.role) {
          return a.order - b.order; // Sort by order if types are the same
        }
        return a.role.localeCompare(b.role); // Sort by role
      });
      setDataOnlyRoles(sortedStrRoles);

      const customerRoles = conversion.getCustomerRoles(sortedRoles);
      setCustomerRoles(customerRoles);
      const otherRoles = sortedRoles.filter((role) => {
        const isCustomer = customerRoles.find((cRole) => cRole.id === role.id);
        return !isCustomer;
      });
      setOtherRoles(otherRoles);
    }
  }, [transactionRoles, transactionStrRoles]);

  const isMultipleOfType = (roles, roleType) => {
    const count = roles.filter((role) => role.role === roleType).length;
    return count > 1;
  };

  return (
    <AbeCard title={'Roles'} {...props}>
      <Divider />
      {customerRoles &&
        customerRoles.map((customer, index) => (
          <React.Fragment key={index}>
            <RoleRow
              role={customer}
              index={index}
              showOrder={isMultipleOfType(customerRoles, customer.role)}
            />
          </React.Fragment>
        ))}
      <ShowMoreCollapse>
        {dataOnlyRoles &&
          dataOnlyRoles.map((role, index) => (
            <React.Fragment key={index}>
              <RoleRow
                role={role}
                index={index + customerRoles.length}
                showOrder={isMultipleOfType(dataOnlyRoles, role.role)}
              />
            </React.Fragment>
          ))}
        {otherRoles &&
          otherRoles.map((role, index) => (
            <React.Fragment key={index}>
              <RoleRow
                role={role}
                index={index + customerRoles.length + dataOnlyRoles.length}
                showOrder={isMultipleOfType(otherRoles, role.role)}
              />
            </React.Fragment>
          ))}
      </ShowMoreCollapse>
    </AbeCard>
  );
};

RolesCard.propTypes = {
  transactionId: PropTypes.string,
};
