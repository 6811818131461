import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

export function HideableTooltip({ title, children }) {
  return (
    <Tooltip
      arrow
      placement={'top'}
      title={title}
      disableHoverListener={title === ''}
    >
      <span style={{ width: '100%', display: 'block' }}>{children}</span>
    </Tooltip>
  );
}

HideableTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
