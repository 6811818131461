import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import ChartForm from 'pages/ReportPages/DetailsReportPage/ChartForm';
import { ChartView } from 'pages/ReportPages/DetailsReportPage/ChartView';
import { useGetReportQuery } from 'redux/rtk-query';

const ReportConfig = ({ reportId }) => {
  const [addChart, setAddChart] = React.useState(false);
  const { data: reportData } = useGetReportQuery(reportId, { skip: !reportId });

  const ChartReportConfig = () => (
    <>
      {addChart && (
        <Grid item xs={6}>
          <ChartForm
            reportId={reportId}
            afterSave={() => {
              setAddChart(false);
            }}
          />
        </Grid>
      )}
      <Grid item xs={addChart ? 6 : 12}>
        <Button onClick={() => setAddChart(true)}>Add Chart</Button>
        <ChartView reportId={reportId} />
      </Grid>
    </>
  );

  return (
    <Grid container>
      {reportData?.data?.reportType === 'chart' && <ChartReportConfig />}
    </Grid>
  );
};

export default ReportConfig;

ReportConfig.propTypes = {
  reportId: PropTypes.string.isRequired,
};
