import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

const BarChartStacked = ({ data, options }) => {
  const stackedOptions = {
    ...options,
    indexAxis: 'x',
    scales: {
      x: {
        stacked: true,
        ...options.scales?.x,
      },
      y: {
        stacked: true,
        ...options.scales?.y,
      },
    },
  };

  return <Bar data={data} options={stackedOptions} />;
};

BarChartStacked.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default BarChartStacked;
