import { useGetContactQuery, useGetTransactionQuery } from 'redux/rtk-query';

export function useFetchRelatedModel(relatedType, relatedTypeId) {
  if (relatedType === 'contact') {
    return useGetContactQuery({ id: relatedTypeId }, { skip: !relatedTypeId });
  }
  return useGetTransactionQuery(
    { id: relatedTypeId },
    { skip: !relatedTypeId },
  );
}
