// ItemGroupMenuItem.js

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  faBan,
  faEyeSlash,
  faLock,
  faEye,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from '@mui/material';
import ItemSelectorMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/ItemSelectorMenuItem';
import ToolbarComponent from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/ToolbarComponent';
import {
  isTemplateViewSelectorCM,
  isDeletingSelectorCM,
  autofillDataSelectorCM,
} from 'redux/selectors';
import { deleteItemGroupCM } from 'redux/slices';
import { changeItemGroupActivePage } from 'redux/slices/clickMarketing/thunk';

function ItemGroupMenuItem({ itemTemplate, modularType, index }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const templateView = useSelector(isTemplateViewSelectorCM);
  const isDeleting = useSelector(isDeletingSelectorCM);
  const agentView = !templateView;

  const { agentIcon, agentTeam } = useSelector(autofillDataSelectorCM);

  const itemTemplateId = itemTemplate.id;

  // Handlers
  const handleToolbarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddItemGroup = useCallback(() => {
    // Implement add item group logic or dispatch if needed
  }, [dispatch, modularType]);

  const handleDeleteItemGroup = useCallback(() => {
    // Implement delete item group logic or dispatch if needed
    dispatch(
      deleteItemGroupCM({
        templateId: itemTemplateId,
        modularType,
        removeGroup: true,
      }),
    );
    handleClose();
  }, [dispatch, itemTemplateId, modularType]);

  const handleLockToggle = useCallback(() => {
    // Implement lock toggle logic or dispatch if needed
  }, [dispatch, itemTemplateId, modularType]);

  const updateActiveStyle = useCallback(
    (page) => {
      if (isDeleting || itemTemplate.activeStyle === page + 1) return;
      dispatch(
        changeItemGroupActivePage({
          templateId: itemTemplateId,
          modularType: modularType,
          page: page + 1, // Assuming `page` is 0-indexed and needs to be 1-indexed
        }),
      );
    },
    [dispatch, itemTemplateId, modularType, isDeleting],
  );

  // Toolbar Component
  const toolbar = useMemo(
    () => (
      <ToolbarComponent
        modularType={modularType}
        agentView={agentView}
        handleToolbarClick={handleToolbarClick}
        handleClose={handleClose}
        handleLockToggle={handleLockToggle}
        handleAddItemGroup={handleAddItemGroup}
        handleDeleteItemGroup={handleDeleteItemGroup}
        itemTemplateId={itemTemplate.id}
        locked={itemTemplate.locked}
        anchorEl={anchorEl}
      />
    ),
    [
      modularType,
      agentView,
      handleToolbarClick,
      handleClose,
      handleLockToggle,
      handleAddItemGroup,
      handleDeleteItemGroup,
      itemTemplate.id,
      itemTemplate.locked,
      anchorEl,
    ],
  );

  // Determine group name
  const groupName = agentView
    ? `${modularType} ${index + 1 === 1 ? '' : index + 1}`
    : itemTemplate.name;

  // Pages array
  const pagesArray = useMemo(
    () => Array.from({ length: itemTemplate.pageCount }, (_, i) => i),
    [itemTemplate.pageCount],
  );
  const filteredPagesArray = useMemo(() => {
    return pagesArray.filter((page) => {
      if (itemTemplate.hidden?.includes(page + 1)) {
        return false;
      }

      if (!agentIcon && itemTemplate.pagesWithAgentIcon?.includes(page + 1)) {
        return false;
      }

      console.log(
        'pagesArray',
        agentTeam,
        itemTemplate.pagesWithTeamName,
        page + 1,
      );
      if (!agentTeam && itemTemplate.pagesWithTeamName?.includes(page + 1)) {
        return false;
      }

      return true;
    });
  }, [
    pagesArray,
    itemTemplate.hidden,
    agentIcon,
    itemTemplate.pagesWithAgentIcon,
    agentTeam,
    itemTemplate.pagesWithTeamName,
  ]);

  const toggleHidden = () => {
    // Implement toggle hidden logic or dispatch if needed
  };

  const hideAllOtherPages = () => {
    // Implement hide all other pages logic or dispatch if needed
  };

  const isCurrentGroupHidden = itemTemplate.hidden
    ? itemTemplate.hidden.includes(itemTemplate.activeStyle)
    : false;

  const itemToggle = useMemo(
    () => (
      <>
        <Tooltip
          title={isCurrentGroupHidden ? 'Group Hidden' : 'Group Visible'}
        >
          <Button
            size="small"
            style={{ minWidth: '30px' }}
            onClick={toggleHidden}
          >
            <FontAwesomeIcon
              color={isCurrentGroupHidden ? 'grey' : '#097CC7'}
              icon={isCurrentGroupHidden ? faEyeSlash : faEye}
            />
          </Button>
        </Tooltip>
        <Tooltip title="Hide All Other Pages">
          <Button
            size="small"
            style={{ minWidth: '30px' }}
            onClick={hideAllOtherPages}
          >
            <FontAwesomeIcon color="#097CC7" icon={faBan} />
          </Button>
        </Tooltip>
      </>
    ),
    [isCurrentGroupHidden, toggleHidden, hideAllOtherPages],
  );

  return (
    <ItemSelectorMenuItem
      modularType={modularType}
      locked={itemTemplate.locked}
      toolbar={toolbar}
      itemToggle={!agentView && itemToggle}
      items={agentView ? filteredPagesArray : pagesArray}
      initialValue={itemTemplate.activeStyle ? itemTemplate.activeStyle - 1 : 0}
      onPageChange={(page) => updateActiveStyle(page)}
      label={
        <>
          {groupName}
          {itemTemplate.locked && (
            <FontAwesomeIcon
              style={{ marginLeft: '8px' }}
              size="sm"
              icon={faLock}
            />
          )}
        </>
      }
    />
  );
}

ItemGroupMenuItem.propTypes = {
  itemTemplate: PropTypes.object.isRequired,
  modularType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default ItemGroupMenuItem;
