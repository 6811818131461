import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { colors } from 'consts';
import { roleSelector } from 'redux/selectors';
import { useCheckCollisions } from '../../../../hooks/useCheckCollisions';
import { getSignatureByRole } from '../../../../utils/field';
import { FieldAlert } from '../../../FieldAlert/FieldAlert';
import signatureImage from '../images/signature-redline.png';
import { DateSpan, ImageContainer, SignatureImage } from './styled';

export const Signature = ({ field }) => {
  let value = 'MM/DD/YY';
  useCheckCollisions(field);
  const documentRoles = useSelector(roleSelector);
  let signature = getSignatureByRole(field, documentRoles);

  const title =
    field.collisions && field.collisions.length > 0
      ? 'These fields overlap and may cause signing issues'
      : 'This field is missing a role';
  const open = (field.collisions && field.collisions.length > 0) || !field.role;

  return (
    <FieldAlert
      showRedOutline
      open={open}
      disableHoverListener={true}
      style={{ backgroundColor: colors.RED }}
      title={title}
    >
      <span
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'block',
          zIndex: 1,
        }}
      >
        {signature}
        <ImageContainer>
          <SignatureImage src={signatureImage} alt="Signature" />
        </ImageContainer>
        <DateSpan>{value}</DateSpan>
      </span>
    </FieldAlert>
  );
};

Signature.propTypes = {
  field: PropTypes.object.isRequired,
};
