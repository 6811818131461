export const SHARE_WITH_OPTIONS = [
  {
    value: 'PARENTS_ONLY',
    label: 'Share with Parents Only',
  },
  {
    value: 'EVERYONE_IN_ROLE',
    label: 'Share with Everyone in Role',
  },
];
