import React from 'react';
import { faClone } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConfirmDialog,
  FontAwesomeIconComp,
  HideableTooltip,
  MenuItemButton,
} from 'components/Common';
import { activeFieldsSelector } from 'redux/selectors';
import { duplicateOnAllPages } from 'redux/slices';

export function DuplicateOnAllPages() {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const selectedField = activeFields[0];
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  return (
    <>
      {activeFields.length === 1 ? (
        <>
          <HideableTooltip
            title={
              !selectedField.role
                ? 'You must assign a role before duplicating this field'
                : ''
            }
          >
            <MenuItemButton
              label={'Duplicate on All Pages'}
              disabled={!selectedField.role}
              icon={<FontAwesomeIconComp icon={faClone} />}
              onClick={() => {
                dispatch(duplicateOnAllPages(selectedField));
                setOpenConfirmDialog(true);
              }}
            />
          </HideableTooltip>
          <ConfirmDialog
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            title={'Duplicate Field'}
            message={
              'Are you sure you want to duplicate this field on all pages?'
            }
            onConfirm={() => {
              setOpenConfirmDialog(false);
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
