import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isFieldVisibleSelector } from 'redux/selectors';
import FieldIcon from './components/FieldIcon';
import {
  Checkbox,
  Date,
  PrintName,
  Signature,
  Strikeout,
  Text,
} from './components/fields';
import { ResizableDraggable } from './components/ResizableDraggable';

export const DocumentItem = ({ field }) => {
  const isFieldVisible = useSelector(isFieldVisibleSelector(field.id));

  const renderItemContent = () => {
    switch (field.type) {
      case 'text':
        return <Text field={field} />;
      case 'datePicker':
        return <Date field={field} />;
      case 'signatureField':
      case 'signatureInitialField':
        return <Signature field={field} />;
      case 'printName':
        return <PrintName field={field} />;
      case 'checkbox':
        return (
          <div
            style={{ zIndex: 2, position: 'relative' }}
            className="document-item__checkbox"
          >
            <Checkbox field={field} />
          </div>
        );
      case 'strikethrough':
        return <Strikeout field={field} />;
      default:
        return <Text field={field} />;
    }
  };

  if (isFieldVisible === false) return null;

  return (
    <ResizableDraggable field={field}>
      <FieldIcon field={field} />
      {renderItemContent()}
    </ResizableDraggable>
  );
};

DocumentItem.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
    top: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    textAlign: PropTypes.oneOf(['left', 'center', 'right', '']),
    fontSize: PropTypes.string,
    role: PropTypes.string,
    autoFill: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
};
