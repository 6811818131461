// ClickMarketingViewer.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import AutofillDataProvider from 'components/ModelViews/Documents/ClickMarketingViewer/AutofillDataProvider';
import { GridCoordinates } from 'components/ModelViews/Documents/ClickMarketingViewer/GridCoordinates';
import { CM_ITEM_TYPES } from 'consts';
import {
  scaleSelectorCM as scaleSelector,
  pixelSizeSelector,
  cmBlockTypeSelectorCM,
  transparentBackgroundSelectorCM,
  showGridSelectorCM,
  showPagesNavSelector,
} from 'redux/selectors';
import {
  setScaleCM as setScale,
  setDocumentDataCM as setDocumentData,
  reset,
  setActiveFieldCM,
} from 'redux/slices';
import { loadItemGroupsCM } from 'redux/slices/clickMarketing/thunk';
import DesignItems from './DesignItems';
import './css/styles.css';

const debounce = (func, delay) => {
  let inDebounce;
  return function (...args) {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(this, args), delay);
  };
};

export const ViewportHoverContext = React.createContext(false);

const ClickMarketingViewer = ({ documentData, templateView = false }) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const showGrid = useSelector(showGridSelectorCM);
  const scale = useSelector(scaleSelector);
  const cmBlockType = useSelector(cmBlockTypeSelectorCM);
  const transparentBackground = useSelector(transparentBackgroundSelectorCM);
  const [width, height] = useSelector(pixelSizeSelector);
  const showPagesNav = useSelector(showPagesNavSelector);

  const [isViewportHovered, setIsViewportHovered] = useState(false);

  // Handle Resize function
  const handleResize = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const containerWidth = container.clientWidth - 40; // 20px buffer each side
      const containerHeight = container.clientHeight - 40; // 20px buffer each side

      const widthScale = containerWidth / width;
      const heightScale = containerHeight / height;

      const newScale = Math.min(widthScale, heightScale);

      dispatch(setScale(newScale));
    }
  }, [width, height, dispatch]);

  // Debounced version of handleResize
  const debouncedHandleResize = useCallback(debounce(handleResize, 250), [
    handleResize,
  ]);

  // Use ResizeObserver to monitor containerRef size changes
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    // Call handleResize initially
    handleResize();

    const resizeObserver = new ResizeObserver(() => {
      debouncedHandleResize();
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
      resizeObserver.disconnect();
    };
  }, [containerRef, debouncedHandleResize, handleResize]);

  useEffect(() => {
    if (documentData) {
      // Deep copy to avoid mutating the original state
      const documentDataCopy = JSON.parse(JSON.stringify(documentData));
      documentDataCopy.templateView = templateView;

      // Conditional logic based on cmBlockType
      if (documentDataCopy.cmBlockType === CM_ITEM_TYPES.modular.Template) {
        documentDataCopy.htmlTemplate.items = [];
      }

      // Ensure itemGroups structure consistency
      if (
        documentDataCopy.itemGroups &&
        typeof documentDataCopy.itemGroups === 'object'
      ) {
        Object.keys(documentDataCopy.itemGroups).forEach((pageId) => {
          // Define the groups to check
          ['Header', 'Body', 'Footer'].forEach((group) => {
            // Initialize the group as an object if it doesn't exist
            if (!documentDataCopy.itemGroups[pageId][group]) {
              documentDataCopy.itemGroups[pageId][group] = {};
            } else {
              // If the group is an array, convert it to an object
              if (Array.isArray(documentDataCopy.itemGroups[pageId][group])) {
                console.warn(
                  `Expected '${group}' to be an object in itemGroups for page '${pageId}', but found an array. Converting to an empty object.`,
                );
                documentDataCopy.itemGroups[pageId][group] = {};
              } else if (
                typeof documentDataCopy.itemGroups[pageId][group] !== 'object'
              ) {
                // If the group is neither an array nor an object, initialize it as an object
                console.warn(
                  `Expected '${group}' to be an object in itemGroups for page '${pageId}', but found a different type. Initializing as an empty object.`,
                );
                documentDataCopy.itemGroups[pageId][group] = {};
              }
            }
          });
        });
      }

      // Dispatch the updated document data
      dispatch(setDocumentData(documentDataCopy));
      if (templateView) {
        dispatch(loadItemGroupsCM());
      }
    }
  }, [documentData, templateView, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(reset(null));
    };
  }, [dispatch]);

  return (
    <ViewportHoverContext.Provider value={isViewportHovered}>
      <AutofillDataProvider />
      <Grid
        item
        onMouseDown={(e) => {
          if (!e.target.classList.contains('react-draggable')) {
            dispatch(setActiveFieldCM(null));
          }
        }}
        ref={containerRef}
        xs={showPagesNav ? 8 : 9.8}
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          flex={2}
          p={0}
          sx={{
            backgroundColor: '#6c6a6a',
            maxWidth: '100%',
            height: '100%',
            position: 'relative',
            padding: '20px',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            className={'viewport'}
            onMouseEnter={() => setIsViewportHovered(true)}
            onMouseLeave={() => setIsViewportHovered(false)}
            position="relative"
            sx={{
              transform: `scale(${scale})`,
              width: `${width}px`,
              height: `${height}px`,
              background: 'white',
              '& .show-on-hover': {
                visibility: 'hidden',
                position: 'absolute',
              },
              '&:hover .show-on-hover': {
                visibility: 'visible',
                position: 'absolute',
              },
              '& .text-label': {
                top: '-20px',
                left: '0',
                padding: '0 3px',
                backgroundColor: '#ffff78',
                fontSize: '12px',
                zIndex: 999,
              },
              '&:hover .text-label.insideLabel': {
                top: '0',
                left: '0',
                padding: '0 3px',
                backgroundColor: '#ffff78',
              },
            }}
          >
            {showGrid && <GridCoordinates />}
            {(cmBlockType !== CM_ITEM_TYPES.modular.Template ||
              transparentBackground) && (
              <div className={'checkered-background'}></div>
            )}
            <DesignItems />
          </Box>
        </Box>
      </Grid>
    </ViewportHoverContext.Provider>
  );
};

ClickMarketingViewer.propTypes = {
  documentData: PropTypes.object.isRequired,
  templateView: PropTypes.bool,
};

export default ClickMarketingViewer;
