import React from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { setActiveFieldCM } from 'redux/slices';

const CloseMenuButton = () => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setActiveFieldCM(null));
  };
  return (
    <IconButton size="small" onClick={handleClose} sx={{ marginLeft: 'auto' }}>
      <FontAwesomeIconComp fontSize={18} icon={faTimes} />
    </IconButton>
  );
};

export default CloseMenuButton;
