import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { updateField } from 'redux/slices';

export const Date = ({ field }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    const dateTimeValue = newValue.toFormat('MM/dd/yyyy');
    dispatch(
      updateField({ fieldId: field.id, properties: { value: dateTimeValue } }),
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        onChange={handleChange}
        value={field.value || null}
        open={isOpen}
        inputFormat={'MM/dd/yyyy'}
        onClose={() => setIsOpen(false)}
        onAccept={() => setIsOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={'standard'}
            sx={{
              zIndex: 5,
              background: 'transparent',
              height: '100%',
            }}
            onClick={() => setIsOpen(true)}
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
              sx: {
                height: '100%',
              },
            }}
            inputProps={{
              ...params.inputProps,
              style: {
                fontSize: field.fontSize ? parseFloat(field.fontSize) : 14,
                fontWeight: 600,
                fontFamily: 'Arial',
                textIndent: field.textIndent ? parseFloat(field.textIndent) : 0,
                textAlign: field.textAlign ? field.textAlign : 'left',
                height: '100%',
                width: '100%',
                padding: 0,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

Date.propTypes = {
  field: PropTypes.object.isRequired,
};
