import React from 'react';
import { Divider, Grid, Table, TableContainer } from '@mui/material';
import { Box } from '@mui/system';
import { ModuleIcon, TableHead } from 'components/Common';
import {
  ToolbarContainer,
  ToolbarRow,
  ToolbarTitle,
} from 'components/Common/Table/TableToolbar/styled';
import { SearchInput } from 'components/SearchInput';
import { TablePaper, TableWrapper } from 'components/Styled';
import { TaskSectionTable } from './components/TaskSectionTable';
import { Cells } from './config';

export const TasksActivityTable = () => {
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = React.useState('');

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Grid container mt={1}>
      <Grid item xs={12}>
        <TableWrapper>
          <TablePaper>
            <ToolbarContainer>
              <ToolbarRow>
                <ToolbarTitle>
                  <ModuleIcon marginRight={10} module={'Tasks'} />
                  Tasks
                </ToolbarTitle>
              </ToolbarRow>
            </ToolbarContainer>
            <Box mt={0.5} mx={2}>
              <SearchInput changeHandler={setSearch} />
            </Box>
            <Divider />
            <TableContainer>
              <Table>
                <TableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  cells={Cells}
                  setSelected={() => {}}
                  selected={[]}
                  hideHeaderCheckbox
                />
                <TaskSectionTable search={search} sectionType={'PAST_DUE'} />
                <TaskSectionTable search={search} sectionType={'DUE_TODAY'} />
                <TaskSectionTable search={search} sectionType={'UPCOMING'} />
                <TaskSectionTable search={search} sectionType={'NO_DUE_DATE'} />
                <TaskSectionTable search={search} sectionType={'COMPLETED'} />
              </Table>
            </TableContainer>
          </TablePaper>
        </TableWrapper>
      </Grid>
    </Grid>
  );
};
