import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { InvoiceTemplateSelection } from 'components/ModelViews/InvoiceTemplates';
import { CREATE_INVOICE } from 'consts';
import { getModuleIcon } from 'helpers';
import { useDrawer } from 'hooks';

export const AddCommissionInvoiceButton = ({ commission }) => {
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onRowClick = (invoiceTemplate) => {
    navigate(`${pathname}${CREATE_INVOICE(invoiceTemplate.id)}`, {
      state: { commission },
    });
    closeDrawer();
  };
  return (
    <>
      <Tooltip title={<Box>Add Invoice</Box>}>
        <IconButton onClick={openDrawer} size={'small'}>
          <FontAwesomeIconComp icon={getModuleIcon('Invoices').icon} />
        </IconButton>
      </Tooltip>
      <SideDrawer width={'40%'} isOpen={isOpen} closeDrawer={closeDrawer}>
        <InvoiceTemplateSelection
          type={'Commission'}
          onRowClick={onRowClick}
          closeDrawer={closeDrawer}
        />
      </SideDrawer>
    </>
  );
};

AddCommissionInvoiceButton.propTypes = {
  commission: PropTypes.object.isRequired,
};
