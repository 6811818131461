import React from 'react';
import PropTypes from 'prop-types';
import {
  UserBox,
  ActiveUserBox,
  EmptyUserBox,
  EmptyActiveUserBox,
  ActiveStatusBox,
  InactiveStatusBox,
} from './styled';

const ACTIVE_STATUS = 'Active';

export const Users = ({ value, onClick }) =>
  value ? (
    <UserBox onClick={onClick}>{value}</UserBox>
  ) : (
    <EmptyUserBox>–</EmptyUserBox>
  );

Users.propTypes = {
  value: PropTypes.number,
  onClick: PropTypes.func,
};

export const ActiveUsers = ({ value, onClick }) =>
  value ? (
    <ActiveUserBox onClick={onClick}>{value}</ActiveUserBox>
  ) : (
    <EmptyActiveUserBox>–</EmptyActiveUserBox>
  );

ActiveUsers.propTypes = {
  value: PropTypes.number,
  onClick: PropTypes.func,
};

export const Status = ({ value }) =>
  value === ACTIVE_STATUS ? (
    <ActiveStatusBox>Active</ActiveStatusBox>
  ) : (
    <InactiveStatusBox>Inactive</InactiveStatusBox>
  );

Status.propTypes = {
  value: PropTypes.string,
};
