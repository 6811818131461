import React from 'react';

export const Required = () => {
  return (
    <span className={'required'} style={{ color: '#c9232d', marginLeft: 4 }}>
      *
    </span>
  );
};

Required.propTypes = {};
