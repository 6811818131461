import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from 'consts/colors';

export const ActivityCenterToolbarWrapper = styled(Grid)({
  marginBottom: 10,
  '& .selected': {
    color: colors.BLUE_1,
    backgroundColor: colors.BLUE_1 + '1A',
  },
  '& .selected .MuiBadge-badge': {
    color: 'white',
    backgroundColor: colors.BLUE_1,
  },
});

ActivityCenterToolbarWrapper.defaultProps = {
  alignItems: 'center',
  container: true,
};
