import React from 'react';
import PropTypes from 'prop-types';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  Tooltip,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { MenuButton } from 'components/Common';
import { columnTypes } from 'consts';

export const TableHeadFilter = ({
  id,
  label,
  filterType,
  filterOptions,
  filterValues,
  setFilterValues,
}) => {
  const handleFilterChange = (event) => {
    let value = event?.target?.value || event; // The selected value or values
    if (filterType === columnTypes.DROP_DOWN) {
      if (event.target.multiple) {
        const currentValues = filterValues[id] || [];
        if (currentValues.includes(value)) {
          setFilterValues({
            ...filterValues,
            [id]: currentValues.filter((item) => item !== value),
          });
        } else {
          setFilterValues({
            ...filterValues,
            [id]: [...currentValues, value],
          });
        }
      } else {
        setFilterValues({ ...filterValues, [id]: value });
      }
    } else if (filterType === columnTypes.DATE) {
      value = value.map((date) => (date ? date.toFormat('dd-MM-yyyy') : ''));
      setFilterValues({ ...filterValues, [id]: value });
    } else {
      setFilterValues({ ...filterValues, [id]: value });
    }
  };

  const handleClearFilters = () => {
    if (filterType === columnTypes.DATE) {
      setFilterValues({ ...filterValues, [id]: [null, null] });
    } else {
      setFilterValues({ ...filterValues, [id]: [] });
    }
  };

  const renderFilterInput = () => {
    switch (filterType) {
      case columnTypes.DROP_DOWN:
        return (
          <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
              multiple
              value={filterValues[id] || []}
              onChange={handleFilterChange}
              input={<OutlinedInput label={label} />}
            >
              {filterOptions.map(({ value, label }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case columnTypes.DATE: {
        const dateRange = filterValues[id] || [null, null];
        const formattedDateRange = dateRange.map((dateStr) => {
          // Ensure the date string is not null or undefined and is a valid date
          if (dateStr && DateTime.fromFormat(dateStr, 'dd-MM-yyyy').isValid) {
            return DateTime.fromFormat(dateStr, 'dd-MM-yyyy');
          } else {
            return null;
          }
        });

        return (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateRangePicker
              localeText={{ start: 'Start Image', end: 'End Image' }}
              value={formattedDateRange}
              onChange={handleFilterChange}
            />
          </LocalizationProvider>
        );
      }
      case columnTypes.TEXT:
      default:
        return (
          <OutlinedInput
            value={filterValues[id] || ''}
            onChange={handleFilterChange}
            placeholder="Filter value"
            fullWidth
          />
        );
    }
  };

  const hasFilterValues = filterValues[id] && filterValues[id].length > 0;
  const title = (
    <Tooltip title={`Search by ${label}`}>
      <FontAwesomeIcon
        icon={faSearch}
        color={hasFilterValues ? 'primary' : 'grey'}
      />
    </Tooltip>
  );

  return (
    <span className={hasFilterValues ? 'hasFilterValues' : 'filterComponent'}>
      <MenuButton title={title} size={'x-small'}>
        <Box sx={{ minWidth: '200px' }}>
          {renderFilterInput()}
          {hasFilterValues && (
            <Button
              sx={{ mt: 1 }}
              variant={'outlined'}
              onClick={handleClearFilters}
              size={'small'}
            >
              Clear
            </Button>
          )}
        </Box>
      </MenuButton>
    </span>
  );
};

TableHeadFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  filterValues: PropTypes.object.isRequired,
  setFilterValues: PropTypes.func.isRequired,
  filterOptions: PropTypes.array,
};
