import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { AddToListModal } from 'components/ModelViews/Lists';

export const ListCount = ({ value: { id, lists, uuid } }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && (
        <AddToListModal
          uuid={uuid}
          contactId={id}
          open={open}
          closeHandler={setOpen}
        />
      )}
      <Button
        style={{ minWidth: 20 }}
        fullWidth
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        {lists}
      </Button>
    </>
  );
};

ListCount.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    lists: PropTypes.number,
  }),
};
