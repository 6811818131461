// src/context/sharedContext.js

import { createContext } from 'react';

const defaultSharedState = {
  state: '',
  setState: () => {},
  userToken: null,
  user: null,
  isLoading: false,
};

export const SharedContext = createContext(defaultSharedState);
