import { CM_ITEM_TYPES } from 'consts';
import { getPixelSize } from 'helpers/clickMarketing';

export const arrangeGroupLeft = (state, itemsToArrange) => {
  // Find the smallest x position (far left)
  const farLeft = Math.min(...itemsToArrange.map((item) => parseFloat(item.x)));

  // Update items' x positions
  const updatedItems = itemsToArrange.map((item) => ({
    ...item,
    x: farLeft.toString(),
  }));

  return updateItemsInState(state, updatedItems);
};

export const arrangeGroupRight = (state, itemsToArrange) => {
  // Find the largest x + width position (far right)
  const farRight = Math.max(
    ...itemsToArrange.map((item) => parseFloat(item.x) + parseFloat(item.w)),
  );

  // Update items' x positions
  const updatedItems = itemsToArrange.map((item) => ({
    ...item,
    x: (farRight - parseFloat(item.w)).toString(),
  }));

  return updateItemsInState(state, updatedItems);
};

export const arrangeGroupTop = (state, itemsToArrange) => {
  // Find the smallest y position (top)
  const farTop = Math.min(...itemsToArrange.map((item) => parseFloat(item.y)));

  // Update items' y positions
  const updatedItems = itemsToArrange.map((item) => ({
    ...item,
    y: farTop.toString(),
  }));

  return updateItemsInState(state, updatedItems);
};

export const arrangeGroupBottom = (state, itemsToArrange) => {
  // Find the largest y + height position (bottom)
  const farBottom = Math.max(
    ...itemsToArrange.map((item) => parseFloat(item.y) + parseFloat(item.h)),
  );

  // Update items' y positions
  const updatedItems = itemsToArrange.map((item) => ({
    ...item,
    y: (farBottom - parseFloat(item.h)).toString(),
  }));

  return updateItemsInState(state, updatedItems);
};

export const arrangeGroupCenter = (state, itemsToArrange) => {
  // Use the first item's center as the target center
  const firstItem = itemsToArrange[0];
  const targetCenterX = parseFloat(firstItem.x) + parseFloat(firstItem.w) / 2;

  // Update items' x positions
  const updatedItems = itemsToArrange.map((item) => ({
    ...item,
    x: (targetCenterX - parseFloat(item.w) / 2).toString(),
  }));

  return updateItemsInState(state, updatedItems);
};

export const arrangeGroupMiddle = (state, itemsToArrange) => {
  // Use the first item's middle as the target middle
  const firstItem = itemsToArrange[0];
  const targetCenterY = parseFloat(firstItem.y) + parseFloat(firstItem.h) / 2;

  // Update items' y positions
  const updatedItems = itemsToArrange.map((item) => ({
    ...item,
    y: (targetCenterY - parseFloat(item.h) / 2).toString(),
  }));

  return updateItemsInState(state, updatedItems);
};

const updateItemsInState = (state, updatedItems) => {
  // Create a new items array with updated items
  const newItems = state.currentDocument.htmlTemplate.items.map((item) => {
    const updatedItem = updatedItems.find((i) => i.id === item.id);
    return updatedItem ? updatedItem : item;
  });

  // Return the new state with updated items
  return {
    ...state,
    currentDocument: {
      ...state.currentDocument,
      htmlTemplate: {
        ...state.currentDocument.htmlTemplate,
        items: newItems,
      },
    },
    needsSaving: true, // If you have a flag to indicate changes
  };
};

export function updateItemLabel(state, newItem) {
  const getItemTypeString = (type) => {
    if (type === CM_ITEM_TYPES.text.fillnow) return 'Fill Now';
    if (type === CM_ITEM_TYPES.text.list) return 'List';
    if (type === CM_ITEM_TYPES.image.attachment) return 'Image';
    if (type === CM_ITEM_TYPES.image.property) return 'Property Photo';
    if (type === CM_ITEM_TYPES.image.svgImage) return 'SVG';
  };

  const relevantItemTypes = [
    CM_ITEM_TYPES.text.fillnow,
    CM_ITEM_TYPES.text.list,
    CM_ITEM_TYPES.image.attachment,
    CM_ITEM_TYPES.image.property,
    CM_ITEM_TYPES.image.svgImage,
  ];

  if (relevantItemTypes.includes(newItem.type)) {
    const items = state.currentDocument.htmlTemplate.items;
    let maxNumber = 0;
    const searchString = getItemTypeString(newItem.type);

    if (newItem.type === CM_ITEM_TYPES.image.property) {
      maxNumber = newItem.imageNumber;
    } else {
      items.forEach((item) => {
        if (item.textLabel && item.textLabel.startsWith(`${searchString} `)) {
          const number = parseInt(item.textLabel.split(' ').reverse()[0], 10);
          if (!isNaN(number)) {
            maxNumber = Math.max(maxNumber, number);
          }
        }
      });
    }

    // Generate new label with incremented number
    newItem.textLabel = `${searchString} ${maxNumber + 1}`;
  }
}

export const addItem = (itemType) => {
  return async (dispatch, getState) => {
    let itemSettings = null;
    switch (itemType) {
      case CM_ITEM_TYPES.image.attachment:
        itemSettings = await createAttachmentImage();
        break;
      case CM_ITEM_TYPES.image.property:
        itemSettings = await createPropertyImage(
          getState().clickMarketingReducer,
        );
        break;
      case CM_ITEM_TYPES.image.agent:
      case CM_ITEM_TYPES.image.agent2:
        itemSettings = await createAgentImage(itemType);
        break;
      case CM_ITEM_TYPES.image.logo.svgLogo:
      case CM_ITEM_TYPES.image.logo.darklogo:
      case CM_ITEM_TYPES.image.logo.lightlogo:
      case CM_ITEM_TYPES.image.logo.whitelogo:
        itemSettings = await createLogoImage(itemType);
        break;
      case CM_ITEM_TYPES.image.logo.svgIcon:
      case CM_ITEM_TYPES.image.svgImage:
      case CM_ITEM_TYPES.image.logo.darkiconlogo:
      case CM_ITEM_TYPES.image.logo.lighticonlogo:
      case CM_ITEM_TYPES.image.logo.whiteiconlogo:
      case CM_ITEM_TYPES.image.agentIcon:
        itemSettings = await createIconLogoImage(itemType);
        break;
      case CM_ITEM_TYPES.text.autofill:
        itemSettings = await createAutoFillItem(itemSettings);
        break;
      case CM_ITEM_TYPES.text.list:
        itemSettings = await createTextListItem();
        break;
      case CM_ITEM_TYPES.text.fillnow:
        itemSettings = await createFillNowItem();
        break;
      case CM_ITEM_TYPES.shapes.rectangle:
      case CM_ITEM_TYPES.shapes.circle:
      case CM_ITEM_TYPES.shapes.line:
        itemSettings = await createBlock(itemType);
        break;
      case CM_ITEM_TYPES.line:
        itemSettings = await createLine();
        break;
      default:
        return;
    }
    return itemSettings;
  };
};

export const findItemIndex = (items, itemId) =>
  items.findIndex((item) => item.id === itemId);

export const moveItem = (items, itemId, toFront = true) => {
  const index = findItemIndex(items, itemId);
  if (index === -1) return items;

  const [item] = items.splice(index, 1);
  if (toFront) {
    items.unshift(item);
  } else {
    items.push(item);
  }
  return items;
};

export const updatePageElements = (
  pages,
  activePage,
  itemId,
  toFront = true,
) => {
  if (!activePage || !pages[activePage]) return pages;

  const page = { ...pages[activePage] };
  const elementIndex = page.elements.findIndex((id) => id === itemId);
  if (elementIndex !== -1) {
    page.elements.splice(elementIndex, 1);
  }
  if (toFront) {
    page.elements.unshift(itemId);
  } else {
    page.elements.push(itemId);
  }
  return {
    ...pages,
    [activePage]: page,
  };
};

const defaultFontSize = '16px';
const defaultFontFamily = 'themeFont';

export const createAutoFillItem = async () => {
  let newItem = {
    type: CM_ITEM_TYPES.text.autofill,
    w: 150,
    h: 30,
    x: 0,
    y: 0,
    value: 'Select Auto-Fill',
    autofill: null,
    multiline: false,
    style: {
      color: '#4A494A',
      fontSize: defaultFontSize,
      fontFamily: defaultFontFamily,
      textShadow: {
        y: 0,
        x: 0,
        blur: 0,
        color: '#000',
        transparency: 1,
      },
      lineHeight: 1.2,
      textAlign: 'center',
    },
  };
  return newItem;
};

export const createTextListItem = async () => {
  let newItem = {
    type: CM_ITEM_TYPES.text.list,
    w: 150,
    h: 30,
    x: 0,
    y: 0,
    value: 'Add Items',
    unlocked: true,
    listItems: [],
    style: {
      color: '#4A494A',
      fontSize: defaultFontSize,
      fontFamily: defaultFontFamily,
      textShadow: {
        y: 0,
        x: 0,
        blur: 0,
        color: '#4A494A',
        transparency: 1,
      },
      textAlign: 'center',
    },
  };

  return newItem;
};

export const createFillNowItem = async () => {
  let newItem = {
    type: CM_ITEM_TYPES.text.fillnow,
    w: 150,
    h: 30,
    x: 0,
    y: 0,
    value: 'Fill Now',
    autofill: null,
    unlocked: true,
    multiline: false,
    style: {
      color: '#4A494A',
      fontSize: defaultFontSize,
      fontFamily: defaultFontFamily,
      textShadow: {
        y: 0,
        x: 0,
        blur: 0,
        color: '#000',
        transparency: 1,
      },
      lineHeight: 1.2,
      textAlign: 'center',
    },
  };
  return newItem;
};

export const createLine = async () => {
  let newItem = {
    type: CM_ITEM_TYPES.line,
    w: 500,
    h: 8,
    x: 0,
    y: 0,
    style: {
      backgroundColor: '#000',
      transparency: 1,
      borderRadius: 0.4,
    },
  };
  return newItem;
};

export const createBlock = async (itemType) => {
  let newItem = {
    type: itemType,
    w: 300,
    h: itemType === CM_ITEM_TYPES.shapes.line ? 3 : 300,
    x: 0,
    y: 0,
    style: {
      backgroundColor: '#000',
      transparency: 1,
      borderRadius: itemType === CM_ITEM_TYPES.shapes.circle ? '50%' : 0,
    },
  };
  return newItem;
};

export const createAttachmentImage = async () => {
  return {
    type: CM_ITEM_TYPES.image.attachment,
    w: 350,
    h: 250,
    backgroundW: 350,
    backgroundH: 250,
    x: 0,
    y: 0,
    positionX: 0,
    positionY: 0,
  };
};

const getNextImageNumber = (items, type) => {
  let images = items.filter((obj) => {
    return obj.type === type;
  });
  return images.length;
};

export const createPropertyImage = async (state) => {
  let model = state.currentDocument;
  let activePage = state.activePage;
  let autofillData = state.autofillData;
  let newItem = null;
  let page = model.htmlTemplate.options.pages[activePage];
  let items = model.htmlTemplate.items.filter(
    (item) => item && page.elements.includes(item.id),
  );
  let imageNumber = getNextImageNumber(items, CM_ITEM_TYPES.image.property);
  let url = autofillData.propertyPhotos[imageNumber];
  const [width, height] = getPixelSize(model);
  let scaledImage = await new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = () => {
      let targetWidth = parseFloat(width) * 0.75;
      let targetHeight = parseFloat(height) * 0.75;
      if (image.width > targetWidth) {
        let scaleFactor = targetWidth / image.width;
        image.width = targetWidth;
        image.height = image.height * scaleFactor;
      } else if (image.height > targetHeight) {
        let scaleFactor = targetHeight / targetHeight;
        image.height = targetHeight;
        image.width = image.width * scaleFactor;
      }
      resolve(image);
    };
    image.onerror = reject;
    image.src = url;
  });
  if (scaledImage) {
    newItem = {
      type: CM_ITEM_TYPES.image.property,
      w: scaledImage.width,
      h: scaledImage.height,
      backgroundW: 0,
      backgroundH: 0,
      x: 0,
      y: 0,
      positionX: 0,
      positionY: 0,
      imageNumber: imageNumber,
    };
  }
  return newItem;
};

export const createAgentImage = async (type) => {
  let newItem = {
    type: type,
    w: 150,
    h: 150,
    x: 0,
    y: 0,
  };

  return newItem;
};

export const createLogoImage = async (type) => {
  let newItem = {
    type: type,
    w: 450,
    h: 83,
    x: 0,
    y: 0,
  };
  return newItem;
};

export const createIconLogoImage = async (type) => {
  let newItem = {
    type: type,
    w: 100,
    h: 100,
    x: 0,
    y: 0,
  };

  return newItem;
};
