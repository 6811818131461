import React from 'react';
import {
  faObjectsAlignBottom,
  faObjectsAlignCenterHorizontal,
  faObjectsAlignCenterVertical,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faObjectsAlignTop,
} from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import CloseMenuButton from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/CloseMenuButton';
import { arrangeGroupCM as arrangeGroup } from 'redux/slices';

export function GroupToolbar() {
  const dispatch = useDispatch();
  return (
    <Box>
      <Divider />
      <Box px={2} py={1} display="flex" alignItems="center">
        <Typography pt={1} pl={3} variant="subtitle1" gutterBottom>
          Align Items
        </Typography>
        <CloseMenuButton />
      </Box>
      <Divider />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignLeft} />}
        label={'Left'}
        onClick={() => {
          dispatch(arrangeGroup({ direction: 'left' }));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignCenterHorizontal} />}
        label={'Center'}
        onClick={() => {
          dispatch(arrangeGroup({ direction: 'center' }));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignRight} />}
        label={'Right'}
        onClick={() => {
          dispatch(arrangeGroup({ direction: 'right' }));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignTop} />}
        label={'Top'}
        onClick={() => {
          dispatch(arrangeGroup({ direction: 'top' }));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignCenterVertical} />}
        label={'Middle'}
        onClick={() => {
          dispatch(arrangeGroup({ direction: 'middle' }));
        }}
      />
      <MenuItemButton
        icon={<FontAwesomeIconComp icon={faObjectsAlignBottom} />}
        label={'Bottom'}
        onClick={() => {
          dispatch(arrangeGroup({ direction: 'bottom' }));
        }}
      />
    </Box>
  );
}
