import React from 'react';
import PropTypes from 'prop-types';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { useDrawer } from 'hooks';
import AddDocumentList from './AddDocumentList/AddDocumentList';

export function AddDocumentButton({ item }) {
  const { isOpen, openDrawer, closeDrawer } = useDrawer();

  return (
    <>
      <IconButton
        onClick={() => {
          openDrawer();
        }}
        size="small"
        sx={{ ml: 1, p: 0 }}
      >
        <FontAwesomeIconComp
          fontSize={14}
          icon={faCirclePlus}
          color={'#45aaf2'}
        />
      </IconButton>
      <SideDrawer isOpen={isOpen}>
        <AddDocumentList item={item} handleOpenToggle={closeDrawer} />
      </SideDrawer>
    </>
  );
}

AddDocumentButton.propTypes = {
  item: PropTypes.object,
};
