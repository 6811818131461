import React, { useEffect, useState, useMemo } from 'react';
import { Field } from 'react-final-form';
import { useParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { AbeCard } from 'components/Common';
import { Wizard } from 'components/Common/Wizard/Wizard';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { REPORT_DETAILS } from 'consts';
import GeneralInfoForm from 'pages/ReportPages/CreateReportPage/GeneralInfoForm';
import ReportColumnsPage from 'pages/ReportPages/CreateReportPage/ReportColumnsPage/ReportColumnsPage';
import { ReportFiltersPage } from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFiltersPage';
import ReportGroupingsPage from 'pages/ReportPages/CreateReportPage/ReportGroupingsPage/ReportGroupingsPage';
import ReportSortingsPage from 'pages/ReportPages/CreateReportPage/ReportSortingsPage/ReportSortingsPage';
import SelectReportType from 'pages/ReportPages/CreateReportPage/SelectReportType';
import {
  useUpdateReportMutation,
  useGetReportQuery,
  useCreateColumnsMutation,
  useCreateGroupingsMutation,
  useCreateReportMutation,
  useCreateFiltersMutation,
  useGetColumnsQuery,
  useGetGroupingsQuery,
  useCreateSortingsMutation,
} from 'redux/rtk-query';

const initialFormValues = {
  name: '',
  module: '',
  description: '',
  systemDescription: '',
  testMode: true,
  filters: [{}],
  ownerRecord: 'All',
};

const mapFormValuesToDTO = (values) => ({
  name: values.name,
  systemDescription: values.systemDescription,
  description: values.description,
  status: values.status,
  testMode: values.testMode,
  allowMassUpdate: values.allowMassUpdate,
  unrestrictedReport: values.unrestrictedReport,
  limit: values.limit,
  module: values.module,
  charts: values.charts || [],
  ownerRecord: values.ownerRecord,
  ownerRecordSelectedUserId: values.ownerRecordSelectedUserId,
  isOwnerRecordFilterEditable: values.isOwnerRecordFilterEditable,
  unitRecord: values.unitRecord,
  iUnitRecordFilterEditable: values.iUnitRecordFilterEditable,
  whoCanReadIds: values.whoCanReadIds || [],
  whoCanWriteIds: values.whoCanWriteIds || [],
});

// eslint-disable-next-line no-unused-vars
const mapFiltersToDTO = (formGroups) => {
  const rootGroup = {
    groupingCondition: 'And',
    childGroups: [],
    filters: [],
  };

  formGroups.forEach((groupItem) => {
    const groupDTO = {
      groupingCondition: groupItem.groupingCondition || 'And',
      filters: [],
      childGroups: [],
    };

    if (groupItem.filters && groupItem.filters.length > 0) {
      groupItem.filters.forEach((filterItem) => {
        const filterDTO = {
          name: filterItem.name,
          path: filterItem.path,
          condition: filterItem.condition,
          value: filterItem.value,
          isEditable: filterItem.isEditable || false,
        };

        if (filterItem.conditionType === 'Or') {
          groupDTO.childGroups.push({
            childGroups: [],
            groupingCondition: 'Or',
            filters: [filterDTO],
          });
        } else {
          groupDTO.filters.push(filterDTO);
        }
      });
    }

    rootGroup.childGroups.push(groupDTO);
  });

  return rootGroup;
};

export const CreateReportPage = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState(initialFormValues);
  const [saveProgress, setSaveProgress] = useState(false);

  const { data: reportData } = useGetReportQuery(reportId, { skip: !reportId });
  const { data: columnsData } = useGetColumnsQuery(reportId, {
    skip: !reportId,
  });
  const { data: groupingsData } = useGetGroupingsQuery(reportId, {
    skip: !reportId,
  });

  const [createReport] = useCreateReportMutation();
  const [updateReport] = useUpdateReportMutation();
  const [createFilters] = useCreateFiltersMutation();
  const [createColumns] = useCreateColumnsMutation();
  const [createGroupings] = useCreateGroupingsMutation();
  const [createSortings] = useCreateSortingsMutation();

  const getProcessedColumns = (columnsData, reportType) => {
    let processedColumns = columnsData.map((column) => ({
      ...column,
      path: column.visualPath,
    }));
    let processedDrilldowns = [];

    if (reportType === 'chart') {
      const groupingPaths =
        groupingsData?.data.map((group) => JSON.stringify(group.visualPath)) ||
        [];

      processedDrilldowns = processedColumns.filter(
        (column) =>
          !column.groupingFunction &&
          !groupingPaths.includes(JSON.stringify(column.path)),
      );

      processedColumns = processedColumns.filter(
        (column) =>
          column.groupingFunction ||
          groupingPaths.includes(JSON.stringify(column.path)),
      );
    }

    processedColumns.sort((a, b) => a.order - b.order);
    processedDrilldowns.sort((a, b) => a.order - b.order);

    return [processedColumns, processedDrilldowns];
  };

  useEffect(() => {
    if (reportData?.data && columnsData?.data) {
      const [processedColumns, processedDrilldowns] = getProcessedColumns(
        columnsData?.data,
        reportData?.data?.reportType,
      );
      setInitialValues((prevValues) => ({
        ...prevValues,
        ...reportData.data,
        filters: reportData.data.filters || [{}],
        columns: processedColumns,
        drilldowns: processedDrilldowns,
      }));
    }
  }, [reportData, columnsData]);

  const steps = useMemo(() => {
    return getSteps();
  }, [reportId, initialValues, saveProgress]);

  const saveReport = async (values) => {
    try {
      setSaveProgress({});
      const dtoData = mapFormValuesToDTO(values);
      dtoData.everyone = true;
      console.log('DTO Data:', values);
      // return;
      // eslint-disable-next-line no-unreachable
      let reportResponse;
      if (values.id) {
        reportResponse = await updateReport({
          id: values.id,
          everyone: true,
          ...dtoData,
        }).unwrap();
      } else {
        reportResponse = await createReport(dtoData).unwrap();
      }
      const reportId = reportResponse?.data?.id;
      console.log('Report ID:', reportId);
      setInitialValues((prevValues) => ({
        ...prevValues,
        id: reportId,
      }));
      setSaveProgress((prevProgress) => ({
        ...prevProgress,
        report: 'Saved',
      }));

      // Create filters
      const filtersDTO = mapFiltersToDTO(values.filters);
      await createFilters({ reportId, ...filtersDTO }).unwrap();
      setSaveProgress((prevProgress) => ({
        ...prevProgress,
        filters: 'Saved',
      }));

      // Create groupings if any
      if (values.groupings && values.groupings.length > 0) {
        const groupingsData = values.groupings.map((grouping, index) => ({
          name: grouping.name,
          dateSeparation: grouping.dateSeparation,
          path: grouping.path,
          order: index,
        }));
        await createGroupings({ reportId, data: groupingsData }).unwrap();
        setSaveProgress((prevProgress) => ({
          ...prevProgress,
          groupings: 'Saved',
        }));
      }

      // Create columns
      let columnsData = values.columns.map((column, index) => ({
        name: column.name,
        path: column.path,
        order: index,
        groupingFunction: column.groupingFunction,
        dateSeparation: column.dateSeparation,
      }));
      if (values.drilldowns && values.drilldowns.length > 0) {
        // Add drilldowns to columns using the same method
        columnsData = [
          ...columnsData,
          ...values.drilldowns.map((column, index) => ({
            name: column.name,
            path: column.path,
            order: columnsData.length + index,
            groupingFunction: column.groupingFunction,
            dateSeparation: column.dateSeparation,
          })),
        ];
      }

      console.log('Columns data:', columnsData);
      await createColumns({ reportId, data: columnsData }).unwrap();
      setSaveProgress((prevProgress) => ({
        ...prevProgress,
        columns: 'Saved',
      }));

      // Create sortings if any
      if (values.sortings && values.sortings.length > 0) {
        const sortingsData = values.sortings.map((sorting, index) => ({
          name: sorting.name,
          path: sorting.path,
          order: index,
          direction: sorting.direction,
          dateSeparation: sorting.dateSeparation,
          groupingFunction: sorting.groupingFunction,
        }));
        await createSortings({ reportId, data: sortingsData }).unwrap();
        setSaveProgress((prevProgress) => ({
          ...prevProgress,
          sortings: 'Saved',
        }));
      }

      navigate(`/${REPORT_DETAILS(reportId)}`);
      return reportId;
    } catch (error) {
      console.error('Failed to save report:', error);
      setSaveProgress((prevProgress) => ({
        ...prevProgress,
        error: error.message || 'An error occurred',
      }));
      return false;
    }
  };

  // Moved getSteps back inside the component to have access to necessary variables
  function getSteps() {
    const steps = [];

    if (!reportId) {
      steps.push({
        label: 'Select Report Type',
        onNext: () => true,
        render: (form, values, showAllFields, handleNext) => (
          <SelectReportType
            values={values}
            setInitialValues={setInitialValues}
            handleNext={handleNext}
          />
        ),
      });
    }
    if (!initialValues.reportType) {
      return steps;
    }

    steps.push(
      {
        label: 'General',
        onNext: async (values) => {
          const dtoData = mapFormValuesToDTO(values);
          try {
            if (values.id) {
              dtoData.everyone = true;
              await updateReport({ id: values.id, ...dtoData }).unwrap();
            }
            return true;
          } catch (error) {
            console.error('Failed to create/update report:', error);
            return false;
          }
        },
        render: () => <GeneralInfoForm />,
      },
      {
        label: 'Filters',
        onNext: async () => {
          return true;
        },
        render: () => (
          <Field name="filters" label="Filters" component={ReportFiltersPage} />
        ),
      },
    );

    if (initialValues.reportType === 'chart') {
      steps.push({
        label: 'Groupings',
        onNext: async (values) => {
          if (values.groupings.length === 0) {
            return {
              error: 'Please add at least one grouping',
            };
          }
          return true;
        },
        render: () => (
          <Field
            name="groupings"
            label="Groupings"
            component={ReportGroupingsPage}
          />
        ),
      });
    }

    steps.push({
      label: 'Columns',
      onNext: async (values) => {
        if (values.columns.length === 0) {
          return {
            error: 'Please add at least one column',
          };
        }
        return true;
      },
      render: () => (
        <Field name="columns" label="Columns" component={ReportColumnsPage} />
      ),
    });

    if (initialValues.reportType === 'chart') {
      steps.push({
        label: 'Drill Down',
        onNext: async () => {
          return true;
        },
        render: () => (
          <Field
            name="drilldowns"
            label="Drill Down Columns"
            component={ReportColumnsPage}
          />
        ),
      });
    }
    steps.push({
      label: 'Sorting',
      onNext: async (values) => {
        await saveReport(values);
        return true;
      },
      render: () => (
        <Field
          name="sortings"
          label="Sortings"
          component={ReportSortingsPage}
        />
      ),
    });

    return steps;
  }

  return (
    <>
      <LayoutHeader
        title={reportData?.data ? reportData?.data?.name : 'Create Report'}
      />
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <AbeCard sx={{ width: '100%' }} noTitle>
          <Box>
            <Wizard
              steps={steps}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              onFinalSubmit={(reportId) => {
                console.log('Final submit:', reportId);
                // Redirect to report details page
                // navigate(`/${REPORT_DETAILS(reportId)}`);
              }}
            />
          </Box>
        </AbeCard>
      </PageMainContent>
    </>
  );
};
