import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useField, useForm } from 'react-final-form';
import { Typography } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  STATUS_OPTIONS,
  TIMEFRAME_CALCULATION,
  TRANSACTION_TYPES,
} from 'consts';
import { setDateToEndOfDay } from 'helpers';
import { useGetTransactionsTimeframesQuery } from 'redux/rtk-query';
import { TimeframeField } from './components/TimeframeField';
import { TimeframesWrapper } from './styled';

export const TimeframesPanel = () => {
  // eslint-disable-next-line no-unused-vars
  const [showAddModal, setShowAddModal] = useState(false);
  const [processedData, setProcessedData] = useState(false);
  let form = useForm();
  const timeframes = useField('timeframes');

  const { data: timeframeData, isSuccess } = useGetTransactionsTimeframesQuery(
    {
      limit: 100,
      page: 1,
      uuid: form.getState().values.uuid,
    },
    { skip: !form.getState().values.uuid },
  );

  const hasTimeframeType = (type) => {
    let timeframesCopy = [...timeframes.input.value];
    const checkForType = timeframesCopy.filter(
      (timeframe) => timeframe.type === type,
    );
    return checkForType.length > 0;
  };
  const addTimeframeType = (type) => {
    let timeframesCopy = [...timeframes.input.value];
    const checkForType = timeframesCopy.filter(
      (timeframe) => timeframe.type === type,
    );
    if (checkForType.length === 0) {
      let dateTime = null;
      let numberOfDays = null;
      let noDeadline = false;
      if (
        type === 'Listing Expiration' &&
        form.getState().values.listExpirationDate
      ) {
        let date = setDateToEndOfDay(form.getState().values.listExpirationDate);
        dateTime = date.toISOString();
        dateTime = dateTime.slice(0, 16).replace('T', ' ');
        dateTime = dateTime + ':00';
      }
      if (
        type === 'Listing Execution' &&
        form.getState().values.listExecutionDate
      ) {
        let date = setDateToEndOfDay(form.getState().values.listExecutionDate);
        dateTime = date.toISOString();
        dateTime = dateTime.slice(0, 16).replace('T', ' ');
        dateTime = dateTime + ':00';
        noDeadline = true;
      }
      if (type === 'Lease Begins' && form.getState().values.leaseBegins) {
        let date = setDateToEndOfDay(form.getState().values.leaseBegins);
        dateTime = date.toISOString();
        dateTime = dateTime.slice(0, 16).replace('T', ' ');
        dateTime = dateTime + ':00';
        noDeadline = true;
      }
      if (type === 'Lease Ends' && form.getState().values.leaseEnds) {
        let date = setDateToEndOfDay(form.getState().values.leaseEnds);
        dateTime = date.toISOString();
        dateTime = dateTime.slice(0, 16).replace('T', ' ');
        dateTime = dateTime + ':00';
      }
      if (
        type === 'Expected Closing' &&
        form.getState().values.expectedCloseDate
      ) {
        let date = setDateToEndOfDay(form.getState().values.expectedCloseDate);
        dateTime = date.toISOString();
        dateTime = dateTime.slice(0, 16).replace('T', ' ');
        dateTime = dateTime + ':00';
      }
      if (
        type === 'Contract Execution' &&
        form.getState().values.contractExecutionDate
      ) {
        let date = setDateToEndOfDay(
          form.getState().values.contractExecutionDate,
        );
        dateTime = date.toISOString();
        dateTime = dateTime.slice(0, 16).replace('T', ' ');
        dateTime = dateTime + ':00';
        noDeadline = true;
      }
      if (
        type === 'Escrow Deadline' &&
        form.getState().values.contractEscrowPeriod
      ) {
        numberOfDays = parseInt(form.getState().values.contractEscrowPeriod);
      }
      if (
        type === 'Additional Escrow Deadline' &&
        form.getState().values.contractAdditionalEscrowPeriod
      ) {
        numberOfDays = parseInt(
          form.getState().values.contractAdditionalEscrowPeriod,
        );
      }
      if (
        type === 'Inspection Deadline' &&
        form.getState().values.contractInspectionPeriod
      ) {
        numberOfDays = parseInt(
          form.getState().values.contractInspectionPeriod,
        );
      }
      if (
        type === 'Loan Approval Deadline' &&
        form.getState().values.contractLoanCommitment
      ) {
        numberOfDays = parseInt(form.getState().values.contractLoanCommitment);
      }
      timeframesCopy[timeframesCopy.length] = {
        type,
        dueOn: dateTime,
        numberOfDays,
        noDeadline,
      };
      form.change('timeframes', timeframesCopy);
    }
    setShowAddModal(false);
  };
  const removeTimeframeType = (type) => {
    let timeframesCopy = [...timeframes.input.value];
    const checkForType = timeframesCopy.filter(
      (timeframe) => timeframe.type !== type,
    );
    form.change('timeframes', checkForType);
  };

  const checkForListingExecution = () => {
    if (
      form.getState().values.status &&
      form.getState().values.status === STATUS_OPTIONS.LISTED.value &&
      form.getState().values.pmFile !== 'YES'
    ) {
      if (!hasTimeframeType('Listing Execution')) {
        addTimeframeType('Listing Execution');
      }
    } else {
      if (hasTimeframeType('Listing Execution')) {
        removeTimeframeType('Listing Execution');
      }
    }
  };
  const checkForListingExpiration = () => {
    if (
      form.getState().values.status &&
      form.getState().values.status === STATUS_OPTIONS.LISTED.value &&
      form.getState().values.pmFile !== 'YES'
    ) {
      if (!hasTimeframeType('Listing Expiration')) {
        addTimeframeType('Listing Expiration');
      }
    } else {
      if (hasTimeframeType('Listing Expiration')) {
        removeTimeframeType('Listing Expiration');
      }
    }
  };
  const checkForLeaseBeginsAndEnds = () => {
    if (
      form.getState().values.status &&
      form.getState().values.status === STATUS_OPTIONS.LEASED.value
    ) {
      if (!hasTimeframeType('Lease Begins')) {
        addTimeframeType('Lease Begins');
      }
      if (!hasTimeframeType('Lease Ends')) {
        addTimeframeType('Lease Ends');
      }
    } else {
      if (hasTimeframeType('Lease Begins')) {
        removeTimeframeType('Lease Begins');
      }
      if (hasTimeframeType('Lease Ends')) {
        removeTimeframeType('Lease Ends');
      }
    }
  };
  const checkForInspection = () => {
    if (
      form.getState().values.status &&
      (form.getState().values.status === STATUS_OPTIONS.PENDING.value ||
        form.getState().values.status === STATUS_OPTIONS.SOLD.value)
    ) {
      if (!hasTimeframeType('Inspection Deadline')) {
        addTimeframeType('Inspection Deadline');
      }
    } else {
      if (hasTimeframeType('Inspection Deadline')) {
        removeTimeframeType('Inspection Deadline');
      }
    }
  };
  const checkForLoanApproval = () => {
    if (
      form.getState().values.status &&
      (form.getState().values.status === STATUS_OPTIONS.PENDING.value ||
        form.getState().values.status === STATUS_OPTIONS.SOLD.value) &&
      form.getState().values.financingType &&
      form.getState().values.financingType !== 'Cash' &&
      form.getState().values.representing &&
      (form.getState().values.representing?.includes('Seller') ||
        form.getState().values.representing?.includes('Buyer'))
    ) {
      if (!hasTimeframeType('Loan Approval Deadline')) {
        addTimeframeType('Loan Approval Deadline');
      }
    } else {
      if (hasTimeframeType('Loan Approval Deadline')) {
        removeTimeframeType('Loan Approval Deadline');
      }
    }
  };
  const checkForEscrow = () => {
    if (
      form.getState().values.status &&
      (form.getState().values.status === STATUS_OPTIONS.PENDING.value ||
        form.getState().values.status === STATUS_OPTIONS.SOLD.value) &&
      form.getState().values.escrowAmount > 0
    ) {
      if (!hasTimeframeType('Escrow Deadline')) {
        addTimeframeType('Escrow Deadline');
      }
    } else {
      if (hasTimeframeType('Escrow Deadline')) {
        removeTimeframeType('Escrow Deadline');
      }
    }
  };
  const checkForAdditionalEscrow = () => {
    if (
      form.getState().values.status &&
      (form.getState().values.status === STATUS_OPTIONS.PENDING.value ||
        form.getState().values.status === STATUS_OPTIONS.SOLD.value) &&
      form.getState().values.additionalEscrowAmount > 0
    ) {
      if (!hasTimeframeType('Additional Escrow Deadline')) {
        addTimeframeType('Additional Escrow Deadline');
      }
    } else {
      if (hasTimeframeType('Additional Escrow Deadline')) {
        removeTimeframeType('Additional Escrow Deadline');
      }
    }
  };
  const checkForExpectedCloseDate = () => {
    if (
      form.getState().values.status &&
      (form.getState().values.status === STATUS_OPTIONS.PENDING.value ||
        form.getState().values.status === STATUS_OPTIONS.SOLD.value)
    ) {
      if (!hasTimeframeType('Expected Closing')) {
        addTimeframeType('Expected Closing');
      }
    } else {
      if (hasTimeframeType('Expected Closing')) {
        removeTimeframeType('Expected Closing');
      }
    }
  };
  const checkForExecutionDate = () => {
    if (
      form.getState().values.status &&
      (form.getState().values.status === STATUS_OPTIONS.PENDING.value ||
        form.getState().values.status === STATUS_OPTIONS.SOLD.value)
    ) {
      if (!hasTimeframeType('Contract Execution')) {
        addTimeframeType('Contract Execution');
      }
    } else {
      if (hasTimeframeType('Contract Execution')) {
        removeTimeframeType('Contract Execution');
      }
    }
  };

  const checkForRequiredTimeframes = () => {
    checkForListingExecution();
    checkForListingExpiration();
    checkForLeaseBeginsAndEnds();
    checkForInspection();
    checkForLoanApproval();
    checkForEscrow();
    checkForAdditionalEscrow();
    checkForExpectedCloseDate();
    checkForExecutionDate();
  };

  useEffect(() => {
    if (isSuccess) {
      if (timeframeData?.data?.items.length > 0) {
        let timeframes = timeframeData.data.items.map((timeframe) => {
          const dateTime = DateTime.fromISO(timeframe.dueOn).setZone();
          const formatted = dateTime.toFormat('yyyy-MM-dd HH:mm:ss');
          return {
            taskId: timeframe.id,
            type: timeframe.timeframeType,
            status: timeframe.status,
            name: timeframe.name,
            dueOn: formatted,
            numberOfDays: timeframe.numberOfDays,
          };
        });
        form.change('timeframes', timeframes);
      }
      setProcessedData(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (processedData) {
      checkForRequiredTimeframes();
    }
  }, [processedData, form.getState().values]);

  // eslint-disable-next-line no-unused-vars
  let available = checkAvailableTypes();

  function checkAvailableTypes() {
    if (
      form.getState().values.representing &&
      (form.getState().values.representing?.includes('Seller') ||
        form.getState().values.representing?.includes('Buyer'))
    ) {
      return [
        'Loan Application Deadline',
        'Appraisal Deadline',
        'Attorney Approval Deadline',
        'Sale of Buyer’s Property Deadline',
        'Additional Inspection Deadline',
        'Lead-Based Paint Deadline',
        'Title Evidence Deadline',
        'Title Examination Deadline',
        'Survey Deadline',
        'Flood Zone Deadline',
        'Walkthrough',
      ];
    }
    return [];
  }

  const TimeframeMessage = () => {
    let timeframeCalculation = form.getState().values.timeframeCalculation;
    let message = '';
    if (timeframeCalculation && timeframeCalculation.timeframeCalculation) {
      let revDate = new Date(timeframeCalculation.revisionDate);
      let revDateYear = revDate.getUTCFullYear().toString().slice(-2);
      let revDateMonth = revDate.getUTCMonth() + 1;
      if (
        timeframeCalculation.timeframeCalculation ===
        TIMEFRAME_CALCULATION.ENDS_MIDNIGHT
      ) {
        message = `The timeframes using '# of days' are being calculated based on the FARBAR Contract revised on ${revDateMonth}/${revDateYear}. These timeframes will calculate incorrectly for any other contract.`;
      } else if (
        timeframeCalculation.timeframeCalculation ===
        TIMEFRAME_CALCULATION.SKIP_WEEKENDS_5_DAYS_OR_LESS
      ) {
        message = `The timeframes using "# of days" are being calculated based on the FARBAR Contract revised on ${revDateMonth}/${revDateYear}. These timeframes will calculate incorrectly for any other contract.`;
      } else if (
        timeframeCalculation.timeframeCalculation ===
        TIMEFRAME_CALCULATION.CUSTOM
      ) {
        message =
          'A custom contract has been selected. You must calculate and enter your timeframes manually.';
      }
    }
    return (
      <Typography variant={'body1'} sx={{ pb: 2, px: 1.5 }}>
        {message}
      </Typography>
    );
  };

  return (
    <>
      {([
        STATUS_OPTIONS.LISTED.value,
        STATUS_OPTIONS.PENDING.value,
        STATUS_OPTIONS.SOLD.value,
        STATUS_OPTIONS.LEASED.value,
      ].includes(form.getState().values.status) ||
        form.getState().values.transactionType !==
          TRANSACTION_TYPES.PRIMARY) && (
        <FormPanel
          startOpen
          label={'Timeframes'}
          // toolbar={<AddFromPanelButton onClick={()=>{setShowAddModal(!showAddModal)}} />}
        >
          <TimeframesWrapper>
            <TimeframeMessage />
            {hasTimeframeType('Listing Execution') && (
              <TimeframeField
                noDeadline
                required
                dateOnly
                updateField={'listExecutionDate'}
                type={'Listing Execution'}
              />
            )}
            {hasTimeframeType('Listing Expiration') && (
              <TimeframeField
                required
                dateOnly
                updateField={'listExpirationDate'}
                type={'Listing Expiration'}
              />
            )}
            {hasTimeframeType('Lease Begins') && (
              <TimeframeField
                noDeadline
                required
                dateOnly
                updateField={'leaseBegins'}
                type={'Lease Begins'}
              />
            )}
            {hasTimeframeType('Lease Ends') && (
              <TimeframeField
                required
                dateOnly
                updateField={'leaseEnds'}
                type={'Lease Ends'}
              />
            )}
            {hasTimeframeType('Contract Execution') && (
              <TimeframeField
                noDeadline
                dateOnly
                required
                updateField={'contractExecutionDate'}
                type={'Contract Execution'}
              />
            )}
            {hasTimeframeType('Escrow Deadline') && (
              <TimeframeField
                required
                updatePeriodField={'contractEscrowPeriod'}
                type={'Escrow Deadline'}
              />
            )}
            {hasTimeframeType('Additional Escrow Deadline') && (
              <TimeframeField
                required
                updatePeriodField={'contractAdditionalEscrowPeriod'}
                type={'Additional Escrow Deadline'}
              />
            )}
            {hasTimeframeType('Inspection Deadline') && (
              <TimeframeField
                required
                updatePeriodField={'contractInspectionPeriod'}
                type={'Inspection Deadline'}
              />
            )}
            {hasTimeframeType('Loan Approval Deadline') && (
              <TimeframeField
                required
                updatePeriodField={'contractLoanCommitment'}
                type={'Loan Approval Deadline'}
              />
            )}
            {hasTimeframeType('Appraisal Deadline') && (
              <TimeframeField type={'Appraisal Deadline'} />
            )}
            {hasTimeframeType('Attorney Approval Deadline') && (
              <TimeframeField type={'Attorney Approval Deadline'} />
            )}
            {hasTimeframeType('Sale of Buyer’s Property Deadline') && (
              <TimeframeField type={'Sale of Buyer’s Property Deadline'} />
            )}
            {hasTimeframeType('Additional Inspection Deadline') && (
              <TimeframeField type={'Additional Inspection Deadline'} />
            )}
            {hasTimeframeType('Lead-Based Paint Deadline') && (
              <TimeframeField type={'Lead-Based Paint Deadline'} />
            )}
            {hasTimeframeType('Title Evidence Deadline') && (
              <TimeframeField type={'Title Evidence Deadline'} />
            )}
            {hasTimeframeType('Title Examination Deadline') && (
              <TimeframeField type={'Title Examination Deadline'} />
            )}
            {hasTimeframeType('Survey Deadline') && (
              <TimeframeField type={'Survey Deadline'} />
            )}
            {hasTimeframeType('Flood Zone Deadline') && (
              <TimeframeField type={'Flood Zone Deadline'} />
            )}
            {hasTimeframeType('Walkthrough') && (
              <TimeframeField type={'Walkthrough'} />
            )}
            {hasTimeframeType('Expected Closing') && (
              <TimeframeField
                dateOnly
                required
                updateField={'expectedCloseDate'}
                type={'Expected Closing'}
              />
            )}
          </TimeframesWrapper>
        </FormPanel>
      )}
      {/*<AbeModal*/}
      {/*    hideActions*/}
      {/*    maxWidth={"sm"}*/}
      {/*    onClose={()=>{setShowAddModal(false)}}*/}
      {/*    modalOpen={showAddModal}*/}
      {/*    content={*/}
      {/*        <List>*/}
      {/*            {available.length > 0 ? available.map((timeframeOption, index) => {*/}
      {/*                if(!hasTimeframeType(timeframeOption)){*/}
      {/*                    return <ListItem key={index} button dense onClick={()=>{addTimeframeType(timeframeOption)}}>*/}
      {/*                        <ListItemText primary={timeframeOption}/>*/}
      {/*                    </ListItem>*/}
      {/*                }*/}
      {/*            }) : <div>None available</div>}*/}
      {/*        </List>*/}
      {/*    }*/}
      {/*    title="Add Timeframe"*/}
      {/*/>*/}
    </>
  );
};

TimeframesPanel.propTypes = {};
