import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { AuditLogDrawer } from 'components/Common/AuditLogDrawer/AuditLogDrawer';
import { DetailsLayout } from 'components/Layouts';
import { UPDATE_CONTACT } from 'consts';
import { conversion } from 'helpers';
import { useAccessDeniedHandler } from 'hooks';
import { useGetContactQuery } from 'redux/rtk-query';
import { ContactDetails } from './components';
import { ContactDashboard } from './components/ContactDashboard';

export const DetailsContactPage = () => {
  let { contactDetailsId } = useParams();
  const navigate = useNavigate();
  const [contactInfo, setContactInfo] = useState(null);
  const {
    data: contactData,
    isSuccess,
    error,
  } = useGetContactQuery(
    { id: contactDetailsId },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (isSuccess && contactData.data) {
      let contactValues = { ...contactData.data };
      contactValues.primaryAddress = conversion.addressToString(
        contactValues.primaryAddress,
      );
      setContactInfo(contactValues);
      document.title = `${conversion.getName(contactData?.data)} - Contact`;
    }
  }, [contactDetailsId, isSuccess, contactData]); // include contactDetailsId and contactData.data

  const accessDeniedMessage = useAccessDeniedHandler(error);
  if (accessDeniedMessage) {
    return <div>{accessDeniedMessage}</div>;
  }

  return (
    <>
      <DetailsLayout
        equalWidth
        header={
          <>
            {contactInfo && conversion.getName(contactInfo)}
            <Button
              size={'small'}
              onClick={() => {
                navigate(UPDATE_CONTACT(contactDetailsId));
              }}
            >
              Edit
            </Button>
            <AuditLogDrawer uuid={contactInfo?.uuid} modelType={'Contact'} />
          </>
        }
        leftContent={<ContactDetails contactInfo={contactInfo} />}
        rightContent={<ContactDashboard id={contactDetailsId} />}
      />
      <Outlet />
    </>
  );
};
