// PagesNavigation.js

import React, { useMemo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { Grid, Alert } from '@mui/material';
import AddPageTab from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/PagesNavigation/AddPageTab';
import {
  htmlTemplateSelectorCM as htmlTemplateSelector,
  copyItemToPageSelector,
} from 'redux/selectors';
import { sortPagesCM as sortPages } from 'redux/slices';
import PageTab from './PageTab';

const PagesNavigation = () => {
  const dispatch = useDispatch();
  const htmlTemplate = useSelector(htmlTemplateSelector);
  const copyToPage = useSelector(copyItemToPageSelector);
  const pageOrder = htmlTemplate?.options?.pageOrder || [];

  // Use useRef to maintain stable references to pageItems
  const pageItemsRef = useRef([]);

  useMemo(() => {
    // Update pageItemsRef only when pageOrder changes
    pageItemsRef.current = pageOrder.map((pageId) => ({ id: pageId }));
  }, [pageOrder]);

  // Memoize the setList function
  const handleSetList = useCallback(
    (sortableState) => {
      const pageIds = sortableState.map((item) => item.id);
      if (pageIds.length > 0 && pageIds.join(',') !== pageOrder.join(',')) {
        dispatch(sortPages(pageIds));
      }
    },
    [dispatch, pageOrder],
  );

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ background: '#ababab', minHeight: '100%' }}
    >
      {copyToPage.item && !copyToPage.page && (
        <>
          <Alert severity="info">Select a page to copy the item to</Alert>
        </>
      )}
      <ReactSortable
        style={{ height: '100%', position: 'relative', marginTop: '16px' }}
        list={pageItemsRef.current}
        setList={handleSetList}
      >
        {pageItemsRef.current.map((item) => (
          <PageTab key={item.id} pageId={item.id} />
        ))}
      </ReactSortable>
      <AddPageTab />
    </Grid>
  );
};

export default React.memo(PagesNavigation);
