import { useEffect } from 'react';

export function useResizeObserver(ref, callback) {
  useEffect(() => {
    if (!ref.current) return;

    // Create a ResizeObserver instance, passing a callback
    const observer = new ResizeObserver((entries) => {
      // We'll just grab the first entry, though there can be multiple
      if (entries.length > 0) {
        const { height } = entries[0].contentRect;
        callback(height);
      }
    });

    // Start observing the referenced DOM node
    observer.observe(ref.current);

    // Cleanup - disconnect the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [ref, callback]);
}
