import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { SearchInput } from 'components/SearchInput';

export const SalesPipelineHeader = () => {
  return (
    <>
      <TableHead className={'table-header'}>
        <TableRow>
          <TableCell className={'noPadding'} sx={{ padding: [0, 0, 0, 1] }}>
            <SearchInput placeholder={'Address'} changeHandler={() => {}} />
          </TableCell>
          <TableCell align={'center'}>Documents</TableCell>
          <TableCell align={'right'}>{'Net Comm.'}</TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

SalesPipelineHeader.propTypes = {};
