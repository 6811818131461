import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
  IconButton,
  TextField,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isDeletingSelectorCM } from 'redux/selectors';

const SmallIconButton = styled(IconButton)(() => ({
  fontSize: '0.9rem',
  padding: '4px',
  color: 'rgb(9,124,199,1)',
  '&:disabled': {
    color: 'rgba(9,124,199,0.5)',
  },
}));

function ItemSelectorMenuItem({
  items,
  onPageChange,
  label,
  locked,
  initialValue = null,
  toolbar = null,
  itemToggle = null,
}) {
  const isDeleting = useSelector(isDeletingSelectorCM);

  const findInitialIndex = useCallback(() => {
    if (initialValue !== null) {
      return items.indexOf(initialValue) > -1 ? items.indexOf(initialValue) : 0;
    }
    return 0;
  }, [initialValue, items]);

  const [currentIndex, setCurrentIndex] = useState(findInitialIndex());

  // Synchronize currentIndex with initialValue when initialValue or items change
  useEffect(() => {
    const newIndex = findInitialIndex();
    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex);
    }
    // Removed currentIndex from dependencies to prevent infinite loop
  }, [initialValue, items, findInitialIndex]);

  useEffect(() => {
    if (!isDeleting && currentIndex >= 0 && currentIndex < items.length) {
      onPageChange(items[currentIndex]);
    }
  }, [currentIndex, items, onPageChange, isDeleting]);

  const handlePrevious = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
  };

  const handleNext = () => {
    if (currentIndex < items.length - 1) setCurrentIndex(currentIndex + 1);
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10) - 1;
    if (!isNaN(value) && value >= 0 && value < items.length) {
      setCurrentIndex(value);
    }
  };

  return (
    <ListItem
      sx={{
        display: 'block',
        marginLeft: 0,
        position: 'relative',
        '&:hover $toolbarWrapper': {
          visibility: 'visible',
          opacity: 1,
          width: 'auto',
        },
        '& .MuiListItemText-root': {
          marginLeft: 0,
        },
      }}
    >
      <ListItemText
        primary={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography width={'100%'} noWrap>
              {label}
            </Typography>
            {toolbar && <div style={{ marginLeft: 'auto' }}>{toolbar}</div>}
          </div>
        }
      />
      {!locked && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SmallIconButton
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </SmallIconButton>
          <TextField
            size="small"
            value={currentIndex + 1}
            onChange={handleInputChange}
            inputProps={{
              min: 1,
              max: items.length,
              style: { textAlign: 'center', padding: '5px 0', width: '30px' },
            }}
          />
          <Typography style={{ margin: '0 8px' }}>of {items.length}</Typography>
          <SmallIconButton
            onClick={handleNext}
            disabled={currentIndex === items.length - 1}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </SmallIconButton>
          {itemToggle && <div style={{ marginLeft: '10px' }}>{itemToggle}</div>}
        </div>
      )}
    </ListItem>
  );
}

ItemSelectorMenuItem.propTypes = {
  items: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  locked: PropTypes.bool,
  initialValue: PropTypes.any,
  toolbar: PropTypes.node,
  itemToggle: PropTypes.node,
};

export default ItemSelectorMenuItem;
