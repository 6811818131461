import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, Divider } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { ToolbarRow } from 'components/Common/Table/TableToolbar/styled';
import { SearchInput } from 'components/SearchInput';
import { ListBox, NewButtonBox } from 'components/Styled';
import { CREATE_TASK } from 'consts';
import { useDebounce, useOpenDrawer } from 'hooks';
import { TaskSection } from './components/TaskSection';

export const TasksList = ({
  relatedModel,
  relatedModelType,
  view = 'standard',
}) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 500);
  const openDrawer = useOpenDrawer();

  const createTask = useCallback(() => {
    openDrawer(CREATE_TASK(relatedModelType, relatedModel.id));
  }, [relatedModel]);

  return (
    <>
      <ToolbarRow width="100%" justifyContent="space-between">
        <SearchInput changeHandler={setSearchValue} />
        <NewButtonBox>
          <Button
            size="small"
            variant="text"
            onClick={createTask}
            endIcon={
              <FontAwesomeIconComp fontSize="4rem" icon={faCirclePlus} />
            }
          >
            New
          </Button>
        </NewButtonBox>
      </ToolbarRow>
      <Divider />
      <ListBox>
        {view === 'standard' ? (
          <>
            <TaskSection
              relatedModelType={relatedModelType}
              uuid={relatedModel?.uuid}
              searchValue={debouncedValue}
            />
          </>
        ) : (
          <>
            <TaskSection
              relatedModelType={relatedModelType}
              uuid={relatedModel?.uuid}
              searchValue={debouncedValue}
              type={'PAST_DUE'}
            />
            <TaskSection
              relatedModelType={relatedModelType}
              uuid={relatedModel?.uuid}
              searchValue={debouncedValue}
              type={'DUE_TODAY'}
            />
            <TaskSection
              relatedModelType={relatedModelType}
              uuid={relatedModel?.uuid}
              searchValue={debouncedValue}
              type={'UPCOMING'}
            />
            <TaskSection
              relatedModelType={relatedModelType}
              uuid={relatedModel?.uuid}
              searchValue={debouncedValue}
              type={'NO_DUE_DATE'}
            />
            <TaskSection
              relatedModelType={relatedModelType}
              uuid={relatedModel?.uuid}
              searchValue={debouncedValue}
              type={'COMPLETED'}
              startClosed
            />
          </>
        )}
      </ListBox>
    </>
  );
};

TasksList.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  relatedModel: PropTypes.object,
  relatedModelType: PropTypes.string,
  view: PropTypes.string,
};
