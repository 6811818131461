import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  Table,
  TableBody,
  TableRow as MUITableRowCollapse,
} from '@mui/material';
import { PopperButton } from 'components/Common';
import { mapArrayValues } from 'components/Common/Table/utils';
import {
  TableCell,
  TableCellCollapse,
  TableRow as MUITableRow,
} from 'components/Styled';

export const TableChildRow = ({ open, rows, number, menuItems, cells }) => {
  return rows.map((child) => (
    <>
      <MUITableRowCollapse sx={open ? null : { borderTop: 'hidden' }}>
        <TableCellCollapse colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>
                <>
                  <MUITableRow hover key={child.id}>
                    {cells.map(
                      (
                        { width, value, id, getRowClickHandler, Wrapper },
                        index,
                      ) => {
                        return (
                          <TableCell
                            key={id}
                            width={width}
                            sx={
                              index === 0
                                ? {
                                    paddingLeft: `${90 + number * 40}px`,
                                  }
                                : null
                            }
                          >
                            {Wrapper ? (
                              <Wrapper
                                value={
                                  Array.isArray(value)
                                    ? mapArrayValues(child, value)
                                    : child[value]
                                }
                                item={child}
                                onClick={
                                  getRowClickHandler
                                    ? getRowClickHandler(child)
                                    : null
                                }
                              />
                            ) : (
                              child[value]
                            )}
                          </TableCell>
                        );
                      },
                    )}
                    {menuItems ? (
                      <TableCell width={90}>
                        <PopperButton menuItems={menuItems} item={child} />
                      </TableCell>
                    ) : null}
                  </MUITableRow>
                </>
              </TableBody>
            </Table>
          </Collapse>
        </TableCellCollapse>
      </MUITableRowCollapse>
      {child.children && child.children.length > 0 && (
        <TableChildRow
          key={child.id}
          open={open}
          rows={child.children}
          menuItems={menuItems}
          cells={cells}
          number={number + 1}
        />
      )}
    </>
  ));
};

TableChildRow.defaultProps = {
  menuItems: null,
};

TableChildRow.propTypes = {
  open: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      [PropTypes.string]: PropTypes.string,
    }),
  ),
  number: PropTypes.number,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.object,
    }),
  ),
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      width: PropTypes.number,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      isCollapsibleCell: PropTypes.bool,
    }),
  ),
};
