// src/hooks/useUserToken.js

import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function useUserToken() {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [userToken, setUserToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchToken = async () => {
      setIsLoading(true);

      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          setUserToken(token);
        } catch (error) {
          console.error('Error getting access token:', error);
          setUserToken(null);
        }
      }

      setIsLoading(false);
    };

    fetchToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  return { userToken, isLoading, user };
}

export default useUserToken;
