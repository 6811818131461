import { styled, Typography, Box } from '@mui/material';

export const ToolbarContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '8px',
});

export const ToolbarRow = styled(Box)({
  minHeight: '25px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '12px',
  paddingRight: '12px',
});

export const TableResultsCount = styled('span')({
  fontSize: 13,
  padding: '3px 5px',
  color: '#868181',
  borderRadius: 6,
  backgroundColor: '#eeecec',
  marginLeft: 10,
});

export const ToolbarTitle = styled(Typography)({
  flex: '1 0 fit-content',
  fontWeight: 500,
  fontSize: '1.8rem',
});

ToolbarTitle.defaultProps = {
  variant: 'subtitle1',
  id: 'tableTitle',
  component: 'div',
};
