import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { TreeItem } from '@mui/x-tree-view';

export const DocumentTreeItemBox = styled(TreeItem)({
  marginRight: 3,
  '& .MuiTreeItem-iconContainer': {
    marginRight: 5,
    marginLeft: 5,
  },
  '$ .css-mopuop-MuiTreeItem-content .MuiTreeItem-label': {
    paddingLeft: 10,
  },
  '& .MuiTreeItem-content': {
    padding: '2px 4px',
    marginRight: 8,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: 'rgba(73, 77, 80, 0.08)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(73, 77, 80, 0.08)',
    },
    // Directly target the svg inside the icon container
    '& .MuiTreeItem-iconContainer svg': {
      fontSize: '15px', // Set the desired font size for the icon
    },
  },
  '& .MuiTreeItem-group': {
    marginLeft: 6,
  },
});

export const DocumentItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',

  '& .document-row-menu': {
    width: 0,
    overflow: 'hidden', // Prevent child overflow
    transition: 'width 0.3s ease', // Optional: smooth transition
  },
  '&:hover .document-row-menu': {
    width: 'auto', // Expand to full width on hover
  },
});

export const DocumentItemName = styled(Typography)({
  fontWeight: 'inherit',
  flexGrow: 1,
});

DocumentItemName.defaultProps = {
  display: 'block',
  variant: 'body1',
  noWrap: true,
};

export const DocumentRowMenuWrapper = styled('div')({
  alignItems: 'center',
  display: 'flex',
  width: 0,
});
