import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIconComp } from 'components/Common/';
import { InfoIcon, InfoListItem } from 'components/Styled';

export const ContactInfoItems = ({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <InfoListItem key={index}>
          <InfoIcon>
            <FontAwesomeIconComp icon={item.icon} />
          </InfoIcon>
          {item.content}
        </InfoListItem>
      ))}
    </div>
  );
};

ContactInfoItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
