import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
});

export const profileSliceReducer = profileSlice.reducer;
