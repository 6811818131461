import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ButtonSection } from 'components/Styled';

export const SaveAndCancelButtons = ({
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
  closeDrawer,
  onClickSave,
}) => {
  return (
    <ButtonSection>
      <Button
        variant="outlined"
        onClick={() => {
          closeDrawer();
        }}
      >
        {cancelLabel}
      </Button>
      <Button
        onClick={() => {
          onClickSave();
        }}
        variant="contained"
      >
        {saveLabel}
      </Button>
    </ButtonSection>
  );
};

SaveAndCancelButtons.propTypes = {
  cancelLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  closeDrawer: PropTypes.func,
  onClickSave: PropTypes.func,
};
