import React from 'react';
import PropTypes from 'prop-types';
import { faEllipsisVertical, faEye } from '@fortawesome/pro-regular-svg-icons';
import { Grid, IconButton, Typography } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { CornerButtonWrapper, GreyBox } from 'components/Styled';
import { useHover } from 'hooks';

export const RelationshipInfo = ({ relationship }) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <Grid item xs={4}>
      <GreyBox ref={hoverRef}>
        <Typography variant="body1">{relationship.name}</Typography>
        <Typography variant="caption">{relationship.type.name}</Typography>
        {isHovered && (
          <CornerButtonWrapper>
            <IconButton size={'small'}>
              <FontAwesomeIconComp icon={faEye} fontSize={12} />
            </IconButton>
            <IconButton size={'small'}>
              <FontAwesomeIconComp icon={faEllipsisVertical} fontSize={12} />
            </IconButton>
          </CornerButtonWrapper>
        )}
      </GreyBox>
    </Grid>
  );
};

RelationshipInfo.propTypes = {
  relationship: PropTypes.object.isRequired,
};
