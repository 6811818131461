import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_ROUTES } from 'consts';
import { omitNull } from 'helpers';
import { useGetGroupQuery, useUpdateGroupMutation } from 'redux/rtk-query';
import GroupForm, { defaultValues } from '../GroupForm';

export const EditGroupPage = () => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  let { groupId } = useParams();
  const navigate = useNavigate();
  const { data, isSuccess } = useGetGroupQuery(groupId, { skip: !groupId });
  const [updateGroup] = useUpdateGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull({ ...data.data }),
      });
    }
  }, [isSuccess]);

  const onSubmit = async ({ schema, ...values }) => {
    let actions = [];
    schema.map((module) => {
      module.actions.map((action) => {
        actions.push({ actionId: action.id, enable: action.enabled });
      });
    });
    return await updateGroup({
      groupId: values.id,
      map: actions,
      ...values,
    });
  };

  const afterSave = () => {
    navigate(ADMIN_ROUTES.GROUPS.path);
  };

  return (
    <GroupForm
      initialValues={initialValues}
      handleSubmit={onSubmit}
      title={'Edit Group'}
      afterSave={afterSave}
    />
  );
};
