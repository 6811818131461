import React from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { SideDrawer, FontAwesomeIconComp } from 'components/Common';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import { HeadingSection, Header } from 'components/Styled';

export const PhotoSetDrawer = ({ documentData, open = false, onClose }) => {
  const attachments = documentData?.attachments || [];
  return (
    <SideDrawer width={'60%'} isOpen={open} closeDrawer={onClose}>
      <HeadingSection>
        <Header variant="h4">Photo Set</Header>
        <FontAwesomeIconComp
          icon={faCircleXmark}
          style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
          onClick={() => onClose()}
        />
      </HeadingSection>
      <Box>
        <Grid container spacing={1} p={1}>
          {attachments.map((attachment) => (
            <Grid key={attachment.id} item xs={3}>
              <AbeFileImage file={attachment} style={{ borderRadius: 4 }} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </SideDrawer>
  );
};

PhotoSetDrawer.propTypes = {
  documentData: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
