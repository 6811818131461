import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { Card, Collapse, Grid, Typography } from '@mui/material';
import { FontAwesomeIconComp, ModuleIcon } from 'components/Common';
import { IconButton, StyledBadge } from 'components/Styled';
import { useItemCount } from 'contexts/ItemCountProvider';
import { AbeCardHeader } from './styled';

const CardHeaderContent = ({
  title,
  afterTitle,
  isOpen,
  toggleCard,
  hideArrow,
}) => {
  const { itemCount } = useItemCount();
  return (
    <Grid container alignItems={'center'} onClick={toggleCard}>
      <Grid item xs={hideArrow ? 12 : 9}>
        <Typography
          component={'div'}
          variant={'h5'}
          noWrap
          display={'flex'}
          alignItems={'center'}
        >
          {title}
          {itemCount > 0 && (
            <StyledBadge color={'info'} badgeContent={itemCount} />
          )}
          {afterTitle}
        </Typography>
      </Grid>
      <Grid item xs={hideArrow ? 0 : 3} textAlign={'right'}>
        {!hideArrow && (
          <IconButton size={'small'} onClick={toggleCard}>
            <FontAwesomeIconComp
              icon={isOpen ? faChevronDown : faChevronUp}
              fontSize={'1.4rem'}
            />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

CardHeaderContent.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  afterTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  isOpen: PropTypes.bool,
  toggleCard: PropTypes.func,
  hideArrow: PropTypes.bool,
};

export const AbeCard = ({
  children,
  title,
  icon,
  afterTitle,
  hideContent,
  startClosed,
  forceOpen,
  noTitle,
  hideArrow = false,
  sx = {},
  stickyHeader = false,
  ...other
}) => {
  const [open, setOpen] = useState(startClosed || hideContent ? false : true);
  const isOpen = forceOpen || open || hideArrow;

  const renderAvatar = (title, icon) => {
    const moduleIcon =
      typeof title === 'string' ? (
        <ModuleIcon maxWidth={'20px'} module={title} marginRight={0} />
      ) : null;

    if (!moduleIcon && !icon) {
      return null;
    }

    return (
      <>
        {moduleIcon}
        {icon && icon}
      </>
    );
  };

  return (
    <Card {...other} sx={sx} elevation={0}>
      {!noTitle && (
        <AbeCardHeader
          stickyHeader={stickyHeader}
          title={
            <CardHeaderContent
              title={title}
              afterTitle={afterTitle}
              isOpen={isOpen}
              toggleCard={() => setOpen(!open)}
              hideArrow={hideArrow}
            />
          }
          avatar={renderAvatar(title, icon)}
        />
      )}
      <Collapse in={isOpen || hideContent}>
        <div
          style={{
            visibility: hideContent && !isOpen ? 'hidden' : 'visible',
            height: hideContent && !isOpen ? 0 : 'auto',
          }}
        >
          {children}
        </div>
      </Collapse>
    </Card>
  );
};

AbeCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  icon: PropTypes.object,
  afterTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  startClosed: PropTypes.bool,
  hideContent: PropTypes.bool,
  forceOpen: PropTypes.bool,
  noTitle: PropTypes.bool,
  hideArrow: PropTypes.bool,
  sx: PropTypes.object,
  stickyHeader: PropTypes.bool,
};
