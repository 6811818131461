import axios from 'axios';
import { accessTokenSelector } from 'redux/selectors';
import { setUserLogin, setUserLogout } from 'redux/slices';
import { store } from 'store';

const API_URL = process.env.REACT_APP_BASE_URL;

const axiosAPI = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

axiosAPI.interceptors.request.use((config) => {
  const accToken = accessTokenSelector(store.getState());
  config.headers.Authorization = `Bearer ${accToken}`;
  return config;
});

axiosAPI.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/auth/refresh`, {
          withCredentials: true,
        });
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
        store.dispatch(setUserLogin(response.data));
        return axiosAPI.request(originalRequest);
      } catch {
        store.dispatch(setUserLogout());
      }
    }
    throw error;
  },
);

export default axiosAPI;
