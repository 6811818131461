import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ConfirmDialog } from 'components/Common';

export const ConfirmationButton = ({
  label,
  confirmTitle,
  confirmMessage,
  handleConfirm,
  color,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        sx={{ mx: 1, height: 32 }}
        variant="contained"
        color={color}
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        {label}
      </Button>
      <ConfirmDialog
        title={confirmTitle}
        message={confirmMessage}
        open={open}
        setOpen={setOpen}
        onConfirm={handleConfirm}
      />
    </>
  );
};

ConfirmationButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  color: PropTypes.string,
  confirmTitle: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string.isRequired,
};
