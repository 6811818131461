import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SideDrawer } from 'components/Common';
import { useDrawer } from 'hooks';
import { useCreateInvoiceCategoryMutation } from 'redux/rtk-query';
import { InvoiceCategoryForm } from '../InvoiceCategoryForm/InvoiceCategoryForm';

export const CreateInvoiceCategoryPage = ({ path }) => {
  const [initialValues, setInitialValues] = useState({
    name: '',
    color: null,
  });
  const { isOpen, closeDrawer } = useDrawer(true, path);

  const [createCategory] = useCreateInvoiceCategoryMutation();

  const handleSubmitFunc = async (values) => {
    // let formData = processFormData(values);

    console.log(values, setInitialValues);
    return await createCategory({
      parentCategoryId: values?.parentCategory?.value,
      ...values,
    });
  };

  const afterSave = () => {
    closeDrawer();
  };

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      <InvoiceCategoryForm
        title={'Create Category'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

CreateInvoiceCategoryPage.propTypes = {
  params: PropTypes.object,
  path: PropTypes.string,
};
