import React from 'react';
import PropTypes from 'prop-types';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { isAdmin } from 'helpers';

export const ToggleFieldsButton = ({
  showAllFields,
  setShowAllFields,
  permissions,
}) => {
  if (!isAdmin(permissions)) return null;

  return (
    <Tooltip title={showAllFields ? 'Hide Fields' : 'Show Fields'}>
      <IconButton
        variant="text"
        color="primary"
        size={'small'}
        onClick={() => setShowAllFields(!showAllFields)}
      >
        <FontAwesomeIcon size={'xl'} icon={faEye} />
      </IconButton>
    </Tooltip>
  );
};

ToggleFieldsButton.propTypes = {
  showAllFields: PropTypes.bool,
  setShowAllFields: PropTypes.func,
  permissions: PropTypes.array,
};
