export const STATUS_OPTIONS = {
  DRAFT: {
    value: 'Draft',
    label: 'Draft',
  },
  LISTED: {
    value: 'Listed',
    label: 'Listed',
  },
  PENDING: {
    value: 'Pending',
    label: 'Pending',
  },
  SOLD: {
    value: 'Sold',
    label: 'Sold',
  },
  LEASED: {
    value: 'Leased',
    label: 'Leased',
  },
  PROPERTY_MGMT: {
    value: 'Property Mgmt',
    label: 'Property Mgmt',
  },
  REFERRAL: {
    value: 'Referral',
    label: 'Referral',
  },
  EXPIRED: {
    value: 'Expired',
    label: 'Expired',
  },
  WITHDRAWN: {
    value: 'Withdrawn',
    label: 'Withdrawn',
  },
  EXPIRED_WITHDRAWN: {
    value: 'Expired/Withdrawn',
    label: 'Expired/Withdrawn',
  },
  CANCELLED: {
    value: 'Cancelled',
    label: 'Cancelled',
  },
};

export const REPRESENTING_OPTIONS = {
  SELLER: {
    value: 'Seller',
    label: 'Seller',
  },
  BUYER: {
    value: 'Buyer',
    label: 'Buyer',
  },
  SELLER_AND_BUYER: {
    value: 'Seller & Buyer',
    label: 'Seller & Buyer',
  },
  LANDLORD: {
    value: 'Landlord',
    label: 'Landlord',
  },
  TENANT: {
    value: 'Tenant',
    label: 'Tenant',
  },
  LANDLORD_AND_TENANT: {
    value: 'Landlord & Tenant',
    label: 'Landlord & Tenant',
  },
  REFERRAL: {
    value: 'Referral',
    label: 'Referral',
  },
  NO_PARTY: {
    value: 'No Party',
    label: 'No Party',
  },
};

export const PM_FILE_OPTIONS = {
  LEASE_FOR_PROPERTY_MGMT: {
    value: 'Lease for Property Mgmt',
    label: 'Lease for Property Mgmt',
  },
  PROPERTY_MGMT: {
    value: 'Property Mgmt',
    label: 'Property Mgmt',
  },
  LEASE_ONLY: {
    value: 'Lease Only',
    label: 'Lease Only',
  },
};

export const SALE_TYPE_OPTIONS = {
  TRADITIONAL: {
    value: 'Traditional',
    label: 'Traditional',
  },
  PROPERTY_MGMT: {
    value: 'New Construction',
    label: 'New Construction',
  },
  COMMERCIAL: {
    value: 'Commercial',
    label: 'Commercial',
  },
  VACANT_LAND: {
    value: 'Vacant Land',
    label: 'Vacant Land',
  },
  SHORT_SALE: {
    value: 'Short Sale',
    label: 'Short Sale',
  },
  BANK_OWNED: {
    value: 'Bank Owned',
    label: 'Bank Owned',
  },
  FOR_SALE_BY_OWNER: {
    value: 'For Sale By Owner',
    label: 'For Sale By Owner',
  },
};

export const PAID_BY_TYPE_OPTIONS = {
  CUSTOMER: {
    value: 'Customer',
    label: 'Customer',
  },
  AGENT: {
    value: 'Agent',
    label: 'Agent',
  },
  SPLIT: {
    value: 'Split',
    label: 'Split',
  },
};

export const FINANCING_TYPES = {
  CONVENTIONAL: 'Conventional',
  CASH: 'Cash',
  FHA: 'FHA',
  VA: 'VA',
  USDA: 'USDA',
  JUMBO: 'Jumbo',
  ARM: 'ARM',
  HARD_MONEY: 'Hard Money',
  CONSTRUCTION_LOAN: 'Construction Loan',
  SELLER_FINANCING: 'Seller Financing',
  ASSUMPTION: 'Assumption',
  OTHER: 'Other',
};

export const LEASE_TYPE_OPTIONS = {
  NEW_LEASE: {
    value: 'New Lease',
    label: 'New Lease',
  },
  RENEWAL: {
    value: 'Renewal',
    label: 'Renewal',
  },
};

export const PAYMENT_METHOD_OPTIONS = {
  BILL_COM: {
    value: 'Bill.com',
    label: 'Bill.com',
  },
  PERSONAL_OR_CERTIFIED_CHECK: {
    value: 'Personal or Certified Check',
    label: 'Personal or Certified Check',
  },
  CERTIFIED_FUND_ONLY: {
    value: 'Certified Funds Only',
    label: 'Certified Funds Only',
  },
  PREPAID: {
    value: 'Prepaid',
    label: 'Prepaid',
  },
  ASSISTANCE: {
    value: 'Assistance',
    label: 'Assistance',
  },
};

export const PM_STATUS_OPTIONS = {
  LEASED: {
    value: 'Leased',
    label: 'Lease',
  },
  RENEWAL_PENDING: {
    value: 'Renewal Pending',
    label: 'Renewal Pending',
  },
  LISTED_AND_OCCUPIED: {
    value: 'Listed and Occupied',
    label: 'Listed and Occupied',
  },
  LISTED_AND_VACANT: {
    value: 'Listed and Vacant',
    label: 'Listed and Vacant',
  },
  MOVE_IN_OUT_AND_PRORATED: {
    value: 'Move In/Out and Prorated',
    label: 'Move In/Out and Prorated',
  },
  MONTH_TO_MONTH: {
    value: 'Month to Month',
    label: 'Month to Month',
  },
  EVICTION: {
    value: 'Eviction',
    label: 'Eviction',
  },
  INACTIVE: {
    value: 'Inactive',
    label: 'Inactive',
  },
  UNKNOWN: {
    value: 'Unknown',
    label: 'Unknown',
  },
};

export const YES_OR_NO = {
  YES: {
    label: 'Yes',
    value: 'Yes',
  },
  NO: {
    label: 'No',
    value: 'NO',
  },
};

export const TIMEFRAME_CALCULATION = {
  ENDS_MIDNIGHT:
    'Calendar Days, Ends at Midnight, Skip if ends Weekends, Holidays and Observed Holidays and ends at 11:59pm',
  SKIP_WEEKENDS_5_DAYS_OR_LESS:
    'Calendar Days, Skip Weekends if 5 days or less, Skip if ends on Weekend or Holidays and roll to 5pm next business day',
  CUSTOM: 'Custom',
};

export const roleTypes = {
  TRANSACTION_COORDINATOR: 'Transaction Coordinator',
  STAGER: 'Stager',
  BUILDER: 'Builder',
  ATTORNEY_LEGAL: 'Attorney/Legal',
  APPRAISER: 'Appraiser',
  SURVEYOR: 'Surveyor',
  HOA: 'HOA',
  HOME_INSPECTOR: 'Home Inspector',
  INSURANCE: 'Insurance',
  HOME_WARRANTY: 'Home Warranty',
  PHOTOGRAPHER: 'Photographer',
  RELOCATION: 'Relocation',
  ASSISTANT: 'Assistant',
};

export const TRANSACTION_TYPES = {
  PRIMARY: 'Primary',
  DRAFT_OFFER: 'Draft Offer',
  ARCHIVED_OFFER: 'Archived Offer',
  ACTIVE_OFFER: 'Active Offer',
};
