import React, { useMemo, useCallback } from 'react';
import { faInputText } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItemButton, CollapsableMenuGroup } from 'components/Common';
import { CM_ITEM_TYPES } from 'consts';
import {
  htmlTemplateSelectorCM,
  activePageCM,
  cmBlockTypeSelectorCM,
  itemsSelectorCM,
} from 'redux/selectors';
import { copyItemToPage } from 'redux/slices';

function AddLabeledItemMenuItem() {
  const dispatch = useDispatch();

  const htmlTemplate = useSelector(htmlTemplateSelectorCM);
  const activePage = useSelector(activePageCM);
  const cmBlockType = useSelector(cmBlockTypeSelectorCM);
  const items = useSelector(itemsSelectorCM);

  const handleAddLabeledItem = useCallback(
    (label) => {
      let selectedItem = null;

      const pages = htmlTemplate.options?.pages || {};

      // Search for the item with the given label in any page
      for (const pageId in pages) {
        if (pages[pageId]) {
          const pageElements = new Set(pages[pageId].elements);
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.textLabel === label && pageElements.has(item.id)) {
              selectedItem = item;
              break;
            }
          }
          if (selectedItem) {
            break; // If found, break the outer loop as well
          }
        }
      }

      if (selectedItem) {
        const newItem = { ...selectedItem };
        // Dispatch an action to copy the item to the active page
        dispatch(
          copyItemToPage({
            item: newItem,
            page: activePage,
          }),
        );
      }
    },
    [htmlTemplate, items, activePage, dispatch],
  );

  const labeledItems = useMemo(() => {
    const pages = htmlTemplate.options?.pages || {};
    const activePageElements = pages[activePage]?.elements || [];
    const activePageLabels = new Set();
    const uniqueLabels = new Set();
    let unusedLabelsCount = 0; // Initialize a counter for unused labels

    items.forEach((item) => {
      if (item.textLabel && !uniqueLabels.has(item.textLabel)) {
        uniqueLabels.add(item.textLabel);
        // Check if the label is used on the active page
        if (
          activePageElements.some((elementId) => {
            const elementItem = items.find((it) => it.id === elementId);
            return elementItem?.textLabel === item.textLabel;
          })
        ) {
          activePageLabels.add(item.textLabel);
        } else {
          unusedLabelsCount++; // Increment the counter if the label is unused
        }
      }
    });

    return {
      labeledItems: Array.from(uniqueLabels)
        .filter((label) => !activePageLabels.has(label))
        .map((label) => (
          <MenuItemButton
            key={label}
            onClick={() => handleAddLabeledItem(label)}
            label={label}
          />
        )),
      unusedLabelsCount, // Return the count of unused labels
    };
  }, [htmlTemplate, items, activePage, handleAddLabeledItem]);

  const getText = () => {
    return (
      <p
        style={{
          textAlign: 'center',
          background: '#097CC7',
          color: 'white',
          fontSize: 12,
          padding: 4,
          margin: 0,
        }}
      >
        {labeledItems.unusedLabelsCount} unused labels on this page
      </p>
    );
  };

  return (
    <>
      {cmBlockType !== CM_ITEM_TYPES.modular.Template && (
        <CollapsableMenuGroup
          dense
          hideChevron
          label={'Labeled Item'}
          icon={<FontAwesomeIcon fontSize={18} icon={faInputText} />}
          sx={{ paddingLeft: 1.5, '& .MuiListItemText-dense': { pl: 1 } }}
        >
          {getText()}
          {labeledItems.labeledItems}
        </CollapsableMenuGroup>
      )}
    </>
  );
}

export default AddLabeledItemMenuItem;
