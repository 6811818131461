import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { FieldTooltip, ModalSelectField } from 'components/Fields';
import { BoxInput } from 'components/Styled';
import { conversion } from 'helpers';
import { SettingsTable } from './components/SettingsTable';

export const Setting = ({ label, input, meta, ...other }) => {
  let error = '';

  if (meta.error || meta.submitError) {
    error = meta.submitError || meta.error;
  }

  let tooltip = false;
  let form = useForm();
  const field = form.getFieldState(input.name);
  if (field && field.data && field.data.tooltip) {
    tooltip = field.data.tooltip;
  }

  const getStartAdornment = () => {
    if (tooltip) {
      return <FieldTooltip tooltip={tooltip} />;
    }
    return '';
  };

  const onTableRowClick = (row) => {
    input.onChange({
      id: row.id,
      name: conversion.getName(row),
    });
  };

  return (
    <BoxInput>
      <ModalSelectField
        startAdornment={getStartAdornment()}
        setValue={(value) => {
          input.onChange({
            id: value.id,
            name: conversion.getName(value),
          });
        }}
        name={input.name}
        error={!!error}
        label={label}
        title={label}
        onTableRowClick={onTableRowClick}
        ModalContent={SettingsTable}
        modalProps={{
          title: label,
        }}
        {...other}
      />
    </BoxInput>
  );
};

Setting.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  hinttext: PropTypes.string,
  options: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
};
