import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ConfirmDialog } from 'components/Common';
import {
  useUpdateCommissionMutation,
  useGetTransactionCommissionsQuery,
  useGetTransactionQuery,
  transactionsAPI,
} from 'redux/rtk-query';

export const OverviewButtons = () => {
  const [closingAllCommissions, setClosingAllCommissions] =
    React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const dispatch = useDispatch();
  const { contactDetailsId, transactionDetailsId } = useParams();
  const relatedModelId = contactDetailsId || transactionDetailsId;
  const relatedModelType = contactDetailsId ? 'contact' : 'transaction';

  const { data: transactionData } = useGetTransactionQuery(
    { id: relatedModelId },
    { skip: relatedModelType === 'contact' },
  );

  const { data: commissions } = useGetTransactionCommissionsQuery({
    uuid: transactionData?.data?.uuid,
    period: transactionData?.data?.period,
  });

  const [updateCommission] = useUpdateCommissionMutation();

  const handleConfirm = async () => {
    if (commissions?.data?.items?.length) {
      setIsProcessing(true);
      try {
        const promises = commissions.data.items.map((commission) =>
          updateCommission({
            id: commission.id,
            closedApproved: true,
          }).unwrap(),
        );

        await Promise.all(promises);
        // Invalidate tags after all updates have been completed
        dispatch(transactionsAPI.util.invalidateTags(['Commissions']));
      } catch (error) {
        console.error('Failed to close commissions:', error);
        // You can add error handling UI here if needed
      } finally {
        setIsProcessing(false);
        setClosingAllCommissions(false);
      }
    } else {
      setClosingAllCommissions(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setClosingAllCommissions(true)}
        color="primary"
        disabled={isProcessing}
      >
        Close All Commissions
      </Button>
      <ConfirmDialog
        title="Close Approve All Commissions?"
        message="Are you sure you want to close approve all commissions for this transaction?"
        open={closingAllCommissions}
        setOpen={setClosingAllCommissions}
        onConfirm={handleConfirm}
        disabled={isProcessing}
      />
    </>
  );
};
