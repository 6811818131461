import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SideDrawer } from 'components/Common';
import { UPDATE_OFFER } from 'consts';
import { omitNull } from 'helpers';
import { useDrawer } from 'hooks';
import {
  useGetTransactionQuery,
  useUpdateTransactionMutation,
} from 'redux/rtk-query';
import { defaultValues } from '../TransactionForm/defaultValues';
import { processFormData } from '../TransactionForm/formData';
import { TransactionForm } from '../TransactionForm/TransactionForm';

export const EditOfferTransactionPage = ({ params }) => {
  const [initialValues, setInitialValues] = useState({});
  const { isOpen, closeDrawer } = useDrawer(true, UPDATE_OFFER());

  const paramName = Object.keys(params)[0];
  let offerEditId = params[paramName];
  const { data: transactionData } = useGetTransactionQuery(
    { id: offerEditId },
    { skip: !offerEditId },
  );

  const [updateTransaction] = useUpdateTransactionMutation();

  useEffect(() => {
    if (transactionData?.data) {
      let data = { ...transactionData?.data };
      data.agent = data?.modelAccess?.owner;
      data.shareWithIds = data?.modelAccess?.sharedWithUsers;
      data.companyStructureUnits = data?.modelAccess?.sharedWithUnits;
      data.timeframeCalculation = {
        timeframeCalculation:
          'Calendar Days, Ends at Midnight, Skip if ends Weekends, Holidays and Observed Holidays and ends at 11:59pm',
      };
      data.outsideReferral = data.outsideReferral ? 'Yes' : 'No';
      data.addressConfirmed =
        data.address && data.address.street1 && data.address.street1.length > 0;
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull(data),
      });
    }
  }, [transactionData]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    closeDrawer();
    return await updateTransaction(formData);
  };

  const afterSave = () => {
    closeDrawer();
  };

  return (
    <SideDrawer width={'60%'} isOpen={isOpen}>
      <TransactionForm
        title={'Edit Offer'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditOfferTransactionPage.propTypes = {
  params: PropTypes.object,
};
