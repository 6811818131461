import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { Chip } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { BaseDialogWrapper } from 'components/ModelViews';

export const FileChip = ({ file }) => {
  const [showPreview, setShowPreview] = useState(false);
  return (
    <>
      <Chip
        variant="outlined"
        size="small"
        label={
          <>
            <FontAwesomeIconComp
              color={'#d50808'}
              icon={faFilePdf}
              fixedWidth
            />
            {file.originalname ? file.originalname : file.name}
          </>
        }
        onClick={() => {
          setShowPreview(!showPreview);
        }}
      />
      <BaseDialogWrapper
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
      >
        <div>
          <img style={{ width: '100%', height: 'auto' }} src={file.url} />
        </div>
      </BaseDialogWrapper>
    </>
  );
};

FileChip.propTypes = {
  file: PropTypes.object,
};
