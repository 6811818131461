import React, { useState } from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import { Field, Form } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { RightsPermissions, Select, TextInput } from 'components/Fields';
import { ToggleFieldsButton } from 'components/Forms';
import { CreateLayoutHeader } from 'components/Layouts';
import { CreateBox } from 'components/Styled';
import { CalculationPeriod, CalculationType, RIGHT_OPTIONS } from 'consts';
import { getSelectOptions } from 'helpers/form';
import { useFormSubmitHandler } from 'hooks';
import { useGetProfileQuery } from 'redux/rtk-query';
import { CommissionLevels } from './components/CommissionLevels';

export const CappingForm = ({
  afterSave,
  handleSubmitFunc,
  initialValues,
  title,
  nameButton,
  isLoading,
}) => {
  const { data: { data: { permissions } = {} } = {} } = useGetProfileQuery();

  const [showAllFields, setShowAllFields] = useState(false);

  // const [validate] = useValidateTransactionMutation();
  //
  // const debouncedCheck = useDebouncedFormCheck(validate, processFormData);
  const onSubmit = useFormSubmitHandler(handleSubmitFunc, afterSave);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form
          mutators={{
            setFieldData,
            ...arrayMutators,
          }}
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <CreateBox>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <CreateLayoutHeader
                  title={title}
                  nameButton={nameButton}
                  afterSave={afterSave}
                >
                  <ToggleFieldsButton
                    showAllFields={showAllFields}
                    setShowAllFields={setShowAllFields}
                    permissions={permissions}
                  />
                  <RightsPermissions
                    defaultValue={RIGHT_OPTIONS.agentOnly.value}
                  />
                </CreateLayoutHeader>
                {isLoading ? (
                  'Loading'
                ) : (
                  <Grid container spacing={2} style={{ padding: '0 16px' }}>
                    <Grid item xs={12}>
                      <FormPanel startOpen>
                        <Field
                          name="name"
                          label={'Name'}
                          component={TextInput}
                        />
                        <Field
                          name="calculationType"
                          label={'Calculation Type'}
                          options={getSelectOptions(CalculationType)}
                          component={Select}
                        />
                        <Field
                          name="calculationPeriod"
                          label={'Calculation Period'}
                          options={getSelectOptions(CalculationPeriod)}
                          component={Select}
                        />
                        <CommissionLevels />
                      </FormPanel>
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}></Grid>
                  </Grid>
                )}
              </form>
            </CreateBox>
          )}
        />
      </Grid>
    </Grid>
  );
};

CappingForm.propTypes = {
  afterSave: PropTypes.func,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  nameButton: PropTypes.string,
  isLoading: PropTypes.bool,
};
