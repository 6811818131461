import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SideDrawer } from 'components/Common';
import { useDrawer } from 'hooks';
import { ClickMarketingGallery } from './components/ClickMarketingGallery';

export const MarketingCreateView = ({ createType, onClose }) => {
  const { isOpen, openDrawer, closeDrawer } = useDrawer(!!createType);

  useEffect(() => {
    if (createType) {
      openDrawer();
    }
  }, [createType]);
  const handleClose = () => {
    closeDrawer(onClose);
  };

  return (
    <SideDrawer isOpen={isOpen} width={'80%'}>
      {createType === 'Click Marketing' && (
        <ClickMarketingGallery handleClose={handleClose} />
      )}
    </SideDrawer>
  );
};

MarketingCreateView.propTypes = {
  createType: PropTypes.string,
  onClose: PropTypes.func,
};
