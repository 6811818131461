import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { TaskListModal } from 'components/ModelViews/Tasks';

export const TaskCount = ({ value }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TaskListModal open={open} closeHandler={setOpen} relatedModel={value} />
      <Button
        style={{ minWidth: 20 }}
        fullWidth
        size={'small'}
        onClick={() => {
          setOpen(true);
        }}
      >
        {value.tasks}
      </Button>
    </>
  );
};

TaskCount.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    tasks: PropTypes.number,
  }),
  id: PropTypes.number,
};
