import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faThumbsDown } from '@fortawesome/pro-regular-svg-icons';
import { REPRESENTING_OPTIONS, STATUS_OPTIONS, PM_FILE_OPTIONS } from 'consts';

export const lookForParcelByAddress = async (form, parcelData) => {
  if (parcelData) {
    if (parcelData?.length === 1) {
      let parcelDataItem = parcelData[0];
      form.change('parcel', {
        id: parcelDataItem.hjid,
        name: parcelDataItem.apn,
        addressDetails: parcelDataItem.addressDetails,
      });
      form.change('addressConfirmed', true);
    } else if (parcelData?.length > 1) {
      form.change('parcel', { id: '', name: '' });
      form.mutators.setFieldData('parcel', {
        tooltip: {
          title: 'Multiple Parcels Found',
          icon: faExclamationTriangle,
          color: 'orange',
        },
      });
      form.registerField(
        'parcel',
        (fieldState) => {
          // eslint-disable-next-line no-unused-vars
          const { tooltip } = fieldState.data; // true
        },
        {
          // ...other subscription items
          data: true,
        },
      );
      form.mutators.setFieldData(`${name}.street2`, {
        warning: `${parcelData.data.length} records found. Enter Unit to narrow results and search again.`,
      });
      form.registerField(
        `${name}.street2`,
        (fieldState) => {
          // eslint-disable-next-line no-unused-vars
          const { warning } = fieldState.data; // true
        },
        {
          // ...other subscription items
          data: true,
        },
      );
    } else {
      form.change('addressConfirmed', true);
      form.change('parcel', null);
      form.registerField(
        'parcel',
        (fieldState) => {
          // eslint-disable-next-line no-unused-vars
          const { tooltip } = fieldState.data; // true
        },
        {
          // ...other subscription items
          data: true,
        },
      );
      form.mutators.setFieldData('parcel', {
        tooltip: {
          title: 'No Parcel data found',
          icon: faThumbsDown,
          color: '#c9232d',
        },
      });

      form.registerField(
        'mlsListing',
        (fieldState) => {
          // eslint-disable-next-line no-unused-vars
          const { tooltip } = fieldState.data; // true
        },
        {
          // ...other subscription items
          data: true,
        },
      );
      form.mutators.setFieldData('mlsListing', {
        tooltip: {
          title: 'No MLS Listing Found',
          icon: faThumbsDown,
          color: '#c9232d',
        },
      });
    }
  }
};

export const getMlsParams = (values) => {
  if (values.parcel?.addressDetails?.components) {
    let addressDetails = values.parcel.addressDetails;
    let addressComponents = addressDetails.components;
    let deliveryLine = addressComponents.primaryNumber;
    if (addressComponents.predirectional) {
      deliveryLine = deliveryLine + ' ' + addressComponents.predirectional;
    }
    deliveryLine = deliveryLine + ' ' + addressComponents.streetName;
    if (addressComponents.suffix) {
      deliveryLine = deliveryLine + ' ' + addressComponents.suffix;
    }
    return {
      parcelNumber:
        values.parcel && values.parcel.name ? values.parcel.name : 'null',
      deliveryLine: deliveryLine,
      city: values.address.city,
      state: values.address.state,
      zip: values.address.zipCode,
    };
  }
  return {
    parcelNumber: '',
    deliveryLine: values.address?.street1,
    city: values.address?.city,
    state: values.address?.state,
    zip: values.address?.zipCode,
  };
};

export const lookForMls = async (form, mlsData) => {
  if (mlsData && mlsData?.items[0]) {
    let hjid = mlsData.items[0].hjid;
    let id = mlsData.items[0].id;
    let count = mlsData.totalItems;
    form.change('mlsListing', {
      id: id,
      name: hjid,
    });
    if (count > 1) {
      form.mutators.setFieldData('mlsListing', {
        tooltip: {
          title: 'Multiple MLS Listings Found',
          icon: faExclamationTriangle,
          color: 'orange',
        },
      });
      form.registerField(
        'mlsListing',
        (fieldState) => {
          // eslint-disable-next-line no-unused-vars
          const { tooltip } = fieldState.data; // true
        },
        {
          // ...other subscription items
          data: true,
        },
      );
    }
  } else {
    form.mutators.setFieldData('mlsListing', {
      tooltip: {
        title: 'No MLS Listing Found',
        icon: faThumbsDown,
        color: '#c9232d',
      },
    });
    form.registerField(
      'mlsListing',
      (fieldState) => {
        // eslint-disable-next-line no-unused-vars
        const { tooltip } = fieldState.data; // true
      },
      {
        // ...other subscription items
        data: true,
      },
    );
  }

  // if (error.response) {
  //     form.change("mlsListing", null)
  //     form.mutators.setFieldData("mlsListing", {
  //         tooltip:
  //             {
  //                 title: `There was an error: ${error.response.status}`,
  //                 icon: faThumbsDown,
  //                 color: "#c9232d"
  //             }
  //     })
  //     form.registerField(
  //         'mlsListing',
  //         fieldState => {
  //             // eslint-disable-next-line no-unused-vars
  //             const {tooltip} = fieldState.data // true
  //         },
  //         {
  //             // ...other subscription items
  //             data: true
  //         }
  //     )
  // }
};

export const getCappingType = (transactionData) => {
  if (transactionData === undefined) return;
  if (
    transactionData.representing.includes(REPRESENTING_OPTIONS.BUYER.value) ||
    transactionData.representing.includes(REPRESENTING_OPTIONS.SELLER.value)
  ) {
    return 'sales';
  } else if (transactionData.status === STATUS_OPTIONS.PROPERTY_MGMT.value) {
    return 'propertyMgmt';
  } else if (
    (transactionData.representing.includes(
      REPRESENTING_OPTIONS.LANDLORD.value,
    ) ||
      transactionData.representing.includes(
        REPRESENTING_OPTIONS.TENANT.value,
      )) &&
    transactionData.pmFile !== PM_FILE_OPTIONS.PROPERTY_MGMT.value
  ) {
    return 'lease';
  } else if (transactionData.status === STATUS_OPTIONS.REFERRAL.value) {
    return 'general';
  }
};
