import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const InfiniteScroll = ({
  onBottomReached,
  page,
  totalPages,
  isFetching,
  children,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = ref.current;
      if (!isFetching && element && totalPages > page) {
        if (element.scrollHeight - element.scrollTop >= element.clientHeight) {
          onBottomReached(page + 1);
        }
      }
    };

    const element = ref.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [onBottomReached]);

  return (
    <div ref={ref} style={{ overflow: 'auto', height: '100%' }}>
      {children}
    </div>
  );
};

InfiniteScroll.propTypes = {
  onBottomReached: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number,
  isFetching: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
