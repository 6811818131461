import React from 'react';
import { Field } from 'react-final-form';
import { FormPanel } from 'components/Common';
import { AddressBox } from 'components/Fields';

export const SecondaryAddressBox = () => (
  <FormPanel label={'Secondary Address'}>
    <Field name={'secondaryAddress'} component={AddressBox} />
  </FormPanel>
);

SecondaryAddressBox.propTypes = {};
