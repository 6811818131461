import React, { useEffect, useState } from 'react';
import { SideDrawer } from 'components/Common';
import { useDrawer } from 'hooks';
import { CappingForm } from 'pages/CappingPages';
import { defaultValues } from 'pages/CappingPages/CappingForm/defaultValues';
import { processFormData } from 'pages/CappingPages/CappingForm/formData';
import { useCreateCappingMutation } from 'redux/rtk-query';

export const CreateCappingPage = () => {
  const [initialValues, setInitialValues] = useState({});
  const { isOpen, openDrawer, closeDrawer } = useDrawer();

  const [createCapping] = useCreateCappingMutation();

  useEffect(() => {
    setInitialValues(defaultValues);
    openDrawer();
  }, [0]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    return await createCapping(formData);
  };

  const afterSave = () => {
    closeDrawer();
  };

  return (
    <SideDrawer width={'45%'} isOpen={isOpen}>
      <CappingForm
        title={'Create Compensation'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};
