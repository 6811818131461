import React from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { MenuItemButton } from 'components/Common';
import { colors } from 'consts';
import { setImporting } from 'redux/slices';

const AddModularItemMenuItem = ({ modularType }) => {
  const dispatch = useDispatch();

  const handleImportTemplate = () => {
    dispatch(setImporting(modularType));
  };

  return (
    <MenuItemButton
      noHover
      label={modularType}
      onClick={handleImportTemplate}
      rightAction={
        <IconButton>
          <FontAwesomeIcon
            icon={faPlus}
            fontSize={16}
            color={colors.LIGHT_BLUE_1}
          />
        </IconButton>
      }
    />
  );
};

export default AddModularItemMenuItem;

AddModularItemMenuItem.propTypes = {
  modularType: PropTypes.string.isRequired,
};
