import { useMemo } from 'react';
import _ from 'lodash';

const getChildren = (item) => {
  if (!item.children || !item.children.length) {
    return item;
  }
  return [item, _.flatMapDeep(item.children, getChildren)];
};

export function useLoadHierarchyOptions(useGetListQuery) {
  const list = useGetListQuery();

  const options = useMemo(() => {
    const fullList = _.flatMapDeep(list?.data?.data || [], getChildren);
    return fullList.map(({ id, name }) => ({
      value: id,
      label: name,
      key: id,
    }));
  }, [list?.data?.data]);

  return options;
}
