import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';
import {
  currentDocumentSelectorCM as currentDocumentSelector,
  isTemplateViewSelectorCM as isTemplateViewSelector,
} from 'redux/selectors';
import {
  FileMenuGroup,
  ViewMenuGroup,
  ItemGroupMenu,
  AddItemsMenuGroup,
} from './menuItems';

export const PrimaryMenu = () => {
  const currentDocument = useSelector(currentDocumentSelector);
  const isTemplateView = useSelector(isTemplateViewSelector);
  return (
    <>
      <FileMenuGroup />
      <Divider />
      <ViewMenuGroup />
      <Divider />
      {isTemplateView && currentDocument.cmBlockType !== 'Template' && (
        <AddItemsMenuGroup />
      )}
      {(currentDocument.cmBlockType === 'Template' ||
        currentDocument?.documentTemplate?.cmBlockType === 'Template') && (
        <ItemGroupMenu />
      )}
    </>
  );
};
