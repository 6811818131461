import React from 'react';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { ClickMarketingGallery } from 'components/ModelViews/Marketing/MarketingCreateView/components/ClickMarketingGallery';
import { NewButtonBox } from 'components/Styled';

const NewMarketingButton = () => {
  const [showDrawer, setShowDrawer] = React.useState(false);

  const handleOpen = () => {
    setShowDrawer(true);
  };
  const handleClose = () => {
    setShowDrawer(false);
  };
  return (
    <>
      <NewButtonBox>
        <Button
          size={'small'}
          variant="text"
          onClick={handleOpen}
          endIcon={<FontAwesomeIconComp size="sm" icon={faCirclePlus} />}
        >
          New
        </Button>
        {/*  <MenuButton*/}
        {/*    size={'x-small'}*/}
        {/*    endIcon={<FontAwesomeIconComp size="sm" icon={faCirclePlus} />}*/}
        {/*    title="New"*/}
        {/*    closeOnClick*/}
        {/*  >*/}
        {/*    <MenuItem*/}
        {/*      onClick={() => {*/}
        {/*        setShowDrawer(true);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <ListItemIcon>*/}
        {/*        <MarketingItemIcon type={'Click Marketing'} />*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText primary="Click Marketing" />*/}
        {/*    </MenuItem>*/}
        {/*  </MenuButton>*/}
      </NewButtonBox>
      <SideDrawer isOpen={showDrawer} width={'80%'}>
        <Box p={1}>
          <ClickMarketingGallery handleClose={handleClose} />
        </Box>
      </SideDrawer>
    </>
  );
};

export default NewMarketingButton;
