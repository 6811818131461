import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { trim } from 'lodash';
import { classes, MuiTelInput } from 'mui-tel-input';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Box, styled } from '@mui/system';
import { FontAwesomeIconComp, Required } from 'components/Common';
import { BoxInput, ErrorMsg } from 'components/Styled';
import { getError, hasValue } from 'helpers';
import { useFormValidationProcess, useIsRequired } from 'hooks';

const StyledTelInput = styled(MuiTelInput)(({ showCountryCode }) => ({
  ['& .MuiInputAdornment-positionStart']: {
    display: showCountryCode ? 'flex' : 'none',
  },
  ['& .MuiInputBase-root']: {
    paddingLeft: showCountryCode ? null : 40,
  },
  [`& .${classes.flagImg}`]: {
    maxWidth: 20,
    height: 'auto',
  },
  "& [data-lastpass-icon-root='true']": {
    display: 'none',
  },
}));

export const PhoneNumber = ({ input, label, meta }) => {
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [shrink, setShrink] = useState(false);
  const isFilled = hasValue(input.value);
  const isRequired = useIsRequired(input.name) && !isFilled;
  const validateFormData = useFormValidationProcess(); // Use the custom hook
  const error = getError(meta);

  const handleBlur = async () => {
    if (!input.value || trim(input.value) === '+1') {
      setShrink(false);
      input.onChange('');
    }
    await validateFormData();
  };

  useEffect(() => {
    if (input.value) {
      setShrink(true);
    }
  }, [input.value]);

  return (
    <BoxInput>
      {!showCountryCode && shrink && (
        <Box position="absolute" zIndex="1" left={0} top="15px" pl={1}>
          <Tooltip title={'Show Country Code'}>
            <IconButton
              className={'showCountryCode'}
              onClick={() => setShowCountryCode(true)}
              size="small"
            >
              <FontAwesomeIconComp icon={faGlobe} fontSize={'1.6rem'} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <StyledTelInput
        showCountryCode={showCountryCode}
        forceCallingCode
        label={
          <>
            {label}
            {isRequired ? <Required /> : ''}
          </>
        }
        formControlProps={{
          fullWidth: true,
        }}
        defaultCountry={'US'}
        onChange={input.onChange}
        value={input.value}
        size={'small'}
        onFocus={() => setShrink(true)}
        onBlur={handleBlur}
        InputLabelProps={{
          error: !!error,
          shrink: shrink,
        }}
        error={!!error}
      />
      {meta.touched && error && <ErrorMsg component={'span'}>{error}</ErrorMsg>}
    </BoxInput>
  );
};

PhoneNumber.defaultProps = {
  size: 'small',
  disabled: false,
};

PhoneNumber.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  iconStart: PropTypes.object,
  iconEnd: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
};
