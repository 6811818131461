import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { activeFieldIdsSelectorCM } from 'redux/selectors'; // Adjust the import path as necessary

/**
 * Custom hook to determine if a given itemId is active.
 *
 * @param {string} itemId - The ID of the item to check.
 * @returns {boolean} - True if the item is active, false otherwise.
 */
const useIsActive = (itemId) => {
  const activeItems = useSelector(activeFieldIdsSelectorCM);
  return activeItems.includes(itemId);
};

useIsActive.propTypes = {
  itemId: PropTypes.string.isRequired,
};

export default useIsActive;
