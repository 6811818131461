import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Grid } from '@mui/material';
import { Cells } from 'components/Common/AuditLogDrawer/components/AuditLogList/config';
import { Table } from 'components/Common/Table';
import { conversion } from 'helpers';
import { useTableData } from 'hooks';
import {
  useGetContactAuditLogsQuery,
  useGetTransactionAuditLogsQuery,
} from 'redux/rtk-query';

const AuditRow = ({ log }) => {
  return (
    <>
      <Grid item>
        {DateTime.fromISO(log.createdAt).toLocaleString(
          DateTime.DATETIME_SHORT,
        )}
      </Grid>
      <Grid item>{log.action}</Grid>
      <Grid item>{conversion.getName(log.agent)}</Grid>
    </>
  );
};

AuditRow.propTypes = {
  log: PropTypes.object,
};

export const AuditLogList = ({ modelType, uuid }) => {
  const auditQuery =
    modelType === 'Contact'
      ? useGetContactAuditLogsQuery
      : modelType === 'Transaction'
        ? useGetTransactionAuditLogsQuery
        : null;
  const { page, setPage, totalPages, totalCount, rows } = useTableData(
    auditQuery,
    [],
    'createdAt',
    'asc',
    5,
    {
      uuid: uuid,
    },
  );

  return (
    <div>
      <Table
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        cells={Cells}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

AuditLogList.propTypes = {
  modelType: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};
