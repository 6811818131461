import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { TextInput } from 'components/Fields';
import { CONTACT_TYPES } from 'consts';

export const NameBox = ({ showAllFields }) => {
  const { getState } = useForm();

  return showAllFields ||
    [
      CONTACT_TYPES.LEAD.value,
      CONTACT_TYPES.CUSTOMER.value,
      CONTACT_TYPES.COOPERATING_AGENT.value,
      CONTACT_TYPES.CLOSING_ESCROW_AGENT.value,
      CONTACT_TYPES.LENDER.value,
      CONTACT_TYPES.COMPANY_AGENT.value,
      CONTACT_TYPES.COMPANY_ADMIN.value,
      CONTACT_TYPES.COMPANY_REFERRAL_AGENT.value,
    ].includes(getState().values.type) ? (
    <Grid container>
      <Grid item xs={6}>
        <Field name="firstName" label="First Name" component={TextInput} />
      </Grid>
      <Grid item xs={6}>
        <Field name="middleName" label="Middle Name" component={TextInput} />
      </Grid>
      <Grid item xs={6}>
        <Field name="lastName" label="Last Name" component={TextInput} />
      </Grid>
      <Grid item xs={6}>
        <Field name="nickname" label="Nickname" component={TextInput} />
      </Grid>
    </Grid>
  ) : null;
};

NameBox.propTypes = {
  showAllFields: PropTypes.bool,
};
