import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { FilePreview, LabeledOutline } from 'components/Common';
import { FileInput } from 'components/Fields';
import { FileInputBox } from 'components/Fields/FileInput/styled';

export const DropFileInput = ({ input, label }) => {
  const setFilesValues = (files) => {
    input.onChange(files);
  };

  const filesValues = useMemo(
    () => (input.value ? [input.value] : []),
    [input.value],
  );

  return (
    <LabeledOutline label={label}>
      <FileInputBox id={`drop-${input.name}`}>
        <FileInput
          setFilesValues={setFilesValues}
          name={input.name}
          dropZoneId={`drop-${input.name}`}
        />
        {filesValues.length > 0 && (
          <Grid style={{ paddingTop: 20 }} container>
            {filesValues.map((file) => {
              return (
                <FilePreview
                  key={file.name}
                  file={file}
                  removeFile={() => {}}
                />
              );
            })}
          </Grid>
        )}
      </FileInputBox>
    </LabeledOutline>
  );
};

DropFileInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};
