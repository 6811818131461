import React from 'react';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItemButton, FontAwesomeIconComp } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { copyItemToPage } from 'redux/slices';

const DuplicateMenuItem = () => {
  const dispatch = useDispatch();
  const activeField = useSelector(selectCurrentItem);

  const handleCopyToPageClick = () => {
    dispatch(copyItemToPage(activeField));
  };

  return (
    <MenuItemButton
      icon={<FontAwesomeIconComp fontSize={18} icon={faCopy} />}
      label={'Copy to Page'}
      onClick={handleCopyToPageClick}
    />
  );
};

export default DuplicateMenuItem;
