import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { roleSelector } from 'redux/selectors';
import { getSignatureByRole } from '../../../../utils/field';

export const PrintName = ({ field }) => {
  const documentRoles = useSelector(roleSelector);
  let signature = getSignatureByRole(field, documentRoles);

  return (
    <Box zIndex={1} position={'relative'} height={'100%'}>
      {signature}
    </Box>
  );
};

PrintName.propTypes = {
  field: PropTypes.object.isRequired,
};
