import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { axiosBaseQuery } from 'utils/baseQuery';
import { vendorUrlBuilder } from './urlBuilder/vendors';

export const vendorsAPI = createApi({
  reducerPath: 'vendorsAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['vendors'],
  endpoints: (build) => ({
    getVendorsType: build.query({
      query: (args) => ({
        url: vendorUrlBuilder.getVendorTypesList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['vendors'],
    }),
  }),
});

export const { useGetVendorsTypeQuery } = vendorsAPI;
