import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import ColorPicker from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ColorPicker';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

export const colorOptions = ['#231F20', '#4a494b', '#C9222E', '#fffffe'];

const MainframeThemeColorOptionsMenuItem = () => {
  const dispatch = useDispatch();
  const item = useSelector(selectCurrentItem);
  const [color, setColor] = useState(
    item.style.mainframeThemeColor || '#FFFFFF',
  );

  useEffect(() => {
    if (item) {
      setColor(item.style.mainframeThemeColor || '#FFFFFF');
    }
  }, [item]);

  const handleColorChange = useCallback(
    (newColor) => {
      const updatedColor = newColor.hex ? newColor.hex : newColor;
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            style: {
              ...item.style,
              mainframeThemeColor: updatedColor,
            },
          },
        }),
      );
      setColor(updatedColor);
    },
    [dispatch, item],
  );

  return (
    <Box marginTop={2}>
      <Typography variant="subtitle2" marginBottom={1}>
        Mainframe Theme Color
      </Typography>
      <ColorPicker
        colors={colorOptions}
        color={color}
        defaultColor="#FFFFFF"
        onChange={handleColorChange}
      />
    </Box>
  );
};

export default React.memo(MainframeThemeColorOptionsMenuItem);
