import React, { useCallback } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, InputAdornment, Box } from '@mui/material';
import { CollapsableMenuGroup } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const BorderRadiusMenuItem = () => {
  const dispatch = useDispatch();
  const item = useSelector(selectCurrentItem);

  const handleBorderRadiusChange = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            style: {
              ...item.style,
              borderRadius: `${value}%`,
            },
          },
        }),
      );
    },
    [dispatch, item],
  );

  if (!item) return null;

  return (
    <CollapsableMenuGroup
      hideChevron
      label="Border Radius"
      icon={<FontAwesomeIcon icon={faCircle} />}
    >
      <Box padding={2}>
        <TextField
          value={item.style?.borderRadius?.toString().replace('%', '') || ''}
          onChange={handleBorderRadiusChange}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            style: { width: '112px' },
          }}
          inputProps={{
            min: '0',
            max: '50',
            step: '1',
          }}
          variant="outlined"
        />
      </Box>
    </CollapsableMenuGroup>
  );
};

export default React.memo(BorderRadiusMenuItem);
