import React from 'react';
import { faEraser } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@mui/material';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import { activeFieldsSelector } from 'redux/selectors';
import { updateField } from 'redux/slices';

export function WhiteBackground() {
  const dispatch = useDispatch();
  const activeFields = useSelector(activeFieldsSelector);
  const selectedField = activeFields[0];

  return (
    <MenuItemButton
      label={'White Background'}
      icon={<FontAwesomeIconComp fontSize={18} icon={faEraser} />}
      rightAction={
        <Switch
          checked={selectedField.fieldTransparency}
          onChange={(e) => {
            dispatch(
              updateField({
                properties: { fieldTransparency: e.target.checked },
              }),
            );
          }}
        />
      }
    />
  );
}
