import React from 'react';
import { OwnerInvoicesCard, TenantInvoicesCard } from './components';

function PropertyMgmtInvoicesCards() {
  return (
    <>
      <OwnerInvoicesCard />
      <TenantInvoicesCard />
    </>
  );
}

export default PropertyMgmtInvoicesCards;
