import React from 'react';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconComp } from 'components/Common/index';

export const SearchFilter = () => {
  return (
    <FontAwesomeIconComp
      icon={faFilter}
      fontSize={'1.2rem'}
      style={{ color: '#969696' }}
    />
  );
};
