import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from 'consts';
import { useCreateRoleMutation } from 'redux/rtk-query';
import RoleForm, { defaultValues } from '../RoleForm';

export const CreateRolePage = () => {
  const [createRole, { isSuccess, isLoading }] = useCreateRoleMutation();
  const navigate = useNavigate();

  const onSubmit = ({ parent, ...values }) => {
    createRole({ ...values, parentId: parent ? parent.value : null });
  };

  const afterSave = () => {
    navigate(ADMIN_ROUTES.ROLES.path);
  };

  return (
    <RoleForm
      initialValues={defaultValues}
      handleSubmit={onSubmit}
      title={'Create Role'}
      isLoading={isLoading}
      isSuccess={isSuccess}
      afterSave={afterSave}
    />
  );
};
