import React from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import {
  Header,
  HeaderSubtitle,
  HeadingSection,
  HeadingSectionStack,
} from 'components/Styled';
import { conversion } from 'helpers';
import { TasksList } from '../TasksList';

export const TaskListModal = ({ closeHandler, open, relatedModel }) => {
  const subTitle = conversion.getName(relatedModel);

  return (
    <SideDrawer width={'40%'} isOpen={open}>
      <HeadingSection>
        <HeadingSectionStack>
          <Header variant="h4">Tasks</Header>
          <HeaderSubtitle>{subTitle}</HeaderSubtitle>
        </HeadingSectionStack>
        <FontAwesomeIconComp
          icon={faCircleXmark}
          style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
          onClick={() => closeHandler(false)}
        />
      </HeadingSection>
      <TasksList relatedModelType={'contact'} relatedModel={relatedModel} />
    </SideDrawer>
  );
};

TaskListModal.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  contactId: PropTypes.number,
  relatedModel: PropTypes.object,
};
