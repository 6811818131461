import React from 'react';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { Collapse, Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';

export const ShowMoreCollapse = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Collapse in={open}>
        {children}
        <Divider />
      </Collapse>
      <Box textAlign={'center'}>
        <IconButton size={'small'} onClick={() => setOpen(!open)}>
          <FontAwesomeIconComp
            fontSize={'1.4rem'}
            icon={open ? faChevronUp : faChevronDown}
          />
        </IconButton>
      </Box>
    </>
  );
};

ShowMoreCollapse.propTypes = {
  children: PropTypes.node.isRequired,
};
