import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useDrawer(initialOpen = false, pathTemplate = null) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const openDrawer = () => setIsOpen(true);
  const closeDrawer = (afterFunction, skipRedirect = false) => {
    setIsOpen(false);
    pathTemplate = pathTemplate.replace('*', '');
    if (
      pathTemplate &&
      location.pathname.includes(pathTemplate.split('/:')[0])
    ) {
      const basePath = location.pathname.split(pathTemplate.split('/:')[0])[0];
      setTimeout(() => {
        if (!skipRedirect) {
          navigate(basePath || '/', { replace: true });
        }
        if (typeof afterFunction === 'function') {
          afterFunction();
        }
      }, 500);
    } else if (!pathTemplate) {
      if (typeof afterFunction === 'function') {
        setTimeout(afterFunction, 500);
      }
    }
  };

  useEffect(() => {
    if (initialOpen) {
      setTimeout(openDrawer, 200);
    }
  }, [initialOpen]);

  return { isOpen, openDrawer, closeDrawer };
}
