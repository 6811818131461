import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DocumentCardContext = createContext();

export const DocumentCardProvider = ({ children }) => {
  const [closePreview, setClosePreview] = useState(false);
  const [topFolder, setTopFolder] = useState(null);
  const [refetchDocuments, setRefetchDocuments] = useState(() => () => {});
  const [isForcedRefresh, setIsForcedRefresh] = useState(false);
  const [statusFilter, setStatusFilter] = useState([
    'Draft',
    'In Progress',
    'Completed',
    'Revise',
  ]);

  return (
    <DocumentCardContext.Provider
      value={{
        topFolder,
        setTopFolder,
        statusFilter,
        setStatusFilter,
        refetchDocuments,
        setRefetchDocuments,
        isForcedRefresh,
        setIsForcedRefresh,
        closePreview,
        setClosePreview,
      }}
    >
      {children}
    </DocumentCardContext.Provider>
  );
};

DocumentCardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
