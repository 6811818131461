import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmMenuItem } from 'components/Common';

export const DeleteConfirmMenuItem = ({ onConfirm, onBackToMenu }) => {
  return (
    <ConfirmMenuItem
      title="Delete"
      message={'Are you sure you want to delete this item?'}
      onConfirm={onConfirm}
      onCancel={onBackToMenu}
      confirmText={'Confirm'}
      confirmColor={'error'}
      cancelText={'Go Back'}
    />
  );
};

DeleteConfirmMenuItem.propTypes = {
  onConfirm: PropTypes.func,
  onBackToMenu: PropTypes.func,
};
