import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import { RIGHT_OPTIONS } from 'consts';
import { usePrevious } from 'hooks';
import { useCreateContactMutation, useGetProfileQuery } from 'redux/rtk-query';
import ContactForm from '../ContactForm/ContactForm';
import { defaultValues } from '../ContactForm/defaultValues';
import { processFormData } from '../ContactForm/formData';

export const CreateContactPage = ({ afterSaveAction, createType, path }) => {
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [createContact] = useCreateContactMutation();
  const { data } = useGetProfileQuery();

  const location = useLocation();
  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    setInitialValues({
      ...defaultValues,
      agent: data?.data,
      type: typeof createType === 'string' ? createType : null,
      companyStructureUnits: [],
      shareWithOption: RIGHT_OPTIONS.agentOnly.value,
    });
    setIsOpen(true);
  }, [0]);

  const handleSubmitFunc = async (values, validating = false) => {
    let contactData = processFormData(values);
    const contact = await createContact({ ...contactData, validating });
    if (afterSaveAction && !validating) {
      if (contact.data.success) {
        afterSaveAction(contact.data.data);
      }
    }
    return contact;
  };

  const afterSave = () => {
    setIsOpen(false);
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      setTimeout(() => {
        navigate(location.pathname.replace(path, ''));
      }, 500);
    }
  }, [isOpen]);

  return (
    <SideDrawer width={'80%'} isOpen={isOpen}>
      <ContactForm
        title={'Create Contact'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

CreateContactPage.propTypes = {
  afterSaveAction: PropTypes.func,
  createType: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  path: PropTypes.string,
};
