import React from 'react';
import { useField } from 'react-final-form';
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { LabeledOutline } from 'components/Common';

const DateFields = () => {
  const [postExpires, setPostExpires] = React.useState(false);
  const { input: startInput } = useField('startDate');
  const { input: endInput } = useField('endDate');

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setPostExpires(true);
      startInput.onChange(new Date());
    } else {
      setPostExpires(false);
      startInput.onChange(null);
    }
  };

  return (
    <LabeledOutline label={'Occurs On'}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!startInput.value}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="This post expires?"
              />
            </Grid>
            {!postExpires && (
              <Grid item xs={6}>
                <DatePicker
                  label="Start Date"
                  value={startInput.value || null}
                  onChange={startInput.onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { backgroundColor: '#ffffff' },
                      }}
                      size={'small'}
                      fullWidth
                      margin="dense"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <DatePicker
                label="End Date"
                value={endInput.value || null}
                onChange={endInput.onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { backgroundColor: '#ffffff' },
                    }}
                    size={'small'}
                    fullWidth
                    margin="dense"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    </LabeledOutline>
  );
};

export default DateFields;
